import * as React from "react"
import { connect } from "react-redux"
import { RootState } from "../../../../store"
import { FC, useEffect } from "react"
import {
  UserExpenseForm,
  Affiliate,
  AdminUserModalsContainerProps
} from "../../../../types"
import {
  fetchUserExpenseData,
  paymentMethodsAdminEntry,
  saveUserExpenseData,
  selectUser,
  fetchAffiliateList
} from "../../../../actions/adminActions"
import {
  AdminUserModalOptions,
  USER_ROLE,
  errorFilledToastOptions,
  successFilledToastOptions
} from "../../../../constants"
import AdminPaymentMethodModal from "./modals/AdminPaymentMethodModal"
import AdminAffiliateFromUserModal from "./modals/AdminAffiliateFromUserModal"
import AdminAffiliateToUserModal from "./modals/AdminAffiliateToUserModal"
import { toast } from "react-toastify"
import { affiliate } from "../../../../apis"
import { mapListToSnakeCase } from "../../../../utility"
import { fetchUserData } from "../../../../actions"
import AdminUserCreditModal from "./modals/AdminUserCreditModal"
import AdminNotificationSendModal from "./modals/AdminNotificationSendModal"
import AdminUserAdvanceModal from "./modals/AdminUserAdvanceModal"
import AdminUserExpenseModal from "./modals/AdminUserExpenseModal"
import ReloadModal from "./modals/ReloadModal"

const AdminUserModalsContainer: FC<AdminUserModalsContainerProps> = (props) => {
  const { modalOption, setModalOption } = props
    const isSuperUser = props.loggedInUser?.roleId === USER_ROLE.SuperAdmin
  
    useEffect(() => {
      if (isSuperUser && modalOption === AdminUserModalOptions.USER_EXPENSE && props.user?.id) {
        props.fetchUserExpenseData(props.user?.id)
      }
    }, [modalOption])

    useEffect(() => {
      if (isSuperUser && props.user?.id) {
        props.fetchAffiliateList(props.user.id)
      }
    }, [props.user?.id])

  const onClose = () => {
    setModalOption(undefined)
    //props.selectUser(null)
  }

  const saveAffiliate = async (affiliateList: Affiliate[]) => {
    if (!props.user?.id) {
      return
    }

    try {
      const response = await affiliate(`/`, "post", {
        from_user_id: props.user.id,
        affiliate_list: mapListToSnakeCase(affiliateList)
      })
      if (response.status === 200) {
        toast.success("Affiliate saved successfully", successFilledToastOptions)
        setModalOption(undefined)
        props.fetchAffiliateList(props.user.id)
      } else {
        toast.error("Error saving affiliate", errorFilledToastOptions)
      }
    } catch (err) {
      toast.error("Error saving affiliate", errorFilledToastOptions)
    }
  }

    const onUserExpenseFormSubmit = async (values: UserExpenseForm) => {
      props.saveUserExpenseData(values)
    }

  if (!props.user) return null

  return (
    <div>
      <ReloadModal
        isOpen={modalOption === AdminUserModalOptions.RELOAD}
        handleClose={onClose}
        refreshSummaryForUser={true}
        />
      <AdminPaymentMethodModal
        open={modalOption === AdminUserModalOptions.PAYMENT_METHODS}
        onClose={onClose}
        paymentMethodsAdminEntry={props.paymentMethodsAdminEntry}
        userId={props.user?.id}
      />
      <AdminUserCreditModal
        open={modalOption === AdminUserModalOptions.USER_CREDITS}
        onClose={onClose}
        user={props?.user}
      />
      <AdminUserAdvanceModal
        open={modalOption === AdminUserModalOptions.USER_ADVANCE}
        onClose={onClose}
        userId={props.user?.id}
        user={props?.user}
      />
      <AdminAffiliateFromUserModal
        open={modalOption === AdminUserModalOptions.AFFILIATE_FROM_LIST}
        onClose={onClose}
        user={props?.user}
        saveAffiliate={saveAffiliate}
        affiliateList={props.affiliateFromList}
      />
      <AdminAffiliateToUserModal
        open={modalOption === AdminUserModalOptions.AFFILIATE_TO_LIST}
        onClose={onClose}
        user={props?.user}
        affiliateList={props.affiliateToList}
      />
      <AdminNotificationSendModal
        open={modalOption === AdminUserModalOptions.SEND_NOTIFICATION}
        onClose={onClose}
        user={props.user}
      />
      <AdminUserExpenseModal
        open={modalOption === AdminUserModalOptions.USER_EXPENSE}
        onClose={onClose}
        selectedUserExpense={props.selectedUserExpense}
        onUserExpenseFormSubmit={onUserExpenseFormSubmit}
        user={props.user}
      />
    </div>
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    user: state.admin.selectedUser,
    loggedInUser: state.auth.user,
    selectedUserExpense: state.admin.selectedUserExpense,
    affiliateFromList: state.admin.affiliateFromList,
    affiliateToList: state.admin.affiliateToList
  }
}

export default connect(mapStateToProps, {
  selectUser,
  fetchUserExpenseData,
  saveUserExpenseData,
  paymentMethodsAdminEntry,
  fetchAffiliateList,
  fetchUserData
})(AdminUserModalsContainer)
