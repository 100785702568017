import React, { useEffect, useState } from "react"
import { schedulers } from "../../../../apis"
import { Scheduler } from "../../../../types"
import schedulerColumns from "./schedulerLogColumns"
import TableNew from "../../../ui/table/TableNew"
import { KButton, KTitleSpan } from "kahuna-base-react-components"

const SchedulerLog = () => {
  const [loading, setLoading] = useState(false)
  const [schedulerList, setSchedulerList] = useState<Scheduler[]>([])

  useEffect(() => {
    setLoading(true)
    schedulers(`/latest_reports/`, "get").then((resp) => {
      setSchedulerList(resp.data.results)
      setLoading(false)
    })
  }, [])

  const tableTitle = (
    <div className="flex flex-row gap-2 items-center !cursor-default">
      <div className="w-8 h-8 p-1.5 flex items-center justify-center">
        <img src="/admin_icons/desktop.svg" />
      </div>
      <div className="pt-0.5">
        {" "}
        <KTitleSpan text="Scheduler Logs" color="#111" fontSize={20} lineHeight="28px" />
      </div>
    </div>
  )

  return (
    <div className="catalog flex flex-grow justify-center">
      <div className="w-[100%]">
        <TableNew
          title={tableTitle}
          pagination
          minRowCount={15}
          limit={schedulerList.length}
          columns={schedulerColumns}
          data={schedulerList}
          count={schedulerList.length}
          rowHeightInPx={40}
          loading={loading}
        />
      </div>
    </div>
  )
}

export default SchedulerLog
