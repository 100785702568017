import { Registration, TableColumn } from "../../../../types"
import {
  errorFilledToastOptions,
  REGISTERED_USER_TYPES,
  successFilledToastOptions,
  TABLE_COLUMN_TYPE
} from "../../../../constants"
import React from "react"
import { convertToTurkeyTime } from "../../../../utility"
import { KButton, KDropdown, KSpan, KTextArea } from "kahuna-base-react-components"
import { KSelectOption } from "kahuna-base-react-components/dist/components/KDropdown/KDropdown"
//@ts-ignore
import CalendarIcon from "../../../../assets/admin_icons/calendar.svg"
import { toast } from "react-toastify"

export const statusOptions: KSelectOption[] = [
  { label: "Subscribed", value: 0, value2: "subscribed" },
  { label: "Attended demo", value: 1, value2: "demo" }
]

const registrationColumns = (
  registration: Registration[],
  setRegistration: (registrations: Registration[]) => void,
  description: { [key: string]: string },
  setDescription: (descriptions: { [key: string]: string }) => void,
  onClickDetails: (meetingId: string) => void,
  dbStatus: { [key: string]: string },
  setDbStatus: (status: { [key: string]: string }) => void,
  saveRegistration: (
    meetingId: string,
    description: string,
    db_status: string,
    type: string,
    start_time: string,
    created_at: string,
    name: string,
    country: string,
    email: string,
    phoneNumber: string,
    role: string,
    artists: string,
    hearingSource: string
  ) => Promise<void>
): TableColumn[] => [
  {
    header: "ID",
    headerStyle: {
      textAlign: "left",
      width: "max-content"
    },
    type: TABLE_COLUMN_TYPE.FUNCTION,
    render: (row: Registration) => (
      <div className="flex w-max">
        <div className="rounded-[4px] bg-[#F7F7F7] flex items-center p-1 w-max">
          <KSpan
            text={row.calendly_id}
            fontSize={11}
            lineHeight="12px"
            letterSpacing="0.22px"
            color="#111"
            fontWeight={500}
          />
        </div>
      </div>
    )
  },
  {
    header: "Artist(s)",
    headerStyle: {
      textAlign: "left"
    },
    type: TABLE_COLUMN_TYPE.FUNCTION,
    render: (row: Registration) => (
      <div className="rounded-[4px] bg-[#F7F7F7] flex items-center p-1 w-max max-w-[200px]">
        <KSpan
          text={row.artists}
          fontSize={11}
          lineHeight="12px"
          letterSpacing="0.22px"
          color="#111"
          fontWeight={500}
        />
      </div>
    )
  },
  {
    header: "Date",
    headerStyle: {
      textAlign: "left",
      flexGrow: 1
    },
    type: TABLE_COLUMN_TYPE.FUNCTION,
    render: (row: Registration) => {
      return (
        <div className="flex flex-row gap-1.5 items-center">
          <img src={CalendarIcon} alt="calendar-icon" width={16} height={16} className="min-w-4 min-h-4" />
          <KSpan text={convertToTurkeyTime(row.start_time)} color="#111" fontWeight={500} />
        </div>
      )
    }
  },
  {
    header: "Created",
    headerStyle: {
      textAlign: "left",
      flexGrow: 1
    },
    type: TABLE_COLUMN_TYPE.FUNCTION,
    render: (row: Registration) => {
      return (
        <div className="flex flex-row gap-1.5 items-center">
          <img src={CalendarIcon} alt="calendar-icon" width={16} height={16} className="min-w-4 min-h-4" />
          <KSpan text={convertToTurkeyTime(row.created_at)} color="#111" fontWeight={500} />
        </div>
      )
    }
  },
  {
    header: "Calendly Status",
    headerStyle: {
      textAlign: "left",
      width: "max-content"
    },
    type: TABLE_COLUMN_TYPE.FUNCTION,
    render: (row: Registration) => {
      return (
        <div className="rounded-[4px] bg-[#F7F7F7] flex items-center p-1 w-max">
          <KSpan
            text={row.calendly_status}
            fontSize={11}
            lineHeight="12px"
            letterSpacing="0.22px"
            color="#111"
            fontWeight={500}
          />
        </div>
      )
    }
  },
  {
    header: "DB Status",
    headerStyle: {
      textAlign: "left",
      width: "8%",
      maxWidth: "155px"
    },
    type: TABLE_COLUMN_TYPE.FUNCTION,
    render: (row: Registration) => {
      const defaultValue = dbStatus[row.calendly_id]
      const selectedOption = statusOptions.find((option) => option.value2 === dbStatus[row.calendly_id])
      return (
        <div className="flex flex-col gap-2 w-full">
          <KDropdown
            key={`${selectedOption}`}
            width={130}
            placeholder="DB Status"
            options={statusOptions}
            rightIcon="/analytics_icons/caret-down-new.svg"
            selected={selectedOption}
            padding="8px 14px"
            menuWidth={150}
            menuLeftMargin={-14}
            background={defaultValue === "demo" || defaultValue === "subscribed" ? "#FFF" : "#F5F5F5"}
            activeBackground="#FFF"
            onSelect={(selected) => {
              const new_db_status = (selected as KSelectOption)?.value2 || "-"
              const registrationIndex = registration.findIndex((reg) => reg.calendly_id === row.calendly_id)
              const current_db_status = registration[registrationIndex].db_status
              setDbStatus({ ...dbStatus, [row.calendly_id]: new_db_status })
              saveRegistration(
                row.calendly_id,
                description[row.calendly_id],
                new_db_status,
                REGISTERED_USER_TYPES.DEMO,
                row.start_time,
                row.created_at,
                row.name,
                row.country,
                row.email,
                row.phone_number,
                row.role,
                row.artists,
                row.hearing_source
              )
                .then(() => {
                  toast.success("Registration has been updated successfully", successFilledToastOptions)
                })
                .catch(() => {
                  setDbStatus({ ...dbStatus, [row.calendly_id]: current_db_status })
                  toast.error("Failed to update registration", errorFilledToastOptions)
                })
            }}
            isEllipsis
          />
        </div>
      )
    }
  },
  {
    header: "Admin Notes",
    headerStyle: {
      textAlign: "left",
      width: "max-content"
    },
    type: TABLE_COLUMN_TYPE.FUNCTION,
    render: (row: Registration) => {
      return (
        <div className="max-w-[350px] min-w-[220px]">
          <KTextArea
            value={description[row.calendly_id]}
            onChange={(value: string) => {
              setDescription({ ...description, [row.calendly_id]: value })
            }}
            onBlur={() => {
              if (description[row.calendly_id] !== row.description) {
                const registrationIndex = registration.findIndex((reg) => reg.calendly_id === row.calendly_id)
                saveRegistration(
                  row.calendly_id,
                  description[row.calendly_id],
                  dbStatus[row.calendly_id],
                  REGISTERED_USER_TYPES.DEMO,
                  row.start_time,
                  row.created_at,
                  row.name,
                  row.country,
                  row.email,
                  row.phone_number,
                  row.role,
                  row.artists,
                  row.hearing_source
                )
                  .then(() => {
                    toast.success("Registration has been updated successfully", successFilledToastOptions)
                    const updatedRegistration = [...registration]
                    updatedRegistration[registrationIndex] = {
                      ...updatedRegistration[registrationIndex],
                      description: description[row.calendly_id]
                    }
                    setRegistration(updatedRegistration)
                  })
                  .catch(() => {
                    toast.error("Failed to update registration", errorFilledToastOptions)
                    setDescription({ ...description, [row.calendly_id]: registration[registrationIndex].description })
                  })
              }
            }}
          />
        </div>
      )
    }
  },
  {
    header: "",
    headerStyle: {
      textAlign: "left",
      width: "7%"
    },
    type: TABLE_COLUMN_TYPE.FUNCTION,
    render: (row: Registration) => {
      return (
        <KButton
          text="Details"
          width="auto"
          background="#FFF"
          onClick={() => {
            onClickDetails(row.calendly_id)
          }}
          padding="8px 10px"
        />
      )
    }
  }
]

export default registrationColumns
