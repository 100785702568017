import { Dialog } from "@headlessui/react"
import { KTitleSpan, KSpan, KButton, KDropdown, KInput } from "kahuna-base-react-components"
import React, { useEffect, useState } from "react"
import { lang } from "../../../../../constants/languages"
import { KSelectOption } from "kahuna-base-react-components/dist/components/KDropdown/KDropdown"
import { User, UserExpense, UserExpenseForm } from "../../../../../types"
import adminUserExpenseValidator from "./validators/adminUserExpenseValidator"

export const TaxTypeOptions = [
  { value: 1, label: "KDV" },
  { value: 2, label: "Stopaj" }
]
export const PaymentPeriodTypeOptions = [
  { value: 1, label: "Quarterly" },
  { value: 2, label: "Monthly" }
]
export const CurrencyTypeOptions = [
  { value: 1, label: "TRY" },
  { value: 2, label: "USD" },
  { value: 3, label: "EUR" },
  { value: 4, label: "GBP" }
]

const AdminUserExpenseModal = ({
  open,
  onClose,
  selectedUserExpense,
  onUserExpenseFormSubmit,
  user
}: {
  open: boolean
  onClose: () => void
  selectedUserExpense: UserExpense
  onUserExpenseFormSubmit: (userExpenseForm: UserExpenseForm) => Promise<void>
  user: User
}) => {
  const [userExpense, setUserExpense] = useState<UserExpense>(selectedUserExpense)
  useEffect(() => {
    setUserExpense(selectedUserExpense)
  }, [selectedUserExpense])

  const createUserExpenseFormFromUserExpense = (userExpense: UserExpense) => {
    const userExpenseForm: UserExpenseForm = {
      id: userExpense?.id,
      adminFee: userExpense?.adminFee || 0,
      distributionFee: userExpense?.distributionFee || 0,
      user: userExpense?.user,
      taxType: userExpense?.taxType || 1,
      preferredCurrency: userExpense?.preferredCurrency || 1,
      paymentPeriod: userExpense?.paymentPeriod || 1,
      publishingKahunaShare: userExpense?.publishingKahunaShare || 0
    }

    return userExpenseForm
  }

  return (
    <Dialog
      open={open}
      onClose={() => {
        onClose()
      }}
    >
      <div className="fixed w-screen h-screen top-0 left-0 z-250 flex items-center justify-center bg-[#0000004d] ">
        <div className="p-6 shadow-md w-[400px] rounded-[10px] bg-[#fff]">
          <div className="flex flex-col gap-6">
            <div className="flex flex-col gap-6 items-center justify-center">
              <span
                className="w-[72px] aspect-square rounded-full flex justify-center items-center"
                style={{
                  border: "1px solid #F3F3F3"
                }}
              >
                <img className="w-8 h-8" src="/admin_icons/user-gray.svg" />
              </span>
              <KTitleSpan text={"User Expense"} fontSize={20} lineHeight="28px" color="#000" />
            </div>
            <div className="flex flex-row items-center gap-1">
              <KSpan text="User:" fontWeight={500} color="#000" />
              <KSpan text={`${user?.firstName} ${user?.lastName}`} color="#000" />
            </div>
            <div className="flex flex-col gap-2">
              <div className="flex flex-row gap-2">
                <div className="flex flex-col w-1/2 gap-0.5 items-start">
                  <KSpan text="Admin Fee" />
                  <KInput
                    type="number"
                    value={userExpense?.adminFee?.toString() || ""}
                    onChange={(text) => {
                      setUserExpense({ ...userExpense, adminFee: Number(text) })
                    }}
                  />
                </div>
                <div className="flex flex-col w-1/2 gap-0.5 items-start">
                  <KSpan text="Distribution Fee" />
                  <KInput
                    type="number"
                    value={userExpense?.distributionFee?.toString() || ""}
                    onChange={(text) => {
                      setUserExpense({ ...userExpense, distributionFee: Number(text) })
                    }}
                  />
                </div>
              </div>
              <div className="flex flex-row gap-2">
                <div className="flex flex-col w-1/2 gap-0.5 items-start">
                  <KSpan text="Tax Type" />
                  <KDropdown
                    options={TaxTypeOptions}
                    selected={TaxTypeOptions.find((option) => option.value === userExpense?.taxType)}
                    onSelect={(selected) => {
                      setUserExpense({ ...userExpense, taxType: (selected as KSelectOption)?.value })
                    }}
                    rightIcon="/analytics_icons/caret-down-new.svg"
                    enableIndicator
                  />
                </div>
                <div className="flex flex-col w-1/2 gap-0.5 items-start">
                  <KSpan text="Preferred Currency" />
                  <KDropdown
                    options={CurrencyTypeOptions}
                    selected={CurrencyTypeOptions.find((option) => option.value === userExpense?.preferredCurrency)}
                    onSelect={(selected) => {
                      setUserExpense({ ...userExpense, preferredCurrency: (selected as KSelectOption)?.value })
                    }}
                    rightIcon="/analytics_icons/caret-down-new.svg"
                    enableIndicator
                  />
                </div>
              </div>
              <div className="flex flex-row ">
                <div className="flex flex-col w-full gap-0.5 items-start">
                  <KSpan text="Payment Period Type" />
                  <KDropdown
                    options={PaymentPeriodTypeOptions}
                    selected={PaymentPeriodTypeOptions.find((option) => option.value === userExpense?.paymentPeriod)}
                    onSelect={(selected) => {
                      setUserExpense({ ...userExpense, paymentPeriod: (selected as KSelectOption)?.value })
                    }}
                    rightIcon="/analytics_icons/caret-down-new.svg"
                    enableIndicator
                  />
                </div>
              </div>
              <div className="flex flex-row">
                <div className="flex flex-col w-full gap-0.5 items-start">
                  <KSpan text="Publishing Kahuna Share" />
                  <KInput
                    type="number"
                    value={userExpense?.publishingKahunaShare?.toString() || ""}
                    onChange={(text) => {
                      setUserExpense({ ...userExpense, publishingKahunaShare: Number(text) })
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="flex gap-3 justify-center items-center">
              <KButton
                text={lang.common.cancel}
                onClick={() => {
                  onClose()
                }}
                background="#fff"
                shadowDisabled={true}
              />
              <KButton
                text={lang.common.save}
                textColor="white"
                background="black"
                onClick={() => {
                  const errors = adminUserExpenseValidator(userExpense)
                  if (Object.keys(errors).length === 0) {
                    onUserExpenseFormSubmit(createUserExpenseFormFromUserExpense(userExpense))
                    onClose()
                  }
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  )
}

export default AdminUserExpenseModal
