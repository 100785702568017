import { TableProps } from "../../../types"
import React, { FC, useEffect, useRef, useState } from "react"
import { tableCellClasses } from "../../../constants"
import { isEqual } from "lodash"
import { KButton, KInput, KSpan, KTitleSpan, KTooltip } from "kahuna-base-react-components"
import ListComponent from "./ListComponent"
import NotFound from "../../NotFound"
import { lang } from "../../../constants/languages"

const ReportsTable: FC<TableProps> = (props) => {
  const {
    topRightElement,
    className,
    size = "lg",
    title,
    columns,
    data,
    minRowCount = 10,
    rowHeightInPx,
    count,
    onClickPage,
    loading,
    onSelectRow,
    setTableAPI,
    hidden,
    onSortOrFilterChange,
    additionalFilters,
    inactiveRows,
    isAdmin,
    openSidebar,
    setOpenSidebar,
    topFilters
  } = props

  interface Data {
    quarter: string
    revenue: number
    total_payment: number
    currency: string
    index: number
    payment_day?: string[]
  }

  const [currentPage, setCurrentPage] = useState(1)
  const [filters, setFilters] = useState<{ [key: string]: string | number | boolean }>({})
  const [searchText, setSearchText] = useState<string>("")
  const [filteredData, setFilteredData] = useState<Data[]>(data)
  const additionalFiltersRef = useRef(additionalFilters)

  const handleChange = (newValue: string) => {
    setSearchText(newValue)
  }

  const filterDataBySearchText = (data: Data[], searchText: string): Data[] => {
    if (data.length > 0) {
      return data.filter((item) => {
        return item.quarter.toLowerCase().includes(searchText.toLowerCase())
      })
    } else {
      return []
    }
  }

  useEffect(() => {
    const filterData = filterDataBySearchText(data, searchText)
    setFilteredData(filterData)
  }, [searchText, data])

  useEffect(() => {
    if (setTableAPI) setTableAPI({ currentPage, setCurrentPage })
  }, [currentPage, count])

  useEffect(() => {
    if (!isEqual(additionalFiltersRef.current, additionalFilters)) {
      const newFilters = { ...filters, ...additionalFilters }
      setFilters(newFilters)
      additionalFiltersRef.current = additionalFilters
    }
  }, [additionalFilters])

  if (hidden) return null

  return (
    <div>
      <div className={`flex flex-row justify-between items-center ${isAdmin ? "py-4" : "py-6 px-3"}`}>
        <div className="flex flex-row items-center justify-start gap-2">
          {isAdmin && (
            <KTooltip
              backgroundColor="#000"
              padding="0px 4px"
              height="20px"
              arrowColor="#000"
              position="bottom"
              content={
                <div className="h-5 w-max flex items-center">
                  <KSpan
                    text={openSidebar ? "Hide sidebar" : "Open sidebar"}
                    fontSize={12}
                    lineHeight="16px"
                    color="#FFF"
                  />
                </div>
              }
            >
              <div className="flex items-center">
                <KButton
                  icon="/left-sidebar.svg"
                  width="32px"
                  height="32px"
                  background="transparent"
                  shadowDisabled={true}
                  padding="6px"
                  onClick={() => {
                    if (setOpenSidebar) {
                      setOpenSidebar(!openSidebar)
                    }
                  }}
                />
              </div>
            </KTooltip>
          )}
          <div className="flex items-center pt-1">
            <KTitleSpan text={title?.toString() || ""} fontSize={20} color="#111" lineHeight={"28px"} fontWeight={500} />
          </div>
        </div>

        {topRightElement ? (
          topRightElement
        ) : (
          <div style={{ width: 300 }}>
            <KInput
              onChange={handleChange}
              value={searchText}
              placeholder={lang.reports.search_text_placeholder}
              padding="14px"
              leftIcon="/catalog_icons/search-grey.svg"
              hoverBackground="white"
              background="#f5f5f5"
            />
          </div>
        )}
      </div>
      <div className={`mt-6 bg-white elev-b-sm rounded-lg w-full shadow-none ${!isAdmin && "overflow-hidden"}`}>
        {topFilters && isAdmin && topFilters}
        {!loading && filteredData.length > 0 && (
          <div className="flex flex-row justify-between items-center h-[52px] py-2 px-3">
            <div className="flex w-[25%] items-center justify-start">
              <KSpan text={lang.reports.list_component.report} fontWeight={500} color="#000" />
            </div>
            <div className="flex w-[20%] items-center justify-start">
              <KSpan text={lang.reports.list_component.revenue} fontWeight={500} color="#000" />
            </div>
            <div className="flex w-[20%] items-center justify-start">
              <KSpan text={lang.reports.list_component.total_payment} fontWeight={500} color="#000" />
            </div>
            <div className="flex w-[25%] items-center justify-start">
              <KSpan text={lang.reports.list_component.payment_date} fontWeight={500} color="#000" />
            </div>
            <div className="flex w-[10%] items-center justify-start">
              <KSpan text={""} fontWeight={500} color="#000" />
            </div>
          </div>
        )}

        <ul>
          {!loading &&
            filteredData?.map((row, i) => {
              return (
                <ListComponent
                  key={`list-component-${i}`}
                  i={i}
                  row={row}
                  isAdmin={props.isAdmin}
                  selectedUserId={props.selectedUserId}
                  approveDisabled={props.approveDisabled}
                />
              )
            })}
          {!loading && filteredData.length === 0 && (
            <div className="flex justify-center mt-12">
              <NotFound
                title={lang.not_found.reports.title}
                description={lang.not_found.reports.desc}
                imageWidth={225}
              />
            </div>
          )}
          {isAdmin && !props.selectedUserId && (
            <div className="flex justify-center mt-12">
              <NotFound
                title={lang.not_found.reports.title}
                description={'No data available. Please select a user to view details.'}
                imageWidth={225}
              />
            </div>
          )}
        </ul>
      </div>
    </div>
  )
}

export default ReportsTable
