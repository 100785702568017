import { Dialog } from "@headlessui/react"
import { KTitleSpan, KSpan, KButton } from "kahuna-base-react-components"
import React from "react"
import { lang } from "../../../../../constants/languages"

const ConfirmationModal = ({
  isOpen,
  handleClose,
  handleConfirm,
  description
}: {
  isOpen: boolean
  handleClose: () => void
  handleConfirm: () => void
  description: string
}) => {
  return (
    <Dialog
      open={isOpen}
      onClose={() => {
        handleClose()
      }}
    >
      <div className="fixed w-screen h-screen top-0 left-0 z-250 flex items-center justify-center bg-[#0000004d] ">
        <div className="p-6 shadow-md w-[400px] rounded-[10px] bg-[#fff]">
          <div className="flex flex-col gap-6">
            <div className="flex flex-col gap-8 items-center justify-center">
              <span className="w-[96px] aspect-square rounded-[48px] shadow-md flex justify-center items-center">
                <img className="w-12 h-12" src="/account_icons/error.svg"></img>
              </span>
              <KTitleSpan text={lang.common.confirm_dialog.title} fontSize={24} lineHeight="32px" />
              <div className="flex" style={{ textAlign: "center" }}>
                <KSpan text={description} />
              </div>
            </div>
            <div className="flex gap-3 justify-center items-center">
              <KButton
                text={lang.common.cancel}
                onClick={() => {
                  handleClose()
                }}
                background="#fff"
                shadowDisabled={true}
              />
              <KButton
                text={lang.common.confirm}
                textColor="white"
                background="black"
                onClick={() => {
                  handleConfirm()
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  )
}

export default ConfirmationModal
