import * as React from "react"
import { FC, useEffect, useRef, useState } from "react"
import { AdminAssetProps, TableAPI, UserTrackShareData, UserYoutubeVideoShareData } from "../../../../types"
import { connect } from "react-redux"
import {
  deleteTrackShareData,
  deleteYoutubeVideoShareData,
  fetchUserTrackShareData,
  fetchUserYoutubeVideoShareData,
  selectCollaboratorData,
  selectTrackShareData,
  selectYoutubeVideoShareData
} from "../../../../actions/adminActions"
import { RootState } from "../../../../store"
import AdminTrackForm from "./modals/AdminTrackForm.tsx"
import { adminTableLimit, ASSET_TYPES } from "../../../../constants"
import adminYoutubeVideoColumns from "./columns/adminYoutubeVideoColumns"
import adminTrackColumns from "./columns/adminTrackColumns"
import AdminYoutubeVideoForm from "./modals/AdminYoutubeVideoForm"
import AdminTrackShareHolderTable from "./AdminTrackShareHolderTable"
import AdminCollaboratorForm from "./modals/AdminCollaboratorForm"
import TableNew from "../../../ui/table/TableNew"
import UserDetailCard from "./UserDetailCard.tsx"
import { KButton, KDropdown, KInput, KSpan, KTitleSpan } from "kahuna-base-react-components"
import { KSelectOption } from "kahuna-base-react-components/dist/components/KDropdown/KDropdown"
import ConfirmationDialog from "../../../ui/modals/ConfirmationDialog.tsx"
import { AdminUserAssetTabOptions } from "../AdminUserAsset.tsx"

const AdminAsset: FC<AdminAssetProps> = (props) => {
  const {
    selectedUser,
    fetchUserTrackShareData,
    fetchUserYoutubeVideoShareData,
    paginatedUserTrackShareData,
    selectTrackShareData,
    deleteTrackShareData,
    selectedTrackShareData,
    paginatedUserYoutubeVideoShareData,
    selectYoutubeVideoShareData,
    deleteYoutubeVideoShareData,
    selectedYoutubeVideoShareData,
    selectCollaboratorData,
    selectedCollaboratorData
  } = props

  const [assetType, setAssetType] = useState(ASSET_TYPES.TRACK)
  const [tableAPI, setTableAPI] = useState<TableAPI | undefined>(undefined)
  const [trackLoading, setTrackLoading] = useState(false)
  const [videoLoading, setVideoLoading] = useState(false)
  const [timeoutId, setTimeoutId] = useState(undefined)
  const [inputTimeoutId, setInputTimeoutId] = useState(undefined)
  const deleting = useRef(false)
  const creating = useRef(false)
  const divRef = useRef<HTMLDivElement | null>(null)
  const [openAssetTypes, setOpenAssetTypes] = useState<boolean>(false)

  const [openTrackForm, setOpenTrackForm] = useState<boolean>(false)
  const [openYoutubeVideoForm, setOpenYoutubeVideoForm] = useState<boolean>(false)
  const [openConfirmation, setOpenConfirmation] = useState<boolean>(false)

  const trackColumns = adminTrackColumns(selectTrackShareData, setOpenTrackForm, setOpenConfirmation)
  const youtubeVideoColumns = adminYoutubeVideoColumns(
    selectYoutubeVideoShareData,
    setOpenYoutubeVideoForm,
    setOpenConfirmation
  )

  const [searchValue, setSearchValue] = useState<string>("")
  const searchOptions: KSelectOption[] = [
    { label: "Title", value: 0, value2: "title" },
    { label: "ISRC", value: 1, value2: "isrc" }
  ]
  const [selectedSearchOption, setSelectedSearchOption] = useState<KSelectOption>(searchOptions[0])
  const [trackPageNumber, setTrackPageNumber] = useState<number>(1)
  const [youtubeVideoPageNumber, setYoutubeVideoPageNumber] = useState<number>(1)
  const [additionalTrackFilters, setAdditionalTrackFilters] = useState<{ [key: string]: string | undefined }>({
    title: "",
    track__isrc: ""
  })
  const [additionalYoutubeVideoFilters, setAdditionalYoutubeVideoFilters] = useState<{
    [key: string]: string | undefined
  }>({ title: "", youtube_video_track_isrc: "" })

  useEffect(() => {
    selectTrackShareData(null)
    selectYoutubeVideoShareData(null)
    selectCollaboratorData(null)
  }, [])

  useEffect(() => {
    selectTrackShareData(null)
  }, [paginatedUserTrackShareData.results])

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (divRef.current && !divRef.current.contains(event.target as Node)) {
        setOpenAssetTypes(false)
      }
    }

    document.addEventListener("mousedown", handleClickOutside)

    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [])

  useEffect(() => {
    if (props.tab === AdminUserAssetTabOptions.USER) {
      setTrackPageNumber(1)
      setYoutubeVideoPageNumber(1)
      setSearchValue("")
      setSelectedSearchOption(searchOptions[0])
      setTrackLoading(true)
      setVideoLoading(true)
      tableAPI?.setCurrentPage(1)
    } else if (props.tab === AdminUserAssetTabOptions.ASSET && selectedUser?.id) {
      fetchUserTrackShareData(selectedUser.id, adminTableLimit, 0, {}).then(() => setTrackLoading(false))
      fetchUserYoutubeVideoShareData(selectedUser.id, adminTableLimit, 0, {}).then(() => setVideoLoading(false))
    }
  }, [props.tab, selectedUser])

  useEffect(() => {
    if (!tableAPI || paginatedUserTrackShareData?.count === undefined) return
    if (assetType === ASSET_TYPES.TRACK) {
      if (creating.current && paginatedUserTrackShareData.count / adminTableLimit > tableAPI.currentPage) {
        const targetPage = Math.ceil(paginatedUserTrackShareData.count / adminTableLimit)
        tableAPI.setCurrentPage(targetPage)
        onClickPage(targetPage, {}).then()
      }
      if (
        deleting.current &&
        paginatedUserTrackShareData.results.length < adminTableLimit &&
        paginatedUserTrackShareData.count >= tableAPI.currentPage * adminTableLimit &&
        selectedUser?.id
      ) {
        fetchUserTrackShareData(
          selectedUser.id,
          adminTableLimit,
          (tableAPI.currentPage - 1) * adminTableLimit,
          {}
        ).then(() => setTrackLoading(false))
      } else if (
        deleting.current &&
        paginatedUserTrackShareData.results.length === 0 &&
        paginatedUserTrackShareData.count > 0
      ) {
        const targetPage = tableAPI.currentPage - 1
        tableAPI.setCurrentPage(targetPage)
        onClickPage(targetPage, {}).then()
      }
    }
    deleting.current = false
    creating.current = false
  }, [paginatedUserTrackShareData.count])

  useEffect(() => {
    if (!tableAPI || paginatedUserYoutubeVideoShareData?.count === undefined) return
    if (assetType === ASSET_TYPES.YOUTUBE_VIDEO) {
      if (creating.current && paginatedUserYoutubeVideoShareData.count / adminTableLimit > tableAPI.currentPage) {
        const targetPage = Math.ceil(paginatedUserYoutubeVideoShareData.count / adminTableLimit)
        tableAPI.setCurrentPage(targetPage)
        onClickPage(targetPage, {}).then()
      }
      if (
        deleting.current &&
        paginatedUserYoutubeVideoShareData.results.length < adminTableLimit &&
        paginatedUserYoutubeVideoShareData.count >= tableAPI.currentPage * adminTableLimit &&
        selectedUser?.id
      ) {
        fetchUserYoutubeVideoShareData(
          selectedUser.id,
          adminTableLimit,
          (tableAPI.currentPage - 1) * adminTableLimit,
          {}
        ).then(() => setVideoLoading(false))
      } else if (
        deleting.current &&
        paginatedUserYoutubeVideoShareData.results.length === 0 &&
        paginatedUserYoutubeVideoShareData.count > 0
      ) {
        const targetPage = tableAPI.currentPage - 1
        tableAPI.setCurrentPage(targetPage)
        onClickPage(targetPage, {}).then()
      }
    }
    deleting.current = false
    creating.current = false
  }, [paginatedUserYoutubeVideoShareData.count])

  const onClickPage = async (
    pageNumber: number,
    filters: { [key: string]: string | number | boolean } | undefined,
    sortField?: string,
    sortDirection?: "asc" | "desc"
  ) => {
    if (!selectedUser?.id) return
    if (assetType === ASSET_TYPES.TRACK) {
      setTrackPageNumber(pageNumber)
      setTrackLoading(true)
      fetchUserTrackShareData(
        selectedUser.id,
        adminTableLimit,
        (pageNumber - 1) * adminTableLimit,
        filters,
        sortField,
        sortDirection
      ).then(() => setTrackLoading(false))
    } else {
      setYoutubeVideoPageNumber(pageNumber)
      setVideoLoading(true)
      fetchUserYoutubeVideoShareData(
        selectedUser.id,
        adminTableLimit,
        (pageNumber - 1) * adminTableLimit,
        filters,
        sortField,
        sortDirection
      ).then(() => setVideoLoading(false))
    }
  }

  const onClickAdd = () => {
    if (assetType === ASSET_TYPES.TRACK) {
      setOpenTrackForm(true)
      selectTrackShareData(new UserTrackShareData())
    } else {
      setOpenYoutubeVideoForm(true)
      selectYoutubeVideoShareData(new UserYoutubeVideoShareData())
    }
  }

  const onClickDelete = () => {
    if (assetType === ASSET_TYPES.TRACK) {
      deleteTrackShareData(selectedTrackShareData.id)
    } else {
      deleteYoutubeVideoShareData(selectedYoutubeVideoShareData.id)
    }
    setOpenConfirmation(false)
  }

  const onSelectRow = (index: number) => {
    deleting.current = true
    if (assetType === ASSET_TYPES.TRACK) {
      selectTrackShareData(paginatedUserTrackShareData.results[index])
    } else {
      selectYoutubeVideoShareData(paginatedUserYoutubeVideoShareData.results[index])
    }
  }

  const onSortOrFilterChange = async (
    filters: { [key: string]: string | number | boolean },
    sortField: string,
    sortDirection?: "asc" | "desc",
    debounce?: boolean
  ) => {
    if (timeoutId) clearTimeout(timeoutId)
    setTimeoutId(
      //@ts-ignore
      setTimeout(
        async () => {
          if (!selectedUser?.id) return
          if (assetType === ASSET_TYPES.TRACK) {
            setTrackLoading(true)
            await fetchUserTrackShareData(selectedUser.id, adminTableLimit, 0, filters, sortField, sortDirection)
            setTrackLoading(false)
            setTrackPageNumber(1)
          } else {
            setVideoLoading(true)
            await fetchUserYoutubeVideoShareData(selectedUser.id, adminTableLimit, 0, filters, sortField, sortDirection)
            setVideoLoading(false)
            setYoutubeVideoPageNumber(1)
          }
        },
        debounce ? 500 : 0
      )
    )
  }

  useEffect(() => {
    clearTimeout(inputTimeoutId)
    setInputTimeoutId(
      // @ts-ignore
      setTimeout(async () => {
        if (selectedSearchOption?.value2) {
          if (assetType === ASSET_TYPES.TRACK) {
            setAdditionalTrackFilters({
              title: selectedSearchOption?.value2 === "title" ? searchValue : "",
              track__isrc: selectedSearchOption?.value2 === "isrc" ? searchValue : ""
            })
          } else if (assetType === ASSET_TYPES.YOUTUBE_VIDEO) {
            setAdditionalYoutubeVideoFilters({
              title: selectedSearchOption?.value2 === "title" ? searchValue : "",
              youtube_video_track_isrc: selectedSearchOption?.value2 === "isrc" ? searchValue : ""
            })
          }
        }
      }, 500)
    )
  }, [selectedSearchOption, searchValue, assetType])

  const topRightElements = () => (
    <div className="flex flex-row gap-2.5 items-center">
      <div
        className="flex items-center py-[13px] pl-3.5 pr-3 gap-2 rounded-[10px] w-[300px]"
        style={{
          background: searchValue ? "#FFF" : "#F7F7F7",
          border: "1px solid #F3F3F3",
          height: "48px",
          transition: "0.3s"
        }}
      >
        <KInput
          value={searchValue}
          padding="0px"
          background="transparent"
          onChange={(text: string) => {
            setSearchValue(text)
          }}
          leftIcon="/catalog_icons/search-grey.svg"
          gap="8px"
          borderRadius={0}
          shadowDisabled
          placeholder={"Search..."}
        />
        <KDropdown
          rightIcon="/admin_icons/caret-down.svg"
          options={searchOptions}
          selected={selectedSearchOption}
          onSelect={(selected) => {
            setSelectedSearchOption(selected as KSelectOption)
          }}
          padding="0px"
          background="transparent"
          activeBackground="transparent"
          shadowDisabled={true}
          enableIndicator={true}
          menuWidth={140}
          width={140}
          menuLeftMargin={-40}
          textColor="#F3F3F3"
        />
      </div>
      <KButton
        icon="/admin_icons/plus.svg"
        onClick={onClickAdd}
        background="#F7F7F7"
        hoverBackground="#F3F3F3"
        padding="14px"
        disabled={!selectedUser?.id}
        width="48px"
        height="48px"
      />
    </div>
  )

  const renderTitleSwitch = () => {
    return (
      <div className="flex items-center">
        {assetType === ASSET_TYPES.TRACK ? (
          <div className={`flex items-center pt-2 px-3 cursor-pointer`} onClick={() => setAssetType(ASSET_TYPES.TRACK)}>
            <KTitleSpan
              text={`Tracks (${paginatedUserTrackShareData.count})`}
              fontSize={32}
              lineHeight="40px"
              color="#000"
            />
          </div>
        ) : (
          <div
            className={`flex items-center pt-2 px-3 cursor-pointer`}
            onClick={() => setAssetType(ASSET_TYPES.YOUTUBE_VIDEO)}
          >
            <KTitleSpan
              text={`Youtube Videos (${paginatedUserYoutubeVideoShareData.count})`}
              fontSize={32}
              lineHeight="40px"
              color="#000"
            />
          </div>
        )}
        <div className="relative" ref={divRef}>
          <KButton
            padding="6px"
            height="32px"
            width="32px"
            background="#F7F7F7"
            hoverBackground="#F3F3F3"
            icon="/admin_icons/caret-down.svg"
            onClick={() => {
              setOpenAssetTypes(!openAssetTypes)
            }}
          />
          {openAssetTypes && (
            <div className="flex shadow-lg flex-col gap-1 w-[200px] rounded-[10px] p-1 bg-[#F7F7F7] absolute top-10">
              <div
                className="py-2.5 px-2 flex flex-row justify-between hover:bg-[#F3F3F3] rounded-[10px] cursor-pointer"
                onClick={() => {
                  setAssetType(ASSET_TYPES.TRACK)
                  setOpenAssetTypes(false)
                  selectYoutubeVideoShareData(null)
                }}
              >
                <KSpan text="Tracks" color="#000" />
                <KSpan text={`${paginatedUserTrackShareData.count}`} color="#000" />
              </div>
              <div
                className="py-2.5 px-2 flex flex-row justify-between hover:bg-[#F3F3F3] rounded-[10px] cursor-pointer"
                onClick={() => {
                  setAssetType(ASSET_TYPES.YOUTUBE_VIDEO)
                  setOpenAssetTypes(false)
                  selectTrackShareData(null)
                }}
              >
                <KSpan text="Youtube Videos" color="#000" />
                <KSpan text={`${paginatedUserYoutubeVideoShareData.count}`} color="#000" />
              </div>
            </div>
          )}
        </div>
      </div>
    )
  }

  return (
    <div className="flex flex-row gap-3 overflow-hidden">
      <div className="w-auto">
        <UserDetailCard setModalOption={props.setModalOption} setTab={props.setTab} />
      </div>
      <div className="flex flex-col flex-grow max-h-[100vh] overflow-auto">
        <div className="mt-2 mb-2 container flex gap-4 pl-1">
          <TableNew
            hidden={assetType !== ASSET_TYPES.TRACK}
            title={renderTitleSwitch()}
            size={"sm"}
            className="flex-grow"
            pagination
            limit={adminTableLimit}
            columns={trackColumns}
            minRowCount={adminTableLimit}
            data={paginatedUserTrackShareData.results}
            count={paginatedUserTrackShareData.count}
            onClickPage={onClickPage}
            topRightElement={topRightElements()}
            onSelectRow={onSelectRow}
            setTableAPI={setTableAPI}
            loading={trackLoading}
            onSortOrFilterChange={onSortOrFilterChange}
            doNotApplyAdditionalFilters={props.tab === AdminUserAssetTabOptions.USER}
            overrideTableOverflow="auto"
            pageNumber={trackPageNumber}
            additionalFilters={additionalTrackFilters}
          />
          <TableNew
            hidden={assetType !== ASSET_TYPES.YOUTUBE_VIDEO}
            title={renderTitleSwitch()}
            size={"sm"}
            className="flex-grow"
            pagination
            limit={adminTableLimit}
            columns={youtubeVideoColumns}
            minRowCount={adminTableLimit}
            data={paginatedUserYoutubeVideoShareData.results}
            count={paginatedUserYoutubeVideoShareData.count}
            onClickPage={onClickPage}
            topRightElement={topRightElements()}
            onSelectRow={onSelectRow}
            setTableAPI={setTableAPI}
            loading={videoLoading}
            onSortOrFilterChange={onSortOrFilterChange}
            doNotApplyAdditionalFilters={props.tab === AdminUserAssetTabOptions.USER}
            overrideTableOverflow="auto"
            pageNumber={youtubeVideoPageNumber}
            additionalFilters={additionalYoutubeVideoFilters}
          />
          {selectedUser?.id && (
            <div>
              <AdminTrackForm
                open={assetType === ASSET_TYPES.TRACK && openTrackForm}
                onClose={() => {
                  setOpenTrackForm(false)
                }}
                creatingRef={creating}
              />
              <AdminYoutubeVideoForm
                open={assetType === ASSET_TYPES.YOUTUBE_VIDEO && openYoutubeVideoForm}
                onClose={() => {
                  setOpenYoutubeVideoForm(false)
                }}
                creatingRef={creating}
              />
            </div>
          )}
        </div>
        {selectedTrackShareData?.trackDetail.id && (
          <div className="w-full mt-2 mb-2 container flex gap-4 pl-1">
            <div className="w-full">
              <AdminTrackShareHolderTable trackId={selectedTrackShareData?.trackDetail.id} />
            </div>

            <div>
              <AdminCollaboratorForm
                open={selectedCollaboratorData !== null}
                onClose={() => {
                  selectCollaboratorData(null)
                }}
              />
            </div>
          </div>
        )}
      </div>
      <ConfirmationDialog
        openConfirmation={openConfirmation}
        setOpenConfirmation={setOpenConfirmation}
        loading={false}
        handleConfirm={onClickDelete}
        overrideDescription={
          assetType === ASSET_TYPES.TRACK
            ? `This action will delete track share: ${selectedTrackShareData?.trackDetail.title}`
            : `This action will delete youtube video share: ${selectedYoutubeVideoShareData?.youtubeVideoDetail.title}`
        }
      />
    </div>
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    selectedUser: state.admin.selectedUser,
    selectedTrackShareData: state.admin.selectedTrackShareData,
    selectedYoutubeVideoShareData: state.admin.selectedYoutubeVideoShareData,
    paginatedUserTrackShareData: state.admin.paginatedUserTrackShareData,
    paginatedUserYoutubeVideoShareData: state.admin.paginatedUserYoutubeVideoShareData,
    selectedCollaboratorData: state.admin.selectedCollaboratorData
  }
}

export default connect(mapStateToProps, {
  fetchUserTrackShareData,
  fetchUserYoutubeVideoShareData,
  selectTrackShareData,
  deleteTrackShareData,
  selectYoutubeVideoShareData,
  deleteYoutubeVideoShareData,
  selectCollaboratorData
})(AdminAsset)
