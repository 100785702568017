import { PaymentPageProps } from "../../../../types"
import * as React from "react"
import { FC, useEffect, useState } from "react"
import { connect } from "react-redux"
import PaymentForm from "./PaymentForm"
import { fetchPaymentMethods } from "../../../../actions"
import { RootState } from "../../../../store"
import Title from "../Title"
import { KButton, KSpan } from "kahuna-base-react-components"
import { Dialog } from "@headlessui/react"
import ListComponent from "./ListComponent"
import { lang } from "../../../../constants/languages"

const Payment: FC<PaymentPageProps> = (props) => {
  const [adding, setAdding] = useState(false)

  const [edit, setEdit] = useState(false)

  const [editableMethodKey, setEditableMethodKey] = useState<string>("") // chooses the payment method which will be edited
  useEffect(() => {
    props.fetchPaymentMethods(props.adminPanelUserId)
  }, [props.adminPanelUserId])

  const [addPaymentMethod, setAddPaymentMethod] = useState<boolean>(false)

  const renderPaymentMethods = () => {
    return (
      <>
        <div className="flex flex-col ">
          <div className="flex flex-row justify-between items-center">
            <div className="flex items-center gap-1">
              <Title
                sectionTitle={`${lang.account.payment_methods.title}`}
                sectionDetail="Select IBAN wire and check"
                iconPath="/account_icons/credit-card.svg"
              />
              {props?.adminPage && (props.user?.firstName || props.user?.lastName) && (
                <KSpan text={`(User:${props.user?.firstName} ${props.user?.lastName})`} color="#000" fontWeight={500} />
              )}
            </div>
            <KButton
              text={lang.account.payment_methods.add_button}
              textColor="#666"
              leftIcon="/account_icons/add-line.svg"
              onClick={() => {
                setAddPaymentMethod(true)
              }}
              shadowDisabled={true}
              background="#ffffff"
              width="auto"
              height="36px"
              padding="8px"
              gap="4px"
            />
          </div>
          <div
            className="flex flex-row flex-wrap gap-4 mt-3 w-full"
            style={{ overflowY: "scroll", height: "75vh", display: "block" }}
          >
            {Object.keys(props.paymentMethods).map((key) => (
              <ListComponent
                paymentMethod={props.paymentMethods[key]}
                setEdit={setEdit}
                key={`list-component-${key}`}
                paymentId={key.toString()}
                setEditableMethodKey={setEditableMethodKey}
              />
            ))}
          </div>
          <Dialog
            open={addPaymentMethod}
            onClose={() => {
              setAddPaymentMethod(false)
            }}
          >
            <div className="fixed top-0 z-200 left-0 w-screen h-screen flex items-center justify-center bg-[#0000004d] ">
              <PaymentForm
                title={lang.account.payment_methods.payment_form.add_bank_account}
                addPaymentMethod={addPaymentMethod}
                setAddPaymentMethod={setAddPaymentMethod}
                adminPanelUserId={props.adminPanelUserId}
              />
            </div>
          </Dialog>
          <Dialog
            open={edit}
            onClose={() => {
              setEdit(false)
            }}
          >
            <div className="fixed top-0 z-200 left-0 w-screen h-screen flex items-center justify-center bg-[#0000004d] ">
              <PaymentForm
                title={lang.account.payment_methods.payment_form.edit_bank_account}
                edit={edit}
                setEdit={setEdit}
                choosenMethod={props.paymentMethods[editableMethodKey]}
                adminPanelUserId={props.adminPanelUserId}
              />
            </div>
          </Dialog>
        </div>
      </>
    )
  }

  return props.adminPanelUserId ? renderPaymentMethods() : <>{renderPaymentMethods()}</>
}

const mapStateToProps = (state: RootState) => {
  return {
    paymentMethods: state.paymentMethods,
    adminPanelUserId: state.admin.adminPanelUserId,
    user: state.admin.selectedUser
  }
}

export default connect(mapStateToProps, { fetchPaymentMethods })(Payment)
