import "./styles/Admin.css"
import React, { FC, useState } from "react"
import AdminUser from "./user/AdminUser"
import AdminAsset from "./asset/AdminAsset"
import AdminUserModalsContainer from "./user/AdminUserModalsContainer"

export enum AdminUserAssetTabOptions {
  USER = 1,
  ASSET = 2
}
const AdminUserAsset: FC = () => {
  const [tab, setTab] = useState<number>(AdminUserAssetTabOptions.USER)
  const [modalOption, setModalOption] = useState<number | undefined>(undefined)

  return (
    <div className="admin flex relative">
      <div
        className="w-full absolute left-0 top-0 right-0"
        style={{
          opacity: tab === AdminUserAssetTabOptions.USER ? 1 : 0,
          pointerEvents: tab === AdminUserAssetTabOptions.USER ? "auto" : "none"
        }}
      >
        <AdminUser tab={tab} setTab={setTab} modalOption={modalOption} setModalOption={setModalOption} />
      </div>
      <div
        className="w-full absolute left-0 top-0 right-0"
        style={{
          opacity: tab === AdminUserAssetTabOptions.ASSET ? 1 : 0,
          pointerEvents: tab === AdminUserAssetTabOptions.ASSET ? "auto" : "none"
        }}
      >
        <AdminAsset tab={tab} setTab={setTab} modalOption={modalOption} setModalOption={setModalOption} />
      </div>
      <AdminUserModalsContainer modalOption={modalOption} setModalOption={setModalOption} />
    </div>
  )
}

export default AdminUserAsset
