import { Dialog } from "@headlessui/react"
import React, { FC, useState } from "react"
import { X } from "heroicons-react"
import { User } from "../../../../../types"
import { KButton, KInput, KSpan, KTextArea, KTitleSpan } from "kahuna-base-react-components"
import { notifications } from "../../../../../apis"
import { errorFilledToastOptions, NOTIFICATION_TYPES, successFilledToastOptions } from "../../../../../constants"
import { toast } from "react-toastify"

type AdminNotificationSendModalProps = {
  open: boolean
  onClose: () => void
  user: User
}
const AdminNotificationSendModal: FC<AdminNotificationSendModalProps> = (props) => {
  const { onClose, open } = props

  const add = (id: number | undefined, content: string, urlParam: string, buttonParam: string) => {
    if (!id) return

    notifications("/create_notification/", "post", {
      user_id: id.toString(),
      param1: content,
      notification_id: NOTIFICATION_TYPES.OTHER,
      url_param: urlParam,
      button_param: buttonParam
    }).then((resp) => {
      if (resp && resp.status === 200) {
        toast.success("Notification sent successfully.", successFilledToastOptions)
      } else {
        toast.error("Error sending notification.", errorFilledToastOptions)
      }
    })
  }

  const [content, setContent] = useState<string>("") // param1 for userNotification
  const [buttonParam, setButtonParam] = useState<string>("") // buttonParam for userNotification
  const [urlParam, setUrlParam] = useState<string>("")

  return (
    <Dialog
      open={open}
      onClose={() => {
        onClose()
      }}
      className="relative"
    >
      <div className="fixed inset-0 bg-[rgba(0,0,0,0.3)]" aria-hidden="true" />
      <div className="fixed inset-0 flex items-center justify-center p-4">
        <div>
          <div className={`flex justify-end`}>
            <X
              className="h-4 cursor-pointer mt-1"
              style={{ position: "absolute" }}
              onClick={() => {
                onClose()
              }}
            />
          </div>
          <div
            className="bg-white elev-b-sm rounded-lg overflow-hidden w-[450px] flex flex-col gap-4"
            style={{ overflowY: "auto", padding: 42 }}
          >
            <KTitleSpan text={"Send Notification"} fontSize={32} />
            <div className="flex flex-row gap-1">
              <KSpan text="Send to:" color="#000" />
              <KSpan text={`${props.user?.firstName} ${props.user?.lastName}`} color="#000" fontWeight={500} />
            </div>

            <KTextArea
              value={content}
              onChange={(text) => {
                setContent(text)
              }}
              placeholder="Notification content"
              maxHeight={200}
              height={120}
            />

            <KInput
              value={buttonParam}
              onChange={(text) => {
                setButtonParam(text)
              }}
              placeholder="Button text (Optional)"
            />

            <KInput
              value={urlParam}
              onChange={(text) => {
                setUrlParam(text)
              }}
              placeholder="Button Url (Optional)"
            />

            <div className="flex pt-4" style={{ borderTop: "1px solid rgb(220 220 220)" }}>
              <KButton
                text="Cancel"
                background="white"
                shadowDisabled={true}
                onClick={() => {
                  onClose()
                }}
              />
              <KButton
                text="Confirm"
                background="black"
                textColor="white"
                onClick={() => {
                  add(props.user.id, content, urlParam, buttonParam)
                  onClose()
                  // Clear input fields
                  setContent("")
                  setButtonParam("")
                  setUrlParam("")
                }}
                disabled={content.length === 0}
              />
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  )
}

export default AdminNotificationSendModal
