import { Menu, Transition } from "@headlessui/react"
import { getInitials } from "../../../utility"
import * as React from "react"
import { FC, Fragment } from "react"
import { Link, useNavigate } from "react-router-dom"
import { RootState } from "../../../store"
import { connect } from "react-redux"
import { ProfileMenuProps } from "../../../types"
import { logout } from "../../../actions/loginActions"
import ProfileListElement from "./ProfileListElement"
import { KSpan } from "kahuna-base-react-components"
import { lang } from "../../../constants/languages"

const ProfileDropdownMenu: FC<ProfileMenuProps> = (props) => {
  const history = useNavigate()

  const renderPicture = (width: number) => {
    if (props.user && props.user.photo) {
      return <img className={`h-[${width}px] w-[${width}px] rounded-full`} src={props.user.photo} alt="profile_pic" />
    }
    return <img className={`h-[${width}px] w-[${width}px]`} src={"/account_icons/user-circle.svg"} alt="profile_pic" />
  }

  if (!props.loggedIn) return null

  return (
    <Menu as="div" className="ml relative z-50 ">
      <div>
        <Menu.Button>
          <div
            className="flex items-center gap-2 pr-2 pl-2"
            style={{
              height: 36,
              width: 36,
              borderRadius: 10
            }}
          >
            {renderPicture(20)}
          </div>
        </Menu.Button>
      </div>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items
          className={`origin-top-right absolute ${props.isAdmin ? "bottom-2 left-[50px]" : "right-0"} mt-2 p-2 w-[264px] rounded-md shadow-lg py-1 bg-[#ffffff] ring-1
          ring-black ring-opacity-5 focus:outline-none flex flex-col divide-y divide-gray-200`}
        >
          <Menu.Item>
            <div className="flex flex-row gap-2.5 px-[14px] py-3">
              <Link to={"/account/profile"}>
                <div className="aspect-square">{renderPicture(40)}</div>
              </Link>
              <span className="flex flex-col gap-2">
                <KSpan text={`${props.user.firstName} ${props.user.lastName}`} color="#0A0D14" fontWeight={500}></KSpan>
                <KSpan text={`${props.user.role?.name}`} color="#525866" lineHeight="16px" fontSize={12}></KSpan>
              </span>
            </div>
          </Menu.Item>

          <div className="px-1 py-1  ">
            <Menu.Item>
              {({ active }) => (
                <ProfileListElement
                  href="/account/profile"
                  text={lang.menu.profile}
                  iconPath="/navigation_icons/profile_dropdownmenu_icons/user-filled.svg"
                />
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <ProfileListElement
                  href="/account/language"
                  text={lang.menu.language}
                  iconPath="/navigation_icons/profile_dropdownmenu_icons/language.svg"
                />
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <ProfileListElement
                  href="/account/payment-methods"
                  text={lang.menu.payment_methods}
                  iconPath="/navigation_icons/profile_dropdownmenu_icons/wallet-filled.svg"
                />
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <ProfileListElement
                  href="/account/copyright-and-production"
                  text={lang.menu.copyright_and_production}
                  iconPath="/navigation_icons/profile_dropdownmenu_icons/briefcase-filled.svg"
                />
              )}
            </Menu.Item>
          </div>

          {/** TODO: Will be implemented once contracts are ready
             * <Menu.Item>
              {({ active }) => (
                <ProfileListElement
                  href="/account/contracts"
                  text="Contracts"
                  iconPath="/navigation_icons/profile_dropdownmenu_icons/sign-contract.svg"
                />
              )}
            </Menu.Item>
             */}
          {/*props.user.appliedAffiliateUser && (
            <div className="px-1 py-1">
              <Menu.Item>
                {({ active }) => (
                  <ProfileListElement
                    href={""}
                    text={localStorage.getItem("appliedAffiliate") ? "Go Back to Account" : "Change To Affiliate"}
                    iconPath="/navigation_icons/profile_dropdownmenu_icons/swap-small-vertical.svg"
                    onClick={() => {
                      if (localStorage.getItem("appliedAffiliate")) {
                        localStorage.removeItem("appliedAffiliate")
                      } else {
                        localStorage.setItem("appliedAffiliate", `${props.user.appliedAffiliateUser?.id}`)
                      }
                      window.location.reload()
                    }}
                  />
                )}
              </Menu.Item>
            </div>
          )
            */}
          <div className="px-1 py-1">
            <Menu.Item>
              {({ active }) => (
                <ProfileListElement
                  href="/account/profile"
                  text={lang.menu.logout}
                  iconPath="/navigation_icons/profile_dropdownmenu_icons/log-out-filled.svg"
                  onClick={() => props.logout(() => history("/login"))}
                />
              )}
            </Menu.Item>
            <Menu.Item>
              <div className="px-[10px]">
                <a href="https://kahuna.io/pages/terms-of-services" target="_blank">
                  <KSpan text={`v.1.0 -  ${lang.menu.terms_and_condition}`} />
                </a>
              </div>
            </Menu.Item>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  )
}
//appliedAffiliateUser: state.auth.user.appliedAffiliateUser,

const mapStateToProps = (state: RootState) => {
  return { loggedIn: state.auth.loggedIn, user: state.auth.user }
}

export default connect(mapStateToProps, { logout })(ProfileDropdownMenu)
