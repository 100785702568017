import React from "react"
import CardTable from "../../../ui/table/CardTable"

const ReleaseUpcCodes = () => {

  return (
    <div className="flex justify-center">
      <CardTable title={`Release Upc Codes`} urlBase="upc_codes" dataName = "UPC" dataKey="upc" itemName = "UPC Code" iconPath="/record-label.svg" />
    </div>
  )
}

export default ReleaseUpcCodes
