import React, { useEffect, useState } from "react"
import { userRegistration } from "../../../../apis"
import { Registration } from "../../../../types"
import Table from "../../../ui/table/Table"
import registrationColumns from "./registrationColumns"
import RegistrationDetailModal from "./RegistrationDetailModal"
import { KSpan, KTitleSpan } from "kahuna-base-react-components"
import PreviousNextButtons from "./PreviousNextButtons"
import TableNew from "../../../ui/table/TableNew"
//@ts-ignore
import UsersIcon from "../../../../assets/admin_icons/users.svg"

const Registrations = () => {
  const [loading, setLoading] = useState(false)
  const [registration, setRegistration] = useState<Registration[]>([])
  const [selectedRegistration, setSelectedRegistration] = useState<Registration>({} as Registration)
  const [openModal, setOpenModal] = useState(false)
  const [description, setDescription] = useState<{ [key: string]: string }>({})
  const [dbStatus, setDbStatus] = useState<{ [key: string]: string }>({})
  const [startTime, setStartTime] = useState<{ [key: string]: string }>({})
  const [showPastEvents, setShowPastEvents] = useState<boolean>(false)
  const [showCanceledEvents, setShowCanceledEvents] = useState<boolean>(false)
  const [pageTokens, setPageTokens] = useState<{ previousPageToken: string | null; nextPageToken: string | null }>({
    previousPageToken: null,
    nextPageToken: null
  })
  const [minDate, setMinDate] = useState<string | null>(null)

  const count = 10

  const fetchMeets = (
    pastEvents: boolean,
    canceledEvents: boolean,
    previousOrNextPageToken?: string | null,
    minDate?: string | null
  ) => {
    setLoading(true)
    const showPast = pastEvents ? "&show_past=true" : ""
    const showCanceled = canceledEvents ? "" : "&status=active"
    const pageToken = previousOrNextPageToken ? `&page_token=${previousOrNextPageToken}` : ""
    const chosenMinDate = minDate ? `&min_date=${minDate}` : ""
    const requestUrl = `/get_filtered_meetings/?count=${count}${showPast}${showCanceled}${pageToken}${chosenMinDate}`
    userRegistration(requestUrl, "get").then((resp) => {
      const fetchedRegistrations: Registration[] = resp.data.collection?.map((meeting_event: any) => {
        const meetingId = meeting_event.uri.replace("https://api.calendly.com/scheduled_events/", "")
        setDescription((prev) => {
          return {
            ...prev,
            [meetingId]: meeting_event?.details?.description || ""
          }
        })
        setDbStatus((prev) => {
          return {
            ...prev,
            [meetingId]: meeting_event?.details?.db_status || ""
          }
        })
        setStartTime((prev) => {
          return {
            ...prev,
            [meetingId]: meeting_event?.details?.start_time || ""
          }
        })
        return {
          calendly_id: meetingId,
          start_time: meeting_event.start_time,
          calendly_status: meeting_event.status,
          db_status: meeting_event?.details?.db_status || "-",
          description: meeting_event?.details?.description || "",
          created_at: meeting_event?.created_at || "",
          name: meeting_event?.details?.name || "-",
          email: meeting_event?.details?.email || "-",
          phone_number: meeting_event?.details?.phone_number || "-",
          country: meeting_event?.details?.country || "-",
          role: meeting_event?.details?.role || "-",
          artists: meeting_event?.details?.artists || "-",
          hearing_source: meeting_event?.details?.hearing_source || "-"
        }
      })
      setPageTokens({
        previousPageToken: resp.data.pagination?.previous_page_token,
        nextPageToken: resp.data.pagination?.next_page_token
      })
      const pageUrl = resp.data.pagination?.next_page || resp.data.pagination?.previous_page

      if (pageUrl) {
        const parsedUrl = new URL(pageUrl)

        // Use URLSearchParams to extract the query parameters
        const params = new URLSearchParams(parsedUrl.search)

        // Get the value of the 'min_start_time' parameter
        const minStartTime = params.get("min_start_time")

        setMinDate(minStartTime)
      }

      setRegistration(fetchedRegistrations)
      setLoading(false)
    })
  }

  const saveRegistration = async (
    meetingId: string,
    description: string,
    db_status: string,
    type: string,
    start_time: string,
    created_at: string,
    name: string,
    country: string,
    email: string,
    phoneNumber: string,
    role: string,
    artists: string,
    hearingSource: string
  ) => {
    // authentication is needed
    const response = await userRegistration(`/save_registration/`, "POST", {
      calendly_id: meetingId,
      description,
      db_status,
      type,
      start_time,
      created_at,
      name,
      country,
      email,
      phone_number: phoneNumber,
      role,
      artists,
      hearing_source: hearingSource
    })
  }

  useEffect(() => {
    fetchMeets(showPastEvents, showCanceledEvents)
  }, [])

  const onClickDetails = (meetingId: string) => {
    setOpenModal(false)
    const selectedRegistration = registration.find((reg) => reg.calendly_id === meetingId)
    if (!selectedRegistration) return 
    const registrationDetails: Registration = {
      calendly_id: selectedRegistration.calendly_id,
      start_time: selectedRegistration.start_time,
      calendly_status: selectedRegistration.calendly_status,
      db_status: selectedRegistration.db_status,
      description: selectedRegistration.description,
      created_at: selectedRegistration.created_at,
      name: selectedRegistration.name,
      email: selectedRegistration.email,
      phone_number: selectedRegistration.phone_number,
      country: selectedRegistration.country,
      role: selectedRegistration.role,
      artists: selectedRegistration.artists,
      hearing_source: selectedRegistration.hearing_source
    }
    setSelectedRegistration(registrationDetails)
    setOpenModal(true)
  }

  const columns = registrationColumns(
    registration,
    setRegistration,
    description,
    setDescription,
    onClickDetails,
    dbStatus,
    setDbStatus,
    saveRegistration
  )

  const tableTitle = (
    <div className="flex flex-row gap-2 items-center !cursor-default">
      <div className="w-8 h-8 p-1.5 flex items-center justify-center">
        <img src={UsersIcon} />
      </div>
      <div className="pt-0.5">
        <KTitleSpan text="Registrations" color="#111" fontSize={20} lineHeight="28px" />
      </div>
    </div>
  )

  const topRightElements = () => (
    <div className="flex items-end gap-4">
      <div className="flex items-center gap-2">
        <KSpan text="Show Past Events:" color="#000" fontWeight={500} />
        <input
          type="checkbox"
          value=""
          checked={showPastEvents}
          disabled={loading}
          style={{
            accentColor: "#000"
          }}
          onChange={() => {
            const pastEvents = !showPastEvents
            setShowPastEvents(!showPastEvents)
            fetchMeets(pastEvents, showCanceledEvents)
          }}
        />
      </div>
      <div className="flex items-center gap-2">
        <KSpan text="Show Cancelled Events:" color="#000" fontWeight={500} />
        <input
          type="checkbox"
          value=""
          checked={showCanceledEvents}
          disabled={loading}
          style={{
            accentColor: "#000"
          }}
          onChange={() => {
            const canceledEvents = !showCanceledEvents
            setShowCanceledEvents(!showCanceledEvents)
            fetchMeets(showPastEvents, canceledEvents)
          }}
        />
      </div>
    </div>
  )

  return (
    <div className="catalog flex-grow justify-center">
      <div className="w-[100%]">
        <TableNew
          title={tableTitle}
          minRowCount={count + 1}
          limit={count + 1}
          columns={columns}
          data={registration}
          count={registration?.length || 0}
          rowHeightInPx={40}
          loading={loading}
          topRightElement={topRightElements()}
        />
        <div className="w-full pb-5">
          {registration.length > 0 && (
            <PreviousNextButtons
              isPreviousDisabled={!(pageTokens.previousPageToken && !loading)}
              isNextDisabled={!(pageTokens.nextPageToken && !loading)}
              previousClick={() => {
                fetchMeets(showPastEvents, showCanceledEvents, pageTokens.previousPageToken, minDate)
              }}
              nextClick={() => {
                fetchMeets(showPastEvents, showCanceledEvents, pageTokens.nextPageToken, minDate)
              }}
            />
          )}
        </div>
      </div>
      {openModal && (
        <RegistrationDetailModal
          openModal={openModal}
          setOpenModal={setOpenModal}
          meetDetails={selectedRegistration}
          description={description}
          setDescription={setDescription}
          dbStatus={dbStatus}
          setDbStatus={setDbStatus}
          saveRegistration={saveRegistration}
          startTime={startTime}
        />
      )}
    </div>
  )
}

export default Registrations
