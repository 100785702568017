import React, { useState } from "react"
import { USER_ROLE } from "../../../../constants"
import { adminNavElements } from "./adminNavElements"
import AdminNavListElement from "./AdminNavListElement"
export interface adminNavElement {
  name: string
  href: string
  permittedRoles: USER_ROLE[]
  attributeToCheck?: string
  borderBottom?: boolean
  icon: (color?: string) => React.JSX.Element
}

const AdminNavSection = ({ user }: any) => {
  const [selectedIndex, setSelectedIndex] = useState<number>(0)

  return (
    <div className="flex flex-col grow justify-start ">
      {adminNavElements.map((nav:adminNavElement, index: number) => {
        const permittedRoles = nav.permittedRoles.includes(user.roleId)
        const attributeCheck = nav.attributeToCheck ? user[nav.attributeToCheck] : true

        return permittedRoles && attributeCheck ? (
          <div key={`navlist-element-${index}`}
          style={{
            marginTop: nav.name === "Registrations" ? "auto" : "0px"
          }} >
            <AdminNavListElement
              index={index}
              name={nav.name}
              selectedIndex={selectedIndex}
              setSelectedIndex={setSelectedIndex}
              href={nav.href}
              Icon={nav.icon}
            />
          </div>
        ) : null
      })}
    </div>
  )
}

export default AdminNavSection
