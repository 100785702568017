import "./styles/Reports.css"
import ReportsTable from "../../ui/table/ReportsTable"
import reportsColumns from "./columns/reportsColumns"
import React, { FC, useEffect, useState } from "react"
import { RootState } from "../../../store"
import { connect } from "react-redux"
import { ReportsProps, SelectOption, User } from "../../../types"
import { fetchReportsData } from "../../../actions/reportsActions"
import { SearchSelect } from "../../ui/form/select/SearchSelect"
import { handleAuthorizedRequest, users } from "../../../apis"
import { KButton, KSpan, KTitleSpan, KDropdown } from "kahuna-base-react-components"
import { lang } from "../../../constants/languages"
import { KSelectOption } from "kahuna-base-react-components/dist/components/KDropdown/KDropdown"

const Reports: FC<ReportsProps> = (props) => {
  const { fetchReportsData } = props

  const [userOptions, setUserOptions] = useState<KSelectOption[]>([])
  const [loading, setLoading] = useState(true)
  const [userLoading, setUserLoading] = useState(false)
  const [selectedUser, setSelectedUser] = useState<KSelectOption>()
  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout>()
  const [approveDisabled, setApproveDisabled] = useState<boolean>(false)

  const [openSidebar, setOpenSidebar] = useState<boolean>(true)

  const [searchUser, setSearchUser] = useState<boolean>(false)

  const limit = 10

  useEffect(() => {
    if (!props.isAdmin) {
      fetchReportsData().then(() => setLoading(false))
    } else if (props.isAdmin && selectedUser?.value) {
      fetchReportsData(selectedUser?.value, approveDisabled).then(() => setLoading(false))
    }
  }, [selectedUser, approveDisabled])

  const onClickPage = () => {
    setLoading(true)
    fetchReportsData(selectedUser?.value).then(() => setLoading(false))
  }

  const onSearchTextChanged = async (text: string) => {
    if (!text) return
    setUserOptions([])
    setUserLoading(true)
    clearTimeout(timeoutId)
    setTimeoutId(
      setTimeout(async () => {
        await handleAuthorizedRequest(async () => {
          const response = await users(`/?limit=10&offset=0&username=${text}`, "get")
          setUserOptions(
            response.data.results.map((user) => ({
              value: user.id,
              label: user.username
            }))
          )
          setUserLoading(false)
        }, "")
      }, 500)
    )
  }

  const topFilters = (
    <div className="px-0 mb-6">
      {searchUser ? (
        <div
          className="relative"
          style={{ width: 250 }}
          onKeyDown={(event) => {
            if (event.key === "Escape") {
              setSearchUser(false)
            }
          }}
        >
          <KDropdown
            placeholder={"Search user to filter"}
            width={240}
            selected={selectedUser}
            onInputChange={onSearchTextChanged}
            options={userOptions}
            onSelect={(selectedOption) => {
              setSelectedUser(selectedOption as KSelectOption)
            }}
          />
        </div>
      ) : (
        <div className="">
          <KButton
            leftIcon="/reports_icons/user.svg"
            gap="4px"
            text="Search user"
            height="36px"
            width="auto"
            background="#F7F7F7"
            hoverBackground="#F3F3F3"
            activeBackground="#F7F7F7"
            padding="8px"
            shadowDisabled
            onClick={() => {
              setSearchUser(true)
            }}
          />
        </div>
      )}
    </div>
  )

  return (
    <div
      className="catalog flex bg-[#fff]"
      style={{
        flexDirection: props.isAdmin ? "row" : "column"
      }}
    >
      {props.isAdmin && (
        <div
          className={`${
            openSidebar ? "min-w-[230px]" : "min-w-0 !w-[0px]"
          } max-w-[320px] w-[20%] bg-[#F7F7F7] min-h-[100vh] flex flex-col overflow-hidden`}
          style={{
            transitionDuration: "0.5s"
          }}
        >
          <div className="pl-6 pr-3 py-4">
            <KTitleSpan text={lang.reports.title} lineHeight="28px" fontSize={20} color="#111" />
          </div>
          <div className="py-3.5 px-4 h-11">
            <div
              className={`rounded-[10px] p-2 flex flex-row gap-2 ${
                approveDisabled ? "transparent" : "bg-[#FFF]"
              } cursor-pointer`}
              onClick={() => {
                setApproveDisabled(false)
              }}
            >
              <img src="/reports_icons/user-circle.svg" />
              <KSpan text="Overview" fontSize={12} lineHeight="16px" fontWeight={500} color="#111" />
            </div>
          </div>
          <div className="py-3.5 px-4 h-11">
            <div
              className={`rounded-[10px] p-2 flex flex-row gap-2 ${
                approveDisabled ? "bg-[#FFF]" : "transparent"
              } cursor-pointer whitespace-nowrap`}
              onClick={() => {
                setApproveDisabled(!approveDisabled)
              }}
            >
              <img src={"/reports_icons/check.svg"} />
              <KSpan text="Disable approve status " fontSize={12} lineHeight="16px" fontWeight={500} color="#111" />
            </div>
          </div>
        </div>
      )}
      <div className={`grow flex justify-center`}>
        <div className={`${props.isAdmin ? "w-full px-8" : "w-full"}`}>
          <ReportsTable
            title={lang.reports.title}
            pagination
            minRowCount={limit}
            limit={limit}
            columns={reportsColumns}
            data={props.paymentHistory}
            count={props.paymentHistory.length}
            onClickPage={onClickPage}
            rowHeightInPx={40}
            loading={loading}
            isAdmin={props.isAdmin}
            selectedUserId={selectedUser?.value}
            approveDisabled={approveDisabled}
            openSidebar={openSidebar}
            setOpenSidebar={setOpenSidebar}
            topFilters={topFilters}
          />
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    user: state.auth.user,
    paymentHistory: state.reportsData.paymentHistory
  }
}

export default connect(mapStateToProps, { fetchReportsData })(Reports)
