import { TableColumn, UserYoutubeVideoShareData } from "../../../../../types"
import { ROLES, TABLE_COLUMN_TYPE } from "../../../../../constants"
import React, { CSSProperties } from "react"
//@ts-ignore
import CheckCircleGreen from "../../../../../assets/admin_icons/check-circle.svg"
//@ts-ignore
import CloseCircle from "../../../../../assets/admin_icons/close-circle.svg"
//@ts-ignore
import TrashIcon from "../../../../../assets/admin_icons/trash-white.svg"
import { KButton, KSpan, KTooltip } from "kahuna-base-react-components"
import ImageWithFallback from "../../../../ui/components/ImageWithFallback"

const headerStyle: CSSProperties = { textAlign: "left", width: "25%" }
const cellStyle: CSSProperties = { fontWeight: 400, lineHeight: "20px", color: "#111827" }

const adminYoutubeVideoColumns = (
  selectYoutubeVideoShareData: (youtubeVideoShareData: UserYoutubeVideoShareData) => void,
  setOpenYoutubeVideForm: (open: boolean) => void,
  setOpenConfirmation: (open: boolean) => void
): TableColumn[] => {
  return [
    {
      header: "Youtube Video",
      sortFilterField: "youtube_video__title",
      type: TABLE_COLUMN_TYPE.FUNCTION,
      headerStyle: { ...headerStyle, width: "max-content" },
      cellStyle,
      render: (row: UserYoutubeVideoShareData) => (
        <div className="flex">
          <div className="flex w-max">
            <div className="flex flex-row gap-3 items-center">
              <ImageWithFallback coverFileUrl={row?.youtubeVideoDetail?.trackDetail?.release?.coverFileUrl} />
              <div className="flex flex-col justify-between gap-0.5">
                <div>
                  <KSpan text={row.youtubeVideoDetail?.title} color="#000" fontWeight={500} />
                </div>
                <div className="flex flex-row gap-0.5">
                  <div className="rounded-[4px] bg-[#F7F7F7] flex items-center p-1 w-auto no-parent-trigger">
                    <KSpan
                      text={
                        row?.youtubeVideoDetail?.trackDetail?.artistList.map((artist) => artist?.name).join(", ") || "-"
                      }
                      color="#111"
                      fontSize={11}
                      fontWeight={500}
                      lineHeight="12px"
                      letterSpacing="0.22px"
                    />
                  </div>
                  <div className="rounded-[4px] bg-[#F7F7F7] p-1 flex justify-center items-center">
                    <KSpan
                      text={`ISRC: ${row?.youtubeVideoDetail?.isrc}`}
                      color="#111"
                      fontSize={11}
                      fontWeight={500}
                      lineHeight="12px"
                      letterSpacing="0.22px"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    },
    {
      header: "Main Owner",
      type: TABLE_COLUMN_TYPE.FUNCTION,
      headerStyle: { ...headerStyle, width: "15%" },
      cellStyle,
      render: (row: UserYoutubeVideoShareData) => {
        const isMainOwner = row?.roleList?.some((role) => role?.role === ROLES.MAIN_OWNER)
        return (
          <img
            className="w-5 h-5 min-w-5 min-h-5"
            src={isMainOwner ? CheckCircleGreen : CloseCircle}
            alt="check-close-icon"
          />
        )
      }
    },
    {
      header: "Roles",
      type: TABLE_COLUMN_TYPE.FUNCTION,
      headerStyle,
      cellStyle,
      render: (row: UserYoutubeVideoShareData) => {
        const filteredRoles = row.roleList?.filter((role) => role?.role !== 10) //10: Main Owner
        return filteredRoles.length > 0 ? (
          <div className="flex flex-row gap-0.5">
            {filteredRoles.slice(0, 2).map((role, index: number) => {
              return (
                <div
                  key={`${role}-${index}`}
                  className="rounded-[4px] bg-[#F7F7F7] p-1 flex justify-center items-center"
                >
                  <KSpan
                    text={role?.roleDetail?.name}
                    color="#111"
                    fontSize={11}
                    fontWeight={500}
                    lineHeight="12px"
                    letterSpacing="0.22px"
                  />
                </div>
              )
            })}
            {filteredRoles.length > 2 && (
              <KTooltip
                backgroundColor="#000"
                padding="8px"
                arrowColor="#000"
                position="bottom"
                content={
                  <div className="flex flex-col gap-1 items-start">
                    {filteredRoles.slice(2, filteredRoles.length).map((role, index: number) => {
                      return (
                        <KSpan
                          key={`${role}-${index}`}
                          text={role?.roleDetail?.name}
                          fontSize={12}
                          fontWeight={500}
                          lineHeight="16px"
                          color="#FFF"
                        />
                      )
                    })}
                  </div>
                }
              >
                <div className="rounded-[4px] bg-[#F7F7F7] p-1 flex justify-center items-center">
                  <KSpan
                    text={`+${filteredRoles.length - 2}`}
                    color="#111"
                    fontSize={11}
                    fontWeight={500}
                    lineHeight="12px"
                    letterSpacing="0.22px"
                  />
                </div>
              </KTooltip>
            )}
          </div>
        ) : (
          <div className="flex">
            <div className="rounded-[4px] bg-[#F7F7F7] p-1 flex justify-center items-center">
              <KSpan text={`-`} color="#111" fontSize={11} fontWeight={500} lineHeight="12px" letterSpacing="0.22px" />
            </div>
          </div>
        )
      }
    },
    {
      header: "Share",
      attribute: "share",
      sortFilterField: "share",
      type: TABLE_COLUMN_TYPE.FUNCTION,
      headerStyle: { ...headerStyle, width: "15%" },
      cellStyle,
      render: (row: UserYoutubeVideoShareData) => {
        return (
          <div className="flex">
            <div className="rounded-[4px] bg-[#F7F7F7] p-1 flex justify-center items-center">
              <KSpan
                text={`${row?.share}%`}
                color="#111"
                fontSize={11}
                fontWeight={500}
                lineHeight="12px"
                letterSpacing="0.22px"
              />
            </div>
          </div>
        )
      }
    },
    {
      header: "",
      type: TABLE_COLUMN_TYPE.FUNCTION,
      headerStyle: { ...headerStyle, width: "10%", maxWidth: "80px" },
      cellStyle,
      render: (row: UserYoutubeVideoShareData) => {
        return (
          <div className="flex flex-row gap-2 shrink-0 w-full no-parent-trigger">
            <KButton
              icon="/admin_icons/edit.svg"
              padding="8px"
              width="36px"
              height="36px"
              background="#FFF"
              border="1px solid #F3F3F3"
              onClick={() => {
                selectYoutubeVideoShareData(row)
                setOpenYoutubeVideForm(true)
              }}
            />
            <KButton
              icon={TrashIcon}
              padding="8px"
              height="36px"
              width="36px"
              background="#FF5865"
              hoverBackground="#A33841"
              activeBackground="#FF5865"
              onClick={() => {
                setOpenConfirmation(true)
                selectYoutubeVideoShareData(row)
              }}
            />
          </div>
        )
      }
    }
  ]
}

export default adminYoutubeVideoColumns
