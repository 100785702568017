import { AdminCatalogTableColumnType, SelectOption } from "../types"
// @ts-ignore
import countryCodesMap from "./countryCodesMap.json"
import { ToastOptions } from "react-toastify"
import { NotificationIconSolid, WarningIcon } from "../components/ui/icons"
import { lowerCase, omit, startCase } from "lodash"
import { KSelectOption } from "kahuna-base-react-components/dist/components/KDropdown/KDropdown"
import AppleMusicIcon from "../components/ui/img/platforms/apple-music.svg"
import BoomplayIcon from "../components/ui/img/platforms/boom-play.svg"
import MetaIcon from "../components/ui/img/platforms/Meta.svg"
import RessoIcon from "../components/ui/img/platforms/resso.svg"
import SoundcloudIcon from "../components/ui/img/platforms/soundcloud.svg"
import SpotifyIcon from "../assets/release-platform-icons/SpotifyIcon.png"
import TiktokIcon from "../components/ui/img/platforms/tiktok.svg"
import YoutubeIcon from "../components/ui/img/platforms/youtube.svg"
import DeezerIcon from "../components/ui/img/platforms/deezer.svg"
import FizyIcon from "../components/ui/img/platforms/fizy.svg"
import MuudIcon from "../components/ui/img/platforms/muud.svg"
import SnapIcon from "../components/ui/img/platforms/snap.svg"
import PandoraIcon from "../components/ui/img/platforms/pandora.svg"
import NcmIcon from "../components/ui/img/platforms/ncm.svg"
import AppleMusicPlatformIcon from "../assets/release-platform-icons/Apple-Music.png"
import ShazamIcon from "../assets/release-platform-icons/Shazam.png"
import SpotifyPlatformIcon from "../assets/release-platform-icons/Spotify.png"
import SevendigitalIcon from "../assets/release-platform-icons/Sevendigital.png"
import iTunesIcon from "../assets/release-platform-icons/iTunes.png"
import KeefeIcon from "../assets/release-platform-icons/Keefe.png"
import NapsterIcon from "../assets/release-platform-icons/Napster.png"
import StyngrIcon from "../assets/release-platform-icons/Styngr.png"
import ViaPathTechIcon from "../assets/release-platform-icons/ViaPath-Technologies.png"
import AmazonICon from "../assets/release-platform-icons/Amazon.png"
import AudibleMusicIcon from "../assets/release-platform-icons/Audible-Music.png"
import HuaweiMusicIcon from "../assets/release-platform-icons/Huawei-Music.png"
import JPayIcon from "../assets/release-platform-icons/JPay.png"
import BroadTimeIcon from "../assets/release-platform-icons/BroadTime.png"
import AlbaniaIcon from "../assets/country-icons/albania.svg"
import ArgentinaIcon from "../assets/country-icons/argentina.svg"
import AustraliaIcon from "../assets/country-icons/australia.svg"
import AustriaIcon from "../assets/country-icons/austria.svg"
import AzerbaijanIcon from "../assets/country-icons/azerbaijan.svg"
import BelgiumIcon from "../assets/country-icons/belgium.svg"
import BrazilIcon from "../assets/country-icons/brazil.svg"
import BulgariaIcon from "../assets/country-icons/bulgaria.svg"
import CanadaIcon from "../assets/country-icons/canada.svg"
import ColombiaIcon from "../assets/country-icons/colombia.svg"
import CroatiaIcon from "../assets/country-icons/croatia.svg"
import CzechRepublicIcon from "../assets/country-icons/czechrepublic.svg"
import DenmarkIcon from "../assets/country-icons/denmark.svg"
import EnglandIcon from "../assets/country-icons/england.svg"
import EstoniaIcon from "../assets/country-icons/estonia.svg"
import FinlandIcon from "../assets/country-icons/finland.svg"
import FranceIcon from "../assets/country-icons/france.svg"
import GeorgiaIcon from "../assets/country-icons/georgia.svg"
import GermanyIcon from "../assets/country-icons/germany.svg"
import IcelandIcon from "../assets/country-icons/iceland.svg"
import IndiaIcon from "../assets/country-icons/india.svg"
import IranIcon from "../assets/country-icons/iran.svg"
import IraqIcon from "../assets/country-icons/iraq.svg"
import GreeceIcon from "../assets/country-icons/greece.svg"
import IrelandIcon from "../assets/country-icons/ireland.svg"
import ItalyIcon from "../assets/country-icons/italy.svg"
import NetherlandsIcon from "../assets/country-icons/netherlands.svg"
import NorwayIcon from "../assets/country-icons/norway.svg"
import PolandIcon from "../assets/country-icons/poland.svg"
import PortugalIcon from "../assets/country-icons/portugal.svg"
import RussiaIcon from "../assets/country-icons/russia.svg"
import SlovakiaIcon from "../assets/country-icons/slovakia.svg"
import SloveniaIcon from "../assets/country-icons/slovenia.svg"
import SpainIcon from "../assets/country-icons/spain.svg"
import SwedenIcon from "../assets/country-icons/sweden.svg"
import SwitzerlandIcon from "../assets/country-icons/switzerland.svg"
import TurkeyIcon from "../assets/country-icons/turkiye.svg"
import UnitedKingdomIcon from "../assets/country-icons/uk.svg"
import UkraineIcon from "../assets/country-icons/ukraine.svg"
import UruquayIcon from "../assets/country-icons/uruguay.svg"
import UnitedStatesIcon from "../assets/country-icons/usa.svg"
import WalesIcon from "../assets/country-icons/wales.svg"
import EarthIcon from "../assets/country-icons/earth.svg"

//@ts-ignore
import SuccessIcon from "../assets/toast-icons/success.svg"
//@ts-ignore
import WarningIconNew from "../assets/toast-icons/warning.svg"
//@ts-ignore
import ErrorIcon from "../assets/toast-icons/error.svg"
//@ts-ignore
import InfoIcon from "../assets/toast-icons/info.svg"
//@ts-ignore
import EarthDarkerIcon from "../assets/admin_icons/earth.svg"
//@ts-ignore
import MusicAlbumIcon from "../assets/admin_icons/music-album.svg"
//@ts-ignore
import MusicLibraryIcon from "../assets/admin_icons/music-library.svg"
//@ts-ignore
import UserMusicIcon from "../assets/admin_icons/user-music.svg"
//@ts-ignore
import UserIcon from "../assets/admin_icons/user.svg"
//@ts-ignore
import ReviewingIcon from "../assets/admin_icons/clock-gray.svg"
//@ts-ignore
import ActiveIcon from "../assets/admin_icons/check-circle-gray.svg"
//@ts-ignore
import DraftIcon from "../assets/admin_icons/disable-circle-gray.svg"
//@ts-ignore
import ArchivedIcon from "../assets/admin_icons/close-gray.svg"

import React from "react"
import { lang } from "./languages"
import { KSpan } from "kahuna-base-react-components"

export const TITLE_TEMPLATE = "%s - Kahuna"
export const DEFAULT_ROUTE = "/overview"
export const HEADER_PATHS: string[] = [
  "/login",
  "/forgot-password",
  "/reset-password",
  "/account/",
  "/catalog",
  "/overview",
  "/admin",
  "/analytics",
  "/reports",
  "/invitation",
  "/affiliate",
  "/notifications"
]

export const FOOTER_PATHS = [
  "/login",
  "/forgot-password",
  "/reset-password",
  "/account/profile",
  "/account/security",
  "/account/payment",
  "/overview",
  "/catalog",
  "/analytics",
  "/reports",
  "/invitation",
  "/affiliate"
]

export enum TABLE_COLUMN_TYPE {
  TEXT,
  IMG,
  LINK,
  DATE,
  CONST,
  BOOL,
  FUNCTION,
  ROW_NUMBER
}

export const RELEASE_STATUS = {
  active: (
    <div className="bg-green-100 rounded-xl max-w-min text-green-800 text-s pl-[16px] pr-[16px] pt-[2px] pb-[2px] text-center">
      Active
    </div>
  ),
  reviewing: (
    <div className="bg-blue-100 rounded-xl max-w-min text-blue-800 text-s pl-[16px] pr-[16px] pt-[2px] pb-[2px] text-center">
      Reviewing
    </div>
  ),
  draft: (
    <div className="bg-gray-100 rounded-xl max-w-min text-gray-800 text-s pl-[16px] pr-[16px] pt-[2px] pb-[2px] text-center">
      Draft
    </div>
  ),
  archived: (
    <div className="bg-red-100 rounded-xl max-w-min text-red-800 text-s pl-[16px] pr-[16px] pt-[2px] pb-[2px] text-center">
      Archived
    </div>
  )
}

export const ID_SECRET_PHRASE = "4d657373616765"
export const INPUT_DECORATION = {
  HTTPS: "https://"
}

export const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,63}$/

/*Table Styles*/

export const tableCellClasses = {
  sm: "pt-2 pb-2 pl-4 pr-4 text-xs",
  md: "pt-3 pb-3 pl-6 pr-6 text-[13px]",
  lg: "pt-4 pb-4 pl-7 pr-7 text-[14px]"
}

export const tableTitleClasses = {
  sm: "text-l leading-3",
  md: "text-xl leading-5",
  lg: "text-2xl leading-7"
}

export const buttonClasses = {
  sm: "text-xs",
  md: "text-sm",
  lg: "text-base"
}

export const ASSET_TYPES = {
  TRACK: "track",
  YOUTUBE_VIDEO: "youtubeVideo"
}

export const UNAUTHORIZED_ERROR_NAME = "Unauthorized"

export const monthList = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December"
]
export const monthShortList = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]

export const ANALYSIS_FILTER = {
  TOP_TRACKS: "topTracks",
  TOP_PLATFORMS: "platform",
  TOP_COUNTRIES: "storeFront",
  ALL_PLATFORM_TOTAL: "allPlatformTotal",
  ALL_ACCOUNTS: "allAccounts"
}

export const PAYMENT_PERIOD_TYPE_ID = {
  QUARTERLY: 1,
  MONTHLY: 2
}

export const PAYMENT_PERIOD_TYPE = {
  1: "QUARTERLY",
  2: "MONTHLY"
}

export const analysisFilterOptions: KSelectOption[] = [
  {
    value: 2,
    value2: ANALYSIS_FILTER.TOP_PLATFORMS,
    label: "Filtered by platforms",
    icon: MusicLibraryIcon
  },
  {
    value: 1,
    value2: ANALYSIS_FILTER.TOP_TRACKS,
    label: "Filtered by tracks",
    icon: MusicAlbumIcon
  },
  {
    value: 3,
    value2: ANALYSIS_FILTER.TOP_COUNTRIES,
    label: "Filtered by countries",
    icon: EarthDarkerIcon
  },
  {
    value: 4,
    value2: ANALYSIS_FILTER.ALL_PLATFORM_TOTAL,
    label: "All platform totals",
    icon: UserMusicIcon
  },
  {
    value: 5,
    value2: ANALYSIS_FILTER.ALL_ACCOUNTS,
    label: "All accounts",
    icon: UserIcon
  }
]

export const analysisFilterOptionsKDropdown: KSelectOption[] = [
  {
    value: 0,
    value2: ANALYSIS_FILTER.TOP_PLATFORMS,
    label: "Filtered by platforms"
  },
  {
    value: 1,
    value2: ANALYSIS_FILTER.TOP_TRACKS,
    label: "Filtered by tracks"
  },
  {
    value: 2,
    value2: ANALYSIS_FILTER.TOP_COUNTRIES,
    label: "Filtered by countries"
  }
]

export const pieChartColorList = [
  "#F0FED8",
  "#E4DFFF",
  "#FFF1E9",
  "#FAE1EE",
  "#AFEDFA",
  "#CBFFEC",
  "#D7FFC4",
  "#AFB6FA",
  "#FFDACB",
  "#ef5b5b"
]

export const platformIcons = {
  "snp-snap": SnapIcon,
  tiktok: TiktokIcon,
  "res-resso": RessoIcon,
  "spo-spotify": SpotifyIcon,
  youtube: YoutubeIcon,
  "YouTube - Audio Tier": YoutubeIcon,
  "Metafbk-facebook": MetaIcon,
  "Meta-instagram": MetaIcon,
  "apl-apple": AppleMusicIcon,
  "boo-boomplay": BoomplayIcon,
  "dzr-deezer": DeezerIcon,
  "pnd-pandora": PandoraIcon,
  "scu-soundcloud": SoundcloudIcon,
  "ncm-netease-cloud-music": NcmIcon,
  "Fizy - Fizy": FizyIcon,
  "Muud - Muud": MuudIcon
}

export const chartLabels = {
  [ANALYSIS_FILTER.TOP_TRACKS]: { null: "Other tracks total" },
  [ANALYSIS_FILTER.TOP_PLATFORMS]: {
    top_songs: lang.common.chartLabels.platform_options_top_songs,
    "snp-snap": "Snapchat",
    tiktok: "Tiktok",
    "res-resso": "Resso",
    "spo-spotify": "Spotify",
    youtube: "YouTube",
    "YouTube - Audio Tier": "YouTube - Audio Tier",
    "Metafbk-facebook": "Meta Facebook",
    "Meta-instagram": "Meta Instagram",
    "apl-apple": "Apple Music",
    "boo-boomplay": "Boomplay",
    "dzr-deezer": "Deezer",
    "pnd-pandora": "Pandora",
    "scu-soundcloud": "Soundcloud",
    "ncm-netease-cloud-music": "NetEase Cloud Music",
    "Fizy - Fizy": "Fizy",
    "Muud - Muud": "Muud"
  },
  [ANALYSIS_FILTER.TOP_COUNTRIES]: {
    ...countryCodesMap.reduce((map, obj) => {
      map[obj.Code] = obj.Name
      return map
    }, {}),
    "N/A": "Other countries total",
    "": "Not Available"
  }
}

export enum TOAST_TYPES {
  SuccessFilled = "success-filled",
  SuccessStroke = "success-stroke",
  ErrorFilled = "error-filled",
  ErrorStroke = "error-stroke",
  WarningFilled = "warning-filled",
  WarningStroke = "warning-stroke",
  InfoFilled = "info-filled",
  InfoStroke = "info-stroke",
  Other = "other"
}

export const toastTextColors = {
  "success-filled": {
    titleColor: "rgba(22, 56, 39, 1)",
    contentColor: "rgba(22, 56, 39, 0.8)"
  },
  "error-filled": {
    titleColor: "rgba(64, 22, 26, 1)",
    contentColor: "rgba(64, 22, 26, 0.8)"
  },
  "warning-filled": {
    titleColor: "rgba(64, 38, 22, 1)",
    contentColor: "rgba(64, 38, 22, 0.8)"
  },
  "info-filled": {
    titleColor: "rgba(22, 43, 64, 1)",
    contentColor: "rgba(22, 43, 64, 0.8)"
  },
  // success-stroke, error-stroke, warning-stroke, info-stroke and other font colors are same. So they are collected in the same prop.
  // use those types with CustomToastContent.
  other: {
    titleColor: "rgba(0, 0, 0, 1)",
    contentColor: "rgba(0, 0, 0, 0.8)"
  }
}

// use only if the toastContent includes a title too. If just content no need to use this.
export const CustomToastContent = (content: string, title?: string, type?: string) => {
  const toastType = type || TOAST_TYPES.Other
  const titleColor = toastTextColors[toastType]?.titleColor || "#000"
  const contentColor = toastTextColors[toastType]?.contentColor || "rgba(0, 0, 0, 0.8)"

  return (
    <div className="flex flex-col">
      {title && <KSpan text={title} fontWeight={500} color={titleColor} />}
      <KSpan text={content} fontWeight={400} color={contentColor} />
    </div>
  )
}

export const successFilledToastOptions: ToastOptions = {
  className: "custom-toast",
  position: "top-right",
  hideProgressBar: true,
  autoClose: 4000,
  closeButton: false,
  icon: <img src={SuccessIcon} width={44} height={44} />,
  style: {
    backgroundColor: "#ECFBF0",
    borderRadius: "10px",
    color: "#163827",
    fontFamily: "Inter",
    fontSize: "14px",
    padding: "10px",
    lineHeight: "20px",
    fontWeight: "500"
  }
}

export const successStrokeToastOptions: ToastOptions = {
  className: "custom-toast",
  position: "top-right",
  hideProgressBar: true,
  autoClose: 4000,
  closeButton: false,
  icon: <img src={SuccessIcon} width={44} height={44} />,
  style: {
    backgroundColor: "#FFF",
    borderRadius: "10px",
    boxShadow:
      "0px 32px 24px -12px rgba(17, 17, 17, 0.06), 0px 11px 4px 0px rgba(17, 17, 17, 0.01), 0px 6px 4px 0px rgba(17, 17, 17, 0.02), 0px 3px 3px 0px rgba(17, 17, 17, 0.03), 0px 1px 1px 0px rgba(17, 17, 17, 0.04), 0px 0px 0px 1px rgba(17, 17, 17, 0.04)",
    color: "#000",
    fontFamily: "Inter",
    fontSize: "14px",
    padding: "10px",
    lineHeight: "20px"
  }
}

export const errorFilledToastOptions: ToastOptions = {
  className: "custom-toast",
  position: "top-right",
  hideProgressBar: true,
  autoClose: 4000,
  closeButton: false,
  icon: <img src={ErrorIcon} width={44} height={44} />,
  style: {
    backgroundColor: "#FFE6E8",
    borderRadius: "10px",
    color: "#40161A",
    fontFamily: "Inter",
    fontSize: "14px",
    padding: "10px",
    lineHeight: "20px",
    fontWeight: "500"
  }
}

export const errorStrokeToastOptions: ToastOptions = {
  className: "custom-toast",
  position: "top-right",
  hideProgressBar: true,
  autoClose: 4000,
  closeButton: false,
  icon: <img src={ErrorIcon} width={44} height={44} />,
  style: {
    backgroundColor: "#FFF",
    borderRadius: "10px",
    boxShadow:
      "0px 32px 24px -12px rgba(17, 17, 17, 0.06), 0px 11px 4px 0px rgba(17, 17, 17, 0.01), 0px 6px 4px 0px rgba(17, 17, 17, 0.02), 0px 3px 3px 0px rgba(17, 17, 17, 0.03), 0px 1px 1px 0px rgba(17, 17, 17, 0.04), 0px 0px 0px 1px rgba(17, 17, 17, 0.04)",
    color: "#000",
    fontFamily: "Inter",
    fontSize: "14px",
    padding: "10px",
    lineHeight: "20px"
  }
}

export const warningFilledToastOptions: ToastOptions = {
  className: "custom-toast",
  position: "top-right",
  hideProgressBar: true,
  autoClose: 4000,
  closeButton: false,
  icon: <img src={WarningIconNew} width={44} height={44} />,
  style: {
    backgroundColor: "#FFEFE6",
    borderRadius: "10px",
    color: "#402616",
    fontFamily: "Inter",
    fontSize: "14px",
    padding: "10px",
    lineHeight: "20px",
    fontWeight: "500"
  }
}

export const warningStrokeToastOptions: ToastOptions = {
  className: "custom-toast",
  position: "top-right",
  hideProgressBar: true,
  autoClose: 4000,
  closeButton: false,
  icon: <img src={WarningIconNew} width={44} height={44} />,
  style: {
    backgroundColor: "#FFF",
    borderRadius: "10px",
    boxShadow:
      "0px 32px 24px -12px rgba(17, 17, 17, 0.06), 0px 11px 4px 0px rgba(17, 17, 17, 0.01), 0px 6px 4px 0px rgba(17, 17, 17, 0.02), 0px 3px 3px 0px rgba(17, 17, 17, 0.03), 0px 1px 1px 0px rgba(17, 17, 17, 0.04), 0px 0px 0px 1px rgba(17, 17, 17, 0.04)",
    color: "#000",
    fontFamily: "Inter",
    fontSize: "14px",
    padding: "10px",
    lineHeight: "20px"
  }
}

export const infoFilledToastOptions: ToastOptions = {
  className: "custom-toast",
  position: "top-right",
  hideProgressBar: true,
  autoClose: 4000,
  closeButton: false,
  icon: <img src={InfoIcon} width={44} height={44} />,
  style: {
    backgroundColor: "#E6F2FF",
    borderRadius: "10px",
    color: "#162B40",
    fontFamily: "Inter",
    fontSize: "14px",
    padding: "10px",
    lineHeight: "20px",
    fontWeight: "500"
  }
}

export const infoStrokeToastOptions: ToastOptions = {
  className: "custom-toast",
  position: "top-right",
  hideProgressBar: true,
  autoClose: 4000,
  closeButton: false,
  icon: <img src={InfoIcon} width={44} height={44} />,
  style: {
    backgroundColor: "#FFF",
    borderRadius: "10px",
    boxShadow:
      "0px 32px 24px -12px rgba(17, 17, 17, 0.06), 0px 11px 4px 0px rgba(17, 17, 17, 0.01), 0px 6px 4px 0px rgba(17, 17, 17, 0.02), 0px 3px 3px 0px rgba(17, 17, 17, 0.03), 0px 1px 1px 0px rgba(17, 17, 17, 0.04), 0px 0px 0px 1px rgba(17, 17, 17, 0.04)",
    color: "#000",
    fontFamily: "Inter",
    fontSize: "14px",
    padding: "10px",
    lineHeight: "20px"
  }
}

export const adminTableLimit = 10

export const ROLES = {
  AUTHOR: 1,
  ARRANGER: 2,
  CONTRIBUTOR: 3,
  FEATURING: 4,
  REMIXER: 5,
  LYRICIST: 6,
  COMPOSER: 7,
  PRODUCER: 8,
  OTHER: 9,
  MAIN_OWNER: 10
}

export const ROLE_NAMES = {
  1: "Author",
  2: "Arranger",
  3: "Contributor",
  4: "Feauturing",
  5: "Remixer",
  6: "Lyricist",
  7: "Composer",
  8: "Producer",
  9: "Partial Owner",
  10: "Main Owner"
}

export const roleOptions = Object.keys(omit(ROLES, ["MAIN_OWNER"])).map((key) => ({
  label: startCase(lowerCase(key)),
  value: ROLES[key]
}))

export const completeRoleOptions = Object.keys(ROLES).map((key) => ({
  label: startCase(lowerCase(key)),
  value: ROLES[key]
}))

export const avatarColorList = ["#F7F7F7", "#111111", "#D5E9FF", "#D5FFFD", "#DECAFE", "#FFD5D8"]

export const avatarFontColorList = ["#111111", "#FFFFFF", "#162B40", "#16403E", "#1F0C3F", "#40161A"]

export const getRandomAvatarIndex = (index?: number) => {
  if (index) {
    const randomIndex = index % 6
    return randomIndex
  } else {
    const randomIndex = Math.floor(Math.random() * avatarColorList.length)
    return randomIndex
  }
}

export const RELEASE_FILTER = {
  ALL: "all",
  OWNER: "owner",
  CONTRIBUTOR: "contributor"
}

export const defaultTopSongsFilter = "top_songs"

export const topSongsFilterOptions: SelectOption[] = [
  {
    value: "top_songs",
    label: "All Platforms"
  },
  {
    value: "apl-apple",
    label: "Apple Music"
  },
  {
    value: "res-resso",
    label: "Resso"
  },
  {
    value: "snp-snap",
    label: "Snapchat"
  },
  {
    value: "boo-boomplay",
    label: "Boomplay"
  },
  {
    value: "spo-spotify",
    label: "Spotify"
  },
  {
    value: "Metafbk-facebook",
    label: "Meta"
  },
  {
    value: "tiktok",
    label: "Tiktok"
  },
  {
    value: "scu-soundcloud",
    label: "Soundcloud"
  },
  {
    value: "youtube",
    label: "YouTube"
  }
]

export enum USER_ROLE {
  SuperAdmin = 1,
  Admin = 2,
  Artist = 3,
  Collaborator = 4,
  Affiliate = 5,
  MarketingAdmin = 6
}

export const allRoleList = [
  USER_ROLE.SuperAdmin,
  USER_ROLE.Admin,
  USER_ROLE.MarketingAdmin,
  USER_ROLE.Artist,
  USER_ROLE.Collaborator,
  USER_ROLE.Affiliate
]

export const regularRoleList = [
  USER_ROLE.SuperAdmin,
  USER_ROLE.Admin,
  USER_ROLE.MarketingAdmin,
  USER_ROLE.Artist,
  USER_ROLE.Collaborator
]

export const adminRoleList = [USER_ROLE.SuperAdmin, USER_ROLE.Admin, USER_ROLE.MarketingAdmin]

export const userRoleOptions = [
  { label: "Super Admin", value: USER_ROLE.SuperAdmin },
  { label: "Admin", value: USER_ROLE.Admin },
  { label: "Marketing Admin", value: USER_ROLE.MarketingAdmin },
  { label: "Artist", value: USER_ROLE.Artist },
  { label: "Collaborator", value: USER_ROLE.Collaborator },
  { label: "Affiliate", value: USER_ROLE.Affiliate }
]

export const superAdminSelectOption = [{ label: "Super Admin", value: USER_ROLE.SuperAdmin }]

export enum PERMISSIONS {
  NewRelease = 1,
  GetAdvance = 2
}

export const DEFAULT_ROUTES = {
  [USER_ROLE.SuperAdmin]: "/admin/reports-approval",
  [USER_ROLE.Admin]: "/admin/user-asset",
  [USER_ROLE.MarketingAdmin]: "/admin/catalog",
  [USER_ROLE.Artist]: "/overview",
  [USER_ROLE.Collaborator]: "/overview",
  [USER_ROLE.Affiliate]: "/affiliate"
}

export enum COLLAB_INVITATION_TABLE_ROW_STATUS {
  Active = "1",
  Pending = "2",
  Expired = "3",
  Accepted = "4",
  NotPublished = "5" 
}

export const collaboratorInvitationStatus = {
  [COLLAB_INVITATION_TABLE_ROW_STATUS.Active]: {text:"Active", icon: "/catalog_icons/active-status.svg"},
  [COLLAB_INVITATION_TABLE_ROW_STATUS.Pending]: {text: "Pending", icon: "/catalog_icons/reviewing-status.svg"},
  [COLLAB_INVITATION_TABLE_ROW_STATUS.Expired]: {text:"Expired", icon: "/catalog_icons/archived-status.svg"},
  [COLLAB_INVITATION_TABLE_ROW_STATUS.Accepted]: {text: "Accepted", icon: "/catalog_icons/draft-status.svg"},
  [COLLAB_INVITATION_TABLE_ROW_STATUS.NotPublished]: {text: "Not Published", icon: "/catalog_icons/draft-status.svg"}
}

export enum INVITATION_STATUS {
  AcceptRegistered = 1,
  AcceptUnregistered = 2,
  AlreadyAccepted = 3,
  Expired = 4,
  TokenUnrecognized = 5
}

export const INVITATION_ACCEPT_TITLE = {
  [INVITATION_STATUS.AcceptRegistered]: "You accepted invitation!",
  [INVITATION_STATUS.AcceptUnregistered]: "Welcome to the club!",
  [INVITATION_STATUS.AlreadyAccepted]: "Something's wrong!",
  [INVITATION_STATUS.Expired]: "Something's wrong!",
  [INVITATION_STATUS.TokenUnrecognized]: "Something's wrong!"
}

export const INVITATION_ACCEPT_TEXT = {
  [INVITATION_STATUS.AcceptRegistered]:
    "Congratulations! 🎉\n" +
    "\n" +
    "You've successfully accepted this invitation, and as a registered user, you can now access your royalty share information via our dashboard.\n" +
    "\n" +
    "Simply log in to your account to explore your royalty share details and make the most of our platform's features.",
  [INVITATION_STATUS.AcceptUnregistered]:
    "Congratulations! 🎉\n" +
    "\n" +
    "You've successfully accepted this invitation. You will recieve your log in credentials via email soon.\n" +
    "\n" +
    "We're excited to have you on board and look forward to helping you navigate your royalty share journey with ease. Happy exploring!",
  [INVITATION_STATUS.AlreadyAccepted]:
    "Uh-oh, it looks like you've already accepted this invitation 🙌\n" +
    "\n" +
    "The invitation link you clicked on from your email has already approved, as you've previously accepted it.\n" +
    "\n" +
    "If you're experiencing any issues or need assistance with your account, please feel free to reach out to our support.",
  [INVITATION_STATUS.Expired]:
    "Oops, it seems this invitation has expired ⌛\n" +
    "\n" +
    "The invitation link you clicked on from your email has expired, and unfortunately, we can no longer process it. These links typically have a limited timeframe of 28 days for activation.\n" +
    "\n" +
    "If you still wish to access your royalty share information or have any questions, please don't hesitate to contact our support.",
  [INVITATION_STATUS.TokenUnrecognized]:
    "Oops! It seems there's been a hiccup 🙁\n" +
    "\n" +
    "The invitation link you clicked on from your email appears to be corrupt and we couldn't recognize it.\n" +
    "\n" +
    "Please kindly contact our support for further assistance."
}


export const spotifyEditorialPitchingGenreOptions = ["Pop", "R&B", "Rock", "Electronic", "World", "Jazz"]
export const spotifyEditorialPitchingMoodOptions = [
  "Chill",
  "Energetic",
  "Happy",
  "Fierce",
  "Meditative",
  "Romantic",
  "Sad",
  "Sexy",
  "None of these"
]
export const spotifyEditorialPitchingStyleOptions = [
  "Acoustic",
  "Ballad",
  "Beats",
  "Christmas",
  "Experimental",
  "Holiday",
  "Traditional",
  "None of these"
]
export const spotifyEditorialPitchingInstrumentionOptions = [
  "Accordion",
  "Acoustic Guitar",
  "Banjo",
  "Bass Guitar",
  "Bouzouki",
  "Cello",
  "Clarinet",
  "Djembe",
  "Drums",
  "Electric Guitar",
  "Erhu",
  "Flute",
  "Harmonica",
  "Harp",
  "Kora",
  "Mandolin",
  "Mbira",
  "Oboe",
  "Organ",
  "Oud",
  "Pedal Steel Guitar",
  "Piano",
  "Samples",
  "Sanxian",
  "Sarod",
  "Saxophone",
  "Sitar",
  "Steel Drum",
  "Synthesizer",
  "Tabla",
  "Trombone",
  "Trumpet",
  "Ukulele",
  "Violin",
  "Xylophone"
]

export enum USER_PERMISSION {
  NEW_RELEASE = 1,
  GET_ADVANCE = 2,
  UPLOAD_BMV = 3,
  UPLOAD_STATE51 = 4,
  UPLOAD_MESAM = 5,
  UPLOAD_MSG = 6
}

export enum CURRENCY_TYPES {
  TRY = 1,
  USD = 2,
  EUR = 3,
  GBP = 4,
  USD_GBP = 5
}

export const platforms: string[] = [
  "iTunes",
  "Shazam",
  "Apple Music",
  "Spotify",
  "Amazon",
  "Napster",
  "Huawei Music",
  "Audible Music",
  "BroadTime",
  "ViaPath Technologies",
  "JPay",
  "Keefe",
  "Styngr",
  "7digital"
]
export const releasePlatformIcons = {
  iTunes: iTunesIcon,
  Shazam: ShazamIcon,
  "Apple Music": AppleMusicPlatformIcon,
  Spotify: SpotifyPlatformIcon,
  Amazon: AmazonICon,
  Napster: NapsterIcon,
  "Huawei Music": HuaweiMusicIcon,
  "Audible Music": AudibleMusicIcon,
  BroadTime: BroadTimeIcon,
  "ViaPath Technologies": ViaPathTechIcon,
  JPay: JPayIcon,
  Keefe: KeefeIcon,
  Styngr: StyngrIcon,
  SevenDigital: SevendigitalIcon
}
export const state51GenreMapping = {
  "Blues : Acoustic Blues": "Blues",
  "Blues : Blues Rock": "Blues",
  "Blues : Punk Blues": "Blues",
  "Blues : Soul Blues": "Blues",
  "Blues : Traditional": "Blues",
  "Blues : Soul/R&B": "Blues",
  "Children's : Children's": "Children's",
  "Classical : Classical": "Classical",
  "Classical : Film Music": "Classical",
  "Classical : Other": "Classical",
  "Country : Country": "Country",
  "Country : Other": "Country",
  "Electronic : Dance": "Dance",
  "Electronic : Dance-Pop": "Dance",
  "Electronic : DJ": "Electronic",
  "Electronic : Downbeat": "Electronic",
  "Electronic : Electro": "Electronic",
  "Electronic : House": "Electronic",
  "Electronic : I.D.M": "Electronic",
  "Electronic : Other": "Electronic",
  "Electronic : Trip Hop": "Electronic",
  "Folk : Acoustic": "Folk",
  "Folk : Alternative Folk": "Folk",
  "Folk : Folk": "Folk",
  "Folk : Folk Singer/Songwriter": "Folk",
  "Folk : Traditional Folk": "Folk",
  "Hip-Hop/Rap : Alternative Rap": "Hip-Hop/Rap",
  "Hip-Hop/Rap : Other": "Hip-Hop/Rap",
  "Hip-Hop/Rap : R&B": "Hip-Hop/Rap",
  "Hip-Hop/Rap : Rap": "Hip-Hop/Rap",
  "Jazz : Classic Jazz": "Jazz",
  "Jazz : Contemporary Jazz": "Jazz",
  "Jazz : Jazz": "Jazz",
  "Jazz : Jazz/Blues": "Jazz",
  "Jazz : Other": "Jazz",
  "Latin Music : Latin Music": "Latin",
  "Metal : Metal": "Metal",
  "Metal : Other": "Metal",
  "New Age : Other": "New Age",
  "Pop : Dance-Pop": "Dance",
  "Pop : Indie Pop": "Indo Pop",
  "Pop : Other": "Pop",
  "Pop : Pop": "Pop",
  "Pop : Pop Singer/Songwriter": "Singer/Songwriter",
  "Punk : Punk": "Punk",
  "R&B : Neo-Soul": "R&B",
  "R&B : Other": "R&B",
  "R&B : Soul": "R&B",
  "Reggae : Other": "Reggae",
  "Reggae : Reggae": "Reggae",
  "Reggae : Ska": "Reggae",
  "Rock : Pop-Rock": "Rock",
  "Rock : Rock": "Rock",
  "Rock : Rock'n Roll": "Rock",
  "Rock : Rock Singer/Songwriter": "Rock",
  "Soul : Modern Soul": "Rock",
  "Soul : Soul": "Soul",
  "Soul : LoFi": "Soul",
  "Soundtracks : Film Soundtracks": "Soundtrack",
  "Soundtracks : Original Score": "Soundtrack",
  "Soundtracks : Other": "Soundtrack",
  "Soundtracks : Soundtracks": "Soundtrack",
  "Soundtracks : TV Soundtracks": "Soundtrack",
  "Soundtracks : Vocal Pop": "Soundtrack",
  "Video : Video": "Instrumental",
  "World Music : Turkish Electronic": "World",
  "World Music : Turkish Folk": "World",
  "World Music : Turkish Pop": "World",
  "World Music : Turkish Rock": "World",
  "World Music : Turkish World": "World",
  "World Music : World Music": "World"
}

export const state51LanguageMapping = {
  Acoli: "No linguistic content - zxx",
  Afrikaans: "Afrikaans - afr",
  Akan: "No linguistic content - zxx",
  Amharic: "No linguistic content - zxx",
  Arabic: "Arabic - ara",
  Armenian: "No linguistic content - zxx",
  Baatonum: "No linguistic content - zxx",
  Bambara: "No linguistic content - zxx",
  Basque: "No linguistic content - zxx",
  Bassa: "No linguistic content - zxx",
  Belarusian: "No linguistic content - zxx",
  Bengali: "Bengali - ben",
  Bete: "No linguistic content - zxx",
  Bhojpuri: "No linguistic content - zxx",
  Bosnian: "No linguistic content - zxx",
  Breton: "No linguistic content - zxx",
  Bulgarian: "Bulgarian - bul",
  Cantonese: "No linguistic content - zxx",
  Catalan: "Catalan or Valencian - cat",
  "Central Huasteca Nahuatl": "No linguistic content - zxx",
  Chinese: "Chinese - zho",
  "Chinese (Simplified)": "Chinese - zho",
  "Chinese (Traditional)": "Yue Chinese - yue",
  "Church Slavic": "No linguistic content - zxx",
  Corsican: "No linguistic content - zxx",
  "Creole (Guadeloupe)": "No linguistic content - zxx",
  "Creole (Martinique)": "No linguistic content - zxx",
  "Creole (Reunion)": "No linguistic content - zxx",
  Croatian: "Croatian - hrv",
  Czech: "Czech - ces",
  Danish: "Danish - dan",
  Ditammari: "No linguistic content - zxx",
  Duala: "No linguistic content - zxx",
  Dutch: "Dutch or Flemish - nld",
  "Dyula (Burkina Faso)": "No linguistic content - zxx",
  "Dyula (Côte d'Ivoire)": "No linguistic content - zxx",
  Dzongkha: "No linguistic content - zxx",
  English: "English - eng",
  Estonian: "Estonian - est",
  Fanti: "No linguistic content - zxx",
  Finnish: "Finnish - fin",
  Fon: "No linguistic content - zxx",
  French: "French - fra",
  "Fulah (Burkina Faso)": "No linguistic content - zxx",
  "Fulah (Guinea)": "No linguistic content - zxx",
  "Fulah (Mali)": "No linguistic content - zxx",
  "Fulah (Nigeria)": "No linguistic content - zxx",
  "Fulah (Senegal)": "No linguistic content - zxx",
  Fulfulde: "No linguistic content - zxx",
  Gaelic: "No linguistic content - zxx",
  Galician: "No linguistic content - zxx",
  Georgian: "No linguistic content - zxx",
  German: "German - deu",
  "Ghanaian Pidgin English": "No linguistic content - zxx",
  Greek: "Modern Greek (1453-) - ell",
  "Guianese Creole French": "No linguistic content - zxx",
  Gujarati: "No linguistic content - zxx",
  Haitian: "Haitian or Haitian Creole - hat",
  Haryanvi: "No linguistic content - zxx",
  Hebrew: "Hebrew - heb",
  Hindi: "Hindi - hin",
  "Humburi Senni Songhay": "No linguistic content - zxx",
  Hungarian: "Hungarian - hun",
  Icelandic: "Icelandic - isl",
  Igbo: "No linguistic content - zxx",
  Indonesian: "Indonesian - ind",
  Instrumental: "No linguistic content - zxx",
  Irish: "Irish - gle",
  Italian: "Italian - ita",
  Japanese: "Japanese - jpn",
  Kabyle: "No linguistic content - zxx",
  Kamba: "No linguistic content - zxx",
  Kannada: "No linguistic content - zxx",
  Kazakh: "Kazakh - kaz",
  Khmer: "No linguistic content - zxx",
  Kikuyu: "No linguistic content - zxx",
  Kimbundu: "No linguistic content - zxx",
  Korean: "Korean - kor",
  "Koyra Chiini Songhay": "No linguistic content - zxx",
  Lao: "Lao - lao",
  Latin: "Latin - lat",
  Latvian: "Latvian - lav",
  "Lingala (Angola)": "No linguistic content - zxx",
  "Lingala (Central African Republic)": "No linguistic content - zxx",
  "Lingala (Democratic Republic of the Congo)": "No linguistic content - zxx",
  "Lingala (Republic of the Congo)": "No linguistic content - zxx",
  Lithuanian: "Lithuanian - lit",
  Luo: "No linguistic content - zxx",
  Magahi: "No linguistic content - zxx",
  Maithili: "No linguistic content - zxx",
  Malagasy: "No linguistic content - zxx",
  Malay: "Malay - msa",
  Maltese: "No linguistic content - zxx",
  Mandinka: "No linguistic content - zxx",
  Maori: "No linguistic content - zxx",
  Marathi: "No linguistic content - zxx",
  Mongolian: "No linguistic content - zxx",
  Multilingual: "No linguistic content - zxx",
  Neapolitan: "No linguistic content - zxx",
  "Ngazidja Comorian": "No linguistic content - zxx",
  Njebi: "No linguistic content - zxx",
  "North Ndebele": "No linguistic content - zxx",
  "Northern Sotho": "No linguistic content - zxx",
  Norwegian: "Norwegian - nor",
  Nuer: "No linguistic content - zxx",
  Occitan: "No linguistic content - zxx",
  Odia: "No linguistic content - zxx",
  Persian: "Persian - fas",
  Polish: "Polish - pol",
  Portuguese: "Portuguese - por",
  Punjabi: "Panjabi or Punjabi - pan",
  Rajasthani: "No linguistic content - zxx",
  Romanian: "Romanian - ron",
  Russian: "Russian - rus",
  Sanskrit: "Sanskrit - san",
  Saramacan: "No linguistic content - zxx",
  Serbian: "No linguistic content - zxx",
  Shona: "No linguistic content - zxx",
  Slovak: "Slovak - slk",
  Slovenian: "Slovenian - slv",
  Somali: "No linguistic content - zxx",
  "Soninke (Gambia)": "No linguistic content - zxx",
  "Soninke (Mali)": "No linguistic content - zxx",
  "Soninke (Mauritania)": "No linguistic content - zxx",
  "Soninke (Senegal)": "No linguistic content - zxx",
  "Southern Samo": "No linguistic content - zxx",
  "Southern Sotho": "No linguistic content - zxx",
  Spanish: "Spanish or Castilian - spa",
  "Spanish (Latin America)*": "Spanish or Castilian - spa",
  "Susu (Guinea)": "No linguistic content - zxx",
  "Susu (Sierra Leone)": "No linguistic content - zxx",
  "Swahili (Democratic Republic of the Congo)": "No linguistic content - zxx",
  "Swahili (Kenya)": "No linguistic content - zxx",
  "Swahili (Uganda)": "No linguistic content - zxx",
  Swedish: "Swedish - swe",
  Tagalog: "Tagalog - tgl",
  Tamasheq: "No linguistic content - zxx",
  Tamil: "Tamil - tam",
  Telugu: "Telugu - tel",
  Thai: "Thai - tha",
  Tsonga: "No linguistic content - zxx",
  Tsotsitaal: "No linguistic content - zxx",
  Tswana: "No linguistic content - zxx",
  Turkish: "Turkish - tur",
  Twi: "No linguistic content - zxx",
  Ukrainian: "Ukrainian - ukr",
  Undefined: "No human vocals",
  Urdu: "Urdu - urd",
  "Varhadi-Nagpuri": "No linguistic content - zxx",
  Vietnamese: "Vietnamese - vie",
  Waama: "No linguistic content - zxx",
  Walloon: "No linguistic content - zxx",
  Welsh: "No linguistic content - zxx",
  "Wolof (Gambia)": "No linguistic content - zxx",
  "Wolof (Mauritania)": "No linguistic content - zxx",
  "Wolof (Senegal)": "No linguistic content - zxx",
  Xhosa: "No linguistic content - zxx",
  Yoruba: "No linguistic content - zxx",
  Zulu: "Zulu - zul",
  dmn: "No linguistic content - zxx"
}

export const NEW_RELEASE_TYPES = {
  BMV: "bmv",
  MESAM_MSG: "mesam_msg",
  EDITORIAL_PITCHING: "editorial_pitching",
  STATE_51: "state_51"
}

export const USER_CREDIT_TYPES = {
  FREE: "free",
  ESSENTIALS: "essentials",
  STANDARD: "standard",
  PREMIUM: "premium",
  CUSTOM: "custom"
}

export const userCreditTypeOptions: KSelectOption[] = [
  { label: "Free", value: 1, value2: USER_CREDIT_TYPES.FREE },
  { label: "Essentials", value: 2, value2: USER_CREDIT_TYPES.ESSENTIALS },
  { label: "Standard", value: 3, value2: USER_CREDIT_TYPES.STANDARD },
  { label: "Premium", value: 4, value2: USER_CREDIT_TYPES.PREMIUM },
  { label: "Custom", value: 5, value2: USER_CREDIT_TYPES.CUSTOM }
]

export const RELEASE_STATUSES = {
  DRAFT: "draft",
  ACTIVE: "active",
  REVIEWING: "reviewing",
  ARCHIVED: "archived"
}

export const releaseStatusOptions = [
  { label: "Draft", value: RELEASE_STATUSES.DRAFT, icon: DraftIcon, iconColored: "/catalog_icons/draft-status.svg" },
  {
    label: "Active",
    value: RELEASE_STATUSES.ACTIVE,
    icon: ActiveIcon,
    iconColored: "/catalog_icons/active-status.svg"
  },
  {
    label: "Reviewing",
    value: RELEASE_STATUSES.REVIEWING,
    icon: ReviewingIcon,
    iconColored: "/catalog_icons/reviewing-status.svg"
  },
  {
    label: "Archived",
    value: RELEASE_STATUSES.ARCHIVED,
    icon: ArchivedIcon,
    iconColored: "/catalog_icons/archived-status.svg"
  }
]

export const countryIcons = {
  Albania: AlbaniaIcon,
  Argentina: ArgentinaIcon,
  Australia: AustraliaIcon,
  Austria: AustriaIcon,
  Azerbaijan: AzerbaijanIcon,
  Belgium: BelgiumIcon,
  Brazil: BrazilIcon,
  Bulgaria: BulgariaIcon,
  Canada: CanadaIcon,
  Colombia: ColombiaIcon,
  Croatia: CroatiaIcon,
  "Czech Republic": CzechRepublicIcon,
  Denmark: DenmarkIcon,
  England: EnglandIcon,
  Estonia: EstoniaIcon,
  Finland: FinlandIcon,
  France: FranceIcon,
  Georgia: GeorgiaIcon,
  Germany: GermanyIcon,
  Iceland: IcelandIcon,
  India: IndiaIcon,
  Iran: IranIcon,
  Iraq: IraqIcon,
  Greece: GreeceIcon,
  Ireland: IrelandIcon,
  Italy: ItalyIcon,
  Netherlands: NetherlandsIcon,
  Norway: NorwayIcon,
  Poland: PolandIcon,
  Portugal: PortugalIcon,
  Russia: RussiaIcon,
  Slovakia: SlovakiaIcon,
  Slovenia: SloveniaIcon,
  Spain: SpainIcon,
  Sweden: SwedenIcon,
  Switzerland: SwitzerlandIcon,
  Turkey: TurkeyIcon,
  "United Kingdom": UnitedKingdomIcon,
  Ukraine: UkraineIcon,
  Uruquay: UruquayIcon,
  "United States": UnitedStatesIcon,
  Wales: WalesIcon,
  "Other countries total": EarthIcon
}

export const initialHearingSources = {
  social_media: {
    label: lang.start_your_journey.how_hear_us_page.social_media,
    selected: false,
    suboptions: {
      facebook: { label: "Facebook", selected: false },
      instagram: { label: "Instagram", selected: false },
      twitter: { label: "X (Twitter)", selected: false },
      youtube: { label: "Youtube", selected: false },
      other: { label: lang.start_your_journey.how_hear_us_page.other, selected: false, value: "" }
    }
  },
  search_engine: {
    label: lang.start_your_journey.how_hear_us_page.search_engine,
    selected: false,
    suboptions: {
      google: { label: "Google", selected: false },
      bing: { label: "Bing", selected: false },
      yandex: { label: "Yandex", selected: false },
      other: { label: lang.start_your_journey.how_hear_us_page.other, selected: false, value: "" }
    }
  },
  email_newsletter: {
    label: lang.start_your_journey.how_hear_us_page.email_newsletter,
    selected: false
  },
  online_ads: {
    label: lang.start_your_journey.how_hear_us_page.online_ads,
    selected: false,
    suboptions: {
      facebook: { label: "Facebook ads", selected: false },
      instagram: { label: "Instagram ads", selected: false },
      youtube: { label: "Youtube ads", selected: false },
      other: { label: lang.start_your_journey.how_hear_us_page.other, selected: false }
    }
  },
  blogs_or_articles: {
    label: lang.start_your_journey.how_hear_us_page.blogs_or_articles,
    selected: false
  },
  affiliate_links: {
    label: lang.start_your_journey.how_hear_us_page.affiliate_links,
    selected: false
  },
  customer_review_sites: {
    label: lang.start_your_journey.how_hear_us_page.customer_review_sites,
    selected: false
  },
  other: {
    label: lang.start_your_journey.how_hear_us_page.other,
    selected: false,
    value: ""
  }
}

export enum NOTIFICATION_TYPES {
  OTHER = 1,
  RELEASE = 2
}

export enum REGISTERED_USER_TYPES {
  DEMO = "demo",
  SUBSCRIPTION = "subscription",
  NOT_ELIGIBLE = "not eligible"
}

export const adminCatalogTableColumns: AdminCatalogTableColumnType[] = [
  { name: "Release", visible: true },
  { name: "Release Date", visible: true },
  { name: "Genre", visible: true },
  { name: "Label", visible: true },
  { name: "UPC", visible: true },
  { name: "Status", visible: true },
  { name: "BMV", visible: true },
  { name: "MESAM MSG", visible: true },
  { name: "Editorial Pitching", visible: true },
  { name: "State51 ID", visible: true },
  {name: "Show", visible: true },
  {name: "Upload", visible: true }
]

export const userTypeOptions: KSelectOption[] = [
  { label: "Overview", value: -1 },
  { label: "Super Admin", value: USER_ROLE.SuperAdmin },
  { label: "Admin", value: USER_ROLE.Admin },
  { label: "Marketing Admin", value: USER_ROLE.MarketingAdmin },
  { label: "Artist", value: USER_ROLE.Artist },
  { label: "Affiliate", value: USER_ROLE.Affiliate },
  { label: "Collaborator", value: USER_ROLE.Collaborator }
]

export enum AdminUserModalOptions {
  RELOAD,
  PAYMENT_METHODS,
  USER_CREDITS,
  USER_ADVANCE,
  AFFILIATE_FROM_LIST,
  AFFILIATE_TO_LIST,
  SEND_NOTIFICATION,
  USER_EXPENSE
}