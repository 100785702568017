import React, { useEffect, useRef, useState } from "react"
import { RootState } from "../../../store"
import { connect } from "react-redux"
import { AnalysisSummary, AnalysisTotal } from "../../../types"
import { KDropdown, KSelectRange, KSpan, KTitleSpan } from "kahuna-base-react-components"
import { KSelectOption } from "kahuna-base-react-components/dist/components/KDropdown/KDropdown"
import { MultiValue } from "react-select"
import { Line } from "react-chartjs-2"
import {
  addCommasToNumber,
  fillDatesBetween,
  formatDateObject,
  formatNumber,
  getCurrency,
  getSummaries,
  mapListToCamelCase,
  subOptionsSetter
} from "../../../utility"
import ChartDataLabels from "chartjs-plugin-datalabels"
import { Chart as ChartJS, registerables, LineElement, ChartOptions } from "chart.js"
import { externalTooltipHandler } from "./externalTooltip"
import { analysisFilterOptionsKDropdown } from "../../../constants"
import { DateRangeType } from "kahuna-base-react-components/dist/components/KSelectRange/KSelectRange"
import { lang } from "../../../constants/languages"

ChartJS.register(...registerables, ChartDataLabels, LineElement)

const rangeOptions: KSelectOption[] = [
  { label: "3 months", value: 2 },
  { label: "6 months", value: 5 },
  { label: "12 months", value: 11 },
  { label: "All times", value: -1 }
]

export interface ChartData {
  totalNetDatas: {
    date: string
    totalNet: number
    labelSpecificTotalNet: {
      label: string
      totalNet: number
    }[]
  }[]
  streamDatas: {
    date: string
    stream: number
    labelSpecificStream: {
      label: string
      stream: number
    }[]
  }[]
}

const AnalysisLineChart = ({
  totals,
  currency,
  initialData,
  filterOptions,
  monthRange,
  defaultStartDate,
  defaultEndDate
}: {
  totals: AnalysisTotal[]
  currency: string
  initialData: AnalysisSummary[]
  filterOptions?: { [key: string]: KSelectOption[] }
  monthRange: number
  defaultStartDate: Date
  defaultEndDate: Date
}) => {
  const initialChartData: ChartData = {
    totalNetDatas: [],
    streamDatas: []
  }
  const [dateValues, setDateValues] = useState<DateRangeType>([
    defaultStartDate,
    defaultEndDate
  ])
  const [approvedDateValues, setApprovedDateValues] = useState<DateRangeType>([null, null])

  const [loading, setLoading] = useState<boolean>(false)
  const [totalValues, setTotalValues] = useState<{ revenue: number; stream: number }>({
    revenue: 0,
    stream: 0
  })
  const [data, setData] = useState<AnalysisSummary[]>(initialData)
  //const [chosenRange, setChosenRange] = useState<KSelectOption | undefined>(undefined)
  const [firstAndLastMonths, setFirstAndLastMonths] = useState<{
    firstMonth: string | undefined
    lastMonth: string | undefined
  }>({
    firstMonth: undefined,
    lastMonth: undefined
  })

  const [selectedSubOptions, setSelectedSubOptions] = useState<KSelectOption | MultiValue<KSelectOption> | undefined>(
    [] as KSelectOption[]
  )

  const [hoveredIndex, setHoveredIndex] = useState<{ datasetIndex: number; dataIndex: number }>({
    datasetIndex: -1,
    dataIndex: -1
  })
  const [percentChange, setPercentChange] = useState<number | undefined>(undefined)
  const [increased, setIncreased] = useState<boolean | null>(null)

  const [chartData, setChartData] = useState<ChartData>(initialChartData)
  const [subOptions, setSubOptions] = useState<KSelectOption[]>([])

  const datePickerRef = useRef<any>(null)

  useEffect(() => {
    const subOptionSetting = async () => {
      if (filterOptions) {
        setLoading(true)

        await subOptionsSetter(analysisFilterOptionsKDropdown[0], filterOptions, setSubOptions)
        setLoading(false)
      }
    }
    if (filterOptions) {
      subOptionSetting()
    }
  }, [filterOptions])

  useEffect(() => {
    const getAllSummary = async () => {
      if (approvedDateValues && approvedDateValues[0] && approvedDateValues[1]) {
        let responseData
        const startDate = formatDateObject(approvedDateValues[0])
        const endDate = formatDateObject(approvedDateValues[1])

        if ((selectedSubOptions as KSelectOption[]).length === 0) {
          // This will show the specific month, not a range, so start and end will be the same.
          responseData = await getSummaries("platform", "all_platforms", false, startDate, endDate)
        } else {
          const optionsArray = selectedSubOptions as KSelectOption[]
          const value = optionsArray.map((subOption) => subOption.value2).join(",")
          responseData = await getSummaries("platform", value, false, startDate, endDate)
        }
        setData(mapListToCamelCase(responseData))
      }
    }
    getAllSummary().then(() => {})
  }, [selectedSubOptions, approvedDateValues])

  useEffect(() => {
    if (initialData) {
      setData(initialData)
    }
  }, [initialData])

  useEffect(() => {
    if (hoveredIndex.dataIndex > 0) {
      if (hoveredIndex.datasetIndex === 0) {
        const currentMonth = chartData?.totalNetDatas?.[hoveredIndex.dataIndex]?.totalNet
        const previousMonth = chartData?.totalNetDatas?.[hoveredIndex.dataIndex - 1]?.totalNet
        if (currentMonth && previousMonth) {
          const calculatedPercentChange = Number(
            (((Number(currentMonth) - Number(previousMonth)) / Number(previousMonth)) * 100).toFixed(0)
          )
          setPercentChange(calculatedPercentChange)
        }
        if (
          chartData?.totalNetDatas?.[hoveredIndex.dataIndex]?.totalNet >
          chartData?.totalNetDatas?.[hoveredIndex.dataIndex - 1]?.totalNet
        ) {
          setIncreased(true)
        } else {
          setIncreased(false)
        }
      } else if (hoveredIndex.datasetIndex === 1) {
        const currentMonth = chartData?.streamDatas?.[hoveredIndex.dataIndex]?.stream
        const previousMonth = chartData?.streamDatas?.[hoveredIndex.dataIndex - 1]?.stream
        if (currentMonth && previousMonth) {
          const calculatedPercentChange = Number(
            (((Number(currentMonth) - Number(previousMonth)) / Number(previousMonth)) * 100).toFixed(0)
          )
          setPercentChange(calculatedPercentChange)
        }
        if (
          chartData?.streamDatas?.[hoveredIndex.dataIndex]?.stream >
          chartData?.streamDatas?.[hoveredIndex.dataIndex - 1]?.stream
        ) {
          setIncreased(true)
        } else {
          setIncreased(false)
        }
      }
    } else {
      setIncreased(null)
      setPercentChange(undefined)
    }
  }, [hoveredIndex])

  useEffect(() => {
    if (data && totals) {
      const defaultStartYear = defaultStartDate.getFullYear()
      const defaultStartMonth = String(defaultStartDate.getMonth() + 1).padStart(2, "0")
      const firstMonth =
        approvedDateValues && approvedDateValues[0]
          ? formatDateObject(approvedDateValues[0])
          : `${defaultStartYear}${defaultStartMonth}`
      
      const defaultEndYear = defaultEndDate.getFullYear()
      const defaultEndMonth = String(defaultEndDate.getMonth() + 1).padStart(2, "0")
      const lastMonth =
        approvedDateValues && approvedDateValues[1]
          ? formatDateObject(approvedDateValues[1])
          : `${defaultEndYear}${defaultEndMonth}`
      const months = fillDatesBetween(firstMonth, lastMonth)
      const newDatasTotalNet: {
        date: string
        totalNet: number
        labelSpecificTotalNet: { label: string; totalNet: number }[]
      }[] = []
      const newDatasStream: {
        date: string
        stream: number
        labelSpecificStream: { label: string; stream: number }[]
      }[] = []
      //const platformSpecificValues: { [key: string]: number} = []
      months.forEach((month, index) => {
        const labelSpecificTotal: { label: string; totalNet: number }[] = []
        const labelSpecificStream: { label: string; stream: number }[] = []

        const result = data
          .filter((summary) => {
            if (summary.date === month) {
              const label = summary.label
              labelSpecificTotal.push({ label, totalNet: Number(summary.totalNet) })
              labelSpecificStream.push({ label, stream: summary.quantity })

              return true
            }
            return false
          })
          .reduce(
            (accumulator, currentValue) => {
              accumulator.totalNet += Number(currentValue.totalNet)
              accumulator.stream += currentValue.quantity

              return accumulator
            },
            { totalNet: 0, stream: 0 }
          )

        newDatasTotalNet.push({
          date: month,
          totalNet: result.totalNet || 0,
          labelSpecificTotalNet: labelSpecificTotal
        })
        newDatasStream.push({ date: month, stream: result.stream || 0, labelSpecificStream })
      })
      const totalRevenue = newDatasTotalNet.reduce((sum, item) => sum + item.totalNet, 0)
      const totalStream = newDatasStream.reduce((sum, item) => sum + item.stream, 0)

      setChartData({ totalNetDatas: newDatasTotalNet, streamDatas: newDatasStream })
      setTotalValues({ revenue: totalRevenue, stream: totalStream })
    }
  }, [data])

  const myChartData = {
    labels: chartData.totalNetDatas.map((data) => data?.date),
    datasets: [
      {
        label: lang.analytics.revenue,
        data: chartData.totalNetDatas.map((data) => Number(data.totalNet.toFixed(2))),
        borderColor: hoveredIndex.datasetIndex === 1 ? "#EBE0FF" : "#7C2DFC",
        backgroundColor: hoveredIndex.datasetIndex === 1 ? "#EBE0FF" : "#7C2DFC",
        borderWidth: 2,
        yAxisID: "y",
        pointRadius: 0,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: "#000",
        pointHoverBorderColor: "#FFF",
        order: hoveredIndex.datasetIndex === 1 ? 2 : 1,
        borderSkipped: false
      },
      {
        label: lang.analytics.stream,
        data: chartData.streamDatas.map((data) => data?.stream),
        borderColor: hoveredIndex.datasetIndex === 0 ? "#F7DADA" : "#FF58EC",
        backgroundColor: hoveredIndex.datasetIndex === 0 ? "#F7DADA" : "#FF58EC",
        yAxisID: "y1",
        pointRadius: 0,
        borderWidth: 2,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: "#000",
        pointHoverBorderColor: "#FFF",
        order: hoveredIndex.datasetIndex === 0 ? 2 : 1,
        borderSkipped: false
      }
    ]
  }

  const options: ChartOptions<"line"> = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true,
        position: "bottom",
        align: "end",
        labels: {
          color: "#000",
          usePointStyle: true,
          pointStyle: "circle",
          font: {
            family: "Inter",
            weight: 500,
            size: 13,
            lineHeight: "13px"
          },
          boxWidth: 5,
          boxHeight: 5
        }
      },
      title: {
        display: false,
        text: "Line Chart with Two Datasets"
      },
      datalabels: {
        display: false
      },
      tooltip: {
        intersect: false,
        displayColors: false,
        enabled: false, // it makes the original tooltip appear or disappear
        padding: 12,
        external: (context) => externalTooltipHandler(context, chartData, currency),
        xAlign: "left"

        /* callbacks: {
          label: (tooltipItem) => {
            const value = tooltipItem.raw
            const datasetIndex = tooltipItem.datasetIndex
            if (datasetIndex === 0) {
              return `Revenue: ${value}`
            } else {
              return `Streams: ${addCommasToNumber(String(value))}`
            }
          }
        }*/
      }
    },
    hover: {
      intersect: false
    },
    scales: {
      x: {
        offset: true,
        display: false,
        border: {
          display: false
        },
        grid: {
          display: false
        }
      },
      y: {
        offset: true,
        display: false,
        border: {
          display: false
        },
        grid: {
          display: false
        }
      },
      y1: {
        offset: true,
        display: false,
        border: {
          display: false
        },
        grid: {
          display: false
        }
      }
    },
    onHover: (event, chartElements) => {
      if (chartElements.length > 0) {
        const element = chartElements[0]
        const hoveredDatasetIndex = element.datasetIndex
        const hoveredDataIndex = element.index
        if (hoveredDatasetIndex !== hoveredIndex.datasetIndex || hoveredDataIndex !== hoveredIndex.dataIndex) {
          setHoveredIndex({ datasetIndex: hoveredDatasetIndex, dataIndex: hoveredDataIndex })
        }
      } else {
        setHoveredIndex((prev) => {
          return { ...prev, datasetIndex: -1, dataIndex: -1 }
        })
      }
    }
  }
  
  return (
    <div
      className="flex flex-row h-[324px] w-full p-5"
      style={{
        borderRadius: "10px",
        border: "1px solid var(--stroke-soft-200, #F3F3F3)",
        background: "var(--bg-white-0, #FFF)",
        boxShadow: "0px 2px 4px 0px rgba(27, 28, 29, 0.04)"
      }}
    >
      <div className="flex flex-col justify-between w-[25%]">
        <div className="flex flex-row gap-3 items-start">
          <div className="max-w-[160px]">
            <KDropdown
              options={subOptions}
              isMulti
              onSelect={(elements) => {
                if (elements) {
                  setSelectedSubOptions(elements)
                }
                if (!approvedDateValues || (approvedDateValues && !approvedDateValues[0] && !approvedDateValues[1])) {
                  setApprovedDateValues([defaultStartDate, defaultEndDate])
                }
              }}
              placeholder={lang.analytics.all_platforms_placeholder}
              showOnlyIconsInMulti
              selected={selectedSubOptions}
              rightIcon="/analytics_icons/caret-down-new.svg"
              menuWidth={170}
              enableIndicator={true}
              placeholderColor="#000"
            />
          </div>
          <div className="mt-[1.5px]">
            <KSelectRange
              value={dateValues}
              onChange={(event) => {
                if (
                  event &&
                  dateValues &&
                  (event[0]?.getTime() !== dateValues[0]?.getTime() || event[1]?.getTime() !== dateValues[1]?.getTime())
                ) {
                  setDateValues(event)
                  setApprovedDateValues(event)
                }
              }}
            />
          </div>
        </div>
        <div className="flex flex-col gap-2.5 pl-3">
          <div className="h-auto flex flex-row justify-start items-center gap-2 p-0">
            {currency && hoveredIndex.datasetIndex !== 1 && (
              <div className="flex items-center">
                <KTitleSpan
                  text={getCurrency(currency)}
                  fontSize={32}
                  lineHeight="40px"
                  letterSpacing="-0.4px"
                  color="#000"
                />
              </div>
            )}
            <div className="flex items-center">
              <KTitleSpan
                //text={hoveredIndex === -1 ? `${total}` : `${activeBarData.totalNet}`}
                text={
                  hoveredIndex.datasetIndex === 1
                    ? `${formatNumber(totalValues.stream)}`
                    : `${addCommasToNumber(totalValues.revenue.toFixed(0))}`
                }
                fontSize={48}
                lineHeight="56px"
                letterSpacing="-0.48px"
                color="#000"
              />
            </div>
          </div>

          <div className="h-8">
            {percentChange && (
              <div className="flex flex-row items-center gap-1">
                {" "}
                <img
                  src={percentChange > 0 ? "/analytics_icons/caret-up.svg" : "/analytics_icons/caret-down.svg"}
                  alt="change-icon"
                  height={16}
                  width={16}
                />
                <KSpan text={`${percentChange}%`} color={percentChange > 0 ? "#56E099" : "#FF5865"} />
                <KSpan text={lang.analytics.last_month_comparison_desc} color="#999" fontSize={12} lineHeight="16px" fontWeight={500} />
              </div>
            )}
          </div>
        </div>
      </div>
      <div
        className="w-[75%]"
        onMouseLeave={() => {
          setHoveredIndex((prev) => {
            return { ...prev, datasetIndex: -1, dataIndex: -1 }
          })
        }}
      >
        {chartData.streamDatas && chartData.totalNetDatas && <Line data={myChartData} options={options} />}
      </div>
    </div>
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    filterOptions: state.analysis.filterOptions,
    currency: state.auth.user.preferredCurrency
  }
}

export default connect(mapStateToProps)(AnalysisLineChart)
