// @ts-ignore
import Hashids from "hashids"
import { camelCase, isArray, isBoolean, isNumber, isString, mapKeys, snakeCase } from "lodash"
import { AnalysisTotal, GroupedNotifications, LCReleaseFormData, UserNotification, PaginatedData } from "../types"
import { userRegistration, authRefresh, summaries } from "../apis"
import { chartLabels, ID_SECRET_PHRASE, monthShortList, platformIcons, USER_ROLE } from "../constants"
import { Context } from "chartjs-plugin-datalabels"
import { KSelectOption } from "kahuna-base-react-components/dist/components/KDropdown/KDropdown"

// @ts-ignore
import SpotifyLogo from "../assets/release-platform-icons/SpotifyLogo.png"
// @ts-ignore
import AmazonMusicLogo from "../assets/logo-icons/amazon-music.png"
// @ts-ignore
import AppleLogo from "../assets/logo-icons/apple.svg"
// @ts-ignore
import YoutubeLogo from "../assets/logo-icons/youtube.png"
// @ts-ignore
import InstagramLogo from "../assets/logo-icons/instagram.png"
// @ts-ignore
import FacebookLogo from "../assets/logo-icons/facebook.png"
// @ts-ignore
import DeezerLogo from "../assets/logo-icons/deezer.png"
// @ts-ignore
import TiktokLogo from "../assets/logo-icons/tiktok.png"
// @ts-ignore
import ShazamLogo from "../assets/logo-icons/shazam.png"
// @ts-ignore
import TidalLogo from "../assets/logo-icons/tidal.png"
// @ts-ignore
import SoundcloudLogo from "../assets/logo-icons/soundcloud.png"
// @ts-ignore
import YoutubeMusicLogo from "../assets/logo-icons/youtube-music.svg"
// @ts-ignore
import BeatportLogo from "../assets/logo-icons/beatport.png"
// @ts-ignore
import TwitterLogo from "../assets/logo-icons/twitter.png"
// @ts-ignore
import SnapchatLogo from "../assets/logo-icons/snapchat.png"
// @ts-ignore
import PandoraLogo from "../assets/logo-icons/pandora.png"
// @ts-ignore
import BoomplayLogo from "../assets/logo-icons/boomplay.png"
// @ts-ignore
import RessoCustomIcon from "../components/ui/img/platforms/resso.svg"
// @ts-ignore
import MuudCustomIcon from "../components/ui/img/platforms/muud.svg"
// @ts-ignore
import FizyCustomIcon from "../components/ui/img/platforms/fizy.svg"
// @ts-ignore
import NeteaseCustomIcon from "../components/ui/img/platforms/ncm.svg"
import { lang } from "../constants/languages"

//############ Case transformation functions ################//

export const mapToCamelCase = <T extends { [key: string]: any }>(object: any): T => {
  const result: any = {}
  mapKeys(
    object,
    (value, key) =>
      (result[camelCase(key)] = isArray(value)
        ? mapListToCamelCase(value)
        : isBoolean(value) || isString(value) || isNumber(value) || !value
        ? value
        : mapToCamelCase(value))
  )
  return result
}

export const mapListToCamelCase = <T>(objectList: any[]): any[] =>
  objectList.map((object) =>
    isBoolean(object) || isString(object) || isNumber(object) || !object ? object : mapToCamelCase<T>(object)
  )

export const mapPaginatedDataToCamelCase = <T>(PaginatedData: PaginatedData<any>): PaginatedData<T> => ({
  ...PaginatedData,
  results: mapListToCamelCase<T>(PaginatedData.results)
})

export const mapToSnakeCase = (object: any) => {
  const result: any = {}
  mapKeys(
    object,
    (value, key) =>
      (result[snakeCase(key)] = isArray(value)
        ? mapListToSnakeCase(value)
        : isBoolean(value) || isString(value) || isNumber(value) || !value
        ? value
        : mapToSnakeCase(value))
  )
  return result
}

export const mapListToSnakeCase = <T>(objectList: any[]): any[] =>
  objectList.map((object) =>
    isBoolean(object) || isString(object) || isNumber(object) || !object ? object : mapToSnakeCase(object)
  )

//############## Case transformation functions end ##################//

/*export const getInitialsFromFullName = (fullName: string) =>
  fullName
    .match(/(\b\S)?/g)
    ?.join("")
    .match(/(^\S|\S$)?/g)
    ?.join("")
    .toUpperCase()*/

export const getInitials = (firstName: string, lastName: string) =>
  firstName && lastName ? `${firstName[0].toUpperCase()}${lastName[0].toUpperCase()}` : ""

export const parseJwt = (token: string): { username?: string; iss?: string; exp?: number } => {
  if (!token) return null
  const base64Url = token.split(".")[1]
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/")
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split("")
      .map(function (c) {
        return `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`
      })
      .join("")
  )

  return JSON.parse(jsonPayload)
}

export const refreshToken = async () => {
  const token = localStorage.getItem("token")
  if (token) {
    const tokenExpiry = parseJwt(token).exp
    if (new Date().getTime() > new Date(tokenExpiry * 1000).getTime()) {
      try {
        const refreshResponse = await authRefresh.post("/")

        if (refreshResponse.status === 200) {
          localStorage.setItem("token", refreshResponse.data.token)
          return true
        }
      } catch (e) {
        console.error("Error on refresh token.", e)
      }
      console.log("Logging out because token can't be refreshed.")
      localStorage.setItem("token", "")
      localStorage.setItem("user", "")
    } else {
      return true
    }
  }
  console.log("Logging out because there is no token in session.")
  localStorage.setItem("token", "")
  localStorage.setItem("user", "")
  return false
}

export const hashids = new Hashids(ID_SECRET_PHRASE, 10)

export const calculateChartPercentage = (context: Context) => {
  // @ts-ignore
  const indexMap = context.chart._hiddenIndices
  const hiddenIndices = Object.keys(indexMap)
    .map((key) => (indexMap[key] ? key : null))
    .filter((i) => !!i)
  if (hiddenIndices?.includes(context.dataIndex.toString())) return 0
  return (
    (100 * (context.dataset.data[context.dataIndex] as number)) /
    (context.dataset.data.reduce(
      (a: number, b: number, c: number) => (hiddenIndices.includes(c.toString()) ? a : a + b),
      0
    ) as number)
  )
}

export const getPlaceholderMonth = (totals: AnalysisTotal[], p) =>
  monthShortList[(Number(totals[0]?.date.substring(4)) - p + 11) % 12]
export const getPlaceholderYear = (totals: AnalysisTotal[], p) => {
  if (Number(totals[0]?.date.substring(4)) > p) return totals[0]?.date.substring(0, 4)
  return Number(totals[0]?.date.substring(0, 4)) - 1
}

export const getOrderByString = (sortField?: string, sortDirection?: "asc" | "desc") => {
  if (!sortDirection) return ""
  return sortField ? `order_by=${sortDirection === "asc" ? sortField : `-${sortField}`}` : ""
}

export const getFilterString = (filters: { [key: string]: string | number | boolean } | undefined) => {
  if (!filters) return ""
  let filterString = ""
  Object.keys(filters).map((key) => {
    if (filters[key]) {
      filterString = `${filterString}&${key}=${filters[key]}`
    }
  })
  return filterString
}

export const formatDate = (inputDate: string) => {
  //inputDate format: YYYYMMDD

  if (!inputDate) return ""

  // Parse the input date string
  const year = inputDate.substring(0, 4)
  const month = inputDate.substring(4, 6)
  const day = inputDate.substring(6, 8)

  const monthNames = lang.common.months_short

  // Convert month to its name representation
  const monthName = monthNames[parseInt(month, 10) - 1]

  // Construct the formatted date string
  const formattedDate = `${day} ${monthName} ${year}`

  return formattedDate
}

export const formatTime = (timeStamp: Date) => {
  const hour = timeStamp.getHours().toString().padStart(2, "0")
  const min = timeStamp.getMinutes().toString().padStart(2, "0")

  return `${hour}:${min}`
}

export const formatIsoDate = (inputDate: string, useAlternateOutput?: boolean) => {
  if (!inputDate) {
    return " - "
  }

  //inputDate format: YYYY-MM-DD

  // Parse the input date string
  const [year, month, day] = inputDate.split("-")

  const monthNames = lang.common.months_short
  // Convert month to its name representation
  const monthName = monthNames[parseInt(month, 10) - 1]

  // Construct the formatted date string
  const formattedDate = `${day} ${monthName} ${year}`
  
  if (useAlternateOutput) {
    return `${day}/${month}/${year}`
  }
  
  return formattedDate
}

export const formatDate2 = (inputDate: string) => {
  if (!inputDate || inputDate === "-") {
    return inputDate
  }

  const [year, month, day] = inputDate.split("T")[0].split("-")

  const monthNames = lang.common.months_short

  const monthName = monthNames[parseInt(month, 10) - 1]

  const formattedDate = `${monthName} ${day}, ${year}`

  return formattedDate
}

export const formatDateTurkish = (date: Date) => {
  const months = ["Oca", "Şub", "Mar", "Nis", "May", "Haz", "Tem", "Ağu", "Eyl", "Eki", "Kas", "Ara"]
  const day = date.getDate()
  const month = months[date.getMonth()]
  const year = date.getFullYear()
  return `${month} ${day}, ${year}`
}
export function encodeData(token, expirationMinutes) {
  const now = new Date()
  const expirationSeconds = expirationMinutes * 60000
  const expirationDate = new Date(now.getTime() + expirationSeconds)

  const data = {
    token,
    date: expirationDate.toISOString()
  }

  const jsonString = JSON.stringify(data)
  const encodedString = btoa(jsonString)

  return encodedString
}

export function decodeData(encodedString) {
  try {
    const jsonString = atob(encodedString)
    const data = JSON.parse(jsonString)

    const now = new Date()
    const expirationDate = new Date(data.date)

    if (now > expirationDate) {
      return { expired: true, token: null }
    }

    return { expired: false, token: data.token }
  } catch (error) {
    return { expired: true, token: null }
  }
}

export const turkishCharMap = {
  ç: "c",
  Ç: "C",
  ğ: "g",
  Ğ: "G",
  ı: "i",
  I: "I",
  İ: "I",
  ö: "o",
  Ö: "O",
  ş: "s",
  Ş: "S",
  ü: "u",
  Ü: "U"
}

export const getDuration = (track: LCReleaseFormData) => {
  const duration = track.data?.attributes?.length
  if (duration) {
    const minutes = Math.floor(duration / 60)
    const remainingSeconds = duration % 60

    const formattedMinutes = String(minutes).padStart(2, "0")
    const formattedSeconds = String(remainingSeconds).padStart(2, "0")

    return `${formattedMinutes}:${formattedSeconds}`
  }
  return "00:00"
}

export const setLabel = (label: string) => {
  const splittedLabel = label.split("-")
  const capitalizedArray = splittedLabel.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
  return capitalizedArray.join(" ")
}

export const formatDuration = (value: number) => {
  const min = Math.floor(value / 60)
  const sec = value % 60
  if (sec < 10) {
    return `${min}:0${sec}`
  }
  return `${min}:${sec}`
}

export const checkMissingTrackFields = (track: LCReleaseFormData | undefined) => {
  if (!track) {
    return
  }
  const missingFields: string[] = []
  if (!track?.data?.attributes?.title) {
    missingFields.push("Title")
  }
  if (!track?.data?.attributes?.artist || track?.data?.attributes?.artist?.length === 0) {
    missingFields.push("Artist")
  }
  if (!track?.data?.attributes?.["isrc-code"]) {
    missingFields.push("ISRC code")
  }
  if (!track?.data?.relationships?.["audio-language"]?.data?.id) {
    missingFields.push("Audio Language")
  }
  if (
    !track?.data.attributes?.audio16bits &&
    !track?.data.attributes?.audio24bits &&
    !track?.data.attributes?.["audio-dolby-atmos"]
  ) {
    missingFields.push("Track song file (16bits, 24 bits or dolby-atmos)")
  }
  return missingFields
}

export const addCommasToNumber = (numberString: string): string => {
  const [integerPart, decimalPart] = numberString.split(".")

  const parts: string[] = []
  const startIndex = integerPart.length % 3 || 3
  parts.push(integerPart.slice(0, startIndex))

  for (let i = startIndex; i < integerPart.length; i += 3) {
    parts.push(numberString.slice(i, i + 3))
  }

  return decimalPart ? `${parts.join(",")}.${decimalPart}` : `${parts.join(",")}`
}

export const formatNumber = (number: string | number): string => {
  const myNumber = Number(number)
  if (myNumber === 0) {
    return "0.0"
  } else if (myNumber > 0 && myNumber < 1000) {
    return `${myNumber}`
  } else if (myNumber >= 1000 && myNumber < 1000000) {
    return `${(myNumber / 1000).toFixed(1)}K`
  } else if (myNumber >= 1000000 && myNumber < 1000000000) {
    return `${(myNumber / 1000000).toFixed(1)}M`
  } else if (myNumber >= 1000000000 && myNumber < 1000000000000) {
    return `${(myNumber / 1000000000).toFixed(1)}B`
  } else {
    return `${(myNumber / 1000000000).toFixed(1)}B`
  }
}

export const calculateOffsetMonth = (date: string, offset: number): string => {
  if (!date) {
    return ""
  }

  // Determine the input format
  const hasHyphen = date.includes("-")

  // Split the year and month based on the input format
  const [yearStr, monthStr] = hasHyphen ? date.split("-") : [date.slice(0, 4), date.slice(4)]

  const year = Number(yearStr)
  const month = Number(monthStr)

  // Calculate the new date with the given offset
  const calculatedDate = new Date(year, month - 1 + offset)
  const newYear = calculatedDate.getFullYear()
  const newMonth = String(calculatedDate.getMonth() + 1).padStart(2, "0")

  // Return the result in the same format as the input
  return `${newYear}-${newMonth}`
}

export const generateMonthRange = (startDate: string | undefined, range: number): string[] => {
  //startDate: YYYY-MM or YYYYMM
  if (!startDate) return []
  const hasHyphen = startDate.includes("-")

  const [startYearStr, startMonthStr] = hasHyphen ? startDate.split("-") : [startDate.slice(0, 4), startDate.slice(4)]
  const months: string[] = []

  const yearNum = Number(startYearStr)
  const monthNum = Number(startMonthStr)

  for (let i = 0; i < range; i++) {
    // Calculate the current month and year
    const currentDate = new Date(yearNum, monthNum - 1 + i)
    const year = currentDate.getFullYear()
    const month = String(currentDate.getMonth() + 1).padStart(2, "0") // Add 1 to month and pad with 0 if necessary

    // Add the month in "YYYYMM" format to the array
    months.push(`${year}${month}`)
  }

  return months
}

export const convertToMonthYear = (basicDateFormat: string, showYearOnlyInJanuary?: boolean): string => {
  const year = basicDateFormat.slice(0, 4)
  const month = basicDateFormat.slice(4)

  // Array of month names
  const monthNames = lang.common.months_short
  if (showYearOnlyInJanuary) {
    return Number(month) === 1 ? `${monthNames[Number(month) - 1]} ${year}` : `${monthNames[Number(month) - 1]}`
  }
  return `${monthNames[Number(month) - 1]} ${year}`
}

export const fillDatesBetween = (startDate: string | undefined, endDate: string | undefined): string[] => {
  // Helper function to parse date strings in "YYYYMM" or "YYYY-MM" format
  if (!startDate || !endDate) return []
  const parseDate = (date: string): Date => {
    const formattedDate = date.includes("-") ? date : `${date.slice(0, 4)}-${date.slice(4)}`
    return new Date(`${formattedDate}-01`)
  }

  const start = parseDate(startDate)
  const end = parseDate(endDate)

  const result: string[] = []
  const current = new Date(start)

  while (current <= end) {
    const year = current.getFullYear()
    const month = String(current.getMonth() + 1).padStart(2, "0") // Ensures two-digit month
    result.push(`${year}${month}`)

    // Increment month
    current.setMonth(current.getMonth() + 1)
  }

  return result
}

export const createDateObject = (dateString: string) => {
  // YYYYMM
  const year = Number(dateString.slice(0, 4))
  const month = Number(dateString.slice(4, 6))

  return new Date(year, month - 1)
}

export const getCurrency = (currency: string) => {
  if (currency === "EUR") return "€"
  else if (currency === "TRY") return "₺"
  else if (currency === "USD") return "$"
  else if (currency === "GBP") return "£"
  return ""
}

export const formatYearMonth = (input: string): string => {
  if (!input) {
    return ""
  }
  const year = input.slice(0, 4)
  const month = input.slice(4, 6)

  return `${year}-${month}`
}

export const formatDateObject = (date: Date | null) => {
  if (date) {
    const year = date.getFullYear()
    const month = String(date.getMonth() + 1).padStart(2, "0")

    return `${year}${month}`
  }
  return ""
}

export const subOptionsSetter = async (
  option: KSelectOption,
  filterOptions: { [key: string]: KSelectOption[] },
  setValues: (values: KSelectOption[]) => void
) => {
  if (!filterOptions) {
    return
  }
  let subOptions: KSelectOption[] = []
  const keyIs =
    option.value2 === "topTracks"
      ? "trackOptions"
      : option.value2 === "platform"
      ? "platformOptions"
      : "storefrontOptions"
  const newLabel: KSelectOption[] = []
  if (keyIs === "platformOptions") {
    for (let i = 0; i < filterOptions[keyIs].length; i++) {
      const labels = {
        label: chartLabels["platform"][filterOptions[keyIs][i].label] || filterOptions[keyIs][i].label,
        value: filterOptions[keyIs][i].value,
        value2: filterOptions[keyIs][i].label,
        icon: platformIcons[filterOptions[keyIs][i].label],
        iconLabel: filterOptions[keyIs][i].label === "spo-spotify" && SpotifyLogo
      }
      newLabel.push(labels)
    }
    subOptions = newLabel
  } else if (keyIs === "storefrontOptions") {
    for (let i = 0; i < filterOptions[keyIs].length; i++) {
      const labels = {
        label: chartLabels["storeFront"][filterOptions[keyIs][i].label],
        value: filterOptions[keyIs][i].value,
        value2: filterOptions[keyIs][i].label
      }
      newLabel.push(labels)
    }
    subOptions = newLabel
  } else {
    const subOptionsDefault = filterOptions[keyIs]
    if (!subOptionsDefault.some((option) => option.value === -2)) {
      const allTracksLabel = lang.analytics.all_tracks_label
      subOptionsDefault.unshift({ label: allTracksLabel, value: -2 })
    }
    subOptions = subOptionsDefault
  }

  const sortedSubOptions = subOptions.sort((a, b) => {
    if (b.value2 && b.value2 === "TR") {
      return 1
    }
    if (b.label && b.value === -2) {
      return 1
    }
    return a.label.localeCompare(b.label)
  })

  //setSelectedSubOption(sortedSubOptions[0])
  setValues(sortedSubOptions)
}

export const convertStringDateToDateObject = (date: string): Date => {
  if (!date) {
    return new Date()
  }

  const hasHyphen = date.includes("-")

  // Split the year and month based on the input format
  const [yearStr, monthStr] = hasHyphen ? date.split("-") : [date.slice(0, 4), date.slice(4)]

  const year = Number(yearStr)
  const month = Number(monthStr)

  return new Date(year, month - 1)
}

export const getSummaries = async (
  type: string,
  value: string | number,
  affiliate: boolean,
  startDate?: string,
  endDate?: string
) => {
  const affiliateParam = affiliate ? "&affiliate=1" : ""
  const dateParam = startDate && endDate ? `&start_date=${startDate}&end_date=${endDate}` : ""
  const reqUrl = `/analytics_table/?type=${type}&value=${value}${affiliateParam}${dateParam}`
  const response = await summaries(reqUrl, "get")

  return response.data
}

export const renderSiteTitle = (url?: string) => {
  if (!url) {
    return ""
  }

  if (url.includes("spotify")) return "Spotify"
  else if (url.includes("instagram")) return "Instagram"
  else if (url.includes("facebook")) return "Facebook"
  else if (url.includes("x.com")) return "X"
  else if (url.includes("tiktok")) return "Tiktok"
  else if (url.includes("apple")) return "Apple"
  else if (url.includes("beatport")) return "Beatport"
  else if (url.includes("music.youtube")) return "Youtube Music"
  else if (url.includes("youtube")) return "Youtube"
  else if (url.includes("deezer")) return "Deezer"
  else if (url.includes("amazon")) return "Amazon Music"
  else if (url.includes("tidal")) return "Tidal"
  else if (url.includes("soundcloud")) return "Soundcloud"
  else if (url.includes("shazam")) return "Shazam"
  else if (url.includes("twitter")) return "X"

  const start = url.indexOf("www.")
  const end = url.indexOf(".com")

  return url.substring(start + 4, end)
}

export const getSocialMediaIcon = (url: string) => {
  let icon = undefined
  if (url.includes("music.youtube.com")) icon = YoutubeMusicLogo
  else if (url.includes("youtube.com")) icon = YoutubeLogo
  else if (url.includes("instagram.com")) icon = InstagramLogo
  else if (url.includes("facebook.com")) icon = FacebookLogo
  else if (url.includes("apple.com")) icon = AppleLogo
  else if (url.includes("deezer.com")) icon = DeezerLogo
  else if (url.includes("shazam.com")) icon = ShazamLogo
  else if (url.includes("tidal.com")) icon = TidalLogo
  else if (url.includes("soundcloud.com")) icon = SoundcloudLogo
  else if (url.includes("amazon.com")) icon = AmazonMusicLogo
  else if (url.includes("tiktok.com")) icon = TiktokLogo
  else if (url.includes("beatport.com")) icon = BeatportLogo
  else if (url.includes("x.com")) icon = TwitterLogo
  else if (url.includes("twitter.com")) icon = TwitterLogo
  else if (url.includes("pandora.com")) icon = PandoraLogo
  else if (url.includes("boomplay.com")) icon = BoomplayLogo
  else if (url.includes("snapchat.com")) icon = SnapchatLogo
  else if (url.includes("spotify.com")) icon = SpotifyLogo
  // custom named icons will be changed later
  else if (url.includes("resso.com")) icon = RessoCustomIcon
  else if (url.includes("netease.com")) icon = NeteaseCustomIcon
  else if (url.includes("fizy.com")) icon = FizyCustomIcon
  else if (url.includes("muud.com")) icon = MuudCustomIcon

  return icon
}

// custom named icons will be changed later, others are official.
export const platformLogos = {
  "snp-snap": SnapchatLogo,
  tiktok: TiktokLogo,
  "res-resso": RessoCustomIcon,
  "spo-spotify": SpotifyLogo,
  youtube: YoutubeLogo,
  "YouTube - Audio Tier": YoutubeLogo,
  "Metafbk-facebook": FacebookLogo,
  "Meta-instagram": InstagramLogo,
  "apl-apple": AppleLogo,
  "boo-boomplay": BoomplayLogo,
  "dzr-deezer": DeezerLogo,
  "pnd-pandora": PandoraLogo,
  "scu-soundcloud": SoundcloudLogo,
  "ncm-netease-cloud-music": NeteaseCustomIcon,
  "Fizy - Fizy": FizyCustomIcon,
  "Muud - Muud": MuudCustomIcon
}

export const formatUTCDate = (dateString: string): string => {
  const date = new Date(dateString)

  const hours = date.getUTCHours().toString().padStart(2, "0")
  const minutes = date.getUTCMinutes().toString().padStart(2, "0")
  const day = date.getUTCDate().toString().padStart(2, "0")
  const month = (date.getUTCMonth() + 1).toString().padStart(2, "0") // Months are 0-based
  const year = date.getUTCFullYear()

  // Format the date string as "HH: MM - DD.MM.YYYY"
  return `${day}.${month}.${year} - ${hours}:${minutes}`
}

export const convertToTurkeyTime = (utcDate: string): string => {
  // Create a Date object from the UTC string
  const date = new Date(utcDate)
  // Convert to Turkey's time zone (UTC+3) and include the full time zone name
  const options: Intl.DateTimeFormatOptions = {
    timeZone: "Europe/Istanbul",
    hour12: false, // Use 24-hour format
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    timeZoneName: "long" // Full time zone name
  }

  const turkeyTime = date.toLocaleString("en-GB", options)

  return turkeyTime
}

export const parseFormattedNumber = (formattedNumber: string): number => {
  const suffixes: { [key: string]: number } = {
    K: 1000,
    M: 1000000,
    B: 1000000000,
    T: 1000000000000
  }

  const suffix = formattedNumber.slice(-1)
  const numberPart = parseFloat(formattedNumber.slice(0, -1))

  if (suffixes[suffix]) {
    return numberPart * suffixes[suffix]
  }

  return parseFloat(formattedNumber) // If there's no suffix, return the number as is
}

export const getDayLabel = (dateString: string): string => {
  const today = new Date()
  const date = new Date(dateString)

  // Extract local year, month, and day for comparison (localization-aware)
  const todayDay = new Date(today.getFullYear(), today.getMonth(), today.getDate())
  const dateDay = new Date(date.getFullYear(), date.getMonth(), date.getDate())

  const diffTime = todayDay.getTime() - dateDay.getTime()
  const diffDays = diffTime / (1000 * 60 * 60 * 24)

  if (diffDays === 0) return lang.notifications.today
  if (diffDays === 1) return lang.notifications.yesterday
  return lang.notifications.earlier // For dates older than yesterday
}

export const getDayDifference = (dateString: string): string => {
  const now = new Date()
  const date = new Date(dateString)

  const differenceInMilliseconds = now.getTime() - date.getTime()

  const minute = 1000 * 60
  const hour = minute * 60
  const day = hour * 24
  const year = day * 365

  const ago = lang.common.ago

  if (differenceInMilliseconds < minute) {
    return `${lang.common.just_now}`
  } else if (differenceInMilliseconds < hour) {
    const minutes = Math.floor(differenceInMilliseconds / minute)

    return minutes === 1 ? `1 ${lang.common.min} ${ago}` : `${minutes} ${lang.common.min_plural} ${ago}`
  } else if (differenceInMilliseconds < day) {
    const hours = Math.floor(differenceInMilliseconds / hour)

    return hours === 1 ? `1 ${lang.common.hour} ${ago}` : `${hours} ${lang.common.hour_plural} ${ago}`
  } else if (differenceInMilliseconds < year) {
    const days = Math.floor(differenceInMilliseconds / day)

    return days === 1 ? `1 ${lang.common.day} ${ago}` : `${days} ${lang.common.day_plural} ${ago}`
  } else {
    const years = Math.floor(differenceInMilliseconds / year)
    return years === 1 ? `1 ${lang.common.year} ${ago}` : `${years} ${lang.common.year_plural} ${ago}`
  }
}

export const groupByDates = (notifications: UserNotification[]): GroupedNotifications => {
  return notifications.reduce<{
    [key: string]: { notifications: UserNotification[]; unreadCount: number }
  }>((acc, elem) => {
    const dayLabel = getDayLabel(elem.created)

    // Initialize the structure if it doesn't exist
    if (!acc[dayLabel]) {
      acc[dayLabel] = { notifications: [], unreadCount: 0 }
    }

    // Add the notification to the respective group
    acc[dayLabel].notifications.push(elem)

    // Increment unread count if the notification is unread
    if (!elem.read) {
      acc[dayLabel].unreadCount += 1
    }

    return acc
  }, {})
}

export const notificationContent = (notification: UserNotification) => {
  if (!notification.notificationId) return

  let content = lang.notifications.notification_content[notification.notificationId]

  // Dynamically replace each parameter starting from param1
  let i = 1
  while (notification[`param${i}`] !== undefined) {
    content = content.replace(`<param${i}>`, notification[`param${i}`])
    i++
  }

  return content
}

export const buttonText = (notification: UserNotification) => {
  if (notification.notificationId && notification.buttonParam) {
    return lang.notifications.button_text[notification.notificationId].replace(
      "<buttonParam>",
      notification.buttonParam
    )
  }
}

export const buttonConnectedURL = (notification: UserNotification) => {
  if (!notification.notificationId) return

  if (notification.urlParam && notification.relatedUrl) {
    return `${notification.relatedUrl}${notification.urlParam}`
  } else if (notification.urlParam && !notification.relatedUrl) {
    return `${notification.urlParam}`
  }
}

export const getDaysInMonth = (year:number, month:number) => {
  // Get the first day of the next month
  const firstDayOfNextMonth = new Date(year, month + 1, 1)
  // Subtract 1 day to get the last day of the current month
  const lastDayOfMonth = new Date(firstDayOfNextMonth.getTime() - 1)
  return lastDayOfMonth.getDate()
}

export const iconColor = (roleId: number) => {
  switch (roleId) {
    case USER_ROLE.SuperAdmin:
      return "#56E099"
    case USER_ROLE.Admin:
      return "#FF9558"
    case USER_ROLE.MarketingAdmin:
      return "#7C2DFC"
    case USER_ROLE.Artist:
      return "#58A9FF"
    case USER_ROLE.Collaborator:
      return "#111111"
    case USER_ROLE.Affiliate:
      return "#999999"
    default:
      return "#999999"
  }
}