import { Dialog } from "@headlessui/react"
import React, { FC, useEffect, useState } from "react"
import { X } from "heroicons-react"
import { User, UserCredit } from "../../../../../types"
import {
  errorFilledToastOptions,
  successFilledToastOptions
} from "../../../../../constants"
import { users } from "../../../../../apis"
import { mapToCamelCase, mapToSnakeCase } from "../../../../../utility"
import { KButton, KInput, KSpan, KTitleSpan } from "kahuna-base-react-components"
import { toast } from "react-toastify"

type AdminUserCreditModalProps = {
  open: boolean
  onClose: () => void
  user: User | null
}
const AdminUserCreditModal: FC<AdminUserCreditModalProps> = (props) => {
  const { onClose, open } = props
  const [loading, setLoading] = useState(false)

  const [userCredit, setUserCredit] = useState<UserCredit>()

  useEffect(() => {
    if (!(props.user?.id && open)) {
      setUserCredit(undefined)
      return
    }

    users(`/fetch_user_credit/?user_id=${props.user?.id}`, "GET").then((resp) => {
      setUserCredit({ ...mapToCamelCase(resp.data.result), userId: props.user?.id })
    })
  }, [props.user?.id, open])

  const onCloseModal = () => {
    setUserCredit(undefined)
    props.onClose()
  }

  const submit = () => {
    setLoading(true)
    users(`/user_credit/`, "POST", mapToSnakeCase(userCredit))
      .then((resp) => {
        if (resp.data.success) {
          toast.success("User Credit Saved Successfully.", successFilledToastOptions)
          props.onClose()
        } else {
          toast.error("Error saving User Credit!.", errorFilledToastOptions)
        }
        setLoading(false)
      })
      .catch(() => {
        toast.error("Error saving User Credit!.", errorFilledToastOptions)
        setLoading(false)
      })
  }

  return (
    <Dialog open={open} onClose={() => onCloseModal()} className="relative">
      <div className="fixed inset-0 bg-[rgba(0,0,0,0.3)]" aria-hidden="true" />
      <div className="fixed inset-0 flex items-center justify-center p-4">
        <div>
          <div className={`flex justify-end`}>
            <X className="h-4 cursor-pointer mt-1" style={{ position: "absolute" }} onClick={() => onCloseModal()} />
          </div>
          {userCredit && (
            <div className="bg-white elev-b-sm rounded-lg overflow-hidden" style={{ overflowY: "auto", padding: 42 }}>
              <KTitleSpan text={"User Credits"} fontSize={32} />
              <div className="flex flex-row items-center gap-1 my-5">
                <KSpan text="User:" fontWeight={500} color="#000" />
                <KSpan text={`${props?.user?.firstName} ${props?.user?.lastName}`} color="#000" />
              </div>
              <div className="flex gap-2 items-center">
                <KSpan text={"Enabled:"} />
                <input
                  type="checkbox"
                  style={{
                    accentColor: "black"
                  }}
                  checked={userCredit?.enabled}
                  onChange={() => {
                    setUserCredit({ ...userCredit, enabled: !userCredit.enabled })
                  }}
                />
              </div>

              <div className="flex mt-3 items-center justify-between">
                <KSpan text={"Subscription Type:"} />

                <div
                  className="w-[calc(49%)] flex items-center"
                  style={{
                    borderRadius: 10,
                    background: "var(--bg-weak-100, #F0F0F0)",
                    padding: "8px 12px"
                  }}
                >
                  <KSpan text={userCredit?.subscriptionType || " - "} />
                </div>
              </div>

              <div className="flex mt-3 items-center justify-between">
                <KSpan text={"Takedown:"} />

                <KInput
                  type="number"
                  width={125}
                  value={userCredit?.takedown?.toString() || "0"}
                  onChange={(value) => setUserCredit({ ...userCredit, takedown: parseInt(value) })}
                />
              </div>

              <div className="flex gap-4 mt-3 mb-12 items-center justify-between">
                <KSpan text={"Urgent New Release:"} />
                <KInput
                  type="number"
                  width={125}
                  value={userCredit?.urgentNewRelease?.toString() || "0"}
                  onChange={(value) => setUserCredit({ ...userCredit, urgentNewRelease: parseInt(value) })}
                />
              </div>

              <div className="flex pt-4" style={{ borderTop: "1px solid rgb(220 220 220)" }}>
                <KButton text="Cancel" background="white" shadowDisabled={true} onClick={() => onCloseModal()} />
                <KButton
                  text="Confirm"
                  background="black"
                  textColor="white"
                  onClick={() => submit()}
                  disabled={loading}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </Dialog>
  )
}

export default AdminUserCreditModal
