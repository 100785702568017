import * as React from "react"
import { FC } from "react"
import { RootState } from "../../../../store"
import { connect } from "react-redux"
import { NavigationProps } from "../../../../types"
import { logout } from "../../../../actions/loginActions"
import AdminNavSection from "./AdminNavSection"
import { KLogo } from "kahuna-base-react-components"
import { useNavigate } from "react-router-dom"
import ProfileDropdownMenu from "../ProfileDropdownMenu"

const AdminNavigation: FC<NavigationProps> = (props) => {
  const navigate = useNavigate()

  return (
    <div className={"flex flex-col justify-between h-full" }
    style={{
      borderRight: "1px solid #F3F3F3"
    }}>
      <div className="flex flex-col grow gap-0">
        <div
          className="py-2.5 w-11 flex items-center justify-center cursor-pointer"
          onClick={() => {
            navigate("/")
          }}
        >
          <KLogo hoverEnabled width={44} height={44} logoColor="white" />
        </div>
        <AdminNavSection user={props.user} />
      </div>

      <div className="flex items-center justify-center">
        {/* <InfoSection />
        <SocialSection />*/}
        <ProfileDropdownMenu isAdmin={true} />
      </div>
    </div>
  )
}

const mapStateToProps = (state: RootState) => {
  return { user: state.auth.user }
}

export default connect(mapStateToProps, { logout })(AdminNavigation)
