import { UserForm } from "../../../../../../types"
import { USER_PERMISSION, allRoleList, emailPattern, errorFilledToastOptions } from "../../../../../../constants"
import { toast } from "react-toastify"

const adminUserValidator = (formValues: UserForm) => {
  const errors: any = {}

  if (!formValues.firstName) {
    errors.firstName = "You must enter a first name"
    toast.error("You must enter a first name", errorFilledToastOptions)
  }
  if (!formValues.lastName) {
    errors.lastName = "You must enter a last name"
    toast.error("You must enter a last name", errorFilledToastOptions)
  }
  if (!formValues.username) {
    errors.username = "You must enter an email"
    toast.error("You must enter an email", errorFilledToastOptions)
  } else if (!formValues.username.match(emailPattern)) {
    errors.username = "You must enter a valid email"
    toast.error("You must enter a valid email", errorFilledToastOptions)
  }

  if (formValues.permissionIds?.includes(USER_PERMISSION.UPLOAD_BMV)) {
    const bmwCustomId = formValues.bmwCustomId?.length || 0
    if (bmwCustomId < 2 || bmwCustomId > 3) {
      errors.bmwCustomId = "BMV Custom Id must be 2 - 3 characters"
      toast.error("BMV Custom Id must be 2 - 3 characters", errorFilledToastOptions)
    }
  }

  if (
    formValues?.permissionIds &&
    formValues?.permissionIds.includes(USER_PERMISSION.UPLOAD_MESAM) &&
    formValues?.permissionIds.includes(USER_PERMISSION.UPLOAD_MSG)
  ) {
    errors.permissionIds = "Choose one: Mesam / Msg"
    toast.error("Choose one: Mesam / Msg", errorFilledToastOptions)
  }
  
  if (!allRoleList.includes(formValues?.roleId)) {
    errors.roleId = "You must select a role"
    toast.error("You must select a role", errorFilledToastOptions)
  }

  return errors
}

export default adminUserValidator
