import * as React from "react"
import { FC, useEffect, useState } from "react"
import { AdminUserProps, TableColumn, User } from "../../../../types"
import adminUserColumns from "./columns/adminUserColumns"
import { connect } from "react-redux"
import {
  fetchAllUsers,
  fetchLastUpdate,
  refreshLabelCamp,
  refreshReports,
  refreshSummaries,
  selectUser
} from "../../../../actions/adminActions"
import { RootState } from "../../../../store"
import {
  adminTableLimit,
  USER_ROLE,
  userTypeOptions
} from "../../../../constants"
import { KButton, KInput, KSpan, KTitleSpan, KTooltip, KDropdown } from "kahuna-base-react-components"
import TableNew from "../../../ui/table/TableNew"
import { KSelectOption } from "kahuna-base-react-components/dist/components/KDropdown/KDropdown"
import ReloadModal from "./modals/ReloadModal"
// @ts-ignore
import PlusIcon from "../../../../assets/release-registry-icons/plus.svg"
import AdminUserCreateModal from "./modals/AdminUserCreateModal"
import { AdminUserAssetTabOptions } from "../AdminUserAsset"

const AdminUser: FC<AdminUserProps> = (props) => {
  const [loading, setLoading] = useState(true)
  const [timeoutId, setTimeoutId] = useState(undefined)
  const [inputTimeoutId, setInputTimeoutId] = useState(undefined)
  const [openSidebar, setOpenSidebar] = useState<boolean>(true)
  const [selectedUserTypes, setSelectedUserTypes] = useState<number[]>([])
  const [pageNumber, setPageNumber] = useState<number>(1)
  const [searchValue, setSearchValue] = useState<string>("")
  const searchOptions: KSelectOption[] = [
    { label: "User Name", value: 0, value2: "username" },
    { label: "First Name", value: 1, value2: "first_name" },
    { label: "Last Name", value: 2, value2: "last_name" }
  ]
  const [selectedSearchOption, setSelectedSearchOption] = useState<KSelectOption>(searchOptions[0])
  const [additionalFilters, setAdditionalFilters] = useState<{ [key: string]: string | undefined }>({})
  const [hoveredRowId, setHoveredRowId] = useState<number | undefined>(undefined)

  const isSuperAdmin = props.loggedInUser?.roleId === USER_ROLE.SuperAdmin

  const [reloadModalOpen, setReloadModalOpen] = useState<boolean>(false)
  const [addEditUserModal, setAddEditUserModalOpen] = useState<boolean>(false)

  const adminUserCols: TableColumn[] = adminUserColumns(isSuperAdmin, props.setModalOption, props.selectUser, setAddEditUserModalOpen)
  const [columns, setColumns] = useState<TableColumn[]>(adminUserCols)

  const onClickPage = async (
    pageNumber: number,
    filters: { [key: string]: string | number | boolean } | undefined,
    sortField?: string,
    sortDirection?: "asc" | "desc"
  ) => {
    setLoading(true)
    await props.fetchAllUsers(adminTableLimit, (pageNumber - 1) * adminTableLimit, filters, sortField, sortDirection)
    setLoading(false)
    setPageNumber(pageNumber)
  }

  const onSortOrFilterChange = async (
    filters: { [key: string]: string | number | boolean },
    sortField: string,
    sortDirection?: "asc" | "desc",
    debounce?: boolean
  ) => {
    // runs in first render (mount) without applying any filter or sort param
    if (timeoutId) {
      clearTimeout(timeoutId)
    }
    setTimeoutId(
      //@ts-ignore
      setTimeout(
        async () => {
          setLoading(true)
          await props.fetchAllUsers(adminTableLimit, 0, filters, sortField, sortDirection)
          setLoading(false)
          setPageNumber(1)
        },
        debounce ? 500 : 0
      )
    )
  }

  useEffect(() => {
    clearTimeout(inputTimeoutId)
    setInputTimeoutId(
      // @ts-ignore
      setTimeout(async () => {
        if (selectedSearchOption?.value2) {
          setAdditionalFilters({
            username: selectedSearchOption?.value2 === "username" ? searchValue : "",
            first_name: selectedSearchOption?.value2 === "first_name" ? searchValue : "",
            last_name: selectedSearchOption?.value2 === "last_name" ? searchValue : "",
            role__id: selectedUserTypes.length > 0 ? selectedUserTypes.join(",") : undefined
          })
          setPageNumber(1)
        }
      }, 500)
    )
  }, [selectedSearchOption, searchValue, selectedUserTypes])

  const onClickAdd = () => {
    props.selectUser(new User())
    setAddEditUserModalOpen(true)
  }

  const onSelectRow = (index: number) => {
    if (index === -1) return // onClickPagination triggers onSelectRow with index -1, so I cancelled it
    props.selectUser(props.paginatedUsers.results[index])
    props.setTab(AdminUserAssetTabOptions.ASSET)
  }

  const topRightElements = (
    <div className="flex flex-row items-center gap-3">
      <div
        className="flex items-center py-[13px] pl-3.5 pr-3 gap-2 rounded-[10px] w-[300px]"
        style={{
          background: searchValue ? "#FFF" : "#F7F7F7",
          border: "1px solid #F3F3F3",
          height: "48px",
          transition: "0.3s"
        }}
      >
        <KInput
          value={searchValue}
          padding="0px"
          background="transparent"
          onChange={(text: string) => {
            setSearchValue(text)
          }}
          leftIcon="/catalog_icons/search-grey.svg"
          gap="8px"
          borderRadius={0}
          shadowDisabled
          placeholder={"Search..."}
        />
        <KDropdown
          rightIcon="/admin_icons/caret-down.svg"
          options={searchOptions}
          selected={selectedSearchOption}
          onSelect={(selected) => {
            setSelectedSearchOption(selected as KSelectOption)
          }}
          padding="0px"
          background="transparent"
          activeBackground="transparent"
          shadowDisabled={true}
          enableIndicator={true}
          menuWidth={140}
          width={140}
          menuLeftMargin={-40}
          textColor="#F3F3F3"
        />
      </div>
      <div className="flex">
        <KButton
          leftIcon={PlusIcon}
          background="#000"
          hoverBackground="#111"
          activeBackground="#000"
          onClick={onClickAdd}
          width="auto"
          height="48px"
          padding="14px"
          text="New User"
          textColor="#FFF"
          gap="4px"
        />
      </div>
      {isSuperAdmin && <div className="flex">
        <KButton
          text="Reload"
          height="48px"
          width="82px"
          background="#F7F7F7"
          hoverBackground="#F3F3F3"
          padding="14px"
          onClick={() => {
            setReloadModalOpen(true)
          }}
        />
      </div>}
    </div>
  )

  const tableTitle = (
    <div className="flex flex-row gap-2 items-center !cursor-default">
      <KTooltip
        backgroundColor="#000"
        padding="0px 4px"
        height="20px"
        arrowColor="#000"
        position="bottom"
        content={
          <div className="h-5 w-max flex items-center">
            <KSpan text={openSidebar ? "Hide sidebar" : "Open sidebar"} fontSize={12} lineHeight="16px" color="#FFF" />
          </div>
        }
      >
        <div className="flex items-center">
          <KButton
            icon="/left-sidebar.svg"
            width="32px"
            height="32px"
            background="transparent"
            shadowDisabled={true}
            padding="6px"
            onClick={() => {
              if (setOpenSidebar) {
                setOpenSidebar(!openSidebar)
              }
            }}
          />
        </div>
      </KTooltip>
      <div className="pt-1">
        {" "}
        <KTitleSpan text="User Asset" color="#111" fontSize={20} lineHeight="28px" />
      </div>
    </div>
  )

  return (
    <div className="catalog flex bg-[#fff]">
      <div
        className={`${
          openSidebar ? "min-w-[230px]" : "min-w-0 !w-[0px]"
        } max-w-[320px] w-[20%] bg-[#F7F7F7] min-h-[100vh] flex flex-col overflow-hidden shrink-0`}
        style={{
          transitionDuration: "0.5s"
        }}
      >
        <div className="pl-6 pr-3 py-4 whitespace-nowrap">
          <KTitleSpan text={"User Asset"} lineHeight="28px" fontSize={20} color="#111" />
        </div>

        {[...userTypeOptions].map((option, index: number) => {
          const selected =
            option.value === -1 ? selectedUserTypes.length === 0 : selectedUserTypes.includes(option.value)
          return (
            <div className="py-1.5 px-4 h-11" key={`${index}`}>
              <div
                className={`rounded-[10px] p-2 flex flex-row items-center gap-2 ${
                  selected ? "bg-[#FFF]" : "transparent"
                } cursor-pointer whitespace-nowrap`}
                onClick={() => {
                  if (option.value === -1) {
                    setSelectedUserTypes([])
                  } else if (selectedUserTypes.includes(option.value)) {
                    const filteredUserTypes = [...selectedUserTypes].filter(
                      (userTypeRoleId: number) => userTypeRoleId !== option.value
                    )
                    setSelectedUserTypes(filteredUserTypes)
                  } else {
                    setSelectedUserTypes([...selectedUserTypes, option.value])
                  }
                }}
              >
                <img
                  src={option.value === -1 ? "/reports_icons/user-circle.svg" : "/admin_icons/user.svg"}
                  width={16}
                  height={16}
                  className="shrink-0"
                />
                <KSpan text={option.label} fontSize={12} lineHeight="16px" fontWeight={500} color="#111" />
              </div>
            </div>
          )
        })}
      </div>
      <div className="grow flex justify-center overflow-x-auto">
        <div className="w-full">
          <TableNew
            title={tableTitle}
            pagination
            limit={adminTableLimit}
            columns={columns}
            minRowCount={adminTableLimit}
            data={props.paginatedUsers.results}
            count={props.paginatedUsers.count}
            onClickPage={onClickPage}
            topRightElement={topRightElements}
            onSelectRow={onSelectRow}
            loading={loading}
            onSortOrFilterChange={onSortOrFilterChange}
            pageNumber={pageNumber}
            additionalFilters={additionalFilters}
            onMouseEnterRow={(row: any, index: number) => {
              setHoveredRowId(index)
            }}
            onMouseLeaveRow={(row: any, index: number) => {
              if (hoveredRowId === index) {
                setHoveredRowId(undefined)
              }
            }}
            hoveredRowId={hoveredRowId}
            overrideTableOverflow="auto"
          />
          <ReloadModal
            isOpen={reloadModalOpen}
            handleClose={() => {
              setReloadModalOpen(false)
            }}
          />
          <AdminUserCreateModal
            open={props.selectedUser !== null && addEditUserModal}
            onClose={() => {
              setAddEditUserModalOpen(false)
            }}
            isEdit={props.selectedUser?.email !== ""}
          />
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    paginatedUsers: state.admin.paginatedUsers,
    selectedUser: state.admin.selectedUser,
    lastUpdate: state.admin.lastUpdate,
    loggedInUser: state.auth.user
  }
}

export default connect(mapStateToProps, {
  fetchAllUsers,
  selectUser,
  fetchLastUpdate,
  refreshLabelCamp,
  refreshReports,
  refreshSummaries
})(AdminUser)
