import { Dialog } from "@headlessui/react"
import { FC, useEffect, useState } from "react"
import { PAYMENT_PERIOD_TYPE, PAYMENT_PERIOD_TYPE_ID, tableCellClasses } from "../../../../../constants"
import { X } from "heroicons-react"
import * as React from "react"
import Button from "../../../../ui/form/Button"
import { Select } from "../../../../ui/form/select/Select"
import { SelectOption } from "../../../../../types"
import { Input } from "../../../../ui/form/Input"
import { KButton, KDropdown, KInput, KSpan, KTitleSpan } from "kahuna-base-react-components"
import { KSelectOption } from "kahuna-base-react-components/dist/components/KDropdown/KDropdown"

type ApprovePaymentModalProps = {
  open: boolean
  userPaymentPeriodId: number | undefined
  selectedPeriod: string
  selectedUsername?: string
  onClose: () => void
  approve: (paymentDay: string, selectedDate: string[]) => void
}

const quarterMap = {
  "01": "q1",
  "02": "q1",
  "03": "q1",
  "04": "q2",
  "05": "q2",
  "06": "q2",
  "07": "q3",
  "08": "q3",
  "09": "q3",
  10: "q4",
  11: "q4",
  12: "q4"
}

const monthToQuarter = {
  q1: ["01", "02", "03"],
  q2: ["04", "05", "06"],
  q3: ["07", "08", "09"],
  q4: ["10", "11", "12"]
}

const ApprovePaymentModal: FC<ApprovePaymentModalProps> = (props) => {
  const { onClose, open } = props
  const [dateList, setDateList] = useState<KSelectOption[]>([])
  const [selectedDate, setSelectedDate] = useState<string>(props.selectedPeriod)
  const [paymentDay, setPaymentDay] = useState<string>("")

  useEffect(() => {
    if (!props.userPaymentPeriodId) {
      return
    }

    const startDate = new Date("2022-03-01")
    const currentDate = new Date()
    const dates: KSelectOption[] = []

    while (startDate <= currentDate) {
      const year = startDate.getFullYear()

      if (props.userPaymentPeriodId === PAYMENT_PERIOD_TYPE_ID.QUARTERLY) {
        const month = startDate.getMonth() + 1
        const quarter = month / 3
        dates.push({ label: `${year}q${quarter}`, value: Number(`${year}${quarter}`), value2: `${year}q${quarter}` })
        startDate.setMonth(startDate.getMonth() + 3)
      } else if (props.userPaymentPeriodId === PAYMENT_PERIOD_TYPE_ID.MONTHLY) {
        const month = String(startDate.getMonth() + 1).padStart(2, "0")
        dates.push({ label: `${year}${month}`, value: Number(`${year}${month}`), value2: `${year}${month}` })
        startDate.setMonth(startDate.getMonth() + 1)
      } else {
        startDate.setFullYear(9999)
      }
    }

    setDateList(dates)
  }, [props.userPaymentPeriodId])

  useEffect(() => {
    if (!props.userPaymentPeriodId) {
      return
    }

    if (props.userPaymentPeriodId === PAYMENT_PERIOD_TYPE_ID.QUARTERLY && props.selectedPeriod.length === 6) {
      const month = props.selectedPeriod.substring(4, 6)
      const year = props.selectedPeriod.substring(0, 4)
      setSelectedDate(`${year}${quarterMap[month]}`)
      return
    }
    setSelectedDate(props.selectedPeriod)
  }, [props.selectedPeriod, props.userPaymentPeriodId])

  const approvePayment = () => {
    const date: string[] = []
    if (selectedDate.includes("q")) {
      const year = selectedDate.substring(0, 4)
      monthToQuarter[selectedDate.substring(4, 6)].forEach((month: string) => date.push(`${year}${month}`))
    } else {
      date.push(selectedDate)
    }
    props.approve(paymentDay, date)
  }

  return (
    <Dialog open={open} onClose={onClose} className="relative z-50">
      <div className="fixed inset-0 bg-[rgba(0,0,0,0.3)]" aria-hidden="true" />
      <div className="fixed inset-0 flex items-center justify-center p-4 rounded-lg">
        <div className="bg-white elev-b-sm rounded-lg ">
          <div
            className={`bg-gray-50 text-xs text-gray-500 font-medium rounded-t-lg
              uppercase border-b flex justify-between ${tableCellClasses.sm}`}
          >
            <KTitleSpan
              text="APPROVE PAYMENT"
              color="#111"
              fontWeight={500}
              fontSize={14}
              lineHeight="20px"
              letterSpacing="0.2px"
            />
            <X className="h-4 mr-[-6px] cursor-pointer" onClick={onClose} />
          </div>
          <div className="p-4 !pt-6 px-8">
            <div className="flex flex-row gap-1">
              <KSpan text=" Selected User:" color="#000" fontSize={16} lineHeight="20px" />
              <KSpan
                text={props.selectedUsername || ""}
                color="#000"
                fontWeight={600}
                fontSize={16}
                lineHeight="20px"
              />
            </div>
            <div className="mt-12 mb-2 flex flex-row gap-1 items-center">
              <KSpan text=" Payment Period:" color="#000" fontSize={16} lineHeight="20px" />
              <KSpan
                text={props.userPaymentPeriodId ? PAYMENT_PERIOD_TYPE[props.userPaymentPeriodId] : "-"}
                color="#000"
                fontWeight={600}
                fontSize={16}
                lineHeight="20px"
              />
            </div>
            <div className="mb-12">
              {
                <KDropdown
                  width={200}
                  padding="14px"
                  defaultValuePrimitive={selectedDate}
                  rightIcon="/analytics_icons/caret-down-new.svg"
                  enableIndicator={true}
                  options={dateList}
                  onSelect={(selectedOption) => {
                    const selected = selectedOption as KSelectOption
                    if (!selected.value2) return
                    setSelectedDate(selected.value2)
                  }}
                  selected={dateList.find((option) => option.value2 === selectedDate)}
                />
              }
            </div>

            <KSpan text="Payment Day (YYYYMMDD)" color="#000" fontSize={16} lineHeight="20px" />

            <div className="mt-1">
              <KInput
                value={paymentDay}
                placeholder={"Ex: 20230123"}
                padding="14px"
                onChange={(text) => {
                  if (text.length <= 8) {
                    setPaymentDay(text)
                  }
                }}
              />
            </div>
          </div>
          <div className="flex gap-4 p-4 px-8 mt-3">
            <KButton
              text={"Cancel"}
              background="#F7F7F7"
              textColor="#000"
              height="48px"
              padding="14px"
              onClick={props.onClose}
            />
            <KButton
              text={"Approve"}
              background="#000"
              textColor="#FFF"
              height="48px"
              padding="14px"
              onClick={() => approvePayment()}
            />
          </div>
        </div>
      </div>
    </Dialog>
  )
}

export default ApprovePaymentModal
