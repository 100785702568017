import { TableColumn } from "../../../types"
import { TABLE_COLUMN_TYPE } from "../../../constants"
import { Link } from "react-router-dom"
import { LinkIcon } from "../icons"
import { DateTime } from "luxon"
import { hashids } from "../../../utility"
//@ts-ignore
import CheckIcon from "../../../assets/admin_icons/check-circle.svg"
//@ts-ignore
import CloseIcon from "../../../assets/admin_icons/close-circle.svg"
import React from "react"

export const renderCell = (
  row: any,
  rowIndex: number,
  col: TableColumn,
  colIndex: number,
  hoveredRowId?: number | undefined
) => {
  try {
    const getAttribute = () => {
      if (col.attribute && col.attribute.includes(".")) {
        let value = row
        col.attribute.split(".").map((attr) => {
          if (value) {
            value = value[attr]
          }
        })
        return value
      } else if (col.attribute) {
        return row[col.attribute!!]
      }
      return null
    }

    if (col.type === TABLE_COLUMN_TYPE.TEXT) {
      return getAttribute()
    } else if (col.type === TABLE_COLUMN_TYPE.IMG) {
      return (
        <div className="h-[40px] w-[40px] rounded-[100%] bg-gray-800" style={col.cellStyle}>
          <img src={getAttribute()} alt={"table_image"} />
        </div>
      )
    } else if (col.type === TABLE_COLUMN_TYPE.LINK) {
      return (
        <Link to={hashids.encode(getAttribute())}>
          <LinkIcon />
        </Link>
      )
    } else if (col.type === TABLE_COLUMN_TYPE.CONST) {
      return col.constType[getAttribute()] // todo
    } else if (col.type === TABLE_COLUMN_TYPE.DATE) {
      return DateTime.fromFormat(getAttribute(), col.dateParsePattern).toFormat(col.datePattern)
    } else if (col.type === TABLE_COLUMN_TYPE.BOOL) {
      return (
        <img
          className="min-w-5 max-w-5 min-h-5 max-h-5"
          src={getAttribute() ? CheckIcon : CloseIcon}
          alt="status-icon"
        />
      )
    } else if (col.type === TABLE_COLUMN_TYPE.FUNCTION) {
      if (!col.render) return
      return col.renderOnlyWhenHovered ? (
        rowIndex === hoveredRowId && <div>{col.render(row)}</div>
      ) : (
        <div>{col.render(row)}</div>
      )
    } else if (col.type === TABLE_COLUMN_TYPE.ROW_NUMBER) {
      return <div>{(rowIndex + 1).toString().padStart(2, "0")}</div>
    }
  } catch (e) {
    // console.log(e)
  }
  return " - "
}
