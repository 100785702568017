import React from "react"

const ToggleInput = ({
  checked,
  handleChange,
  height,
  width,
  backgroundColor,
  color,
  disabled
}: {
  checked: boolean
  handleChange: () => void
  height?: number
  width?: number
  backgroundColor?: string
  color?: string
  disabled?: boolean
}) => {
  const bgColor = backgroundColor || "#000"
  const containerHeight = height || 20
  const containerWidth = width || 32

  return (
    <div className="relative"
      style={{
        height: containerHeight,
        width: containerWidth,
        borderRadius: "999px",
        backgroundColor: checked && !disabled  ? bgColor : "#F3F3F3",
        cursor: "pointer",
        transition: "0.2s"
      }}
      onClick={() => {
        if (!disabled) {
          handleChange()
        }
      }}
    >
        <div style={{
            backgroundColor: color || "#FFF",
            height: containerHeight - 6,
            width: containerHeight - 6,
            borderRadius: "999px",
            position: "absolute",
            top: 3,
            left: checked ? containerWidth - (containerHeight - 3) : 3,
            transition: "0.2s"
        }}/>

    </div>
  )
}

export default ToggleInput