import { toast } from "react-toastify"
import { errorFilledToastOptions } from "../../../../constants"
import { AnalysisSummary } from "../../../../types"

const summaryValidator = (formValues: AnalysisSummary) => {
  const errors: any = {}

  if (!formValues.totalNet) {
    errors.totalNet = "You must enter a net amount"
    toast.error("You must enter a net amount", errorFilledToastOptions)
  } else if (!/^\d*\.?\d*$/.test(formValues.totalNet.toString())) {
    errors.totalNet = "You must enter a number"
    toast.error("You must enter a number", errorFilledToastOptions)

  }

  return errors
}

export default summaryValidator
