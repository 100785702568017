import { Dialog } from "@headlessui/react"
import React, { FC, useEffect, useState } from "react"
import { X } from "heroicons-react"
import { handleAuthorizedRequest, users } from "../../../../../apis"
import { Affiliate, SelectOption, User } from "../../../../../types"
import { KButton, KSpan, KDropdown, KInput, KTitleSpan } from "kahuna-base-react-components"
import { mapToCamelCase } from "../../../../../utility"
import { toast } from "react-toastify"
import { errorFilledToastOptions } from "../../../../../constants"
import { KSelectOption } from "kahuna-base-react-components/dist/components/KDropdown/KDropdown"
//@ts-ignore

type AdminAffiliateFromUserModalProps = {
  open: boolean
  onClose: () => void
  user: User
  saveAffiliate: (affiliateList: Affiliate[]) => void
  affiliateList: Affiliate[]
}
const AdminAffiliateFromUserModal: FC<AdminAffiliateFromUserModalProps> = (props) => {
  const { onClose, open } = props

  const [affiliateList, setAffiliateList] = useState<Affiliate[]>([])

  const [commission, setCommission] = useState<number | undefined>(0)
  const [selectedUser, setSelectedUser] = useState<User | undefined>(undefined)
  const [selectedDropdownOption, setSelectedDropdownOption] = useState<KSelectOption | undefined>(undefined)
  const [userOptions, setUserOptions] = useState<SelectOption[]>([])
  const [userDropdownOptions, setUserDropdowOptions] = useState<KSelectOption[]>([])
  const [userLoading, setUserLoading] = useState(false)
  const [timeoutId, setTimeoutId] = useState()

  useEffect(() => {
    setAffiliateList([...props.affiliateList])
  }, [props.affiliateList])

  const onSearchTextChanged = async (text: string) => {
    if (!text) return
    setUserOptions([])
    setUserDropdowOptions([])
    setUserLoading(true)
    clearTimeout(timeoutId)

    setTimeoutId(
      //@ts-ignore
      setTimeout(async () => {
        await handleAuthorizedRequest(async () => {
          const response = await users(`/?limit=10&offset=0&username=${text}`, "get")
          setUserOptions(
            response.data.results.map((user: { username: any; id: number }) => ({
              value: mapToCamelCase(user),
              label2: user?.id?.toString(),
              label: user.username
            }))
          )
          setUserDropdowOptions(
            response.data.results.map((user: User) => ({
              value: user.id,
              label: user.username
            }))
          )
          setUserLoading(false)
        }, "")
      }, 500)
    )
  }

  const onDialogClose = () => {
    setSelectedUser(undefined)
    setSelectedDropdownOption(undefined)
    setCommission(0)
    setAffiliateList([...props.affiliateList])
    onClose()
  }

  const onAddNewAffiliate = () => {
    if (!props.user.id || !commission || !selectedUser?.id) {
      return
    }

    if (selectedUser.id === props.user.id) {
      toast.error("Same User!", errorFilledToastOptions)
      return
    }

    if (affiliateList.filter((entity) => entity.toUserId === selectedUser.id).length > 0) {
      toast.error("User Already Exist!", errorFilledToastOptions)
      return
    }

    setAffiliateList([
      ...affiliateList,
      { commission, fromUserId: props.user.id, toUserId: selectedUser.id, toUser: selectedUser }
    ])
  }

  const onDeleteAffiliate = (affiliate: Affiliate) => {
    setAffiliateList([...affiliateList.filter((entity) => entity.toUserId !== affiliate.toUserId)])
  }

  const onSave = () => {
    setSelectedUser(undefined)
    setSelectedDropdownOption(undefined)
    setCommission(0)
    props.saveAffiliate(affiliateList)
  }

  return (
    <Dialog open={open} onClose={() => onDialogClose()} className="relative z-50">
      <div className="fixed inset-0 bg-[rgba(0,0,0,0.3)]" aria-hidden="true" />
      <div className="fixed inset-0 flex items-center justify-center p-4">
        <div className="bg-white elev-b-sm rounded-lg overflow-hidden">
          <div className={`flex justify-end`}>
            <X className="h-4 cursor-pointer mt-1" style={{ position: "absolute" }} onClick={() => onDialogClose()} />
          </div>
          <div className="flex">
            <div style={{ height: "60vh", width: "50vh", padding: 42, position: "relative" }}>
              <div className="pb-6">
                <KTitleSpan text={"Add New Affiliate"} fontSize={24} lineHeight="32px" />
              </div>
              <KSpan text="Username" color="#000" />
              <KDropdown
                placeholder={"Search user to filter"}
                padding="14px"
                gap="8px"
                selected={selectedDropdownOption}
                onInputChange={onSearchTextChanged}
                options={userDropdownOptions}
                isClearable={true}
                onSelect={(selectedOption) => {
                  const selected = selectedOption as KSelectOption
                  setSelectedDropdownOption(selected)
                  const selectedUserOption = userOptions.find((option) => option?.label2 === String(selected?.value))
                  // @ts-ignore
                  setSelectedUser(selectedUserOption?.value)
                }}
                leftIcon="/admin_icons/user-gray.svg"
              />
              <div className="mb-5" />
              <KSpan text="Commission (%)" color="#000" />
              <KInput
                type="number"
                padding="14px 12px 14px 14px"
                gap="8px"
                leftIcon="/admin_icons/soundwave.svg"
                value={commission?.toString() || ""}
                onChange={(text) => {
                  setCommission(Number(text))
                }}
                placeholder="Commission %"
              />
              <div className="flex mt-7" style={{ width: "100%" }}>
                <KButton text="+ Add New" onClick={() => onAddNewAffiliate()} disabled={!selectedUser || !commission} />
              </div>
              <div className="flex flex-row pb-2 items-center gap-1 absolute bottom-0">
                <KSpan text="User:" fontWeight={500} color="#000" />
                <KSpan text={`${props?.user?.firstName} ${props?.user?.lastName}`} color="#000" />
              </div>
            </div>
            <div style={{ height: "60vh", width: "100vh", padding: 42, borderLeft: "1px solid grey" }}>
              <div className="pb-6">
                <KTitleSpan text={"Affiliate From List"} fontSize={24} lineHeight="32px" />
              </div>
              <div className="flex flex-col gap-2" style={{ overflowY: "scroll", height: "90%" }}>
                {affiliateList.map((affiliate: Affiliate, index: number) => {
                  return (
                    <div
                      key={`${affiliate.id}-${index}`}
                      className="flex flex-row items-center justify-between"
                      style={{
                        borderRadius: "10px",
                        padding: "12px",
                        border: "1px solid #F3F3F3"
                      }}
                    >
                      <div className="flex flex-row gap-4 items-center">
                        <img
                          src={affiliate?.toUser?.photo || "/reports_icons/avatar.svg"}
                          alt="profile-photo"
                          className="min-w-16 min-h-16 max-h-10 max-w-10 rounded-full"
                          width={64}
                          height={64}
                        />
                        <div className="flex flex-col justify-between gap-2">
                          {(affiliate?.toUser?.firstName || affiliate?.toUser?.lastName) && (
                            <KTitleSpan
                              text={`${affiliate?.toUser?.firstName} ${affiliate?.toUser?.lastName}`}
                              color="#000"
                              fontWeight={500}
                              fontSize={20}
                              lineHeight="28px"
                            />
                          )}
                          <div className="flex flex-row gap-1.5 items-center">
                            <div className="rounded-[4px] bg-[#F7F7F7] p-1 flex gap-0.5 items-center">
                              <KSpan
                                text={`Commission:`}
                                color="#000"
                                fontSize={12}
                                lineHeight="16px"
                                fontWeight={500}
                              />
                              <KSpan
                                text={`${affiliate.commission?.toString() || "-"}%`}
                                color="#000"
                                fontSize={12}
                                lineHeight="16px"
                              />
                            </div>
                            <div className="rounded-[4px] bg-[#F7F7F7] p-1 flex gap-0.5 items-center">
                              <KSpan
                                text={`Distribution Fee:`}
                                color="#000"
                                fontSize={12}
                                lineHeight="16px"
                                fontWeight={500}
                              />
                              <KSpan
                                text={`${affiliate?.toUser?.userExpense?.distributionFee}%`}
                                color="#000"
                                fontSize={12}
                                lineHeight="16px"
                              />
                            </div>
                            <div className="rounded-[4px] bg-[#F7F7F7] p-1 flex justify-center">
                              <KSpan
                                text={affiliate?.toUser?.email || ""}
                                color="#000"
                                fontSize={12}
                                lineHeight="16px"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="flex items-center">
                        <KButton
                          icon={"/account_icons/trash_red.svg"}
                          padding="8px"
                          height="36px"
                          width="36px"
                          background="#FFF"
                          hoverBackground="#FFE6E8"
                          activeBackground="#FFF"
                          border="1px solid #FF5865"
                          hoverBorder="1px solid transparent"
                          activeBorder="1px solid #FF5865"
                          onClick={() => onDeleteAffiliate(affiliate)}
                        />
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
          <div
            className="justify-end items-center flex mb-4 pt-4 pr-4"
            style={{ width: "100%", borderTop: "1px solid grey" }}
          >
            <KButton
              width="250px"
              background="transparent"
              shadowDisabled={true}
              text="Close"
              onClick={() => onDialogClose()}
            />
            <KButton width="250px" background="black" textColor="white" text="Save" onClick={() => onSave()} />
          </div>
        </div>
      </div>
    </Dialog>
  )
}

export default AdminAffiliateFromUserModal
