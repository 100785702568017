import { Dialog } from "@headlessui/react"
import React, { FC } from "react"
import { X } from "heroicons-react"
import { Affiliate, User } from "../../../../../types"
import { KSpan, KTitleSpan } from "kahuna-base-react-components"

type AdminAffiliateToUserModalProps = {
  open: boolean
  onClose: () => void
  affiliateList: Affiliate[]
  user: User | null
}
const AdminAffiliateToUserModal: FC<AdminAffiliateToUserModalProps> = (props) => {
  const { onClose, open } = props

  const onDialogClose = () => {
    onClose()
  }

  return (
    <Dialog open={open} onClose={() => onDialogClose()} className="relative z-50">
      <div className="fixed inset-0 bg-[rgba(0,0,0,0.3)]" aria-hidden="true" />
      <div className="fixed inset-0 flex items-center justify-center p-4">
        <div className="bg-white elev-b-sm rounded-lg overflow-hidden">
          <div className={`flex justify-end`}>
            <X className="h-4 cursor-pointer mt-1" style={{ position: "absolute" }} onClick={() => onDialogClose()} />
          </div>
          <div className="flex">
            <div style={{ height: "60vh", width: "80vh", padding: 42, borderLeft: "1px solid grey" }}>
              <div className="pb-4">
                <KTitleSpan text={"Affiliate To List"} fontSize={24} lineHeight="32px" />
              </div>
              <div className="flex flex-row items-center gap-1 mb-6">
                <KSpan text="User:" fontWeight={500} color="#000" />
                <KSpan text={`${props?.user?.firstName} ${props?.user?.lastName}`} color="#000" />
              </div>
              <div className="flex flex-col gap-2 pb-5" style={{ overflowY: "scroll", height: "90%" }}>
                {props.affiliateList.map((affiliate: Affiliate) => {
                  return (
                    <div key={affiliate.fromUserId}>
                      <div
                        className="flex flex-row items-center justify-between"
                        style={{
                          borderRadius: "10px",
                          padding: "12px",
                          border: "1px solid #F3F3F3"
                        }}
                      >
                        <div className="flex flex-row gap-4 items-center">
                          <img
                            src={affiliate?.fromUser?.photo || "/reports_icons/avatar.svg"}
                            alt="profile-photo"
                            className="min-w-16 min-h-16 max-h-10 max-w-10 rounded-full"
                            width={64}
                            height={64}
                          />
                          <div className="flex flex-col justify-between gap-2">
                            {(affiliate?.fromUser?.firstName || affiliate?.fromUser?.lastName) && (
                              <KTitleSpan
                                text={`${affiliate?.fromUser?.firstName} ${affiliate?.fromUser?.lastName}`}
                                color="#000"
                                fontWeight={500}
                                fontSize={20}
                                lineHeight="28px"
                              />
                            )}
                            <div className="flex flex-row gap-1.5 items-center">
                              <div className="rounded-[4px] bg-[#F7F7F7] p-1 flex gap-0.5 items-center">
                                <KSpan
                                  text={`Commission:`}
                                  color="#000"
                                  fontSize={12}
                                  lineHeight="16px"
                                  fontWeight={500}
                                />
                                <KSpan
                                  text={`${affiliate.commission?.toString() || "-"}%`}
                                  color="#000"
                                  fontSize={12}
                                  lineHeight="16px"
                                />
                              </div>
                              <div className="rounded-[4px] bg-[#F7F7F7] p-1 flex justify-center">
                                <KSpan
                                  text={affiliate?.fromUser?.email || ""}
                                  color="#000"
                                  fontSize={12}
                                  lineHeight="16px"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  )
}

export default AdminAffiliateToUserModal
