import { KSpan } from "kahuna-base-react-components"
import React from "react"

const CalendarDate = ({ quarter }: { quarter: string }) => {
  //prop olan quarter data'dan gelir.
  //const [year, quar] = quarter.split(' ')
  //svg elemanlari public icine ekledndi. Cunku baska bir klasore eklendiginde prop olarak
  //girildiginde relative pathden dolayi svg bulunamiyor.
  const [year, quar] = quarter!.split(" ")

  return (
    <div className="flex flex-row h-full items-center gap-3">
      <div>
        <img
          src="/reports_icons/calendar-new.svg"
          style={{ backgroundColor: "#F7F7F7", borderRadius: 999, padding: 12 }}
        />
      </div>
      <div className="flex flex-col items-start p-0.5">
        <KSpan text={year} color="#000" fontWeight={500} fontSize={14} lineHeight="20px" letterSpacing="-0.084px" />
        <div className="flex items-center justify-center p-1 w-auto rounded-[4px] bg-[#F7F7F7]">
          <KSpan text={quar} color="#111" fontWeight={500} fontSize={11} lineHeight="12px" letterSpacing="0.22px" />
        </div>
      </div>
    </div>
  )
}

export default CalendarDate
