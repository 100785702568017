import { KSpan, KTooltip } from "kahuna-base-react-components"
import React, { SetStateAction, useEffect, useState } from "react"
import { Link } from "react-router-dom"

const AdminNavListElement = ({
  name,
  Icon,
  index,
  selectedIndex,
  setSelectedIndex,
  href
}: {
  name: string
  Icon: (color?: string) => React.JSX.Element
  index: number
  selectedIndex: number
  setSelectedIndex: React.Dispatch<SetStateAction<number>>
  href: string
}) => {
  const onClickEvent = () => {
    setSelectedIndex(index)
  }

  const isSelected = window.location.pathname.endsWith(href)

  const [hover, setHover] = useState<boolean>(false)

  const chooseIconColor = () => {
    if (isSelected) return "#303315"
    else {
      return hover ? "#111" : "#666"
    }
  }
  const chooseBgColor = () => {
    if (isSelected) return "#F2FE67"
    else {
      return hover ? "#F7F7F7" : "#FFF"
    }
  }

  return (
    <KTooltip
      backgroundColor="#000"
      padding="0px 4px"
      height="20px"
      arrowColor="#000"
      position="right"
      content={
        <div className="h-5 w-max flex items-center">
          <KSpan text={name} fontSize={12} lineHeight="16px" color="#FFF" />
        </div>
      }
    >
      <Link to={`/admin${href}`}>
        <div
          onClick={onClickEvent}
          onMouseEnter={() => {
            setHover(true)
          }}
          onMouseLeave={() => {
            setHover(false)
          }}
          className={`w-11 h-11 flex flex-row justify-start items-center group`}
          style={{
            backgroundColor: chooseBgColor(),
            padding: "14px",
            transition: "0.4s"
          }}
        >
          {Icon(chooseIconColor())}
        </div>
      </Link>
    </KTooltip>
  )
}

export default AdminNavListElement
