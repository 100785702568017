import { Action, Dispatch } from "redux"
import {
  handleAuthorizedRequest,
  trackShareHolders,
  users,
  youtubeVideoShareHolders,
  releases,
  summaries,
  completeShareHolders,
  collaboratorInvitation,
  affiliate
} from "../apis"
import {
  getFilterString,
  getOrderByString,
  mapPaginatedDataToCamelCase,
  mapToCamelCase,
  mapToSnakeCase
} from "../utility"
import {
  AdminCollaboratorShareForm,
  AnalysisSummary,
  CollaboratorData,
  User,
  UserExpenseForm,
  UserForm,
  UserTrackShareData,
  UserTrackShareForm,
  UserYoutubeVideoShareData,
  UserYoutubeVideoShareForm
} from "../types"
import { toast } from "react-toastify"
import { errorFilledToastOptions, infoFilledToastOptions, successFilledToastOptions } from "../constants"
import { DateTime } from "luxon"

export const ADMIN_ACTION = {
  FETCH_ALL_USERS: "FETCH_ALL_USERS",
  SELECT_USER: "SELECT_USER",
  SAVE_USER: "SAVE_USER",
  FETCH_USER_EXPENSE: "FETCH_USER_EXPENSE",
  FETCH_AFFILIATE_LIST: "FETCH_AFFILIATE_LIST",
  CREATE_USER_EXPENSE: "CREATE_USER_EXPENSE",
  FETCH_TRACK_SHARE_DATA: "FETCH_TRACK_SHARE_DATA",
  SELECT_TRACK_SHARE: "SELECT_TRACK_SHARE",
  SELECT_PAYMENT_METHOD_USER: "SELECT_PAYMENT_METHOD_USER",
  SAVE_TRACK_SHARE: "SAVE_TRACK_SHARE",
  DELETE_TRACK_SHARE: "DELETE_TRACK_SHARE",
  FETCH_YOUTUBE_VIDEO_SHARE_DATA: "FETCH_YOUTUBE_VIDEO_SHARE_DATA",
  SELECT_YOUTUBE_VIDEO_SHARE: "SELECT_YOUTUBE_VIDEO_SHARE",
  DELETE_YOUTUBE_VIDEO_SHARE: "DELETE_YOUTUBE_VIDEO_SHARE",
  SAVE_YOUTUBE_VIDEO_SHARE: "SAVE_YOUTUBE_VIDEO_SHARE",
  FETCH_LAST_UPDATE: "FETCH_LAST_UPDATE",
  REFRESH_LABEL_CAMP: "REFRESH_LABEL_CAMP",
  REFRESH_REPORTS: "REFRESH_REPORTS",
  REFRESH_SUMMARIES: "REFRESH_SUMMARIES",
  FETCH_SUMMARY: "FETCH_SUMMARY",
  APPROVE_SUMMARY: "APPROVE_SUMMARY",
  SELECT_SUMMARY_FILTER: "SELECT_SUMMARY_FILTER",
  FETCH_COLLABORATOR_DATA: "FETCH_COLLABORATOR_DATA",
  SELECT_COLLABORATOR: "SELECT_COLLABORATOR",
  SEND_COLLABORATOR_INVITATION: "SEND_COLLABORATOR_INVITATION"
}

export const fetchAllUsers =
  (
    limit: number,
    offset: number,
    filters: { [key: string]: string | number | boolean } | undefined,
    sortField?: string,
    sortDirection?: "asc" | "desc"
  ) =>
  async (dispatch: Dispatch<Action>) => {
    await handleAuthorizedRequest(
      async () => {
        const response = await users(
          `/?limit=${limit}&offset=${offset}${getFilterString(filters)}&${getOrderByString(sortField, sortDirection)}`,
          "get"
        )

        dispatch({
          type: ADMIN_ACTION.FETCH_ALL_USERS,
          payload: mapPaginatedDataToCamelCase<User>(response.data)
        })
      },
      "Error fetching users: ",
      dispatch
    )
  }

export const selectUser = (user: User | null) => async (dispatch: Dispatch<Action>) => {
  dispatch({
    type: ADMIN_ACTION.SELECT_USER,
    payload: user
  })
}

export const saveUser = (formValues: UserForm) => async (dispatch: Dispatch<Action>) => {
  await handleAuthorizedRequest(
    async () => {
      const permission_ids = formValues.permissionIds
      const email = formValues.username
      const requestPayload = {
        ...mapToSnakeCase(formValues),
        permission_ids,
        email
      }
      let sendEmailParam = ""
      if (formValues.sendEmail) {
        sendEmailParam = "?send_email=1"
      }
      const response = await users(`/create_user/${sendEmailParam}`, "post", requestPayload)
      dispatch({
        type: ADMIN_ACTION.SAVE_USER,
        payload: mapToCamelCase(response.data)
      })
      toast.info("User saved. Refresh the page to see the changes.", infoFilledToastOptions)
      //TODO: it gives incomplete wrong values to SELECT_USER, causes SELECT_USER details to be undefined. Check this later
      /*dispatch({
        type: ADMIN_ACTION.SELECT_USER,
        payload: mapToCamelCase(response.data)
      })*/
    },
    "Error saving user: ",
    dispatch
  )
}

export const fetchUserExpenseData = (userId: number) => async (dispatch: Dispatch<Action>) => {
  await handleAuthorizedRequest(
    async () => {
      const response = await users(`/fetch_user_expense/?user_id=${userId}`, "get")

      dispatch({
        type: ADMIN_ACTION.FETCH_USER_EXPENSE,
        payload: mapToCamelCase(response.data.model)
      })
    },
    "Error fetching user expense data: ",
    dispatch
  )
}

export const fetchAffiliateList = (userId: number) => async (dispatch: Dispatch<Action>) => {
  await handleAuthorizedRequest(
    async () => {
      const response = await affiliate(`/?admin=1&user_id=${userId}`, "get")

      dispatch({
        type: ADMIN_ACTION.FETCH_AFFILIATE_LIST,
        payload: mapToCamelCase(response.data)
      })
    },
    "Error fetching user affiliate data: ",
    dispatch
  )
}

export const saveUserExpenseData = (formValues: UserExpenseForm) => async (dispatch: Dispatch<Action>) => {
  await handleAuthorizedRequest(
    async () => {
      const requestPayload = {
        // @ts-ignore
        ...mapToSnakeCase(formValues)
      }
      const response = await users(`/create_user_expense/`, "post", requestPayload)
      dispatch({
        type: ADMIN_ACTION.CREATE_USER_EXPENSE,
        payload: mapToCamelCase(response.data.model)
      })
      toast.success("User Expense Saved.", successFilledToastOptions)
    },
    "Error saving user expense: ",
    dispatch
  )
}

export const fetchUserTrackShareData =
  (
    id: number,
    limit: number,
    offset: number,
    filters: { [key: string]: string | number | boolean } | undefined,
    sortField?: string,
    sortDirection?: "asc" | "desc"
  ) =>
  async (dispatch: Dispatch<Action>) => {
    await handleAuthorizedRequest(
      async () => {
        const response = await trackShareHolders(
          `/?user=${id}&limit=${limit}&offset=${offset}${getFilterString(filters)}&${getOrderByString(
            sortField,
            sortDirection
          )}`,
          "get"
        )
        dispatch({
          type: ADMIN_ACTION.FETCH_TRACK_SHARE_DATA,
          payload: mapPaginatedDataToCamelCase(response.data)
        })
      },
      "Error fetching user share data: ",
      dispatch
    )
  }

export const selectTrackShareData = (data: UserTrackShareData | null) => async (dispatch: Dispatch<Action>) => {
  dispatch({
    type: ADMIN_ACTION.SELECT_TRACK_SHARE,
    payload: data
  })
}

export const paymentMethodsAdminEntry = (adminPanelUserId?: number) => async (dispatch: Dispatch<Action>) => {
  dispatch({
    type: ADMIN_ACTION.SELECT_PAYMENT_METHOD_USER,
    payload: adminPanelUserId
  })
}

export const saveTrackShareForm = (formValues: UserTrackShareForm) => async (dispatch: Dispatch<Action>) => {
  await handleAuthorizedRequest(
    async () => {
      const response = await trackShareHolders("/", "post", formValues)

      if (response.status === 203) {
        toast.error(response.data.message, errorFilledToastOptions)
        return
      }

      dispatch({
        type: ADMIN_ACTION.SAVE_TRACK_SHARE,
        payload: mapToCamelCase(response.data)
      })
      toast.success("Action successfully completed", successFilledToastOptions)
      
    },
    "Error saving track share form: ",
    dispatch
  )
}

export const deleteTrackShareData = (shareDataId: number) => async (dispatch: Dispatch<Action>) => {
  await handleAuthorizedRequest(
    async () => {
      const response = await trackShareHolders(`/${shareDataId}/`, "delete")

      if (response.status === 204) {
        dispatch({
          type: ADMIN_ACTION.DELETE_TRACK_SHARE,
          payload: shareDataId
        })
        toast.success("Track share deleted successfully.", successFilledToastOptions)
      }
    },
    "Deleting track share data has error.",
    dispatch
  )
}

export const fetchUserYoutubeVideoShareData =
  (
    id: number,
    limit: number,
    offset: number,
    filters: { [key: string]: string | number | boolean } | undefined,
    sortField?: string,
    sortDirection?: "asc" | "desc"
  ) =>
  async (dispatch: Dispatch<Action>) => {
    await handleAuthorizedRequest(
      async () => {
        const response = await youtubeVideoShareHolders(
          `/?user=${id}&limit=${limit}&offset=${offset}${getFilterString(filters)}&${getOrderByString(
            sortField,
            sortDirection
          )}`,
          "get"
        )

        dispatch({
          type: ADMIN_ACTION.FETCH_YOUTUBE_VIDEO_SHARE_DATA,
          payload: mapPaginatedDataToCamelCase(response.data)
        })
      },
      "Error fetching user share data: ",
      dispatch
    )
  }

export const selectYoutubeVideoShareData = (data: UserYoutubeVideoShareData | null) => async (dispatch: Dispatch<Action>) => {
  dispatch({
    type: ADMIN_ACTION.SELECT_YOUTUBE_VIDEO_SHARE,
    payload: data
  })
}

export const deleteYoutubeVideoShareData = (shareDataId: number) => async (dispatch: Dispatch<Action>) => {
  await handleAuthorizedRequest(
    async () => {
      const response = await youtubeVideoShareHolders(`/${shareDataId}/`, "delete")

      if (response.status === 204) {
        dispatch({
          type: ADMIN_ACTION.DELETE_YOUTUBE_VIDEO_SHARE,
          payload: shareDataId
        })
        toast.success("Youtube video share deleted successfully.", successFilledToastOptions)
      }
    },
    "Deleting youtube share data has error: ",
    dispatch
  )
}

export const saveYoutubeVideoShareForm =
  (formValues: UserYoutubeVideoShareForm) => async (dispatch: Dispatch<Action>) => {
    await handleAuthorizedRequest(
      async () => {
        const response = await youtubeVideoShareHolders("/", "post", mapToSnakeCase(formValues))

        /*dispatch({
          type: ADMIN_ACTION.SAVE_YOUTUBE_VIDEO_SHARE,
          payload: mapToCamelCase(response.data)
        })*/
        toast.info("Action successfully completed. Refresh the page to see the changes.", infoFilledToastOptions)

      },
      "Error saving youtube video share form",
      dispatch
    )
  }

export const fetchLastUpdate = () => async (dispatch: Dispatch<Action>) => {
  await handleAuthorizedRequest(
    async () => {
      const response = await releases(`/labelcamp-request-status`, "get")
      response.data.results.sort((a, b) => a.id - b.id)
      const lastUpdate = response.data.results[response.data.results.length - 1]?.date
      const dateObject = DateTime.fromISO(lastUpdate)
      const formattedDate = dateObject.toFormat("dd LLLL yyyy, EEEE")
      dispatch({
        type: ADMIN_ACTION.FETCH_LAST_UPDATE,
        payload: formattedDate
      })
    },
    "Error fetching last update date.",
    dispatch
  )
}

export const refreshLabelCamp = () => async (dispatch: Dispatch<Action>) => {
  await handleAuthorizedRequest(
    async () => {
      const response = await releases(`/summary/refresh_assets/`, "post")
      const message = response.data
      dispatch({
        type: ADMIN_ACTION.REFRESH_LABEL_CAMP,
        payload: message
      })
      toast.success(message, successFilledToastOptions)
    },
    "Error on refreshing label camp.",
    dispatch
  )
}

export const refreshReports = (date: string) => async (dispatch: Dispatch<Action>) => {
  await handleAuthorizedRequest(
    async () => {
      const response = await releases(`/summary/refresh_reports/`, "post", { date })
      const message = response.data
      dispatch({
        type: ADMIN_ACTION.REFRESH_REPORTS,
        payload: message
      })
      toast.success(message, successFilledToastOptions)
    },
    "Error on refreshing reports.",
    dispatch
  )
}

export const refreshSummaries = (date: string, userId: number) => async (dispatch: Dispatch<Action>) => {
  await handleAuthorizedRequest(
    async () => {
      const response = await releases(`/summary/refresh_summaries/`, "post", { date, userId })
      const message = response.data
      dispatch({
        type: ADMIN_ACTION.REFRESH_SUMMARIES,
        payload: message
      })
      toast.success(message, successFilledToastOptions)
    },
    "Error on refreshing summaries.",
    dispatch
  )
}

export const fetchApprovalSummaries =
  (
    type: string,
    filters: { [key: string]: string | number | boolean } | undefined,
    sortField?: string,
    sortDirection?: "asc" | "desc"
  ) =>
  async (dispatch: Dispatch<Action>) => {
    await handleAuthorizedRequest(
      async () => {
        const response = await summaries(
          `/summary_table/?page=1&type=${type}${getFilterString(filters)}&${getOrderByString(
            sortField,
            sortDirection
          )}`,
          "get"
        )

        dispatch({
          type: ADMIN_ACTION.FETCH_SUMMARY,
          payload: mapToCamelCase(response.data)
        })
      },
      "Error on fetching summaries.",
      dispatch
    )
  }

export const selectFilter = (filter: string) => async (dispatch: Dispatch<Action>) => {
  dispatch({
    type: ADMIN_ACTION.SELECT_SUMMARY_FILTER,
    payload: filter
  })
}

export const approveSummary = (summary: AnalysisSummary, type: string) => async (dispatch: Dispatch<Action>) => {
  await handleAuthorizedRequest(
    async () => {
      await summaries(`/approve_summary/?type=${type}`, "post", summary)

      dispatch({
        type: ADMIN_ACTION.APPROVE_SUMMARY,
        payload: { ...summary, approved: true }
      })
      toast.success("Report summary successfully saved.", successFilledToastOptions)
    },
    "Error on approving summary.",
    dispatch
  )
}

export const fetchTrackCollaboratorData = (trackId: number) => async (dispatch: Dispatch<Action>) => {
  await handleAuthorizedRequest(
    async () => {
      const response = await completeShareHolders(`?track_id=${trackId}`, "get")
      dispatch({
        type: ADMIN_ACTION.FETCH_COLLABORATOR_DATA,
        payload: response.data
      })
    },
    "Error fetching track share data: ",
    dispatch
  )
}

export const selectCollaboratorData = (data: CollaboratorData | null) => async (dispatch: Dispatch<Action>) => {
  dispatch({
    type: ADMIN_ACTION.SELECT_COLLABORATOR,
    payload: data
  })
}

export const saveCollaboratorForm = (formValues: AdminCollaboratorShareForm) => async (dispatch: Dispatch<Action>) => {
  const body = {
    ...formValues,
    roles: formValues.roles.toString()
  }
  await handleAuthorizedRequest(
    async () => {
      const response = await collaboratorInvitation("/", "post", body)

      if (response.status === 203) {
        toast.error(response.data.message, errorFilledToastOptions)
        return
      }

      dispatch({
        type: ADMIN_ACTION.SEND_COLLABORATOR_INVITATION,
        payload: response.data
      })
      toast.success("Collaborator invitation sent.", successFilledToastOptions)
    },
    "Error sending invitation to collaborator.",
    dispatch
  )
}
