import { AdminCatalogSelectedActionParams, Release, TableColumn } from "../../../../types"
import { releaseStatusOptions, TABLE_COLUMN_TYPE } from "../../../../constants"
import { KButton, KSpan, KDropdown, KTooltip } from "kahuna-base-react-components"
import React from "react"
import { useNavigate } from "react-router-dom"
import { formatIsoDate, hashids } from "../../../../utility"
import { KSelectOption } from "kahuna-base-react-components/dist/components/KDropdown/KDropdown"
//@ts-ignore
import CheckCircleGreen from "../../../../assets/admin_icons/check-circle.svg"
//@ts-ignore
import CloseCircle from "../../../../assets/admin_icons/close-circle.svg"
//@ts-ignore
import TrashIcon from "../../../../assets/admin_icons/trash-white.svg"
import UploadOptionsMenu from "./dropdown-menu/UploadOptionsMenu"
import ImageWithFallback from "../../../ui/components/ImageWithFallback"

const adminCatalogColumns = (
  onSubmitBmv: (release: Release) => void,
  onSubmitMesamMsg: (release: Release) => void,
  onSubmitEditorialPitching: (release: Release) => void,
  onSubmitState51: (release: Release) => void,
  isAdmin: boolean,
  setSelectedActionParams: (params: AdminCatalogSelectedActionParams) => void
): TableColumn[] => {
  const navigate = useNavigate()
  const releaseNameColumn: TableColumn = {
    header: "Release",
    headerStyle: {
      textAlign: "left",
      width: "max-content"
    },
    sortable: true,
    sortFilterField: "title",
    type: TABLE_COLUMN_TYPE.FUNCTION,
    render: (row: Release) => {
      const artists = row?.artistsList || []
      return (
        <div className="flex w-max">
          <div className="flex flex-row gap-3 items-center">
            <ImageWithFallback coverFileUrl={row?.coverFileUrl}/>
            <div className="flex flex-col justify-between gap-0.5">
              <div
                style={{
                  cursor: isAdmin ? "pointer" : "default"
                }}
                onClick={() => {
                  if (isAdmin) {
                    navigate(`${hashids.encode(row?.id?.toString() || "")}`)
                  }
                }}
              >
                <KSpan text={row.title} color="#000" fontWeight={500} />
              </div>

              <div className="flex flex-row gap-0.5">
                <div className="rounded-[4px] bg-[#F7F7F7] p-1 flex justify-center items-center">
                  <KSpan
                    text={`${row.totalTrack} ${row.totalTrack > 1 ? "songs" : "song"}`}
                    color="#111"
                    fontSize={11}
                    fontWeight={500}
                    lineHeight="12px"
                    letterSpacing="0.22px"
                  />
                </div>
                <div className="rounded-[4px] bg-[#F7F7F7] flex items-center p-1 w-auto">
                  <KSpan
                    text={
                      artists
                        ?.slice(0, 2)
                        ?.map((artist) => artist?.name)
                        .join(", ") || "-"
                    }
                    color="#111"
                    fontSize={11}
                    fontWeight={500}
                    lineHeight="12px"
                    letterSpacing="0.22px"
                  />
                  {artists?.length > 2 && (
                    <KTooltip
                      backgroundColor="#000"
                      arrowColor="#000"
                      position="bottom"
                      content={
                        <div className="w-max flex flex-col gap-1 items-start">
                          {artists.slice(2, artists.length).map((artist, index: number) => {
                            return (
                              <KSpan
                                key={`${artist?.name}-${index}`}
                                text={artist?.name}
                                fontSize={12}
                                fontWeight={500}
                                lineHeight="16px"
                                color="#FFF"
                              />
                            )
                          })}
                        </div>
                      }
                    >
                      {" "}
                      <div className="flex items-center">
                        <KSpan
                          text={`, (+${artists?.length - 2})`}
                          color="#111"
                          fontSize={11}
                          fontWeight={500}
                          lineHeight="12px"
                          letterSpacing="0.22px"
                        />
                      </div>
                    </KTooltip>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    }
  }

  const releaseDateColumn: TableColumn = {
    header: "Release Date",
    headerStyle: {
      textAlign: "left",
      width: "max-content"
    },
    sortable: true,
    sortFilterField: "release_date",
    type: TABLE_COLUMN_TYPE.FUNCTION,
    render: (row: Release) => {
      return (
        <div className="flex">
          <div className="rounded-[4px] bg-[#F7F7F7] flex items-center p-1 w-max no-parent-trigger">
            <KSpan
              text={formatIsoDate(row?.releaseDate, true)}
              color="#111"
              fontSize={11}
              fontWeight={500}
              lineHeight="12px"
              letterSpacing="0.22px"
            />
          </div>
        </div>
      )
    }
  }

  const genreColumn: TableColumn = {
    header: "Genre",
    headerStyle: {
      textAlign: "left",
      width: "max-content"
    },
    sortable: true,
    sortFilterField: "genre",
    type: TABLE_COLUMN_TYPE.FUNCTION,
    render: (row: Release) => {
      return (
        <div className="flex">
          <div className="rounded-[4px] bg-[#F7F7F7] flex items-center p-1 w-max no-parent-trigger">
            <KSpan
              text={row.genre || "-"}
              color="#111"
              fontSize={11}
              fontWeight={500}
              lineHeight="12px"
              letterSpacing="0.22px"
            />
          </div>
        </div>
      )
    }
  }

  const labelColumn: TableColumn = {
    header: "Label",
    headerStyle: {
      textAlign: "left",
      width: "max-content"
    },
    sortable: true,
    sortFilterField: "label__name",
    type: TABLE_COLUMN_TYPE.FUNCTION,
    render: (row: Release) => {
      return (
        <div className="flex">
          <div className="rounded-[4px] bg-[#F7F7F7] flex items-center p-1 w-auto no-parent-trigger">
            <KSpan
              text={row.labelDetail?.name || ""}
              color="#111"
              fontSize={11}
              fontWeight={500}
              lineHeight="12px"
              letterSpacing="0.22px"
            />
          </div>
        </div>
      )
    }
  }

  const upcColumn: TableColumn = {
    header: "UPC",
    headerStyle: {
      textAlign: "left",
      width: "max-content"
    },
    sortable: true,
    sortFilterField: "upc",
    type: TABLE_COLUMN_TYPE.FUNCTION,
    render: (row: Release) => {
      return (
        <div className="flex">
          <div className="rounded-[4px] bg-[#F7F7F7] flex items-center p-1 w-auto no-parent-trigger">
            <KSpan
              text={row.upc}
              color="#111"
              fontSize={11}
              fontWeight={500}
              lineHeight="12px"
              letterSpacing="0.22px"
            />
          </div>
        </div>
      )
    }
  }

  const bmvDriveId: TableColumn = {
    header: "BMV",
    headerStyle: {
      textAlign: "left",
      width: "max-content"
    },
    cellStyle: {
      fontWeight: 400,
      lineHeight: "20px",
      color: "#111827"
    },
    type: TABLE_COLUMN_TYPE.FUNCTION,
    render: (row: Release) => {
      return (
        <div className="flex gap-2 no-parent-trigger">
          <div style={{ width: 44, display: "grid", alignItems: "center" }}>
            {row.driveId ? (
              <img
                onClick={() => window.open(`https://drive.google.com/drive/folders/${row.driveId}`, "_blank")}
                src={"/catalog_icons/drive_icon.png"}
                alt={`${row.id}_bmv_drive`}
                style={{ width: 24, height: "fit-content", cursor: "pointer" }}
              />
            ) : (
              <div className="rounded-[4px] bg-[#F7F7F7] flex items-center p-1 w-max no-parent-trigger">
                <KSpan
                  text={"-"}
                  color="#111"
                  fontSize={11}
                  fontWeight={500}
                  lineHeight="12px"
                  letterSpacing="0.22px"
                />
              </div>
            )}
          </div>
        </div>
      )
    }
  }

  const publishingDriveId: TableColumn = {
    header: "MESAM MSG",
    headerStyle: {
      textAlign: "left",
      width: "max-content"
    },
    cellStyle: {
      fontWeight: 400,
      lineHeight: "20px",
      color: "#111827"
    },
    type: TABLE_COLUMN_TYPE.FUNCTION,
    render: (row: Release) => {
      return (
        <div className="flex gap-2 no-parent-trigger">
          <div style={{ width: 44, display: "grid", alignItems: "center" }}>
            {row.mesamMsgId ? (
              <img
                src={"/catalog_icons/drive_icon.png"}
                alt={`${row.id}_publishing_drive`}
                style={{ width: 24, cursor: "pointer" }}
                onClick={() => window.open(`https://drive.google.com/drive/folders/${row.mesamMsgId}`, "_blank")}
              />
            ) : (
              <div className="rounded-[4px] bg-[#F7F7F7] flex items-center p-1 w-max no-parent-trigger">
                <KSpan
                  text={"-"}
                  color="#111"
                  fontSize={11}
                  fontWeight={500}
                  lineHeight="12px"
                  letterSpacing="0.22px"
                />
              </div>
            )}
          </div>
        </div>
      )
    }
  }

  const editorialPitchingDriveId: TableColumn = {
    header: "Editorial Pitching",
    headerStyle: {
      textAlign: "left",
      width: "max-content"
    },
    cellStyle: {
      fontWeight: 400,
      lineHeight: "20px",
      color: "#111827"
    },
    type: TABLE_COLUMN_TYPE.FUNCTION,
    render: (row: Release) => {
      return (
        <div className="flex gap-2 no-parent-trigger">
          <div style={{ width: 44, display: "grid", alignItems: "center" }}>
            {row.editorialPitchingId ? (
              <img
                src={"/catalog_icons/drive_icon.png"}
                alt={`${row.id}_pitching_drive`}
                style={{ width: 24, cursor: "pointer" }}
                onClick={() =>
                  window.open(`https://docs.google.com/spreadsheets/d/${row.editorialPitchingId}`, "_blank")
                }
              />
            ) : (
              <div className="rounded-[4px] bg-[#F7F7F7] flex items-center p-1 w-max no-parent-trigger">
                <KSpan
                  text={"-"}
                  color="#111"
                  fontSize={11}
                  fontWeight={500}
                  lineHeight="12px"
                  letterSpacing="0.22px"
                />
              </div>
            )}
          </div>
        </div>
      )
    }
  }

  const state51Column: TableColumn = {
    header: "State51 ID",
    headerStyle: {
      textAlign: "left",
      width: "max-content"
    },
    cellStyle: {
      fontWeight: 400,
      lineHeight: "20px",
      color: "#111827"
    },
    type: TABLE_COLUMN_TYPE.FUNCTION,
    render: (row: Release) => {
      return (
        <div className="flex gap-2 no-parent-trigger" style={{ alignItems: "center" }}>
          <div className="rounded-[4px] bg-[#F7F7F7] flex items-center p-1 w-max no-parent-trigger">
            <KSpan
              text={row.state51Id || "-"}
              color="#111"
              fontSize={11}
              fontWeight={500}
              lineHeight="12px"
              letterSpacing="0.22px"
            />
          </div>
        </div>
      )
    }
  }

  const statusColumn: TableColumn = {
    header: "Status",
    headerStyle: {
      textAlign: "center",
      width: "max-content"
    },
    sortable: true,
    sortFilterField: "status",
    type: TABLE_COLUMN_TYPE.FUNCTION,
    render: (row: Release) => {
      const statusOptions: KSelectOption[] = releaseStatusOptions.map((statusOption, index) => {
        return { label: statusOption.label, value: index, value2: statusOption.value, icon: statusOption.iconColored }
      })

      return (
        <div className="relative no-parent-trigger">
          <KDropdown
            width={135}
            options={statusOptions}
            onSelect={(selected) => {
              const selectedStatus = (selected as KSelectOption).value2
              if (!selected || !selectedStatus || !row.id) return
              setSelectedActionParams({
                type: "statusUpdate",
                id: row.id,
                status: selectedStatus,
                title: row.title,
                checked: false
              })
            }}
            selected={statusOptions.find((option) => row.status === option.value2)}
            rightIcon="/analytics_icons/caret-down-new.svg"
            menuWidth={160}
            menuLeftMargin={-8}
            enableIndicator
          />
        </div>
      )
    }
  }

  const showColumn: TableColumn = {
    header: "Show",
    headerStyle: {
      textAlign: "center",
      width: "max-content",
      color: "transparent"
    },
    type: TABLE_COLUMN_TYPE.FUNCTION,
    render: (row: Release) => (
      <div className="flex flex-row gap-2 shrink-0 w-full no-parent-trigger">
        <KButton
          icon={row.labelCamp ? CheckCircleGreen : CloseCircle}
          padding="8px"
          height="36px"
          width="36px"
          background="#FFF"
          onClick={() => {
            if (!row.id) return
            setSelectedActionParams({
              type: "labelCampUpdate",
              id: row.id,
              status: undefined,
              title: row.title,
              checked: !row.labelCamp
            })
          }}
          border="1px solid #F3F3F3"
        />
      </div>
    )
  }

  const uploadColumn: TableColumn = {
    header: "Upload",
    headerStyle: {
      textAlign: "center",
      width: "max-content",
      color: "transparent"
    },
    type: TABLE_COLUMN_TYPE.FUNCTION,
    render: (row: Release) => (
      <div className="flex flex-row gap-2 shrink-0 w-full no-parent-trigger">
        {isAdmin && (
          <UploadOptionsMenu
            onSubmitBmv={() => {
              onSubmitBmv(row)
            }}
            onSubmitMesamMsg={() => {
              onSubmitMesamMsg(row)
            }}
            onSubmitEditorialPitching={() => {
              onSubmitEditorialPitching(row)
            }}
            onSubmitState51={() => {
              onSubmitState51(row)
            }}
          />
        )}
      </div>
    )
  }

  const deleteActionColumn: TableColumn = {
    header: "",
    renderOnlyWhenHovered: true,
    headerStyle: {
      textAlign: "center",
      width: "max-content",
      minWidth: "60px"
    },
    type: TABLE_COLUMN_TYPE.FUNCTION,
    render: (row: Release) => (
      <div className="flex flex-row gap-2 shrink-0 w-full no-parent-trigger">
        {isAdmin && (
          <KButton
            icon={TrashIcon}
            padding="8px"
            height="36px"
            width="36px"
            background="#FF5865"
            hoverBackground="#A33841"
            activeBackground="#FF5865"
            onClick={() => {
              setSelectedActionParams({
                type: "releaseDelete",
                id: row.id,
                status: undefined,
                title: row.title,
                checked: false
              })
            }}
          />
        )}
      </div>
    )
  }

  const columns = [
    releaseNameColumn,
    releaseDateColumn,
    genreColumn,
    labelColumn,
    upcColumn,
    bmvDriveId,
    publishingDriveId,
    editorialPitchingDriveId,
    state51Column,
    statusColumn,
    showColumn
  ]
  if (isAdmin) {
    columns.push(uploadColumn)
    columns.push(deleteActionColumn)
  }
  return columns
}

export default adminCatalogColumns
