import { KButton, KInput, KTitleSpan } from "kahuna-base-react-components"
import React, { FC, useEffect, useRef, useState } from "react"
import { releases } from "../../../../apis"
import { toast } from "react-toastify"
import { errorFilledToastOptions, successFilledToastOptions } from "../../../../constants"
import { mapListToCamelCase, mapToCamelCase } from "../../../../utility"
import AddItemCard from "../../../ui/AddItemCard"
import { lang } from "../../../../constants/languages"
import CardNew from "../../../ui/CardNew"
import NotFound from "../../../NotFound"
import ConfirmationDialog from "../../../ui/modals/ConfirmationDialog"
import AddDSPModal from "./AddDSPModal"

type DspType = {
  id: number
  label: string
  image: string
}

const AdminTerritoryDspTable = () => {
  const [openAddItemModal, setOpenAddItemModal] = useState<boolean>(false)

  const scrollRef = useRef<HTMLDivElement>(null)
  const [timeoutId, setTimeoutId] = useState(undefined)
  const [loading, setLoading] = useState(false)
  const [idToDelete, setIdToDelete] = useState<number | undefined>(undefined)
  const [count, setCount] = useState<number>(1)
  const [limit, setLimit] = useState(10)
  const [label, setLabel] = useState<string>("")
  const [imageUrl, setImageUrl] = useState<string>("")
  const [searchText, setSearchText] = useState<string>("")
  const [pageNumber, setPageNumber] = useState<number>(1)
  const [dsp, setDsp] = useState<DspType[]>([])

  const scrollToBottom = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollIntoView({ behavior: "smooth" })
    }
  }

  const onDelete = () => {
    if (!idToDelete) return

    setLoading(true)

    releases(`/release/asset_registry_dsp_delete/?id=${idToDelete}`, "delete")
      .then((resp) => {
        if (resp.data.success) {
          setDsp(dsp.filter((row) => row.id !== idToDelete))
          toast.success(`ID: ${idToDelete} is successfully deleted.`, successFilledToastOptions)
          setCount(count - 1)
        } else {
          toast.error(`Error: ${resp.data.message}`, errorFilledToastOptions)
        }
        setLoading(false)
        setIdToDelete(undefined)
      })
      .catch(() => {
        setLoading(false)
        toast.error(`Only super admins have permission for this action.`, errorFilledToastOptions)
      })
  }

  const handleClose = () => {
    setOpenAddItemModal(false)
    setLabel("")
    setImageUrl("")
  }

  const onAddNew = () => {
    setLoading(true)

    if (!label || !imageUrl) {
      setLoading(false)
      return
    }

    releases(`/release/asset_registry_dsp_add/`, "post", { dsp: { label, image: imageUrl } })
      .then((resp) => {
        if (resp.data.result) {
          setDsp([mapToCamelCase(resp.data.result), ...dsp])
          setLoading(false)
          handleClose()
          toast.success(`DSP has been successfully added.`, successFilledToastOptions)
          setCount(count + 1)
        } else {
          setLoading(false)
          setOpenAddItemModal(false)
          toast.error(`Error on DSP adding.`, errorFilledToastOptions)
        }
      })
      .catch(() => {
        setLoading(false)
        toast.error(`Error on DSP adding.`, errorFilledToastOptions)
      })
  }

  const fetchData = (pageNumber: number, search: string) => {
    setLoading(true)
    const searchContent = search !== "" ? `&search=${search}` : ""
    releases(
      `/release/asset_registry_dsp_list/?offset=${(pageNumber - 1) * limit}&limit=${limit}${searchContent}`,
      "get"
    ).then((resp) => {
      if (pageNumber === 1) {
        setDsp(mapListToCamelCase(resp.data.results))
        setCount(resp.data.count)
      } else {
        setDsp([...dsp, ...mapListToCamelCase(resp.data.results)])
      }
      setLoading(false)
    })
  }

  const onSearch = (pageNumber: number, search: string) => {
    if (timeoutId) {
      clearTimeout(timeoutId)
    }
    setTimeoutId(
      //@ts-ignore
      setTimeout(async () => {
        fetchData(pageNumber, search)
      }, 1000)
    )
  }

  useEffect(() => {
    fetchData(1, "")
  }, [])

  useEffect(() => {
    const timeOut = setTimeout(() => {
      scrollToBottom()
    }, 100)
    return () => {
      clearTimeout(timeOut)
    }
  }, [dsp])

  return (
    <div className="w-full">
      <div className="flex flex-row justify-between items-center py-4 px-6">
        <div className="flex flex-row items-center">
          <div className="w-8 h-8 p-1.5 flex items-center justify-center shrink-0">
            <img src={"/navigation_icons/earth.svg"} alt="card-table-icon" />
          </div>
          <div className="flex flex-row items-center pt-0.5 whitespace-nowrap">
            <KTitleSpan
              text={`Territory Dsp List (${count})`}
              fontSize={20}
              color="#111"
              lineHeight={"28px"}
              fontWeight={500}
            />
          </div>
        </div>
        <div className="flex gap-3 items-center grow justify-end pl-5">
          <div className="w-[90%] max-w-[300px]">
            <KInput
              onChange={(text: string) => {
                setSearchText(text)
                setPageNumber(1)
                onSearch(1, text)
              }}
              value={searchText}
              placeholder={lang.reports.search_text_placeholder}
              padding="14px"
              leftIcon="/catalog_icons/search-grey.svg"
              hoverBackground="white"
              background="#F5F5F5"
            />
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-6">
        <div className="flex flex-row flex-wrap gap-6 p-6">
          {dsp.length > 0 ? (
            <React.Fragment>
              <AddItemCard
                title={`New DSP`}
                onClick={() => {
                  setOpenAddItemModal(true)
                }}
                height="184px"
              />
              {dsp.map((data) => {
                return (
                  <CardNew
                    key={data.id}
                    id={data.id}
                    text={`ID: ${data.id}`}
                    titleText={`${data.label}`}
                    inUse={false}
                    imageUrl={`${data.image}`}
                    onDeleteButtonClick={() => {
                      setIdToDelete(data.id)
                    }}
                  />
                )
              })}
            </React.Fragment>
          ) : (
            !loading && (
              <div className="flex w-full h-full items-center justify-center pt-[150px]">
                <NotFound
                  title={`No DSP`}
                  description={`No DSP containing the searched text was found.`}
                  imageWidth={250}
                />
              </div>
            )
          )}
        </div>
        {dsp.length < count && dsp.length !== 0 && (
          <div className="flex justify-center">
            <KButton
              text="Load More"
              width="auto"
              disabled={loading}
              background="#FFF"
              hoverBackground="#F7F7F7"
              onClick={() => {
                setPageNumber(pageNumber + 1)
                fetchData(pageNumber + 1, searchText)
              }}
            />
          </div>
        )}
        <div ref={scrollRef} className="flex h-1 w-full" />
      </div>
      <ConfirmationDialog
        openConfirmation={idToDelete !== undefined}
        setOpenConfirmation={() => setIdToDelete(undefined)}
        loading={loading}
        handleConfirm={onDelete}
        overrideDescription={`This action will delete ID: ${idToDelete}`}
      />
      <AddDSPModal
        label={label}
        setLabel={setLabel}
        imageUrl={imageUrl}
        setImageUrl={setImageUrl}
        open={openAddItemModal}
        onClose={handleClose}
        onConfirm={onAddNew}
        onCancel={handleClose}
        width={550}
      />
    </div>
  )
}
export default AdminTerritoryDspTable
