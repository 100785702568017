import { toast } from "react-toastify"
import { errorFilledToastOptions } from "../../../../../../constants"
import { UserExpense } from "../../../../../../types"

const adminUserExpenseValidator = (formValues: UserExpense) => {
  const errors: any = {}

  if (formValues.distributionFee === undefined || formValues.distributionFee > 100 || formValues.distributionFee < 0) {
    errors.distributionFee = "You must enter a valid distribution fee"
    toast.error("You must enter a valid distribution fee", errorFilledToastOptions)
  }
  if (formValues.adminFee === undefined || formValues.adminFee > 100 || formValues.adminFee < 0) {
    errors.adminFee = "You must enter a valid admin fee"
    toast.error("You must enter a valid admin fee", errorFilledToastOptions)
  }

  if (!formValues.paymentPeriod) {
    errors.paymentPeriod = "You must enter a payment period"
    toast.error("You must enter a payment period", errorFilledToastOptions)
  }

  if (!formValues.preferredCurrency) {
    errors.preferredCurrency = "You must enter a preferred currency"
    toast.error("You must enter a preferred currency", errorFilledToastOptions)
  }

  if (!formValues.taxType) {
    errors.taxType = "You must enter a tax type"
    toast.error("You must enter a tax type", errorFilledToastOptions)
  }

  return errors
}

export default adminUserExpenseValidator
