import React from "react"
//@ts-ignore
import MusicCircleIcon from "../../../assets/admin_icons/music-circle.svg"

const ImageWithFallback = React.memo(({ coverFileUrl }: { coverFileUrl: string }) => {
  
  const handleError = (e: React.SyntheticEvent<HTMLImageElement, Event>) => {
    e.currentTarget.src = MusicCircleIcon
    e.currentTarget.alt = "default-image"
  }

  return (
    <img
      src={coverFileUrl || "-"}
      alt="cover-file"
      className="min-w-10 min-h-10 max-h-10 max-w-10 rounded-[8px] cursor-pointer no-parent-trigger"
      width={40}
      height={40}
      onError={handleError}
      onClick={() => window.open(coverFileUrl, "_blank")}
    />
  )
})

export default ImageWithFallback
