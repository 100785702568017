import { Scheduler, SchedulerReport, TableColumn } from "../../../../types"
import { TABLE_COLUMN_TYPE } from "../../../../constants"
import { CheckCircle, DotsCircleHorizontal, MinusCircle, XCircle } from "heroicons-react"
import Tooltip from "../../../ui/Tooltip"
import React from "react"
import { KSpan, KTitleSpan } from "kahuna-base-react-components"
//@ts-ignore
import SuccessIcon from "../../../../assets/admin_icons/check-circle.svg"
//@ts-ignore
import PartialSuccessIcon from "../../../../assets/admin_icons/minus-circle.svg"
//@ts-ignore
import FailedIcon from "../../../../assets/admin_icons/close-circle.svg"
//@ts-ignore
import InProgressIcon from "../../../../assets/admin_icons/disable-circle.svg"
//@ts-ignore
import CalendarIcon from "../../../../assets/admin_icons/calendar.svg"
import { formatUTCDate } from "../../../../utility"

const renderSchedulerReportLogs = (reportList: SchedulerReport[]) => {
  const reverseOrderList: SchedulerReport[] = []
  for (let i = reportList.length - 1; i >= 0; i--) {
    reverseOrderList.push(reportList[i])
  }
  return (
    <div className={"flex"}>
      {reverseOrderList.map((report, index:number) => {
        let imgSrc = SuccessIcon
        if (report.status === "partial_success") {
          imgSrc = PartialSuccessIcon
        }
        if (report.status === "failed") {
          imgSrc = FailedIcon
        }
        if (report.status === "in_progress") {
          imgSrc = InProgressIcon
        }

        return (
          <div className="relative" key={`${report.status}-${index}`} style={{ direction: "rtl", textAlign: "left" }}>
            <Tooltip
              key={report.id}
              width={300}
              backgroundColor="#111"
              content={
                <div className={"grid p-4"}>
                  <KSpan text={`ID: ${report.id}`} fontSize={12} lineHeight="16px" color="#FFF" />
                  <span className={"mt-2"}>
                    <KSpan text={`UserId: ${report.user_id || "-"}`} fontSize={12} lineHeight="16px" color="#FFF" />
                  </span>
                  <span className={"mt-2"}>
                    <KSpan text={`Date: ${formatUTCDate(report.date)}`} fontSize={12} lineHeight="16px" color="#FFF" />
                  </span>
                  <KSpan text={`Status: ${report.status}`} fontSize={12} lineHeight="16px" color="#FFF" />
                  <span className={"mt-3"}>
                    <KSpan text={`Read Count: ${report._read}`} fontSize={12} lineHeight="16px" color="#FFF" />
                  </span>
                  <KSpan text={`Insert Count: ${report._insert}`} fontSize={12} lineHeight="16px" color="#FFF" />
                  <KSpan text={`Update Count: ${report._update}`} fontSize={12} lineHeight="16px" color="#FFF" />
                  <span className={"mt-3"}>
                    <KSpan text={`Error Count: ${report._error}`} fontSize={12} lineHeight="16px" color="#FFF" />
                  </span>
                  <KSpan text={`Warning Count: ${report._warning}`} fontSize={12} lineHeight="16px" color="#FFF" />
                </div>
              }
            >
              <img
                className={"mr-3"}
                src={imgSrc}
                style={{ minWidth: 24, maxWidth: 24, minHeight: 24, maxHeight: 24 }}
              />
            </Tooltip>
          </div>
        )
      })}
    </div>
  )
}

const schedulerColumns: TableColumn[] = [
  {
    header: "Name",
    headerStyle: {
      textAlign: "left",
      width: "26%"
    },
    cellStyle: {
      maxWidth: "400px"
    },
    type: TABLE_COLUMN_TYPE.FUNCTION,
    render: (row: Scheduler) => (
      <div className="flex flex-col w-full items-start gap-0.5 ">
        <div className="w-full whitespace-nowrap text-ellipsis overflow-hidden">
          <KSpan text={`${row.name}`} color="#000" fontWeight={500} ellipsis={true} />
        </div>
        <div className="rounded-[4px] bg-[#F7F7F7] p-1 flex justify-center">
          <KSpan text={row.type} color="#111" fontSize={11} fontWeight={500} lineHeight="12px" letterSpacing="0.22px" />
        </div>
      </div>
    )
  },
  {
    header: "Enabled",
    headerStyle: {
      textAlign: "left",
      width: "14%"
    },
    type: TABLE_COLUMN_TYPE.FUNCTION,
    render: (row: Scheduler) => {
      return (
        <div className="flex flex-row gap-1">
          <img
            src={row.enabled ? "/reports_icons/check-green.svg" : "/reports_icons/check.svg"}
            width={16}
            height={16}
          />
          <KSpan text={row.enabled ? "enabled" : "inactive"} color="#111" fontWeight={500} />
        </div>
      )
    }
  },
  {
    header: "Previous Run",
    headerStyle: {
      textAlign: "left",
      width: "20%"
    },
    type: TABLE_COLUMN_TYPE.FUNCTION,
    render: (scheduler: Scheduler) => {
      return (
        <div className="flex flex-row gap-1">
          <img src={CalendarIcon} alt="calendar-icon" width={16} height={16} className="min-w-4 min-h-4" />
          <KSpan text={formatUTCDate(scheduler.previous_run)} color="#111" fontWeight={500} />
        </div>
      )
    }
  },
  {
    header: "Next Run",
    headerStyle: {
      textAlign: "left",
      width: "20%"
    },
    type: TABLE_COLUMN_TYPE.FUNCTION,
    render: (scheduler: Scheduler) => {
      return (
        <div className="flex flex-row gap-1">
          <img src={CalendarIcon} alt="calendar-icon" width={16} height={16} className="min-w-4 min-h-4" />
          <KSpan text={formatUTCDate(scheduler.next_run)} color="#111" fontWeight={500} />
        </div>
      )
    }
  },
  {
    header: "Results",
    headerStyle: {
      textAlign: "left",
      width: "20%"
    },
    type: TABLE_COLUMN_TYPE.FUNCTION,
    render: (scheduler: Scheduler) => {
      return renderSchedulerReportLogs(scheduler.report_list)
    }
  }
]

export default schedulerColumns
