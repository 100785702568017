import { connect } from "react-redux"
import { RootState } from "../../../../store"
import React, { FC, useState } from "react"
import { selectTrackShareData, selectUser } from "../../../../actions/adminActions"
import { User, UserTrackShareData } from "../../../../types"
import { KButton, KSpan, KTitleSpan } from "kahuna-base-react-components"
import { USER_PERMISSION, USER_ROLE } from "../../../../constants"
import { UserTypeIcon } from "../../../ui/icons"
import AdminUserMenu from "../../admin/user/menu/AdminUserMenu"
import ToggleInput from "../../../ui/components/ToggleInput.tsx"
import AdminUserCreateModal from "../user/modals/AdminUserCreateModal.tsx"
import { AdminUserAssetTabOptions } from "../AdminUserAsset"
import { iconColor } from "../../../../utility/index.ts"

export type UserDetailCardProps = {
  selectedUser: User
  selectUser: (user: User) => void
  loggedInUser: User
  setModalOption: (option: number | undefined) => void
  setTab: (tab: AdminUserAssetTabOptions) => void
  selectTrackShareData: (trackShareData: UserTrackShareData | null) => void
}

const UserDetailCard: FC<UserDetailCardProps> = (props) => {
  const { selectedUser, loggedInUser, setTab, selectTrackShareData } = props
  const roleIconColor = iconColor(selectedUser?.role?.id || 0)
  const isSuperAdmin = loggedInUser?.roleId === USER_ROLE.SuperAdmin

  const [showPermissions, setShowPermissions] = useState<boolean>(true)

  const [openEditUserModal, setOpenEditUserModal] = useState<boolean>(false)

  const checkPermission = (permissionId: number) => {
    return selectedUser?.permissionUser?.map((pu) => pu.permission.id)?.includes(permissionId)
  }

  
  return (
    <div className="flex w-[296px] p-6">
      <div className="flex w-full flex-col gap-6">
        <div className="flex">
          <KButton
            leftIcon="/navigation_icons/arrow-left-line.svg"
            padding="6px"
            height="32px"
            width="32px"
            background="#F7F7F7"
            hoverBackground="#F3F3F3"
            onClick={() => {
                setTab(AdminUserAssetTabOptions.USER)
                selectTrackShareData(null)
            }}
            gap="4px"
          />
        </div>
        <div className="flex w-full h-[162x] flex-col gap-4">
          <div className="flex items-start w-full h-[94px] relative">
            <div className="w-full h-[74px] bg-[#F7F7F7] rounded-[10px] relative">
              {isSuperAdmin && (
                <div className="absolute right-0">
                  <AdminUserMenu user={selectedUser} setModal={props.setModalOption} userDetailPage={true} />
                </div>
              )}
            </div>
            <div className="flex items-center justify-center p-0.5 rounded-full bg-[#FFF]">
              <img
                className="absolute rounded-full left-4 bottom-0 w-10 h-10 min-w-10 min-h-10"
                src={selectedUser?.photo || "/reports_icons/avatar.svg"}
              />
            </div>
          </div>
          <div className="flex flex-col gap-1 px-4">
            <KTitleSpan
              text={`${selectedUser?.firstName} ${selectedUser?.lastName}`}
              color="#000"
              fontSize={20}
              lineHeight="28px"
            />
            <KSpan text={`${selectedUser?.username}`} color="#000" />
          </div>
        </div>
        <div className="flex flex-row gap-1.5 items-center">
          <img className="w-5 h-5 min-w-5 min-h-5" src="/admin_icons/music-album.svg" />
          <KSpan text={`${selectedUser?.userCredit?.takedown || 0} takedown credit`} color="#000" />
        </div>
        <div className="flex flex-row gap-1.5 items-center">
          <img className="w-5 h-5 min-w-5 min-h-5" src="/admin_icons/music-library.svg" />
          <KSpan text={`${selectedUser?.userCredit?.urgentNewRelease || 0} urgent new release credit`} color="#000" />
        </div>
        <div className="flex flex-row gap-1 items-center">
          <div className="w-5 h-5 min-h-5 min-w-5 flex items-center">{UserTypeIcon(roleIconColor)}</div>
          <KSpan text={selectedUser?.role?.name || "-"} color="#111" />
        </div>
        <div className="flex flex-row gap-1.5 items-center">
          <img className="w-5 h-5 min-w-5 min-h-5" src="/admin_icons/user-gray.svg" />
          <KSpan text={`Labelcamp Username: ${selectedUser?.lcUsername || "-"}`} color="#000" />
        </div>
        <div className="w-full flex flex-col">
          <div
            className="w-full flex flex-row justify-between items-center cursor-pointer"
            onClick={() => setShowPermissions(!showPermissions)}
          >
            <KTitleSpan text="Permissions" fontSize={18} lineHeight="24px" color="#000" />
            <img
              className="min-w-5 min-h-5"
              src={showPermissions ? "/admin_icons/caret-up.svg" : "/admin_icons/caret-down.svg"}
            />
          </div>

          <div
            className="flex flex-col justify-between mt-2"
            style={{
              height: showPermissions ? "220px" : "0px",
              overflow: "hidden",
              transition: "height 0.5s ease"
            }}
          >
            <div className="flex flex-row gap-1.5 items-center pointer-events-none">
              <ToggleInput
                checked={selectedUser?.validateEmail}
                handleChange={() => {
                  // do nothing
                }}
                height={16}
                width={24}
              />
              <KSpan text={"Email validation on authentication"} color="#000" fontSize={13}/>
            </div>
            <div className="flex flex-row gap-1.5 items-center pointer-events-none">
              <ToggleInput
                checked={checkPermission(USER_PERMISSION.GET_ADVANCE)}
                handleChange={() => {
                  // do nothing
                }}
                height={16}
                width={24}
              />
              <KSpan text={"Get advance"} color="#000" fontSize={13} />
            </div>
            <div className="flex flex-row gap-1.5 items-center pointer-events-none">
              <ToggleInput
                checked={checkPermission(USER_PERMISSION.NEW_RELEASE)}
                handleChange={() => {
                  // do nothing
                }}
                height={16}
                width={24}
              />
              <KSpan text={"New Release"} color="#000" fontSize={13} />
            </div>
            <div className="flex flex-row gap-1.5 items-center pointer-events-none">
              <ToggleInput
                checked={checkPermission(USER_PERMISSION.UPLOAD_MSG)}
                handleChange={() => {
                  // do nothing
                }}
                height={16}
                width={24}
              />
              <KSpan text={"Upload MSG"} color="#000" fontSize={13} />
            </div>
            <div className="flex flex-row gap-1.5 items-center pointer-events-none">
              <ToggleInput
                checked={checkPermission(USER_PERMISSION.UPLOAD_MESAM)}
                handleChange={() => {
                  // do nothing
                }}
                height={16}
                width={24}
              />
              <KSpan text={"Upload MESAM"} color="#000" fontSize={13} />
            </div>
            <div className="flex flex-row gap-1.5 items-center pointer-events-none">
              <ToggleInput
                checked={checkPermission(USER_PERMISSION.UPLOAD_BMV)}
                handleChange={() => {
                  // do nothing
                }}
                height={16}
                width={24}
              />
              <KSpan text={"Upload BMV"} color="#000" fontSize={13} />
            </div>
            {checkPermission(USER_PERMISSION.UPLOAD_BMV) && (
              <div className="flex flex-row gap-1.5 items-center pointer-events-none">
                <img className="w-5 h-5 min-w-5 min-h-5" src="/analytics_icons/album-record.svg" />
                <KSpan text={`BMV Custom Id: ${selectedUser?.bmwCustomId || "-"}`} color="#000" fontSize={13} />
              </div>
            )}
            <div className="flex flex-row gap-1.5 items-center pointer-events-none">
              <ToggleInput
                checked={checkPermission(USER_PERMISSION.UPLOAD_STATE51)}
                handleChange={() => {
                  // do nothing
                }}
                height={16}
                width={24}
              />
              <KSpan text={"Upload State51"} color="#000" fontSize={13} />
            </div>
          </div>
        </div>
        <div className="flex">
          <KButton icon="/admin_icons/edit.svg" width="120px" height="48px" background="#FFF"
          border="1px solid #F3F3F3"    onClick={() => {
            setOpenEditUserModal(true)
          }} />
        </div>
      </div>
      <AdminUserCreateModal
        open={ selectedUser !== null && openEditUserModal}
        onClose={() => {
          setOpenEditUserModal(false)
        }}
        isEdit={true}
      />
    </div>
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    selectedUser: state.admin.selectedUser,
    loggedInUser: state.auth.user
  }
}

export default connect(mapStateToProps, {
  selectUser,
  selectTrackShareData
})(UserDetailCard)
