import { Dialog } from "@headlessui/react"
import { KSpan, KButton, KDropdown } from "kahuna-base-react-components"
import React, { useEffect, useState } from "react"
import { DragDropContext, Droppable, Draggable, DraggableProvidedDragHandleProps } from "react-beautiful-dnd"
import { adminCatalogTableColumns } from "../../../../../constants"
//@ts-ignore
import DraggableIcon from "../../../../../assets/admin_icons/drag-new.svg"
//@ts-ignore
import EyeIconGray from "../../../../../assets/admin_icons/eye-gray.svg"
//@ts-ignore
import EyeClosedIcon from "../../../../../assets/admin_icons/eye-closed-gray.svg"
import { AdminCatalogTableColumnType, SortParams, TableColumn } from "../../../../../types"
import { KSelectOption } from "kahuna-base-react-components/dist/components/KDropdown/KDropdown"
//@ts-ignore
import SortDescIcon from "../../../../../assets/admin_icons/sort-desc.svg"
//@ts-ignore
import SortAscIcon from "../../../../../assets/admin_icons/sort-asc.svg"
//@ts-ignore
import SortIcon from "../../../../../assets/admin_icons/sort.svg"

const CustomizeModal = ({
  open,
  setOpen,
  onApprove,
  columnsState,
  setColumnsState,
  sortParams,
  setSortParams
}: {
  open: boolean
  setOpen: (open: boolean) => void
  onApprove: () => void
  columnsState: AdminCatalogTableColumnType[]
  setColumnsState: (columns: AdminCatalogTableColumnType[]) => void
  sortParams: SortParams,
  setSortParams: (params: SortParams) => void
}) => {
  const [columns, setColumns] = useState([...columnsState])

  const onDragEnd = async (result) => {
    if (!result.destination) return
    const reorderedColumns = Array.from(columnsState)
    const [movedColumn] = reorderedColumns.splice(result.source.index, 1)
    reorderedColumns.splice(result.destination.index, 0, movedColumn)

    let columnNumber = 1
    for (const column of reorderedColumns) {
      columnNumber++
    }
    setColumnsState(reorderedColumns)
  }

  const sortOptions: KSelectOption[] = [
      { label: "Release name", value: 0, value2: "title" },
      { label: "Release date ", value: 1, value2: "release_date"},
      { label: "Status", value: 2, value2: "status"},
      { label: "UPC", value: 3, value2: "upc" },
      { label: "Song count", value: 4, value2: "total_track" },
      { label: "Genre", value: 5, value2: "genre" },
      { label: "Label", value: 6, value2: "label__name" }
  ]

  const [selectedSortField, setSelectedSortField] = useState<KSelectOption | undefined>(sortOptions.find((s) => s?.value2 === sortParams.field))
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc' | undefined>(sortParams.direction)
  
  useEffect(() => {
    setSelectedSortField(sortOptions.find((s) => s?.value2 === sortParams.field))
    setSortDirection(sortParams.direction)
  }, [sortParams])

  const handleApprove = () => {
    localStorage.setItem("adminCatalogTableColumns", JSON.stringify(columnsState))
    onApprove()
    setColumns([...columnsState])
    if (sortParams?.direction === undefined && sortDirection === undefined) {
      return
    }
    setSortParams({ field: selectedSortField?.value2 || "", direction: sortDirection })
  }

  const renderColumns = (  
    column: AdminCatalogTableColumnType,
    index: number,
    dragHandleProps: DraggableProvidedDragHandleProps
  ) => {
    return (
      <div key={`${index}`} className="flex flex-row gap-2 items-center rounded-[10px] w-full py-2.5 px-2">
        <img
          src={DraggableIcon}
          style={{
            height: "20px",
            width: "20px",
            background: "#FFF",
            rounded: "10px"
          }}
          className="hover:bg-[#F0F0F0] shrink-0"
          {...dragHandleProps}
        />
        <div className="flex w-full">
          <KSpan text={column.name} color="#000" />
        </div>
        <img
          src={column.visible ? EyeIconGray : EyeClosedIcon}
          style={{
            height: "20px",
            width: "20px",
            background: "#FFF",
            borderRadius: "10px"
          }}
          className="hover:bg-[#F0F0F0] shrink-0 !cursor-pointer"
          onClick={() => {
            const updatedColumns = [...columnsState].map((col) => {
              if (column.name === col.name) {
                return { ...col, visible: !col.visible }
              } else {
                return { ...col }
              }
            })
            setColumnsState(updatedColumns)
          }}
        />
      </div>
    )
  }

  return (
    <Dialog
      open={open}
      onClose={() => {
        setOpen(false)
        setColumnsState([...columns])
      }}
    >
      <div className="fixed w-screen h-screen top-0 left-0 z-250 flex items-center justify-center bg-[#0000004d] ">
        <div className="rounded-[10px] bg-[#fff] flex flex-col p-1" style={{ maxHeight: "90vh", overflowY: "scroll" }}>
          <div className="flex flex-col gap-3 p-3">
            <div className="px-2">
              <KSpan
                text="Customize"
                fontSize={16}
                lineHeight="24px"
                fontWeight={500}
                letterSpacing="-0.176px"
                color="#000"
              />
            </div>
            <div>
              <KButton
                text="Default"
                padding="8px 12px"
                height="36px"
                width="auto"
                background="#F7F7F7"
                hoverBackground="#F3F3F3"
                onClick={() => {
                  setColumnsState([...adminCatalogTableColumns])
                  setSortDirection(undefined)
                }}
              />
            </div>
            <div className="flex flex-row py-2.5 px-2 gap-2 items-center cursor-pointer">
              <div onClick={() => {
                if (sortDirection === "asc") {
                  setSortDirection("desc")
                } else if (sortDirection === "desc") {
                  setSortDirection(undefined)
                } else {
                  setSortDirection("asc")
                }
              }}><img src={sortDirection === "asc" ? SortAscIcon : !sortDirection ? SortIcon : SortDescIcon} /></div>
              <div className="grow">
                <KSpan text="Sort by" color="#000" />
              </div>
              <KDropdown
                key={`${sortParams.field}-${sortParams.direction}`}
                options={sortOptions}
                rightIcon="/admin_icons/caret-down.svg"
                enableIndicator
                width={140}
                defaultValuePrimitive={selectedSortField?.value2 || "title"}
                onSelect={(selected) => {
                  const selectedOption = selected as KSelectOption
                  if (!(selectedOption?.value2)) return 
                  setSelectedSortField(selectedOption)
                }}
                background="transparent"
                shadowDisabled={true}
                textColor="#666"
              />
            </div>
          </div>
          <div className="flex flex-col gap-3 w-[414px] p-3 pt-4">
            <div className="flex flex-row grow justify-between px-2 items-center">
              <KSpan
                text="Views"
                color="#000"
                fontSize={16}
                lineHeight="24px"
                fontWeight={500}
                letterSpacing="-0.176px"
              />
              <KButton
                text="Show All"
                fontWeight={500}
                textDecoration="underline"
                padding="8px"
                width="auto"
                background="transparent"
                shadowDisabled
                onClick={() => {
                  const updatedColumns = [...columnsState].map((column) => {
                    return { ...column, visible: true }
                  })
                  setColumnsState(updatedColumns)
                }}
              />
            </div>
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="tracksList">
                {(provided) => (
                  <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    className={`flex flex-col gap-1 max-h-[45vh] overflow-auto`}
                  >
                    {columnsState.map((column: AdminCatalogTableColumnType, i) => (
                      <Draggable key={column.name} draggableId={column.name} index={i}>
                        {(provided) => (
                          <div ref={provided.innerRef} {...provided.draggableProps} className="flex flex-col">
                            {renderColumns(column, i, provided.dragHandleProps)}
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
            <div className="flex flex-row gap-2 mt-3 px-2">
              <KButton
                text="Cancel"
                onClick={() => {
                  setColumnsState([...columns])
                  setOpen(false)
                }}
                background="#F7F7F7"
                textColor="#000"
                padding="14px"
              />
              <KButton text="Approve" onClick={handleApprove} background="#000" textColor="#FFF" padding="14px" />
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  )
}

export default CustomizeModal
