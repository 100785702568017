import { Dialog } from "@headlessui/react"
import { KTitleSpan, KButton, KDropdown, KInput, KSpan, KTooltip } from "kahuna-base-react-components"
import React, { useEffect, useState } from "react"
import { lang } from "../../../../../constants/languages"
import { KSelectOption } from "kahuna-base-react-components/dist/components/KDropdown/KDropdown"
import { User, UserForm } from "../../../../../types"
import { connect } from "react-redux"
import { saveUser, selectUser } from "../../../../../actions/adminActions"
import { RootState } from "../../../../../store"
//@ts-ignore
import EyeIconGray from "../../../../../assets/admin_icons/eye-gray.svg"
//@ts-ignore
import EyeClosedIcon from "../../../../../assets/admin_icons/eye-closed-gray.svg"
//@ts-ignore
import CheckCircle from "../../../../../assets/admin_icons/check-circle.svg"
//@ts-ignore
import CloseCircle from "../../../../../assets/admin_icons/close-circle.svg"
import {
  errorFilledToastOptions,
  successFilledToastOptions,
  superAdminSelectOption,
  USER_PERMISSION,
  USER_ROLE,
  userRoleOptions
} from "../../../../../constants"
import ToggleInput from "../../../../ui/components/ToggleInput"
import { toast } from "react-toastify"
import adminUserValidator from "./validators/adminUserValidator"
import { users } from "../../../../../apis"

const AdminUserCreateModal = ({
  open,
  onClose,
  selectedUser,
  loggedInUser,
  saveUser,
  selectUser,
  isEdit
}: {
  open: boolean
  onClose: () => void
  selectedUser: User
  loggedInUser: User
  saveUser: (formValues: UserForm) => void
  selectUser: (user: User | null) => void
  isEdit?: boolean
}) => {

  const createUserFormFromUser = (selectedUser: User): UserForm => {
    return {
      id: selectedUser?.id,
      username: selectedUser?.username,
      firstName: selectedUser?.firstName,
      lastName: selectedUser?.lastName,
      password: "",
      roleId: selectedUser?.roleId,
      permissionIds: selectedUser?.permissionUser?.map((pu) => pu.permission.id),
      lcUsername: selectedUser?.lcUsername,
      lcPassword: "",
      sendEmail: false,
      bmwCustomId: selectedUser?.bmwCustomId,
      validateEmail: selectedUser?.validateEmail
    }
  }
  
  const [userInformations, setUserInformations] = useState<UserForm>(createUserFormFromUser(selectedUser))
  const [checkLabelCamp, setCheckLabelCamp] = useState<boolean | undefined>(undefined)
  useEffect(() => {
    setUserInformations(createUserFormFromUser(selectedUser))
  }, [selectedUser])

  const [showPassword, setShowPassword] = useState<{ userPassword: boolean; lcPassword: boolean }>({
    userPassword: false,
    lcPassword: false
  })

  let roleOptions
  if (loggedInUser?.roleId === USER_ROLE.SuperAdmin) roleOptions = userRoleOptions
  if (loggedInUser?.roleId !== USER_ROLE.SuperAdmin && selectedUser?.roleId !== USER_ROLE.SuperAdmin) {
    roleOptions = userRoleOptions.filter((option) => option.value !== USER_ROLE.SuperAdmin)
  }
  if (loggedInUser?.roleId !== USER_ROLE.SuperAdmin && selectedUser?.roleId === USER_ROLE.SuperAdmin) {
    roleOptions = superAdminSelectOption
  }

  const handleInput = (field: keyof UserForm, value: string | number | boolean) => {
    const currentState = { ...userInformations }
    setUserInformations({ ...currentState, [field]: value })
  }

  const handlePermissionChange = (permissionId: number) => {
    const currentPermissions = userInformations?.permissionIds || []
    const newPermissions = currentPermissions.includes(permissionId)
      ? currentPermissions.filter((id) => id !== permissionId)
      : [...currentPermissions, permissionId]
    setUserInformations({ ...userInformations, permissionIds: newPermissions })
  }

  const checkPermission = (permissionId: number) => {
    return userInformations?.permissionIds?.includes(permissionId)
  }

  const onUserFormSubmit = async (values: UserForm) => {
    if (
      values?.permissionIds &&
      values?.permissionIds.includes(USER_PERMISSION.UPLOAD_MESAM) &&
      values?.permissionIds.includes(USER_PERMISSION.UPLOAD_MSG)
    ) {
      toast.error("Choose one: Mesam / Msg", errorFilledToastOptions)
      return
    }
    saveUser(values)
  }

  const handleClose = () => {
    onClose()
    setCheckLabelCamp(undefined)
  }

  const checkLabelCampInformations = async (userId: number | undefined, lcUsername: string | undefined, lcPassword: string) => {

    let requestPayload: { user_id: number | undefined; lc_username: string | undefined; lc_password?: string }

    if (!lcPassword) {
      requestPayload = {
        user_id: userId,
        lc_username: lcUsername
      }
    } else {
      requestPayload = {
        user_id: userId,
        lc_username: lcUsername,
        lc_password: lcPassword
      }
    }

    try {
      const response = await users(
        `/check_label_camp_information/`,
        "post",
        requestPayload
      )
      if (!response.data.success) {
        setCheckLabelCamp(false)
        toast.error("There is no labelcamp account with these credentials", errorFilledToastOptions)
      } else {
        setCheckLabelCamp(true)
        toast.success("Valid account", successFilledToastOptions)
      }

    } catch {
      setCheckLabelCamp(false)
      toast.error("Error on checking labelcamp information", errorFilledToastOptions)
    }
  }

  return (
    <Dialog open={open} onClose={handleClose}>
      <div className="fixed w-screen h-screen top-0 left-0 z-250 flex items-center justify-center bg-[#0000004d] ">
        <div className="p-6 shadow-md w-[440px] rounded-[10px] bg-[#fff]">
          <div className="flex flex-col gap-6">
            <div className="flex flex-col gap-6 items-center justify-center">
              <span
                className="w-[72px] aspect-square rounded-full flex justify-center items-center"
                style={{
                  border: "1px solid #F3F3F3"
                }}
              >
                <img className="w-8 h-8" src={isEdit ? "/admin_icons/edit.svg" : "/admin_icons/user-gray.svg"} />
              </span>
              <KTitleSpan text={isEdit ? "User Edit" : "User Create"} fontSize={20} lineHeight="28px" color="#000" />
            </div>
            <div className="flex flex-col gap-6 py-2 px-0.5 max-h-[60vh] overflow-auto">
              <div className="flex flex-col gap-3">
                <div className="flex flex-col gap-2 ">
                  <div className="flex flex-row gap-2">
                    <div className="w-1/2">
                      <KInput
                        value={userInformations?.firstName || ""}
                        leftIcon="/admin_icons/user-gray.svg"
                        padding="14px 12px 14px 14px"
                        gap="8px"
                        placeholder="Full name"
                        onChange={(text: string) => {
                          handleInput("firstName", text)
                        }}
                      />
                    </div>
                    <div className="w-1/2">
                      <KInput
                        value={userInformations?.lastName || ""}
                        leftIcon="/admin_icons/user-gray.svg"
                        padding="14px 12px 14px 14px"
                        gap="8px"
                        placeholder="Last name"
                        onChange={(text: string) => {
                          handleInput("lastName", text)
                        }}
                      />
                    </div>
                  </div>
                  <KInput
                    value={userInformations?.username || ""}
                    leftIcon="/account_icons/mail.svg"
                    padding="14px 12px 14px 14px"
                    gap="8px"
                    placeholder="Username"
                    disabled={selectedUser?.id ? true : false}
                    onChange={(text: string) => {
                      handleInput("username", text)
                    }}
                  />
                  <KInput
                    type={showPassword.userPassword ? "text" : "password"}
                    value={userInformations?.password || ""}
                    leftIcon="/admin_icons/lock.svg"
                    padding="14px 12px 14px 14px"
                    gap="8px"
                    placeholder="Password"
                    rightIcon={showPassword.userPassword ? EyeIconGray : EyeClosedIcon}
                    rightIconClick={() => {
                      const currentState = showPassword.userPassword
                      setShowPassword({ ...showPassword, userPassword: !currentState })
                    }}
                    onChange={(text: string) => {
                      handleInput("password", text)
                    }}
                  />
                </div>
                <div className="flex flex-col gap-3">
                  <KTitleSpan text="Labelcamp" fontSize={20} lineHeight="28px" color="#000" />
                  <div className="flex flex-col gap-2">
                    <KInput
                      value={userInformations?.lcUsername || ""}
                      leftIcon="/admin_icons/user-gray.svg"
                      padding="14px 12px 14px 14px"
                      gap="8px"
                      placeholder="Username"
                      onChange={(text: string) => {
                        handleInput("lcUsername", text)
                      }}
                    />
                    <KInput
                      type={showPassword.lcPassword ? "text" : "password"}
                      value={userInformations?.lcPassword || ""}
                      leftIcon="/admin_icons/lock.svg"
                      padding="14px 12px 14px 14px"
                      gap="8px"
                      placeholder="Password"
                      rightIcon={showPassword.lcPassword ? EyeIconGray : EyeClosedIcon}
                      rightIconClick={() => {
                        const currentState = showPassword.lcPassword
                        setShowPassword({ ...showPassword, lcPassword: !currentState })
                      }}
                      onChange={(text: string) => {
                        handleInput("lcPassword", text)
                      }}
                    />
                    <div className="flex flex-row gap-2 items-center">
                      <KButton
                        text="Check information"
                        padding="8px"
                        width="146px"
                        height="36px"
                        background="#000"
                        textColor="#FFF"
                        hoverBackground="#111"
                        onClick={() => {
                          checkLabelCampInformations(
                            userInformations?.id,
                            userInformations?.lcUsername,
                            userInformations?.lcPassword
                          )
                        }}
                      />
                      {checkLabelCamp !== undefined && (
                        <img
                          src={checkLabelCamp === true ? CheckCircle : CloseCircle}
                          className="w-5 h-5 min-w-5 min-h-5"
                          alt="labelcamp-checked"
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex flex-col gap-3">
                <KTitleSpan text="Permissions" fontSize={20} lineHeight="28px" color="#000" />
                <div className="flex flex-col gap-2">
                  <KDropdown
                    options={roleOptions}
                    selected={roleOptions.find((roleOption) => roleOption?.value === userInformations?.roleId)}
                    leftIcon="/admin_icons/user-gray.svg"
                    rightIcon="/admin_icons/caret-down.svg"
                    padding="14px 12px 14px 14px"
                    placeholder="Select user type"
                    gap="8px"
                    enableIndicator
                    onSelect={(selected) => {
                      const roleId = (selected as KSelectOption)?.value
                      handleInput("roleId", roleId)
                    }}
                  />
                  <div className="flex flex-row gap-2 px-3.5 py-2 items-center bg-[#F7F7F7] rounded-[10px]">
                    <ToggleInput
                      checked={userInformations.validateEmail}
                      handleChange={() => {
                        const currentState: boolean = userInformations.validateEmail
                        handleInput("validateEmail", !currentState)
                      }}
                    />
                    <KSpan
                      text="Email validation on authentication"
                      color="#000"
                      fontWeight={userInformations.validateEmail ? 500 : 400}
                    />
                  </div>
                  <div className="flex flex-row gap-2 px-3.5 py-2 items-center bg-[#F7F7F7] rounded-[10px]">
                    <ToggleInput
                      checked={checkPermission(USER_PERMISSION.GET_ADVANCE)}
                      handleChange={() => {
                        handlePermissionChange(USER_PERMISSION.GET_ADVANCE)
                      }}
                    />
                    <KSpan
                      text="Get advance"
                      color="#000"
                      fontWeight={checkPermission(USER_PERMISSION.GET_ADVANCE) ? 500 : 400}
                    />
                  </div>
                  <div className="flex flex-col gap-0 bg-[#F7F7F7] rounded-[10px]">
                    <div className="flex flex-row gap-2 px-3.5 py-2 items-center">
                      <ToggleInput
                        checked={checkPermission(USER_PERMISSION.NEW_RELEASE)}
                        handleChange={() => {
                          handlePermissionChange(USER_PERMISSION.NEW_RELEASE)
                        }}
                      />
                      <KSpan
                        text="New Release"
                        color="#000"
                        fontWeight={checkPermission(USER_PERMISSION.NEW_RELEASE) ? 500 : 400}
                      />
                    </div>
                    <div className="flex flex-row gap-2 px-3.5 py-2 items-center">
                      <ToggleInput
                        checked={checkPermission(USER_PERMISSION.UPLOAD_MSG)}
                        handleChange={() => {
                          handlePermissionChange(USER_PERMISSION.UPLOAD_MSG)
                        }}
                        disabled={!checkPermission(USER_PERMISSION.NEW_RELEASE)}
                      />
                      <KSpan
                        text="Upload MSG"
                        color="#000"
                        fontWeight={checkPermission(USER_PERMISSION.UPLOAD_MSG) ? 500 : 400}
                      />
                    </div>
                    <div className="flex flex-row gap-2 px-3.5 py-2 items-center">
                      <ToggleInput
                        checked={checkPermission(USER_PERMISSION.UPLOAD_MESAM)}
                        handleChange={() => {
                          handlePermissionChange(USER_PERMISSION.UPLOAD_MESAM)
                        }}
                        disabled={!checkPermission(USER_PERMISSION.NEW_RELEASE)}
                      />
                      <KSpan
                        text="Upload MESAM"
                        color="#000"
                        fontWeight={checkPermission(USER_PERMISSION.UPLOAD_MESAM) ? 500 : 400}
                      />
                    </div>
                    <div className="flex flex-row gap-2 px-3.5 py-2 items-center">
                      <ToggleInput
                        checked={checkPermission(USER_PERMISSION.UPLOAD_BMV)}
                        handleChange={() => {
                          handlePermissionChange(USER_PERMISSION.UPLOAD_BMV)
                        }}
                        disabled={!checkPermission(USER_PERMISSION.NEW_RELEASE)}
                      />
                      <KSpan
                        text="Upload BMV"
                        color="#000"
                        fontWeight={checkPermission(USER_PERMISSION.UPLOAD_BMV) ? 500 : 400}
                      />
                    </div>
                    {checkPermission(USER_PERMISSION.UPLOAD_BMV) && (
                      <KTooltip
                        backgroundColor="#595959"
                        width="300px"
                        position="top"
                        align="center"
                        content={
                          <KSpan
                            color="#FFF"
                            text={"BMV Custom Id (Min: 2, Max: 3). Replace with X in example => X_TESTT_Nsax...."}
                          />
                        }
                      >
                        {" "}
                        <div className="w-full pl-3.5 pr-3">
                          <KInput
                            value={userInformations?.bmwCustomId || ""}
                            padding="14px 12px 14px 14px"
                            shadowDisabled
                            leftIcon="/analytics_icons/album-record.svg"
                            background="#F7F7F7"
                            placeholder="BMV Custom Id (Min: 2, Max: 3)"
                            onChange={(text: string) => {
                              handleInput("bmwCustomId", text)
                            }}
                            disabled={!checkPermission(USER_PERMISSION.NEW_RELEASE)}
                          />
                        </div>
                      </KTooltip>
                    )}
                    <div className="flex flex-row gap-2 px-3.5 py-2 items-center">
                      <ToggleInput
                        checked={checkPermission(USER_PERMISSION.UPLOAD_STATE51)}
                        handleChange={() => {
                          handlePermissionChange(USER_PERMISSION.UPLOAD_STATE51)
                        }}
                        disabled={!checkPermission(USER_PERMISSION.NEW_RELEASE)}
                      />
                      <KSpan
                        text="Upload State51"
                        color="#000"
                        fontWeight={checkPermission(USER_PERMISSION.UPLOAD_STATE51) ? 500 : 400}
                      />
                    </div>
                  </div>
                  {!selectedUser?.id && (
                    <div className="flex flex-row gap-2 px-3.5 py-2 items-center bg-[#F7F7F7] rounded-[10px]">
                      <ToggleInput
                        checked={userInformations?.sendEmail}
                        handleChange={() => {
                          const currentState: boolean = userInformations?.sendEmail
                          handleInput("sendEmail", !currentState)
                        }}
                      />
                      <KSpan
                        text="Send Credentials Via Email"
                        color="#000"
                        fontWeight={userInformations.sendEmail ? 500 : 400}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="flex gap-3 justify-center items-center">
              <KButton
                text={lang.common.cancel}
                onClick={() => {
                  handleClose()
                }}
                background="#fff"
                shadowDisabled={true}
              />
              <KButton
                text={lang.common.save}
                textColor="white"
                background="black"
                onClick={() => {
                  const errors = adminUserValidator(userInformations)
                  if (Object.keys(errors).length === 0) {
                    onUserFormSubmit(userInformations).then(() => {
                      handleClose()
                    })
                  }
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    selectedUser: state.admin.selectedUser,
    loggedInUser: state.auth.user
  }
}

export default connect(mapStateToProps, {
  selectUser,
  saveUser
})(AdminUserCreateModal)
