import { KSpan } from "kahuna-base-react-components"
import CalendarDate from "./CalendarDate"
import DownloadReportButton from "../../layouts/reports/DownloadReportButton"
import React from "react"
import { formatDate } from "../../../utility"

const ListComponent = ({ i, row, isAdmin, selectedUserId, approveDisabled }) => {
  const divHeight = 40

  const removeDuplicates = (data) => {
    if (!data) return []
    return data.filter((value, index) => data.indexOf(value) === index && value !== "")
  }

  const refactoredPaymentDays = removeDuplicates(row.payment_day)

  return (
    <li
      key={`data-row-${i}`}
      onClick={() => {
        // do nothing
      }}
    >
      <div className="table-row-hover hover:bg-[#F7F7F7] !border-none rounded-lg p-[12px] gap-[20px] h-[64px]">
        <div className={`h-[${divHeight}px] flex flex-row justify-between items-center `}>
          <div className="w-[25%] flex flex-row justify-start">
            <CalendarDate quarter={row.quarter} />
          </div>
          <div className="w-[20%] flex flex-row justify-start">
            <div className="flex justify-center p-1 w-auto rounded-[4px] bg-[#F7F7F7]">
              <KSpan
                text={`${row.revenue?.toFixed(2)} ${row.currency}`}
                color="#111"
                fontWeight={500}
                letterSpacing="0.22px"
                fontSize={11}
                lineHeight="12px"
              />
            </div>
          </div>
          <div className="w-[20%] flex flex-row justify-start">
            <div className="flex justify-center p-1 w-auto rounded-[4px] bg-[#F7F7F7]">
              <KSpan
                text={`${row.total_payment?.toFixed(2)} ${row.currency}`}
                color="#111"
                fontWeight={500}
                letterSpacing="0.22px"
                fontSize={11}
                lineHeight="12px"
              />
            </div>
          </div>
          <div className="w-[25%] flex flex-row gap-1 items-center justify-start">
            {row.payment_day && refactoredPaymentDays.length > 0 && (
              <div className="flex items-center justify-center">
                <img src="/mail-gray.svg" alt="mail-icon" />
              </div>
            )}
            <div className="flex flex-col justify-center items-start">
              {row.payment_day && refactoredPaymentDays.length > 0 ? (
                
                refactoredPaymentDays.map((payment, index) => {
                  return (
                    <KSpan
                      text={formatDate(payment)}
                      key={`payment-${index}`}
                      fontWeight={500}
                      color="#111"
                      letterSpacing="-0.084px"
                      fontSize={14}
                    />
                  )
                })
              ) : (
                <KSpan text="-" fontWeight={500} color="#111" letterSpacing="-0.084px" fontSize={14} />
              )}
            </div>
          </div>
          <div className={`w-[10%] flex flex-row justify-end`}>
            <DownloadReportButton
              reportId={`${parseInt(row.quarter)}${row.quarter.split(" ")[1].toLowerCase()}`}
              isAdmin={isAdmin}
              selectedUserId={selectedUserId}
              approveDisabled={approveDisabled}
            />
          </div>
        </div>
      </div>
    </li>
  )
}

export default ListComponent
