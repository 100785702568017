import * as React from "react"
import { connect } from "react-redux"
import { RootState } from "../../../../../store"
import { FC, useEffect, useState } from "react"
import { AdminTrackFormProps, MainOwnerShareData, Track, UserTrackShareForm } from "../../../../../types"
import { saveTrackShareForm, selectTrackShareData, selectUser } from "../../../../../actions/adminActions"
import { roleOptions, ROLES } from "../../../../../constants"
import adminTrackValidator from "./validators/adminTrackValidator"
import { handleAuthorizedRequest, releases } from "../../../../../apis"
import { uniqBy } from "lodash"
import { mapListToCamelCase } from "../../../../../utility"
import { KButton, KDropdown, KInput, KSpan, KTitleSpan, KTooltip } from "kahuna-base-react-components"
import { Dialog } from "@headlessui/react"
import { lang } from "../../../../../constants/languages"
import { KSelectOption } from "kahuna-base-react-components/dist/components/KDropdown/KDropdown"
import ToggleInput from "../../../../ui/components/ToggleInput"
import { MultiValue } from "react-select"

const AdminTrackForm: FC<AdminTrackFormProps> = (props) => {
  const { user } = props
  const [trackOptions, setTrackOptions] = useState<KSelectOption[]>([])
  const [trackLoading, setTrackLoading] = useState(false)
  const [searchedTracks, setSearchedTracks] = useState<Track[]>([])
  const [timeoutId, setTimeoutId] = useState(undefined)
  const [tooltipContent, setTooltipContent] = useState<string | undefined>(undefined)
  const [hasMainOwner, setHasMainOwner] = useState<boolean>(false)

  const getTrackById = (trackId: number | undefined) => {
    if (!trackId || !props.shareData) return null
    if (trackId === props.shareData.track) {
      return props.shareData.trackDetail
    }
    return searchedTracks.find((trk) => trk.id === trackId)
  }

  const onFormSubmit = async (values: UserTrackShareForm) => {
    props.creatingRef.current = true
    props.saveTrackShareForm({
      ...values,
      user: props.user?.id
    })
  }

  const createTrackShareFormFromData = (): UserTrackShareForm => {
    if (!props.shareData || !props.user) return new UserTrackShareForm()
    const mainOwner = props.shareData?.roleList?.some((role) => role?.role === ROLES.MAIN_OWNER)
    const share = props.shareData?.share
    const filteredRoleOptions: number[] = []
    const roleOptions = props.shareData?.roleList
    roleOptions?.forEach((option) => {
      if (option.role !== ROLES.MAIN_OWNER && option.role !== undefined) {
        filteredRoleOptions.push(option.role)
      }
    })

    return {
      id: props.shareData?.id,
      user: props.user?.id,
      track: props.shareData?.track,
      title: `${props.user?.username} - ${props.shareData?.trackDetail?.title}`,
      roles: filteredRoleOptions,
      mainOwner,
      share
    }
  }

  const [trackShareData, setTrackShareData] = useState<UserTrackShareForm>(createTrackShareFormFromData())

  useEffect(() => {
    const track = getTrackById(trackShareData.track)
    if (!track) {
      setTooltipContent(undefined)
    } else if (track) {
      const content = getTooltipContent(track?.mainOwnerShare)
      setTooltipContent(content)

      const mainOwnerAvailable = track.mainOwnerShare?.user !== "0"
      setHasMainOwner(mainOwnerAvailable)
      if (trackShareData?.mainOwner) {
        setTrackShareData({ ...trackShareData, share: track.mainOwnerShare.share?.toString() || "" })
      }
    }
  }, [trackShareData.track])

  useEffect(() => {
    setTrackShareData(createTrackShareFormFromData())
  }, [props.shareData, props.user])

  useEffect(() => {
    if (props.shareData?.trackDetail?.id) {
      setTrackOptions(
        uniqBy(
          [
            ...trackOptions,
            { value: props.shareData?.track || -1, label: getTrackSelectLabel(props.shareData?.trackDetail) }
          ],
          "value"
        )
      )
    }
  }, [props.shareData])

  const getTrackSelectLabel = (track: Track) => {
    return `${track.title}, ISRC:${track.isrc}${
      track.artistsList?.length > 0 ? `, ${track.artistsList?.map((artist) => artist.name).join(", ")}` : ""
    }`
  }

  const onSearchTextChanged = async (text: string) => {
    if (!text) return
    setTrackOptions([])
    setTrackLoading(true)
    clearTimeout(timeoutId)
    setTimeoutId(
      //@ts-ignore
      setTimeout(async () => {
        await handleAuthorizedRequest(async () => {
          const response = await releases(`/track/?search=${text}&limit=10`, "get")
          const tracks = mapListToCamelCase(response.data.results)
          setSearchedTracks(tracks)
          setTrackOptions(
            tracks.map((track) => ({
              value: track.id,
              label: getTrackSelectLabel(track)
            }))
          )
          setTrackLoading(false)
        }, "")
      }, 500)
    )
  }

  const getTooltipContent = (mainOwnerShare: MainOwnerShareData): string | undefined => {
    if (!mainOwnerShare) return undefined
    if (mainOwnerShare.user === "0") {
      return `There is no main owner assigned to this track. Current main owner share is ${mainOwnerShare.share}%.`
    }
    return `Main owner of this track is ${mainOwnerShare.user} and their share is ${mainOwnerShare.share}%`
  }

  return (
    <Dialog
      open={props.open}
      onClose={() => {
        props.onClose()
      }}
    >
      <div className="fixed w-screen h-screen top-0 left-0 z-250 flex items-center justify-center bg-[#0000004d] ">
        <div className="p-6 shadow-md w-[440px] rounded-[10px] bg-[#fff]">
          <div className="flex flex-col gap-6">
            <div className="flex flex-col gap-6 items-center justify-center">
              <span
                className="w-[72px] aspect-square rounded-full flex justify-center items-center"
                style={{
                  border: "1px solid #F3F3F3"
                }}
              >
                <img className="w-8 h-8" src="/admin_icons/music-album.svg" />
              </span>
              <KTitleSpan text={"Track Share Information"} fontSize={20} lineHeight="28px" color="#000" />
            </div>
            <div className="flex flex-row items-center gap-1">
              <KSpan text="User:" fontWeight={500} color="#000" />
              <KSpan text={`${user?.firstName} ${user?.lastName}`} color="#000" />
            </div>
            <div className="flex flex-col gap-2">
              <KDropdown
                key={`${trackShareData?.track}`}
                placeholder={"Search track"}
                padding="14px"
                gap="8px"
                menuWidth={400}
                menuLeftMargin={-40}
                selected={trackOptions.find((trackOption) => trackOption?.value === trackShareData?.track)}
                onInputChange={onSearchTextChanged}
                options={trackOptions}
                isClearable={true}
                isEllipsis={true}
                onSelect={(selectedOption) => {
                  const selected = selectedOption as KSelectOption
                  if (selected?.value) {
                    setTrackShareData({ ...trackShareData, track: selected?.value })
                  } else {
                    setTrackShareData({ ...trackShareData, track: undefined })
                  }
                }}
                leftIcon="/admin_icons/user-gray.svg"
                rightIcon="/admin_icons/caret-down.svg"
                enableIndicator
              />
              <div className="flex">
                {tooltipContent !== undefined ? (
                  <KTooltip
                    backgroundColor="#000"
                    padding="6px 8px"
                    height="20px"
                    arrowColor="#000"
                    position="bottom"
                    content={
                      <div className="h-auto w-[200px] flex items-center">
                        <KSpan text={tooltipContent} fontSize={12} lineHeight="16px" color="#FFF" />
                      </div>
                    }
                  >
                    {" "}
                    <div className="flex flex-row gap-2 items-center py-2 px-3.5">
                      <ToggleInput
                        checked={trackShareData?.mainOwner}
                        handleChange={() => {
                          setTrackShareData({ ...trackShareData, mainOwner: !trackShareData?.mainOwner })
                        }}
                        disabled={hasMainOwner}
                      />
                      <KSpan text="Main Owner" color="#000" fontWeight={500} />
                    </div>
                  </KTooltip>
                ) : (
                  <div className="flex flex-row gap-2 items-center py-2 px-3.5">
                    <ToggleInput
                      checked={trackShareData?.mainOwner}
                      handleChange={() => {
                        setTrackShareData({ ...trackShareData, mainOwner: !trackShareData?.mainOwner })
                      }}
                    />
                    <KSpan text="Main Owner" color="#000" fontWeight={500} />
                  </div>
                )}
              </div>

              <KDropdown
                placeholder={"Select roles"}
                padding={trackShareData.roles.length > 0 ? "6px" : "14px"}
                gap="8px"
                menuLeftMargin={-40}
                isMulti
                isEllipsis
                selected={[...roleOptions].filter((roleOption) => trackShareData?.roles?.includes(roleOption?.value))}
                onInputChange={onSearchTextChanged}
                options={roleOptions.filter((opt) => !trackShareData?.mainOwner || opt.value !== ROLES.OTHER).sort()}
                onSelect={(selectedOptions) => {
                  if (!selectedOptions || (selectedOptions as MultiValue<KSelectOption>).length === 0) {
                    setTrackShareData({ ...trackShareData, roles: [] })
                  } else {
                    const selectedRoles = (selectedOptions as MultiValue<KSelectOption>).map(
                      (selectedOption) => selectedOption.value
                    )
                    setTrackShareData({ ...trackShareData, roles: selectedRoles })
                  }
                }}
                leftIcon="/admin_icons/user-circle.svg"
                rightIcon="/admin_icons/caret-down.svg"
                enableIndicator
              />
              {!trackShareData.mainOwner && (
                <KInput
                  placeholder="User share"
                  padding="14px"
                  value={trackShareData?.share || ""}
                  onChange={(text: string) => {
                    setTrackShareData({ ...trackShareData, share: text })
                  }}
                  leftIcon="/admin_icons/users.svg"
                />
              )}
            </div>
            <div className="flex gap-3 justify-center items-center">
              <KButton
                text={lang.common.cancel}
                onClick={() => {
                  props.onClose()
                }}
                background="#fff"
                shadowDisabled={true}
              />
              <KButton
                text={lang.common.save}
                textColor="white"
                background="black"
                onClick={() => {
                  const errors = adminTrackValidator(trackShareData)

                  if (Object.keys(errors).length === 0) {
                    onFormSubmit(trackShareData).then(() => {
                      props.onClose()
                    })
                  }
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    shareData: state.admin.selectedTrackShareData,
    user: state.admin.selectedUser
  }
}

export default connect(mapStateToProps, { selectTrackShareData, selectUser, saveTrackShareForm })(AdminTrackForm)
