import { Dialog } from "@headlessui/react"
import { FC, useEffect, useState } from "react"
import { tableCellClasses } from "../../../../../constants"
import { X } from "heroicons-react"
import * as React from "react"
import { AnalysisSummary } from "../../../../../types"
import summaryValidator from "../summaryValidator"
import { KButton, KInput, KSpan, KTitleSpan } from "kahuna-base-react-components"

type ApprovalModalProps = {
  open: boolean
  onClose: () => void
  summary: AnalysisSummary
  approveSummary: (summary: AnalysisSummary) => void
}
const ApproveModal: FC<ApprovalModalProps> = (props) => {
  const { approveSummary, onClose, open, summary } = props
  const [totalNetValue, setTotalNetValue] = useState<number>(summary?.totalNet)
  useEffect(() => {
    setTotalNetValue(summary?.totalNet)
  }, [summary?.totalNet])

  const handleClose = () => {
    onClose()
    setTotalNetValue(summary?.totalNet)
  }

  return (
    <Dialog open={open} onClose={handleClose} className="relative z-50">
      <div className="fixed inset-0 bg-[rgba(0,0,0,0.3)]" aria-hidden="true" />
      <div className="fixed inset-0 flex items-center justify-center p-4">
        <div className="mt-10 bg-white elev-b-sm rounded-lg overflow-hidden">
          <div
            className={`bg-gray-50 text-xs text-gray-500 font-medium 
              uppercase border-b flex justify-between ${tableCellClasses.sm}`}
          >
            <KTitleSpan
              text="REVENUE SUMMARY APPROVAL"
              color="#111"
              fontWeight={500}
              fontSize={14}
              lineHeight="20px"
              letterSpacing="0.2px"
            />
            <X className="h-4 mr-[-6px] cursor-pointer" onClick={handleClose} />
          </div>
          <div className="w-full max-w-sm m-auto flex-col p-4">
            <div className="w-[350px] flex flex-col gap-4">
              <div className="flex flex-col gap-1 grow">
                <KSpan text="User" color="#000" />
                <KInput
                  value={summary?.user}
                  onChange={() => {
                    /*do nothing*/
                  }}
                  activeBackground="#F3F3F3"
                  disabled
                />
              </div>
              <div className="flex flex-col gap-1 grow">
                <KSpan text="Label" color="#000" />
                <KInput
                  value={summary?.label || ""}
                  onChange={() => {
                    /*do nothing*/
                  }}
                  activeBackground="#F3F3F3"
                  disabled
                />
              </div>

              <div className="flex flex-col gap-1 grow">
                <KSpan text="Period" color="#000" />
                <KInput
                  value={summary?.date || ""}
                  onChange={() => {
                    /*do nothing*/
                  }}
                  activeBackground="#F3F3F3"
                  disabled
                />
              </div>
              <div className="flex flex-col gap-1 grow">
                <KSpan text="Currency" color="#000" />
                <KInput
                  value={summary?.currency || ""}
                  onChange={() => {
                    /*do nothing*/
                  }}
                  activeBackground="#F3F3F3"
                  disabled
                />
              </div>
              <div className="flex flex-col gap-1 grow">
                <KSpan text="Gross" color="#000" />
                <KInput
                  value={summary?.totalAmount.toString() || ""}
                  onChange={() => {
                    /*do nothing*/
                  }}
                  activeBackground="#F3F3F3"
                  disabled
                />
              </div>
              <div className="flex flex-col gap-1 grow">
                <KSpan text="Admin Fee" color="#000" />
                <KInput
                  value={summary?.adminFee || ""}
                  onChange={() => {
                    /*do nothing*/
                  }}
                  activeBackground="#F3F3F3"
                  disabled
                />
              </div>
              <div className="flex flex-col gap-1 grow">
                <KSpan text="Net" color="#000" />
                <KInput
                  value={totalNetValue?.toString() || ""}
                  onChange={(text) => {
                    setTotalNetValue(Number(text))
                  }}
                  type="number"
                  background="#F3F3F3"
                  placeholder={"Net amount"}
                />
              </div>
              <div className="grow">
                <KButton
                  onClick={() => {
                    const errors = summaryValidator({ ...summary, totalNet: totalNetValue })
                    if (Object.keys(errors).length === 0) {
                      approveSummary({ ...summary, totalNet: totalNetValue })
                      onClose()
                    }
                  }}
                  background="#000"
                  textColor="#FFF"
                  text="Approve"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  )
}

export default ApproveModal
