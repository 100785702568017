import React from "react"
import CardTable from "../../../ui/table/CardTable"

const TrackIsrcCodes = () => {
  return (
    <div className="flex justify-center">
      <CardTable title={`Track ISRC Codes`} urlBase="isrc_codes" dataName="ISRC" dataKey="isrc" itemName="ISRC Code" iconPath="/lyrics.svg" />
    </div>
  )
}

export default TrackIsrcCodes
