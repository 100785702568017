import { AdminCollaboratorShareForm } from "../../../../../../types"
import { emailPattern, errorFilledToastOptions } from "../../../../../../constants"
import { toast } from "react-toastify"

const adminCollaboratorValidator = (formValues: AdminCollaboratorShareForm) => {
  const errors: any = {}
  if (!formValues.email) {
    errors.email = "You must provide an email."
    toast.error("You must provide an email.", errorFilledToastOptions)
  } else if (!formValues.email.match(emailPattern)) {
    errors.email = "You must enter a valid email."
    toast.error("You must enter a valid email.", errorFilledToastOptions)
  }
  if (!formValues.share) {
    errors.share = "You must enter share"
    toast.error("You must enter share", errorFilledToastOptions)
  } else if (!/^\d*\.?\d*$/.test(formValues.share)) {
    errors.share = "You must enter a number"
    toast.error("You must enter a number", errorFilledToastOptions)
  } else if (Number(formValues.share) > 100) {
    errors.share = "Share can't be greater that 100"
    toast.error("Share can't be greater that 100", errorFilledToastOptions)
  }
  if (!(formValues.roles?.length > 0)) {
    errors.roles = "You must select a role"
    toast.error("You must select a role", errorFilledToastOptions)
  }

  return errors
}

export default adminCollaboratorValidator
