import { Menu, Transition } from "@headlessui/react"
import * as React from "react"
import { FC, Fragment } from "react"
import { KButton } from "kahuna-base-react-components"
//@ts-ignore
import UploadIcon from "../../../../../assets/admin_icons/upload.svg"
import { UploadOptionsDropdownType } from "../../../../../types"

const UploadOptionsDropdown: FC<UploadOptionsDropdownType> = (props) => {
  return (
    <Menu as="div" className="ml relative">
      <div>
        <Menu.Button>
          <div className="w-9 h-9 p-2 rounded-[10px] flex items-center justify-center upload-menu-button"
           style={{
            border: "1px solid #F3F3F3"
           }}>
            <img className="min-w-5 min-h-5" src={UploadIcon} width={20} height={20} alt="upload-icon" />


          </div>

        </Menu.Button>
      </div>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items
          className={`z-20 origin-top-right absolute right-0 mt-2 p-2 w-[px] rounded-md shadow-lg py-1 bg-[#ffffff] ring-1
          ring-black ring-opacity-5 focus:outline-none flex flex-col divide-y divide-gray-200`}
        >
          <div className="flex flex-col gap-2 px-1 py-1 z-20 ">
            <Menu.Item>
              {({ active }) => (
                <div>
                  <KButton
                    text="BMV"
                    onClick={props.onSubmitBmv}
                    padding="8px"
                    height="36px"
                    width="140px"
                    background="white"
                    hoverBackground="#F7F7F7"
                  />
                </div>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <div>
                  <KButton
                    text="Mesam Msg"
                    onClick={props.onSubmitMesamMsg}
                    padding="8px"
                    height="36px"
                    width="140px"
                    background="white"
                    hoverBackground="#F7F7F7"
                  />
                </div>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <div>
                  <KButton
                    text="Editorial Pitching"
                    onClick={props.onSubmitEditorialPitching}
                    padding="8px"
                    height="36px"
                    width="140px"
                    background="white"
                    hoverBackground="#F7F7F7"
                  />
                </div>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <div>
                  <KButton
                    text="State 51"
                    onClick={props.onSubmitState51}
                    padding="8px"
                    height="36px"
                    width="140px"
                    background="white"
                    hoverBackground="#F7F7F7"
                  />
                </div>
              )}
            </Menu.Item>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  )
}

export default UploadOptionsDropdown
