import React from "react"
import AdminTerritoryDspTable from "./AdminTerritoryDSPTable"

const AdminTerritoryDsp = () => {

  return (
    <div className="flex justify-center">
      <AdminTerritoryDspTable/>
    </div>
  )
}

export default AdminTerritoryDsp
