import { Dialog } from "@headlessui/react"
import React, { FC } from "react"
import { X } from "heroicons-react"
import { KButton, KInput, KSpan, KTextArea, KTitleSpan } from "kahuna-base-react-components"

type AddDSPModalProps = {
  label: string
  setLabel: (value: string) => void
  imageUrl: string
  setImageUrl: (value: string) => void
  open: boolean
  onClose: () => void
  onConfirm: () => void
  onCancel: () => void
  width?: number
}
const AddDSPModal: FC<AddDSPModalProps> = (props) => {
  const { onClose, open, onConfirm, onCancel } = props

  return (
    <Dialog open={open} onClose={onClose} className="relative">
      <div className="fixed inset-0 bg-[rgba(0,0,0,0.3)]" aria-hidden="true" />
      <div className="fixed inset-0 flex items-center justify-center p-4">
        <div className="overflow-visible">
          <div className={`flex justify-end`}>
            <X className="h-4 cursor-pointer mt-1" style={{ position: "absolute" }} onClick={onClose} />
          </div>
          <div
            className="bg-white elev-b-sm rounded-lg flex flex-col gap-6"
            style={{
              padding: 42,
              width: props.width || 450
            }}
          >
            {" "}
            <div className="flex flex-col">
              <KTitleSpan text={"Add DSP"} fontSize={32} lineHeight="40px" />
              <KSpan text={ "Write the DSP label and image url"} color={"#111"} />
            </div>
            <KInput value={props.label} placeholder={"Enter label"} onChange={props.setLabel} />
            <KTextArea
              value={props.imageUrl}
              onChange={props.setImageUrl}
              placeholder={"Enter image url"}
              maxHeight={60}
              height={40}
            />
            <div className="flex pt-4 gap-2">
              <KButton text="Cancel" background="white" shadowDisabled={true} onClick={onCancel} />
              <KButton
                text="Confirm"
                background="black"
                textColor="white"
                onClick={onConfirm}
                disabled = {!props.label || !props.imageUrl}
              />
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  )
}

export default AddDSPModal
