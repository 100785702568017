import { adminRoleList, USER_ROLE } from "../../../../constants"
import {
  AssetRegistryIcon,
  RegistrationsIcon,
  ReleaseUpcIcon,
  ReportsApprovalIcon,
  RoyaltyReportsIcon,
  SchedulerLogsIcon,
  State51BatchIDIcon,
  TerritoryDSPListIcon,
  TrackISRCIcon,
  UserAssetIcon
} from "../../../ui/icons"

export const adminNavElements = [
  {
    name: "User Asset",
    href: "/user-asset",
    icon: UserAssetIcon,
    permittedRoles: [USER_ROLE.SuperAdmin, USER_ROLE.Admin]
  },
  {
    name: "Asset Registry (Releases)",
    href: "/catalog",
    icon: AssetRegistryIcon,
    permittedRoles: adminRoleList
  },
  {
    name: "Royalty Reports",
    href: "/royalty-reports",
    icon: RoyaltyReportsIcon,
    permittedRoles: [USER_ROLE.SuperAdmin]
  },
  {
    name: "Reports Approval",
    href: "/reports-approval",
    icon: ReportsApprovalIcon,
    permittedRoles: [USER_ROLE.SuperAdmin]
  },
  {
    name: "Scheduler Logs",
    href: "/scheduler-logs",
    icon: SchedulerLogsIcon,
    permittedRoles: [USER_ROLE.SuperAdmin]
  },
  {
    name: "State51 Batch Ids",
    href: "/state51-batch-ids",
    icon: State51BatchIDIcon,
    permittedRoles: [USER_ROLE.SuperAdmin, USER_ROLE.Admin]
  },
  {
    name: "Release Upc Codes",
    href: "/upc-codes",
    icon: ReleaseUpcIcon,
    permittedRoles: [USER_ROLE.SuperAdmin, USER_ROLE.Admin]
  },
  {
    name: "Track Isrc Codes",
    href: "/isrc-codes",
    icon: TrackISRCIcon,
    permittedRoles: [USER_ROLE.SuperAdmin, USER_ROLE.Admin]
  },
  {
    name: "Registrations",
    href: "/registrations",
    icon: RegistrationsIcon,
    permittedRoles: adminRoleList
  },
  {
    name: "Territory Dsp List",
    href: "/territory-dsp",
    icon: TerritoryDSPListIcon,
    permittedRoles: [USER_ROLE.SuperAdmin]
  }
]
