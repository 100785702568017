import {
  Action,
  AdminState,
  AnalysisSummary,
  CollaboratorData,
  SaveActionResult,
  User,
  UserTrackShareData,
  UserYoutubeVideoShareData
} from "../types"
import { ADMIN_ACTION } from "../actions/adminActions"
import { adminTableLimit } from "../constants"

export default (state = new AdminState(), action: Action) => {
  switch (action.type) {
    case ADMIN_ACTION.FETCH_ALL_USERS:
      return { ...state, paginatedUsers: action.payload }

    case ADMIN_ACTION.SELECT_USER:
      return { ...state, selectedUser: action.payload }

    case ADMIN_ACTION.SAVE_USER:
      const userResult = action.payload as SaveActionResult<User>
      if (userResult.operation === "updated") {
        return {
          ...state,
          paginatedUsers: {
            ...state.paginatedUsers,
            results: state.paginatedUsers.results.map((user) =>
              user.id === userResult.model.id ? userResult.model : user
            )
          }
        }
      }

      const addUserToList =
        state.paginatedUsers.count === 0 ||
        (state.paginatedUsers.count && Math.floor(state.paginatedUsers.count / adminTableLimit) !== state.paginatedUsers.count / adminTableLimit)

      return {
        ...state,
        paginatedUsers: {
          ...state.paginatedUsers,
          results: addUserToList ? [...state.paginatedUsers.results, userResult.model] : state.paginatedUsers.results,
          count: state.paginatedUsers.count ? state.paginatedUsers.count + 1 : undefined
        }
      }

    case ADMIN_ACTION.FETCH_TRACK_SHARE_DATA:
      return {
        ...state,
        paginatedUserTrackShareData: action.payload
      }

    case ADMIN_ACTION.FETCH_YOUTUBE_VIDEO_SHARE_DATA:
      return {
        ...state,
        paginatedUserYoutubeVideoShareData: action.payload
      }

    case ADMIN_ACTION.SELECT_TRACK_SHARE:
      return { ...state, selectedTrackShareData: action.payload }

    case ADMIN_ACTION.SELECT_PAYMENT_METHOD_USER:
      return { ...state, adminPanelUserId: action.payload }

    case ADMIN_ACTION.SELECT_YOUTUBE_VIDEO_SHARE:
      return { ...state, selectedYoutubeVideoShareData: action.payload }

    case ADMIN_ACTION.SAVE_TRACK_SHARE:
      const trackResult = action.payload as SaveActionResult<UserTrackShareData>
      if (trackResult.operation === "updated") {
        return {
          ...state,
          selectedTrackShareData: new UserTrackShareData(),
          paginatedUserTrackShareData: {
            ...state.paginatedUserTrackShareData,
            results: state.paginatedUserTrackShareData.results.map((share) =>
              share.id === trackResult.model.id ? trackResult.model : share
            )
          }
        }
      }
      const addTrackToList =
        state.paginatedUserTrackShareData.count === 0 ||
        (state.paginatedUserTrackShareData.count && Math.floor(state.paginatedUserTrackShareData.count / adminTableLimit) !==
          state.paginatedUserTrackShareData.count / adminTableLimit)
      return {
        ...state,
        selectedTrackShareData: new UserTrackShareData(),
        paginatedUserTrackShareData: {
          ...state.paginatedUserTrackShareData,
          results: addTrackToList
            ? [...state.paginatedUserTrackShareData.results, trackResult.model]
            : state.paginatedUserTrackShareData.results,
          count: state.paginatedUserTrackShareData.count ? state.paginatedUserTrackShareData.count + 1 : undefined
        }
      }

    case ADMIN_ACTION.SAVE_YOUTUBE_VIDEO_SHARE:
      const videoResult = action.payload as SaveActionResult<UserYoutubeVideoShareData>
      if (videoResult.operation === "updated") {
        return {
          ...state,
          selectedYoutubeVideoShareData: new UserYoutubeVideoShareData(),
          paginatedUserYoutubeVideoShareData: {
            ...state.paginatedUserYoutubeVideoShareData,
            results: state.paginatedUserYoutubeVideoShareData.results.map((share) =>
              share.id === videoResult.model.id ? videoResult.model : share
            )
          }
        }
      }
      const addVideoToList =
        state.paginatedUserYoutubeVideoShareData.count === 0 ||
        (state.paginatedUserYoutubeVideoShareData.count && Math.floor(state.paginatedUserYoutubeVideoShareData.count / adminTableLimit) !==
          state.paginatedUserYoutubeVideoShareData.count / adminTableLimit)
      return {
        ...state,
        selectedYoutubeVideoShareData: new UserYoutubeVideoShareData(),
        paginatedUserYoutubeVideoShareData: {
          ...state.paginatedUserYoutubeVideoShareData,
          results: addVideoToList
            ? [...state.paginatedUserYoutubeVideoShareData.results, videoResult.model]
            : state.paginatedUserYoutubeVideoShareData.results,
          count: state.paginatedUserYoutubeVideoShareData.count ? state.paginatedUserYoutubeVideoShareData.count + 1 : undefined
        }
      }

    case ADMIN_ACTION.DELETE_TRACK_SHARE:
      return {
        ...state,
        selectedTrackShareData: new UserTrackShareData(),
        paginatedUserTrackShareData: {
          ...state.paginatedUserTrackShareData,
          results: state.paginatedUserTrackShareData.results.filter((share) => share.id !== action.payload),
          count: state.paginatedUserTrackShareData.count ? state.paginatedUserTrackShareData.count - 1 : undefined
        }
      }

    case ADMIN_ACTION.DELETE_YOUTUBE_VIDEO_SHARE:
      return {
        ...state,
        selectedYoutubeVideoShareData: new UserYoutubeVideoShareData(),
        paginatedUserYoutubeVideoShareData: {
          ...state.paginatedUserYoutubeVideoShareData,
          results: state.paginatedUserYoutubeVideoShareData.results.filter((share) => share.id !== action.payload),
          count: state.paginatedUserYoutubeVideoShareData.count ? state.paginatedUserYoutubeVideoShareData.count - 1 : undefined  
        }
      }

    case ADMIN_ACTION.FETCH_LAST_UPDATE:
      return {
        ...state,
        lastUpdate: action.payload
      }

    case ADMIN_ACTION.REFRESH_LABEL_CAMP:
      return {
        ...state,
        message: action.payload
      }

    case ADMIN_ACTION.REFRESH_REPORTS:
      return {
        ...state,
        message: action.payload
      }

    case ADMIN_ACTION.REFRESH_SUMMARIES:
      return {
        ...state,
        message: action.payload
      }

    case ADMIN_ACTION.FETCH_SUMMARY:
      return {
        ...state,
        summaries: action.payload
      }

    case ADMIN_ACTION.FETCH_USER_EXPENSE:
      return {
        ...state,
        selectedUserExpense: action.payload
      }

    case ADMIN_ACTION.FETCH_AFFILIATE_LIST:
      return {
        ...state,
        affiliateFromList: action.payload.fromList,
        affiliateToList: action.payload.toList
      }

    case ADMIN_ACTION.CREATE_USER_EXPENSE:
      return {
        ...state,
        selectedUserExpense: action.payload
      }

    case ADMIN_ACTION.SELECT_SUMMARY_FILTER:
      return {
        ...state,
        selectedFilter: action.payload
      }

    case ADMIN_ACTION.APPROVE_SUMMARY:
      const approvedSummary = action.payload as AnalysisSummary
      return {
        ...state,
        summaries: {
          ...state.summaries,
          [state.selectedFilter]: state.summaries[state.selectedFilter].map((summary) =>
            summary.id === approvedSummary.id ? approvedSummary : summary
          )
        }
      }

    case ADMIN_ACTION.FETCH_COLLABORATOR_DATA:
      return {
        ...state,
        trackShareData: action.payload
      }

    case ADMIN_ACTION.SELECT_COLLABORATOR:
      return {
        ...state,
        selectedCollaboratorData: action.payload
      }

    case ADMIN_ACTION.SEND_COLLABORATOR_INVITATION:
      const newCollaborator = action.payload as SaveActionResult<CollaboratorData>
      if (newCollaborator.operation === "updated") {
        return {
          ...state,
          trackShareData: state.trackShareData.map((collaborator) =>
            collaborator.email === newCollaborator.model.email ? newCollaborator.model : collaborator
          )
        }
      }

      return {
        ...state,
        trackShareData: [...state.trackShareData, newCollaborator.model]
      }

    default:
      return state
  }
}
