import React, { FC, useEffect, useState } from "react"
import { connect } from "react-redux"
import { RootState } from "../../../../store"
import adminTrackShareHolderColumns from "./columns/adminTrackShareHolderColumns"
import { fetchTrackCollaboratorData, selectCollaboratorData } from "../../../../actions/adminActions"
import { AdminTrackShareHolderTableProps, CollaboratorData } from "../../../../types"
import { COLLAB_INVITATION_TABLE_ROW_STATUS } from "../../../../constants"
import TableNew from "../../../ui/table/TableNew"
import { KButton, KTitleSpan } from "kahuna-base-react-components"

const AdminTrackShareHolderTable: FC<AdminTrackShareHolderTableProps> = (props) => {
  const [loading, setLoading] = useState(true)
  const trackId = props.trackId

  useEffect(() => {
    setLoading(true)
    const fetchData = async () => {
      if (trackId) {
        await props.fetchTrackCollaboratorData(trackId)
      }
    }
    fetchData().then(() => setLoading(false))
  }, [trackId])

  const inactiveRowIndexes = props.trackShareData.reduce(
    (indexes: number[], rowData: CollaboratorData, index: number) => {
      if (Number(rowData?.status) !== Number(COLLAB_INVITATION_TABLE_ROW_STATUS.Active)) {
        indexes.push(index)
      }
      return indexes
    },
    []
  )

  const onCollaboratorSelectRow = (index: number) => {
    //TODO: There is a bug about editing collaborator data, so it is cancelled for now. For now only adding will be possible.
    //props.selectCollaboratorData(props.trackShareData[index])
  }

  const onClickAdd = () => {
    props.selectCollaboratorData(new CollaboratorData())
  }

  const tableTitle = (
      <div className="py-3 px-3">
        {" "}
        <KTitleSpan text="Collaborators" color="#000" fontSize={24} lineHeight="32px" />
      </div>
  )

  const topRightElement = () => {
    return (
      <KButton
        icon="/admin_icons/plus.svg"
        onClick={onClickAdd}
        background="#F7F7F7"
        hoverBackground="#F3F3F3"
        padding="14px"
        disabled={false}
        width="48px"
        height="48px"
      />
    )
  }

  return (
    <div className="catalog flex-grow">
      <div className="mt-[16px] mb-16">
        <TableNew
          title={tableTitle}
          minRowCount={1}
          limit={1}
          columns={adminTrackShareHolderColumns}
          data={props.trackShareData}
          count={props.trackShareData.length}
          rowHeightInPx={40}
          loading={loading}
          topRightElement={topRightElement()}
          inactiveRows={inactiveRowIndexes}
          onSelectRow={onCollaboratorSelectRow}
          overrideTableOverflow="auto"
        />
      </div>
    </div>
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    user: state.auth.user,
    trackShareData: state.admin.trackShareData,
    selectedCollaboratorData: state.admin.selectedCollaboratorData
  }
}

export default connect(mapStateToProps, { fetchTrackCollaboratorData, selectCollaboratorData })(
  AdminTrackShareHolderTable
)
