import { Menu, Transition } from "@headlessui/react"
import * as React from "react"
import { FC, Fragment } from "react"
import { KSpan } from "kahuna-base-react-components"
//@ts-ignore
import { AdminUserMenuProps } from "../../../../../types"
import { RootState } from "../../../../../store"
import { fetchUserData } from "../../../../../actions"
import { connect } from "react-redux"
import { toast } from "react-toastify"
import { AdminUserModalOptions, errorFilledToastOptions } from "../../../../../constants"
import { useNavigate } from "react-router-dom"
import { users } from "../../../../../apis"

const AdminUserMenu: FC<AdminUserMenuProps> = (props) => {
  const { setModal } = props
  const navigate = useNavigate()

  const onChangeUser = () => {
    users(`/change_user/?user_id=${props.user.id}`, "get")
      .then((resp) => {
        if (resp?.data?.token) {
          localStorage.setItem("token", resp.data.token)
          props.fetchUserData().then(() => {
            navigate("/")
          })
        } else {
          toast.error("Error changing account", errorFilledToastOptions)
        }
      })
      .catch((err) => toast.error("Error changing account", errorFilledToastOptions))
  }

  return (
    <Menu as="div" className="ml relative">
      <div>
        <Menu.Button 
        className={"focus:outline-none"}>
          <div className="w-9 h-9 p-2 rounded-[10px] flex items-center justify-center bg-[#F7F7F7] hover:bg-[#F3F3F3]">
            <img
              className="min-w-5 min-h-5"
              src={"/admin_icons/horizontal-dots.svg"}
              width={20}
              height={20}
              alt="dots-icon"
            />
          </div>
        </Menu.Button>
      </div>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items
          className={`z-20 origin-top-right absolute ${props.userDetailPage ? "left-[100%]" : "right-0"} mt-2 p-2 rounded-md shadow-lg py-1 bg-[#F7F7F7] ring-1
          ring-black ring-opacity-5 focus:outline-none flex flex-col divide-y divide-gray-200`}
        >
          <div className="flex flex-col px-1 py-1 gap-1 z-20 w-[220px]">
            <Menu.Item>
              {({ active }) => (
                <div
                  className="flex flex-row gap-2 items-center w-full py-2.5 px-2 bg-[#F7F7F7] hover:bg-[#F3F3F3] rounded-[10px] cursor-pointer"
                  onClick={() => {
                    setModal(AdminUserModalOptions.RELOAD)
                  }}
                >
                  <img className="min-w-5 min-h-5" src="/admin_icons/loading.svg" width={20} height={20} />
                  <KSpan text="Reload" color="#000" />
                </div>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <div
                  className="flex flex-row gap-2 items-center w-full py-2.5 px-2 bg-[#F7F7F7] hover:bg-[#F3F3F3] rounded-[10px] cursor-pointer"
                  onClick={() => {
                    setModal(AdminUserModalOptions.PAYMENT_METHODS)
                  }}
                >
                  <img className="min-w-5 min-h-5" src="/admin_icons/wallet.svg" width={20} height={20} />
                  <KSpan text="Payment methods" color="#000" />
                </div>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <div
                  className="flex flex-row gap-2 items-center w-full py-2.5 px-2 bg-[#F7F7F7] hover:bg-[#F3F3F3] rounded-[10px] cursor-pointer"
                  onClick={() => {
                    setModal(AdminUserModalOptions.USER_CREDITS)
                  }}
                >
                  <img className="min-w-5 min-h-5" src="/admin_icons/user-gray.svg" width={20} height={20} />
                  <KSpan text="User credits" color="#000" />
                </div>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <div
                  className="flex flex-row gap-2 items-center w-full py-2.5 px-2 bg-[#F7F7F7] hover:bg-[#F3F3F3] rounded-[10px] cursor-pointer"
                  onClick={() => {
                    setModal(AdminUserModalOptions.USER_ADVANCE)
                  }}
                >
                  <img className="min-w-5 min-h-5" src="/admin_icons/user-gray.svg" width={20} height={20} />
                  <KSpan text="User advance" color="#000" />
                </div>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <div
                  className="flex flex-row gap-2 items-center w-full py-2.5 px-2 bg-[#F7F7F7] hover:bg-[#F3F3F3] rounded-[10px] cursor-pointer"
                  onClick={() => {
                    setModal(AdminUserModalOptions.AFFILIATE_FROM_LIST)
                  }}
                >
                  <img className="min-w-5 min-h-5" src="/admin_icons/soundwave.svg" width={20} height={20} />
                  <KSpan text={`Affiliate from list (${props.affiliateFromList?.length})`} color="#000" />
                </div>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <div
                  className="flex flex-row gap-2 items-center w-full py-2.5 px-2 bg-[#F7F7F7] hover:bg-[#F3F3F3] rounded-[10px] cursor-pointer"
                  onClick={() => {
                    setModal(AdminUserModalOptions.AFFILIATE_TO_LIST)
                  }}
                >
                  <img className="min-w-5 min-h-5" src="/admin_icons/soundwave.svg" width={20} height={20} />
                  <KSpan text={`Affiliate to list (${props.affiliateToList?.length})`} color="#000" />
                </div>
              )}
            </Menu.Item>

            <Menu.Item>
              {({ active }) => (
                <div
                  className="flex flex-row gap-2 items-center w-full py-2.5 px-2 bg-[#F7F7F7] hover:bg-[#F3F3F3] rounded-[10px] cursor-pointer"
                  onClick={() => {
                    setModal(AdminUserModalOptions.SEND_NOTIFICATION)
                  }}
                >
                  <img className="min-w-5 min-h-5" src="/notification.svg" width={20} height={20} />
                  <KSpan text="Send notification" color="#000" />
                </div>
              )}
            </Menu.Item>

            <Menu.Item>
              {({ active }) => (
                <div
                  className="flex flex-row gap-2 items-center w-full py-2.5 px-2 bg-[#F7F7F7] hover:bg-[#F3F3F3] rounded-[10px] cursor-pointer"
                  onClick={() => {
                    setModal(AdminUserModalOptions.USER_EXPENSE)
                  }}
                >
                  <img className="min-w-5 min-h-5" src="/admin_icons/user-gray.svg" width={20} height={20} />
                  <KSpan text="User expense" color="#000" />
                </div>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <div
                  className="mt-2 flex flex-row gap-2 items-center w-full py-3.5 px-2 bg-[#000] hover:bg-[#111] rounded-[10px] cursor-pointer"
                  onClick={() => {
                    onChangeUser()
                  }}
                >
                  <img className="min-w-5 min-h-5" src="/admin_icons/user-circle-white.svg" width={20} height={20} />
                  <KSpan text="Go to profile" color="#FFF" />
                </div>
              )}
            </Menu.Item>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    selectedUser: state.admin.selectedUser,
    affiliateFromList: state.admin.affiliateFromList,
    affiliateToList: state.admin.affiliateToList
  }
}

export default connect(mapStateToProps, {
  fetchUserData
})(AdminUserMenu)
