import { AnalysisSummary, TableColumn } from "../../../../../types"
import { chartLabels, TABLE_COLUMN_TYPE } from "../../../../../constants"
import React, { CSSProperties } from "react"
import { KSpan } from "kahuna-base-react-components"

const headerStyle: CSSProperties = { textAlign: "left", width: "25%" }
const cellStyle: CSSProperties = { fontWeight: 400, lineHeight: "20px", color: "#111827" }

const idSelectColumn = (selectedIds: number[], setSelectedIds: (selectedIds: number[]) => void, allIds: number[]) => {
  const column = {
    overrideHeader: () => (
      <div className="flex w-full">
        <input
          type="checkbox"
          checked={allIds.length === selectedIds.length}
          style={{
            accentColor: "#000000"
          }}
          onChange={() => {
            if (selectedIds.length === allIds.length) {
              setSelectedIds([])
            } else {
              setSelectedIds(allIds)
            }
          }}
        />
      </div>
    ),
    type: TABLE_COLUMN_TYPE.FUNCTION,
    render: (row: AnalysisSummary) => {
      return (
        <div className="flex w-full" key={`${row?.id}-${selectedIds.includes(Number(row?.id))}`}>
          <input
            checked={selectedIds.includes(Number(row?.id))}
            type="checkbox"
            className="no-parent-trigger"
            style={{
              accentColor: "#000000"
            }}
            onChange={(event) => {
              if (selectedIds.includes(Number(row?.id))) {
                setSelectedIds(selectedIds.filter((id) => id !== Number(row?.id)))
              } else {
                setSelectedIds([...selectedIds, Number(row?.id)])
              }
            }}
          />
        </div>
      )
    },
    headerStyle: { ...headerStyle, width: "3%" },
    cellStyle
  }
  return column
}

const userColumn = {
  header: "User",
  type: TABLE_COLUMN_TYPE.FUNCTION,
  render: (row: AnalysisSummary) => (
    <div className="flex w-max max-[1200px]:!w-auto">
      <div className="flex flex-row gap-3 items-center">
        <img
          src={row?.photo || "/reports_icons/avatar.svg"}
          alt="profile-photo"
          className="min-w-10 min-h-10 max-h-10 max-w-10 rounded-full"
          width={40}
          height={40}
        />
        <div className="flex flex-col justify-between gap-0.5">
          {row?.userName && <KSpan text={row?.userName} color="#000" fontWeight={500} />}
          <div className="flex">
            <div className="rounded-[4px] bg-[#F7F7F7] p-1 flex justify-center">
              <KSpan
                text={row?.user}
                color="#111"
                fontSize={11}
                fontWeight={500}
                lineHeight="12px"
                letterSpacing="0.22px"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  ),
  headerStyle,
  cellStyle,
  sortable: true,
  sortFilterField: "user__username"
}

const labelColumn = {
  header: "Label",
  type: TABLE_COLUMN_TYPE.FUNCTION,
  render: (row: AnalysisSummary) => {
    const text =
      Object.keys(chartLabels)
        .map((key) => chartLabels[key][row.label])
        .filter((l) => !!l)[0] || row.label
    return (
      <div className="flex">
        <div className="rounded-[4px] bg-[#F7F7F7] flex items-center p-1 w-auto">
          <KSpan text={text} color="#111" fontSize={11} fontWeight={500} lineHeight="12px" letterSpacing="0.22px" />
        </div>
      </div>
    )
  },
  headerStyle: {...headerStyle, width: "10%"},
  cellStyle
}

const currencyColumn = {
  header: "Currency",
  type: TABLE_COLUMN_TYPE.FUNCTION,
  render: (row: AnalysisSummary) => (
    <div className="flex w-full">
      <div className="rounded-[4px] bg-[#F7F7F7] flex items-center p-1 w-auto">
        <KSpan
          text={row.currency}
          color="#111"
          fontSize={11}
          fontWeight={500}
          lineHeight="12px"
          letterSpacing="0.22px"
        />
      </div>
    </div>
  ),
  headerStyle: { ...headerStyle, width: "10%" },
  cellStyle,
  sortable: true,
  sortFilterField: "currency"
}

const grossColumn = {
  header: "Gross",
  type: TABLE_COLUMN_TYPE.FUNCTION,
  render: (row: AnalysisSummary) => (
    <div className="flex w-full">
      <div className="rounded-[4px] bg-[#F7F7F7] flex items-center p-1 w-auto">
        <KSpan
          text={row.totalGross.toFixed(2)}
          color="#111"
          fontSize={11}
          fontWeight={500}
          lineHeight="12px"
          letterSpacing="0.22px"
        />
      </div>
    </div>
  ),
  headerStyle: { ...headerStyle, width: "10%" },
  cellStyle,
  sortable: true,
  sortFilterField: "total_gross"
}

const splitGrossColumn = {
  header: "Split Gross",
  type: TABLE_COLUMN_TYPE.FUNCTION,
  render: (row: AnalysisSummary) => (
    <div className="flex w-full">
      <div className="rounded-[4px] bg-[#F7F7F7] flex items-center p-1 w-auto">
        <KSpan
          text={`${Number(row.totalAmount).toFixed(2)}`}
          color="#111"
          fontSize={11}
          fontWeight={500}
          lineHeight="12px"
          letterSpacing="0.22px"
        />
      </div>
    </div>
  ),
  headerStyle: { ...headerStyle, width: "10%" },
  cellStyle,
  sortable: true,
  sortFilterField: "total_amount"
}

const splitNetColumn = {
  header: "Split Net",
  type: TABLE_COLUMN_TYPE.FUNCTION,
  render: (row: AnalysisSummary) => (
    <div className="flex w-full">
      <div className="rounded-[4px] bg-[#F7F7F7] flex items-center p-1 w-auto">
        <KSpan
          text={`${Number(row.totalNetAll).toFixed(2)}`}
          color="#111"
          fontSize={11}
          fontWeight={500}
          lineHeight="12px"
          letterSpacing="0.22px"
        />
      </div>
    </div>
  ),
  headerStyle: { ...headerStyle, width: "10%" },
  cellStyle,
  sortable: true,
  sortFilterField: "total_net_all"
}

const totalApprovedColumn = {
  header: "Approved Total",
  type: TABLE_COLUMN_TYPE.FUNCTION,
  render: (row: AnalysisSummary) => (
    <div className="flex w-full">
      <div className="rounded-[4px] bg-[#F7F7F7] flex items-center p-1 w-auto">
        <KSpan
          text={`${Number(row.totalApproved).toFixed(2)}`}
          color="#111"
          fontSize={11}
          fontWeight={500}
          lineHeight="12px"
          letterSpacing="0.22px"
        />
      </div>
    </div>
  ),
  headerStyle: { ...headerStyle, width: "10%" },
  cellStyle,
  sortable: true,
  sortFilterField: "total_approved"
}

const kahunaShareColumn = {
  header: "Kahuna Share",
  type: TABLE_COLUMN_TYPE.FUNCTION,
  render: (row: AnalysisSummary) => (
    <div className="flex w-full">
      <div className="rounded-[4px] bg-[#F7F7F7] flex items-center p-1 w-auto">
        <KSpan
          text={`${(Number(row.totalGross) - Number(row.totalNet)).toFixed(2)}`}
          color="#111"
          fontSize={11}
          fontWeight={500}
          lineHeight="12px"
          letterSpacing="0.22px"
        />
      </div>
    </div>
  ),
  headerStyle: { ...headerStyle, width: "10%" },
  cellStyle,
  sortable: true,
  sortFilterField: "total_approved"
}

const advanceTotalColumn = {
  header: "Advance Total",
  type: TABLE_COLUMN_TYPE.FUNCTION,
  render: (row: AnalysisSummary) => (
    <div className="flex w-full">
      <div className="rounded-[4px] bg-[#F7F7F7] flex items-center p-1 w-auto">
        <KSpan
          text={`${Number(row.advanceTotal).toFixed(2)}`}
          color="#111"
          fontSize={11}
          fontWeight={500}
          lineHeight="12px"
          letterSpacing="0.22px"
        />
      </div>
    </div>
  ),
  headerStyle: { ...headerStyle, width: "10%" },
  cellStyle,
  sortable: true,
  sortFilterField: "advance_total"
}

const remainingAdvanceTotalColumn = {
  header: "Remaining Advance",
  type: TABLE_COLUMN_TYPE.FUNCTION,
  render: (row: AnalysisSummary) => (
    <div className="flex w-full">
      <div className="rounded-[4px] bg-[#F7F7F7] flex items-center p-1 w-auto">
        <KSpan
          text={`${(Number(row.advanceTotal) - Number(row.paidTotal)).toFixed(2)}`}
          color="#111"
          fontSize={11}
          fontWeight={500}
          lineHeight="12px"
          letterSpacing="0.22px"
        />
      </div>
    </div>
  ),
  headerStyle: { ...headerStyle, width: "10%" },
  cellStyle,
  sortable: true,
  sortFilterField: "paid_total"
}

const shareColumn = {
  header: "Share",
  type: TABLE_COLUMN_TYPE.FUNCTION,
  render: (row: AnalysisSummary) => (
    <div className="flex w-full">
      <div className="rounded-[4px] bg-[#F7F7F7] flex items-center p-1 w-auto">
        <KSpan
          text={`${Number((row.totalAmount / row.totalGross) * 100).toFixed(2)}`}
          color="#111"
          fontSize={11}
          fontWeight={500}
          lineHeight="12px"
          letterSpacing="0.22px"
        />
      </div>
    </div>
  ),
  headerStyle: { ...headerStyle, width: "10%" },
  cellStyle,
  sortable: false
}

const approvedColumn = {
  header: "Reports Approved",
  type: TABLE_COLUMN_TYPE.BOOL,
  attribute: "approved",
  headerStyle: { ...headerStyle, width: "10%" },
  cellStyle,
  sortable: true,
  sortFilterField: "approved"
}

const paymentApprovedColumn = {
  header: "Payment Approved",
  type: TABLE_COLUMN_TYPE.BOOL,
  attribute: "paymentApproved",
  headerStyle: { ...headerStyle, width: "10%" },
  cellStyle,
  sortable: true,
  sortFilterField: "payment_approved"
}

const platformExist = {
  header: "Platform Exist",
  type: TABLE_COLUMN_TYPE.BOOL,
  attribute: "platformExist",
  headerStyle: { ...headerStyle, width: "10%" },
  cellStyle,
  sortable: true,
  sortFilterField: "platformExist"
}

const approvedTotalNetColumn = {
  header: "Approved Total Net",
  type: TABLE_COLUMN_TYPE.FUNCTION,
  render: (row: AnalysisSummary) => (
    <div className="flex w-full">
      <div className="rounded-[4px] bg-[#F7F7F7] flex items-center p-1 w-auto">
        <KSpan
          text={Number(row.totalNet).toFixed(2)}
          color="#111"
          fontSize={11}
          fontWeight={500}
          lineHeight="12px"
          letterSpacing="0.22px"
        />
      </div>
    </div>
  ),
  headerStyle: { ...headerStyle, width: "10%" },
  cellStyle,
  sortable: true,
  sortFilterField: "total_net"
}

export const reportsApprovalPlatformColumns = (
  selectedIds: number[],
  setSelectedIds: (selectedIds: number[]) => void,
  allIds: number[],
  showIdSelect: boolean
): TableColumn[] => {
  const columns: TableColumn[] = [
    userColumn,
    labelColumn,
    currencyColumn,
    splitGrossColumn,
    splitNetColumn,
    approvedColumn,
    paymentApprovedColumn
  ]
  if (showIdSelect) {
    columns.unshift(idSelectColumn(selectedIds, setSelectedIds, allIds))
  }
  return columns
}

export const reportsApprovalTrackColumns = (): TableColumn[] => {
  return [
    userColumn,
    labelColumn,
    currencyColumn,
    grossColumn,
    splitGrossColumn,
    splitNetColumn,
    approvedTotalNetColumn,
    shareColumn
  ]
}

export const reportsApprovalStoreFrontColumns = (): TableColumn[] => {
  return [
    userColumn,
    { ...labelColumn, header: "Country" },
    currencyColumn,
    splitGrossColumn,
    splitNetColumn,
    approvedTotalNetColumn
  ]
}

export const reportsApprovalAllPlatformTotalColumns = (): TableColumn[] => {
  return [labelColumn, splitGrossColumn, splitNetColumn, currencyColumn, platformExist]
}

export const reportsApprovalAllAccountColumns = (): TableColumn[] => {
  const columns: TableColumn[] = [
    userColumn,
    grossColumn,
    splitGrossColumn,
    kahunaShareColumn,
    splitNetColumn,
    totalApprovedColumn,
    currencyColumn,
    advanceTotalColumn,
    remainingAdvanceTotalColumn
  ]
  return columns
}
