import { Dialog } from "@headlessui/react"
import { KTitleSpan, KSpan, KButton, KDropdown, KTooltip } from "kahuna-base-react-components"
import React, { useEffect, useState } from "react"
import { lang } from "../../../../../constants/languages"
import { KSelectOption } from "kahuna-base-react-components/dist/components/KDropdown/KDropdown"
import { User } from "../../../../../types"
import { RootState } from "../../../../../store"
import { connect } from "react-redux"
import { toast } from "react-toastify"
import { errorFilledToastOptions, successFilledToastOptions } from "../../../../../constants"
import {
  fetchLastUpdate,
  refreshLabelCamp,
  refreshReports,
  refreshSummaries,
  selectUser
} from "../../../../../actions/adminActions"
import { handleAuthorizedRequest, summaries as summariesApi } from "../../../../../apis"

const ReloadModal = ({
  isOpen,
  handleClose,
  selectedUser,
  fetchLastUpdate,
  lastUpdate,
  refreshLabelCamp,
  refreshReports,
  refreshSummaries,
  refreshSummaryForUser
}: {
  isOpen: boolean
  handleClose: () => void
  selectedUser?: User
  fetchLastUpdate: () => Promise<any>
  lastUpdate: string | null
  refreshLabelCamp: () => Promise<any>
  refreshReports: (date: string) => Promise<any>
  refreshSummaries: (date: string, userId: number) => Promise<any>
  refreshSummaryForUser?: boolean
}) => {
  const [periods, setPeriods] = useState<KSelectOption[]>([])
  const [selectedPeriod, setSelectedPeriod] = useState<string | undefined>(undefined)
  const reloadOptions: KSelectOption[] = [
    { label: "Assets", value: 0, value2: "assets" },
    { label: "Reports", value: 1, value2: "reports" },
    { label: "Summaries", value: 2, value2: "summaries" }
  ]
  const [selected, setSelected] = useState<KSelectOption | undefined>(reloadOptions[2])

  useEffect(() => {
    const getSummaryPeriods = async () => {
      await handleAuthorizedRequest(
        async () => {
          const response = await summariesApi("/summary_periods", "get")
          if (response.status === 200) {
            const sortedList = response.data.sort().reverse()
            setPeriods(sortedList.map((period, index: number) => ({ label: period, value: index, value2: period })))
            setSelectedPeriod(sortedList[0])
          }
        },
        "Error on fetching summary periods.",
        undefined
      )
    }
    getSummaryPeriods().then()
  }, [])

  useEffect(() => {
    fetchLastUpdate().then()
  }, [])

  const handleCloseClick = () => {
    handleClose()
    setSelected(reloadOptions[2])
  }

  const onRefreshLabelCamp = async () => {
    try {
      await refreshLabelCamp()
      fetchLastUpdate().then(() => {
        toast.success("Successfully refreshed the Label Camp", successFilledToastOptions)
      })
    } catch (error) {
      console.error(error)
      toast.error("Failed to refresh Label Camp.", errorFilledToastOptions)
    }
  }

  const onRefreshReports = async () => {
    if (selectedPeriod) {
      try {
        await refreshReports(selectedPeriod)
        fetchLastUpdate().then(() => {
          toast.success("Successfully refreshed the reports", successFilledToastOptions)
        })
      } catch (error) {
        console.error(error)
        toast.error("Failed to refresh reports.", errorFilledToastOptions)
      }
    } else {
      toast.error("Please select a period.", errorFilledToastOptions)
    }
  }

  const onRefreshSummaries = async () => {
    if (selectedPeriod) {
      try {
        const userId = selectedUser && selectedUser?.id && refreshSummaryForUser ? selectedUser?.id : 0
        await refreshSummaries(selectedPeriod, userId)
        fetchLastUpdate().then(() => {
          toast.success("Successfully refreshed the summaries", successFilledToastOptions)
        })
      } catch (error) {
        console.error(error)
        toast.error("Failed to refresh summaries.", errorFilledToastOptions)
      }
    } else {
      toast.error("Please select a period.", errorFilledToastOptions)
    }
  }

  const handleConfirm = () => {
    
    if (!selected?.value2) return
    if (selected?.value2 === "assets") {
      onRefreshLabelCamp()
    } else if (selected?.value2 === "reports") {
      onRefreshReports()
    } else if (selected?.value2 === "summaries") {
      onRefreshSummaries()
    }
    handleCloseClick()
  }

  return (
    <Dialog
      open={isOpen}
      onClose={() => {
        handleCloseClick()
      }}
    >
      <div className="fixed w-screen h-screen top-0 left-0 z-250 flex items-center justify-center bg-[#0000004d] ">
        <div className="p-6 shadow-md w-[400px] rounded-[10px] bg-[#fff]">
          <div className="flex flex-col gap-6">
            <div className="flex flex-col gap-6 items-center justify-center">
              <span
                className="w-[72px] aspect-square rounded-full flex justify-center items-center"
                style={{
                  border: "1px solid #F3F3F3"
                }}
              >
                <img className="w-8 h-8" src="/admin_icons/loading.svg" />
              </span>
              <KTitleSpan text={refreshSummaryForUser ? "Refresh Summaries" : "Reload"} fontSize={20} lineHeight="28px" color="#000" />
            </div>
            <div className="flex flex-col gap-2">
              <div className="w-full flex flex-row gap-2 items-center">
                {refreshSummaryForUser ? (
                  <div className="flex flex-col gap-4 items-start pb-3">
                    <div className="flex flex-row gap-2 items-center">
                      <KSpan text={`Selected User:`} color="#000" fontWeight={500} />
                      <KSpan text={`${selectedUser?.firstName} ${selectedUser?.lastName}`} color="#000" />
                    </div>
                  </div>
                ) : (
                  <KDropdown
                    rightIcon="/admin_icons/caret-down.svg"
                    options={reloadOptions}
                    onSelect={(selected) => {
                      setSelected(selected as KSelectOption)
                    }}
                    selected={selected}
                    padding="14px"
                    enableIndicator={true}
                    placeholder="Select reload option"
                    textColor="#F3F3F3"
                  />
                )}

                {selected?.value2 === "assets" && (
                  <KTooltip
                    backgroundColor="#000"
                    padding="4px 6px"
                    arrowColor="#000"
                    position="top"
                    align="left"
                    content={
                      <div className="h-auto w-max flex flex-col justify-center gap-1">
                        <KSpan text={"BMV & Labelcamp Assets"} fontSize={12} lineHeight="16px" color="#FFF" />
                        <KSpan text={"Last update:"} fontSize={12} lineHeight="16px" color="#FFF" />
                        <KSpan text={lastUpdate ? lastUpdate : "N/A"} fontSize={12} lineHeight="16px" color="#FFF" />
                      </div>
                    }
                  >
                    <img className="min-w-5 min-h-5" src="/admin_icons/info-circle.svg" width={20} height={20} />
                  </KTooltip>
                )}
              </div>
              { selected?.value2 !== "assets" && (
                <KDropdown
                  rightIcon="/admin_icons/caret-down.svg"
                  options={periods}
                  selected={periods.find((period) => period?.value2 === selectedPeriod)}
                  onSelect={(selected) => {
                    const selectedValue = (selected as KSelectOption)?.value2
                    setSelectedPeriod(selectedValue)
                  }}
                  padding="14px"
                  enableIndicator={true}
                  textColor="#F3F3F3"
                />
              )}
              <div className="pl-3.5">
                <KSpan
                  text={
                    refreshSummaryForUser
                      ? "Summaries will be refreshed for the selected user."
                      : "Refresh action will be performed for all users."
                  }
                  color="#000"
                  fontWeight={400}
                />
              </div>
            </div>
            <div className="flex gap-3 justify-center items-center">
              <KButton
                text={lang.common.cancel}
                onClick={() => {
                  handleCloseClick()
                }}
                background="#fff"
                shadowDisabled={true}
              />
              <KButton
                text={lang.common.confirm}
                textColor="white"
                background="black"
                disabled={  !refreshSummaryForUser && (!selected || (selected?.value2 !== "assets" && !selectedPeriod))}
                onClick={() => {
                  handleConfirm()
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  )
}
const mapStateToProps = (state: RootState) => {
  return {
    selectedUser: state.admin.selectedUser,
    lastUpdate: state.admin.lastUpdate
  }
}

export default connect(mapStateToProps, {
  selectUser,
  fetchLastUpdate,
  refreshLabelCamp,
  refreshReports,
  refreshSummaries
})(ReloadModal)
