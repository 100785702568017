import { toast } from "react-toastify"
import { errorFilledToastOptions } from "../../../../../../constants"
import { UserYoutubeVideoShareForm } from "../../../../../../types"

const adminYoutubeVideoValidator = (formValues: UserYoutubeVideoShareForm) => {
  const errors: any = {}

  if (!formValues.youtubeVideo) {
    errors.youtubeVideo = "You must select a Youtube video"
    toast.error("You must select a Youtube video", errorFilledToastOptions)
  }
  if (!formValues.mainOwner) {
    if (!formValues.share) {
      errors.share = "You must enter share"
      toast.error("You must enter a share", errorFilledToastOptions)
    } else if (!/^\d*\.?\d*$/.test(formValues.share)) {
      errors.share = "You must enter a number"
      toast.error("You must enter a number", errorFilledToastOptions)
    } else if (Number(formValues.share) > 100) {
      errors.share = "Share can't be greater than 100"
      toast.error("Share can't be greater than 100", errorFilledToastOptions)
    }
    if (!formValues.mainOwner && formValues.roles?.length === 0) {
      errors.roles = "You must select a role"
      toast.error("You must select a role", errorFilledToastOptions)
    }
  }

  return errors
}

export default adminYoutubeVideoValidator
