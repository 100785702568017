import { SortParams, TableColumn, TableNewProps, TableProps } from "../../../types"
import React, { FC, useEffect, useRef, useState } from "react"
import { renderCell } from "./renderCell"
import { tableCellClasses, tableTitleClasses } from "../../../constants"
import Pagination from "./Pagination"
import { ArrowDown, ArrowUp, Filter, FilterOutline } from "heroicons-react"
import { isEqual } from "lodash"
import { KSpan } from "kahuna-base-react-components"
import NotFound from "../../NotFound"

const TableNew: FC<TableNewProps> = (props) => {
  const {
    topRightElement,
    className,
    size = "lg",
    title,
    columns,
    data,
    minRowCount = 5,
    rowHeightInPx,
    limit = 20,
    pagination,
    count = 0,
    onClickPage,
    loading,
    onSelectRow,
    setTableAPI,
    hidden,
    onSortOrFilterChange,
    additionalFilters,
    inactiveRows,
    onMouseEnterRow,
    onMouseLeaveRow,
    hoveredRowId,
    sortParamsReader,
    pageNumber,
    overrideSortParams,
    sortParamsValue,
    overrideTableOverflow,
    paginationKey,
    doNotApplyAdditionalFilters
  } = props
  const [currentPage, setCurrentPage] = useState(1)
  const [selectedRowIndex, setSelectedRowIndex] = useState<number | undefined>()
  const [sortParams, setSortParams] = useState<SortParams>(new SortParams())
  const [filtersShown, setFiltersShown] = useState(false)
  const [filters, setFilters] = useState<{ [key: string]: string | number | boolean }>({})
  const additionalFiltersRef = useRef(additionalFilters)

  useEffect(() => {
    if (selectedRowIndex !== undefined) {
    setSelectedRowIndex(undefined) 
  }
  }, [data])

  useEffect(() => {
    if (pageNumber) {
      setCurrentPage(pageNumber)
    }
  }, [pageNumber])

  useEffect(() => {
    if (overrideSortParams && sortParamsValue) {
      setSortParams(sortParamsValue)
    }
  }, [sortParamsValue])

  const onClickPagination = (pageNumber) => {
    if (currentPage !== pageNumber) {
      setSelectedRowIndex(undefined)
      if (onSelectRow) onSelectRow(-1)
      if (onClickPage) onClickPage(pageNumber, filters, sortParams.field, sortParams.direction)
      setCurrentPage(pageNumber)
    }
  }

  const onClickRow = (index: number, row?: any) => {
    if (index === selectedRowIndex) {
      setSelectedRowIndex(undefined)
    } else {
      setSelectedRowIndex(index)
    }
    if (onSelectRow) onSelectRow(index, row)
  }

  useEffect(() => {
    if (setTableAPI) setTableAPI({ currentPage, setCurrentPage })
  }, [currentPage, count])

  const onSort = async (col: TableColumn) => {
    columns.map((cl) => {
      if (cl !== col) {
        cl.sortDirection = undefined
      }
      return cl
    })
    if (!col.sortDirection) {
      col.sortDirection = "asc"
    } else if (col.sortDirection === "asc") {
      col.sortDirection = "desc"
    } else {
      col.sortDirection = undefined
    }
    if (onSortOrFilterChange) {
      await onSortOrFilterChange(filters, col.sortFilterField || "", col.sortDirection)
    }
    setSortParams({ field: col.sortFilterField || "", direction: col.sortDirection })
    if (sortParamsReader) {
      sortParamsReader({ field: col.sortFilterField || "", direction: col.sortDirection })
    }
    setCurrentPage(1)
  }

  const getSortDirectionDecoration = (col: TableColumn) => {
    if (col.sortDirection === "asc") {
      return (
        <>
          &nbsp;
          <ArrowDown className={"inline h-[10px] mb-[2px]"} />
        </>
      )
    } else if (col.sortDirection === "desc") {
      return (
        <>
          &nbsp;
          <ArrowUp className={"inline h-[10px] mb-[2px]"} />
        </>
      )
    }
  }

  useEffect(() => {
    if (!isEqual(additionalFiltersRef.current, additionalFilters)) {
      const newFilters = { ...filters, ...additionalFilters }
      setFilters(newFilters)
      if (onSortOrFilterChange && !doNotApplyAdditionalFilters) {
        onSortOrFilterChange(newFilters, sortParams.field, sortParams.direction, false).then()
      }
      additionalFiltersRef.current = additionalFilters
    }
  }, [additionalFilters])

  const handleFilterChange = async (col: TableColumn, value: string | number | boolean) => {
    const newFilters = { ...filters, [String(col.sortFilterField)]: value }
    setFilters(newFilters)
    if (onSortOrFilterChange) {
      await onSortOrFilterChange(newFilters, col.sortFilterField || "", col.sortDirection, true)
    }
  }

  const isRowInactive = (index: number, inactiveRows?: number[]): boolean => {
    return inactiveRows?.includes(index) || false
  }

  if (hidden) return null

  return (
    <div className={`${className} w-full`}>
      <div className="flex flex-row justify-between h-20 items-center p-4 !px-8">
        <div className={`flex`}>
          {title}
          {columns.some((col) => col.filter) && (
            <span onClick={() => setFiltersShown(!filtersShown)}>
              {filtersShown ? (
                <Filter className={"inline h-3 mb-[2px] cursor-pointer"} />
              ) : (
                <FilterOutline className={"inline h-3 mb-[2px] cursor-pointer"} />
              )}
            </span>
          )}
        </div>
        {topRightElement}
      </div>
      <div className="p-6 px-8 flex flex-col gap-6">
        {props.topFilters && <div className="w-full">{props.topFilters}</div>}
        <table
          className="bg-white w-full"
          style={{
            overflow: overrideTableOverflow ? overrideTableOverflow : "hidden"
          }}
        >
          <thead className="min-h-9">
            <tr className="">
              {data &&
                data?.length > 0 &&
                columns.map((col, i) => (
                  <th
                    className={`px-3 py-2
                  ${col.sortable ? "cursor-pointer" : ""}`}
                    style={col.headerStyle}
                    key={`header-cell-${i}`}
                    onClick={() => (col.sortable ? onSort(col) : null)}
                  >
                    <div className="flex items-center flex-row">
                      {col.overrideHeader ? (
                        col.overrideHeader()
                      ) : (
                        <KSpan text={col.header || ""} color="#111" fontWeight={500} />
                      )}
                      {getSortDirectionDecoration({
                        ...col,
                        sortDirection: sortParams.field === col.sortFilterField ? sortParams.direction : undefined
                      })}
                    </div>
                  </th>
                ))}
            </tr>
            {filtersShown && (
              <tr className="bg-gray-50 text-xs uppercase leading-4 tracking-wide">
                {columns.map((col, i) => (
                  <th
                    className={`text-gray-500 font-medium text-left px-[15px] pb-[2px] pt-0 w-100`}
                    key={`filter-cell-${i}`}
                  >
                    {col.filter && (
                      <input
                        placeholder={"Show all"}
                        className={"text-left border focus:outline-none px-1"}
                        onChange={(e) => handleFilterChange(col, e.target.value)}
                      />
                    )}
                  </th>
                ))}
              </tr>
            )}
          </thead>
          <tbody>
            {!loading &&
              data?.map((row, i) => {
                const isInactive = isRowInactive(i, inactiveRows) 
                return (
                  <tr
                    className={`rounded-[12px] h-[64px] hover:bg-[#F7F7F7]
                      ${selectedRowIndex === i ? "!bg-[#F3F3F3]" : ""}
                      ${isInactive ? "bg-[#F9F9F9]" : ""}
                      `}
                    style={{
                      cursor: onSelectRow ? "pointer" : "default"
                    }}
                    key={`data-row-${i}`}
                    onMouseEnter={() => {
                      if (onMouseEnterRow) {
                        onMouseEnterRow(row, i)
                      }
                    }}
                    onMouseLeave={() => {
                      if (onMouseLeaveRow) {
                        onMouseLeaveRow(row, i)
                      }
                    }}
                    onClick={(event: React.MouseEvent<HTMLTableRowElement>) => {
                      const target = event.target as HTMLElement
                      if (target && target.closest(".no-parent-trigger")) return
                      onClickRow(i, row)
                    }}
                  >
                    {columns.map((col, j) => {
                      return (
                        <td
                          style={{
                            ...col.cellStyle,
                            borderTopLeftRadius: j === 0 ? "12px" : "0px",
                            borderBottomLeftRadius: j === 0 ? "12px" : "0px",
                            borderTopRightRadius: j === columns.length - 1 ? "12px" : "0px",
                            borderBottomRightRadius: j === columns.length - 1 ? "12px" : "0px"
                          }}
                          className={`p-3`}
                          key={`data-cell-${i}-${j}`}
                        >
                          {renderCell(row, i, col, j, hoveredRowId)}
                        </td>
                      )
                    })}
                  </tr>
                )
              })}
            {minRowCount &&
              minRowCount >= data?.length &&
              data?.length !== 0 &&
              Array(loading ? minRowCount : minRowCount - data?.length)
                .fill(0)
                .map((e, i) => i + 1)
                .map((row) => (
                  <tr key={`additional-row-${row}`} className="rounded-lg">
                    {columns.map((col, j) => {
                      return (
                        <td
                          style={col.cellStyle}
                          className={`${tableCellClasses[size]} rounded-t-lg`}
                          key={`additional-data-cell-${row}-${j}`}
                        >
                          <div className={`h-[${rowHeightInPx || 20}px]`}>
                            {loading && (
                              <div className="flex flex-row items-center justify-start h-full space-x-5 animate-pulse">
                                <div style={{ width: "100%" }} className={`h-[20px] bg-gray-300 rounded-md`} />
                              </div>
                            )}
                          </div>
                        </td>
                      )
                    })}
                  </tr>
                ))}
          </tbody>
        </table>
        {data?.length === 0 && !loading && (
          <div className="flex justify-center mt-12">
            <NotFound
              title={props.notFoundTitle || "No Data"}
              description={props.notFoundDesc || "No data found matching the applied filters."}
              imageWidth={225}
            />
          </div>
        )}
      </div>
      <Pagination
        key={paginationKey ? `${paginationKey}` : ""}
        hidden={!pagination || !data || data.length === 0 || limit >= count}
        size={size}
        pageCount={Math.ceil(count / limit)}
        currentPage={currentPage}
        onClickPage={onClickPagination}
      />
    </div>
  )
}

export default TableNew
