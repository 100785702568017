import { KButton, KInput, KTitleSpan } from "kahuna-base-react-components"
import React, { FC, useEffect, useRef, useState } from "react"
import AddItemCard from "../AddItemCard"
import CardNew from "../CardNew"
import NotFound from "../../NotFound"
import ConfirmationDialog from "../modals/ConfirmationDialog"
import AddItemModal from "../modals/AddItemModal"
import { releases } from "../../../apis"
import { toast } from "react-toastify"
import { errorFilledToastOptions, successFilledToastOptions } from "../../../constants"
import { mapListToCamelCase } from "../../../utility"
import { lang } from "../../../constants/languages"

type CardTableProps = {
  title: string
  urlBase: "upc_codes" | "isrc_codes" | "state_51_batch_ids"
  dataKey: string
  itemName: string
  dataName: string
  iconPath: string
}

type DataType = {
  id: number
  releaseId: number
  [key: string]: string | number
}

const CardTable: FC<CardTableProps> = (props) => {
  const { title, urlBase, itemName, dataKey, dataName, iconPath } = props

  const [openAddItemModal, setOpenAddItemModal] = useState<boolean>(false)

  const scrollRef = useRef<HTMLDivElement>(null)
  const [timeoutId, setTimeoutId] = useState(undefined)
  const [loading, setLoading] = useState(false)
  const isFetchingDataRef = useRef(false)
  const [idToDelete, setIdToDelete] = useState<number | undefined>(undefined)
  const [count, setCount] = useState<number>(1)
  const [availableCount, setAvailableCount] = useState<number>(1)
  const [limit, setLimit] = useState(10)
  const [valuesToAdd, setValuesToAdd] = useState<string>("")
  const [searchText, setSearchText] = useState<string>("")
  const [pageNumber, setPageNumber] = useState<number>(1)
  const [data, setData] = useState<DataType[]>([])

  const scrollToBottom = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollIntoView({ behavior: "smooth" })
    }
  }

  const onDelete = () => {
    if (!idToDelete) return

    setLoading(true)

    releases(`/release/asset_registry_${urlBase}_delete/?id=${idToDelete}`, "delete")
      .then((resp) => {
        if (resp.data.success) {
          setData(data.filter((row) => row.id !== idToDelete))
          toast.success(`ID: ${idToDelete} is successfully deleted.`, successFilledToastOptions)
          setCount(count - 1)
          setAvailableCount(availableCount - 1)
        } else {
          toast.error(`Error: ${resp.data.message}`, errorFilledToastOptions)
        }
        setLoading(false)
        setIdToDelete(undefined)
      })
      .catch(() => {
        setLoading(false)
        toast.error(`Only super admins have permission for this action.`, errorFilledToastOptions)
      })
  }

  const handleClose = () => {
    setOpenAddItemModal(false)
    setValuesToAdd("")
  }

  const onAddNew = () => {
    setLoading(true)

    if (!valuesToAdd) {
      setLoading(false)
      return
    }

    const writtenItems = valuesToAdd.replace(/\s+/g, "").split(",")

    releases(`/release/asset_registry_${urlBase}_add/`, "post", { [urlBase]: writtenItems })
      .then((resp) => {
        if (resp.data.results && resp.data.results.length > 0) {
          setData([...mapListToCamelCase(resp.data.results), ...data])
          setLoading(false)
          handleClose()
          const addedCount = resp.data.results.length
          toast.success(`${addedCount} ${dataName}(s) have been successfully added.`, successFilledToastOptions)
          setCount(count + addedCount)
          setAvailableCount(availableCount + addedCount)
        } else {
          setLoading(false)
          setOpenAddItemModal(false)
          toast.error(resp.data.message, errorFilledToastOptions)
        }
      })
      .catch(() => {
        setLoading(false)
        toast.error(`Only super admins have permission for this action.`, errorFilledToastOptions)
      })
  }

  const fetchData = (pageNumber: number, search: string) => {
    setLoading(true)
    isFetchingDataRef.current = true
    const searchContent = search !== "" ? `&search=${search}` : ""
    releases(
      `/release/asset_registry_${urlBase}/?offset=${(pageNumber - 1) * limit}&limit=${limit}${searchContent}`,
      "get"
    ).then((resp) => {
      if (pageNumber === 1) {
        setData(mapListToCamelCase(resp.data.results))
        setCount(resp.data.count)
        setAvailableCount(resp.data.available)
      } else {
        setData([...data, ...mapListToCamelCase(resp.data.results)])
      }
      setLoading(false)
    })
  }

  const onSearch = (pageNumber: number, search: string) => {
    if (timeoutId) {
      clearTimeout(timeoutId)
    }
    setTimeoutId(
      //@ts-ignore
      setTimeout(async () => {
        fetchData(pageNumber, search)
      }, 1000)
    )
  }

  useEffect(() => {
    fetchData(1, "")
  }, [])

  useEffect(() => {
    if (isFetchingDataRef.current) {
      const timeOut = setTimeout(() => {
        scrollToBottom()
        isFetchingDataRef.current = false
      }, 100)
      return () => {
        clearTimeout(timeOut)
      }
    }
  }, [data])

  return (
    <div className="w-full">
      <div className="flex flex-row justify-between items-center py-4 px-6">
        <div className="flex flex-row items-center">
          <div className="w-8 h-8 p-1.5 flex items-center justify-center shrink-0">
            <img src={iconPath} alt="card-table-icon" />
          </div>
          <div className="flex flex-row items-center pt-0.5 whitespace-nowrap">
            <KTitleSpan
              text={`${title} (${availableCount} / ${count})`}
              fontSize={20}
              color="#111"
              lineHeight={"28px"}
              fontWeight={500}
            />
          </div>
        </div>
        <div className="flex gap-3 items-center grow justify-end pl-5">
          <div className="w-[90%] max-w-[300px]">
            <KInput
              onChange={(text: string) => {
                setSearchText(text)
                setPageNumber(1)
                onSearch(1, text)
              }}
              value={searchText}
              placeholder={lang.reports.search_text_placeholder}
              padding="14px"
              leftIcon="/catalog_icons/search-grey.svg"
              hoverBackground="white"
              background="#F5F5F5"
            />
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-6">
        <div className="flex flex-row flex-wrap gap-6 p-6">
          {data.length > 0 ? (
            <React.Fragment>
              <AddItemCard
                title={`New ${itemName}(s)`}
                onClick={() => {
                  setOpenAddItemModal(true)
                }}
              />
              {data.map((data) => {
                return (
                  <CardNew
                    key={data.id}
                    id={data.id}
                    text={`${dataName}: ${data[dataKey]}`}
                    titleText={`ID: ${data.id?.toString()}`}
                    inUse={data.releaseId ? true : false}
                    onDeleteButtonClick={() => {
                      setIdToDelete(data.id)
                    }}
                  />
                )
              })}
            </React.Fragment>
          ) : (
            !loading && (
              <div className="flex w-full h-full items-center justify-center pt-[150px]">
                <NotFound
                  title={`No ${itemName}`}
                  description={`No ${itemName} containing the searched text was found.`}
                  imageWidth={250}
                />
              </div>
            )
          )}
        </div>
        {data.length < count && data.length !== 0 && (
          <div className="flex justify-center">
            <KButton
              text="Load More"
              width="auto"
              disabled={loading}
              background="#FFF"
              hoverBackground="#F7F7F7"
              onClick={() => {
                setPageNumber(pageNumber + 1)
                fetchData(pageNumber + 1, searchText)
              }}
            />
          </div>
        )}
        <div ref={scrollRef} className="flex h-1 w-full" />
      </div>
      <ConfirmationDialog
        openConfirmation={idToDelete !== undefined}
        setOpenConfirmation={() => setIdToDelete(undefined)}
        loading={loading}
        handleConfirm={onDelete}
        overrideDescription={`This action will delete ID: ${idToDelete}`}
      />
      <AddItemModal
        value={valuesToAdd}
        setValue={setValuesToAdd}
        open={openAddItemModal}
        title={`Add ${itemName}(s)`}
        buttonText={`Write the ${itemName.toLowerCase()}(s)`}
        placeholder={`Please enter the ${itemName}(s) you want to add, separated by commas.(e.g. 1111111111, 2222222222)`}
        onClose={handleClose}
        onChange={setValuesToAdd}
        onConfirm={onAddNew}
        onCancel={handleClose}
        width={550}
      />
    </div>
  )
}
export default CardTable
