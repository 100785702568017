import { KButton, KSpan, KTitleSpan } from "kahuna-base-react-components"
import React from "react"
//@ts-ignore
import TrashIcon from "../../assets/release-registry-icons/trash-white.svg"

const AddItemCard = ({ title, onClick, height }: { title: string; onClick: () => void; height?:string }) => {
  return (
    <div
      className="p-6 flex flex-col gap-4 justify-center items-center rounded-[10px] w-[320px] overflow-visible cursor-pointer hover:!bg-[#F3F3F3]"
      style={{
        backgroundColor: "#F7F7F7",
        transition: "0.3s",
        height: height ? height : "166px"
      }}
      onClick={onClick}
    >
      {" "}
      <img width={36} height={36} src="/account_icons/plus-filled.svg" />
      <div className="flex items-center justify-start">
        <KTitleSpan text={title} fontSize={20} lineHeight="28px" color="#111" />
      </div>
    </div>
  )
}

export default AddItemCard
