import "./styles/AdminCatalog.css"
import adminCatalogColumns from "./adminCatalogColumns"
import React, { FC, useEffect, useRef, useState } from "react"
import { RootState } from "../../../../store"
import { connect } from "react-redux"
import {
  AdminCatalogTableColumnType,
  CatalogProps,
  AdminCatalogSelectedActionParams,
  Release,
  SortParams,
  TableColumn
} from "../../../../types"
import {
  fetchCatalogData,
  selectCatalogFilter,
  updateReleaseLabelCamp,
  updateReleaseStatus,
  deleteRelease
} from "../../../../actions/catalogActions"
import {
  adminCatalogTableColumns,
  RELEASE_FILTER,
  releaseStatusOptions,
  successFilledToastOptions,
  USER_ROLE
} from "../../../../constants"
import { toast } from "react-toastify"
import { KButton, KDropdown, KInput, KSelectRange, KSpan, KTitleSpan, KTooltip } from "kahuna-base-react-components"
import ForceUploadModal from "./modals/ForceUploadModal"
import TableNew from "../../../ui/table/TableNew"
//@ts-ignore
import SparkleIcon from "../../../../assets/admin_icons/music-note-sparkle.svg"
//@ts-ignore
import PlusIcon from "../../../../assets/release-registry-icons/plus.svg"
//@ts-ignore
import EyeIcon from "../../../../assets/admin_icons/eye.svg"
import { useNavigate } from "react-router-dom"
import CustomizeModal from "./modals/CustomizeModal"
import { KSelectOption } from "kahuna-base-react-components/dist/components/KDropdown/KDropdown"
import { formatIsoDate, getDaysInMonth, hashids } from "../../../../utility"
import { DateRangeType } from "kahuna-base-react-components/dist/components/KSelectRange/KSelectRange"
import FilterOptionsDialog from "../../catalog/FilterOptionsDialog"
import { isEqual } from "lodash"
import ConfirmationModal from "./modals/ConfirmationModal"

const AdminCatalog: FC<CatalogProps> = (props) => {
  const [timeoutId, setTimeoutId] = useState(undefined)
  const [loading, setLoading] = useState(true)
  const [pageNumber, setPageNumber] = useState<number>(1)
  const [selectedReleaseToUpload, setSelectedReleaseToUpload] = useState<Release | undefined>(undefined)
  const [openSidebar, setOpenSidebar] = useState<boolean>(true)
  const [hoveredRowId, setHoveredRowId] = useState<number | undefined>(undefined)
  const [openCustomizeModal, setOpenCustomizeModal] = useState<boolean>(false)
  const [searchValue, setSearchValue] = useState<string>("")
  const searchOptions: KSelectOption[] = [
    { label: "Release", value: 0, value2: "title" },
    { label: "Artist", value: 1, value2: "artist" },
    { label: "UPC", value: 2, value2: "upc" },
    { label: "Label", value: 3, value2: "label" }
  ]
  const [selectedSearchOption, setSelectedSearchOption] = useState<KSelectOption>(searchOptions[0])

  const [dateValues, setDateValues] = useState<DateRangeType>([null, null])
  const [dateFilters, setDateFilters] = useState<{ startDate: string | undefined; endDate: string | undefined }>({
    startDate: undefined,
    endDate: undefined
  })

  const [openOtherFilters, setOpenOtherFilters] = useState<boolean>(false)

  const { selectCatalogFilter, selectedCatalogFilter } = props

  const isAdmin = props.loggedInUser?.roleId === USER_ROLE.SuperAdmin

  const navigate = useNavigate()

  const filtersRef = useRef({
    filter: selectedCatalogFilter,
    search: searchValue,
    date: dateFilters
  })

  const [sortParams, setSortParams] = useState<SortParams>(new SortParams())

  const [selectedActionParams, setSelectedActionParams] = useState<AdminCatalogSelectedActionParams>({
    type: undefined,
    id: undefined,
    status: undefined,
    title: undefined,
    checked: false
  })

  const handleConfirm = () => {
    if (!(selectedActionParams.title && selectedActionParams.id)) return
    if (selectedActionParams.type === "labelCampUpdate") {
      onClickLabelCampCheckbox(selectedActionParams.id, selectedActionParams.checked, selectedActionParams.title)
    } else if (selectedActionParams.type === "statusUpdate") {
      onSelectStatus(selectedActionParams.id, selectedActionParams.status || "", selectedActionParams.title)
    } else if (selectedActionParams.type === "releaseDelete") {
      onDelete(selectedActionParams.id, selectedActionParams.title)
    }
    setSelectedActionParams({ type: undefined, id: undefined, status: undefined, title: undefined, checked: false })
    setHoveredRowId(undefined)
  }

  const handleDescription = () => {
    if (!(selectedActionParams.title && selectedActionParams.id)) return
    if (selectedActionParams.type === "labelCampUpdate") {
      return `Are you sure you want "${selectedActionParams.title}" to be ${
        selectedActionParams.checked ? "VISIBLE" : "NOT VISIBLE"
      } to users?`
    } else if (selectedActionParams.type === "statusUpdate") {
      return `Are you sure you want to change status to ${String(selectedActionParams.status).toUpperCase()} for "${
        selectedActionParams.title
      }" ? `
    } else if (selectedActionParams.type === "releaseDelete") {
      return `Are you sure you want to delete "${selectedActionParams.title}" ? `
    }
  }

  const onClickLabelCampCheckbox = (id: number, checked: boolean, title: string) => {
    props.updateReleaseLabelCamp(id, checked).then((resp) => {
      if (resp) {
        toast.success("Successfully updated the label camp status.", successFilledToastOptions)
      }
    })
  }

  const onSelectStatus = (id: number, status: string, title: string) => {
    props.updateReleaseStatus(id, status).then((resp) => {
      if (resp) {
        toast.success("Successfully updated the status.", successFilledToastOptions)
      }
      setHoveredRowId(undefined)
    })
  }

  const onDelete = (id: number, title: string) => {
    props.deleteRelease(id).then((resp) => {
      if (resp) {
        toast.success("Successfully Deleted Release.", successFilledToastOptions)
      }
    })
  }

  const onSubmitBmv = (release: Release) => {
    setSelectedReleaseToUpload({ ...release, forceUploadType: "bmv" })
  }

  const onSubmitMesamMsg = (release: Release) => {
    setSelectedReleaseToUpload({ ...release, forceUploadType: "mesam_msg" })
  }

  const onSubmitEditorialPitching = (release: Release) => {
    setSelectedReleaseToUpload({ ...release, forceUploadType: "editorial_pitching" })
  }

  const onSubmitState51 = (release: Release) => {
    setSelectedReleaseToUpload({ ...release, forceUploadType: "state_51" })
  }

  const allColumns = adminCatalogColumns(
    onSubmitBmv,
    onSubmitMesamMsg,
    onSubmitEditorialPitching,
    onSubmitState51,
    isAdmin,
    setSelectedActionParams
  )

  const [columns, setColumns] = useState<TableColumn[]>(allColumns)

  const [columnsState, setColumnsState] = useState<AdminCatalogTableColumnType[]>(
    localStorage.getItem("adminCatalogTableColumns")
      ? JSON.parse(String(localStorage.getItem("adminCatalogTableColumns")))
      : adminCatalogTableColumns
  )

  const updateColumns = () => {
    const updatedTableColumns: TableColumn[] = []

    columnsState.forEach((column) => {
      const matching = allColumns.find(
        (tableColumn: TableColumn) => column.name === tableColumn.header && column.visible
      )
      if (matching) {
        updatedTableColumns.push(matching)
      }
    })
    const deleteColumn = allColumns.find((column: TableColumn) => column.header === "")
    if (deleteColumn) {
      updatedTableColumns.push(deleteColumn)
    }
    setColumns(updatedTableColumns)
    setOpenCustomizeModal(false)
  }

  const limit = 10

  useEffect(() => {
    updateColumns()

    const timer = setTimeout(() => {
      selectCatalogFilter(isAdmin ? [] : ["active"], RELEASE_FILTER.ALL, [])
    }, 100)
    return () => clearTimeout(timer)
  }, [])

  const fetchCatalog = async (pageNumber: number) => {
    await props.fetchCatalogData(
      limit,
      (pageNumber - 1) * limit,
      0,
      selectedCatalogFilter,
      selectedSearchOption?.value2 === "title" ? searchValue : undefined,
      selectedSearchOption?.value2 === "upc" ? searchValue : undefined,
      selectedSearchOption?.value2 === "artist" ? searchValue : undefined,
      selectedSearchOption?.value2 === "label" ? searchValue : undefined,
      dateFilters.startDate,
      dateFilters.endDate,
      sortParams.field,
      sortParams.direction
    )
  }

  const onClickPage = async (pageNumber: number) => {
    setLoading(true)
    await fetchCatalog(pageNumber)
    setLoading(false)
    setPageNumber(pageNumber)
  }

  useEffect(() => {
    const fetchSync = async () => {
      setLoading(true)
      setPageNumber(1)
      await fetchCatalog(1)
    }
    fetchSync().then(() => setLoading(false))
  }, [selectedCatalogFilter, sortParams.field, sortParams.direction])

  const handleDateChange = () => {
    let start_date: string | undefined = undefined
    let end_date: string | undefined = undefined
    if (dateValues?.[0] && dateValues?.[1]) {
      const startYear = dateValues[0].getFullYear()
      const startMonth = String(dateValues[0].getMonth() + 1).padStart(2, "0")
      start_date = `${startYear}-${startMonth}-01`
      const endYear = dateValues[1].getFullYear()
      const endMonth = String(dateValues[1].getMonth() + 1).padStart(2, "0")
      const end_date_last_day = getDaysInMonth(endYear, dateValues[1].getMonth())
      end_date = `${endYear}-${endMonth}-${end_date_last_day}`
    }
    setDateFilters({ startDate: start_date, endDate: end_date })
  }

  useEffect(() => {
    handleDateChange()
  }, [dateValues])

  const onSearchRelease = (search: string, searchKey: string) => {
    clearTimeout(timeoutId)
    setTimeoutId(
      // @ts-ignore
      setTimeout(async () => {
        setLoading(true)
        setPageNumber(1)
        await props.fetchCatalogData(
          limit,
          0,
          0,
          selectedCatalogFilter,
          searchKey === "title" ? search : undefined,
          searchKey === "upc" ? search : undefined,
          searchKey === "artist" ? search : undefined,
          searchKey === "label" ? search : undefined,
          dateFilters.startDate,
          dateFilters.endDate,
          sortParams.field,
          sortParams.direction
        )
        setLoading(false)
      }, 800)
    )
  }

  const onDateChange = async () => {
    const currentParams = {
      filter: selectedCatalogFilter,
      search: searchValue,
      date: dateFilters
    }
    if (!isEqual(currentParams, filtersRef.current)) {
      setLoading(true)
      setPageNumber(1)
      await fetchCatalog(1)
      setLoading(false)
      filtersRef.current = currentParams
    }
  }

  useEffect(() => {
    onDateChange()
  }, [dateFilters.startDate, dateFilters.endDate])

  const renderFilters = () => (
    <div className="flex gap-3">
      <div
        className="flex items-center py-[13px] pl-3.5 pr-3 gap-2 rounded-[10px] w-[300px]"
        style={{
          background: searchValue ? "#FFF" : "#F7F7F7",
          border: "1px solid #F3F3F3",
          height: "48px",
          transition: "0.3s"
        }}
      >
        <KInput
          value={searchValue}
          padding="0px"
          background="transparent"
          onChange={(text: string) => {
            setSearchValue(text)
            onSearchRelease(text, selectedSearchOption?.value2 || "")
          }}
          leftIcon="/catalog_icons/search-grey.svg"
          gap="8px"
          borderRadius={0}
          shadowDisabled
          placeholder={"Search..."}
        />
        <KDropdown
          rightIcon="/admin_icons/caret-down.svg"
          defaultValuePrimitive={searchOptions[0]?.value2}
          options={searchOptions}
          onSelect={(selected) => {
            setSelectedSearchOption(selected as KSelectOption)
          }}
          padding="0px"
          background="transparent"
          shadowDisabled={true}
          enableIndicator={true}
          allowContainerShrink={selectedSearchOption?.value2 === "artist" ? true : false}
          menuWidth={120}
          width={selectedSearchOption?.value2 === "title" ? 100 : 70}
          textColor="#F3F3F3"
        />
      </div>
      <KButton
        leftIcon={EyeIcon}
        background="#F7F7F7"
        hoverBackground="#F3F3F3"
        activeBackground="#F7F7F7"
        onClick={() => {
          setOpenCustomizeModal(true)
        }}
        width="auto"
        height="48px"
        padding="14px"
        text="Customize"
        textColor="#000"
        gap="4px"
      />
      {isAdmin && (
        <KButton
          leftIcon={PlusIcon}
          background="#000"
          hoverBackground="#111"
          activeBackground="#000"
          onClick={() => navigate(`/catalog/new-release`)}
          width="auto"
          height="48px"
          padding="14px"
          text="New Release"
          textColor="#FFF"
          gap="4px"
        />
      )}
    </div>
  )

  const tableTitle = (
    <div className="flex flex-row gap-2 items-center !cursor-default">
      <KTooltip
        backgroundColor="#000"
        padding="0px 4px"
        height="20px"
        arrowColor="#000"
        position="bottom"
        content={
          <div className="h-5 w-max flex items-center">
            <KSpan text={openSidebar ? "Hide sidebar" : "Open sidebar"} fontSize={12} lineHeight="16px" color="#FFF" />
          </div>
        }
      >
        <div className="flex items-center">
          <KButton
            icon="/left-sidebar.svg"
            width="32px"
            height="32px"
            background="transparent"
            shadowDisabled={true}
            padding="6px"
            onClick={() => {
              if (setOpenSidebar) {
                setOpenSidebar(!openSidebar)
              }
            }}
          />
        </div>
      </KTooltip>
      <div className="pt-1">
        {" "}
        <KTitleSpan text="Asset Registry" color="#111" fontSize={20} lineHeight="28px" />
      </div>
    </div>
  )

  const topFilters = (
    <div className="flex flex-row items-center gap-2">
      <KSelectRange
        key={`${dateFilters.startDate}-${dateFilters.endDate}`}
        value={dateValues}
        onChange={(event) => {
          setDateValues(event)
        }}
        height="36px"
        width="auto"
        backgroundColor="#F7F7F7"
        hoverBackgroundColor="#F3F3F3"
        boxShadow="none"
        align={openSidebar ? "center" : "right"}
      />
      {dateFilters.startDate && dateFilters.endDate && (
        <div className="flex flex-row gap-1 h-9 items-center px-1">
          <KSpan
            text={`${formatIsoDate(dateFilters.startDate, true)} - ${formatIsoDate(dateFilters.endDate, true)}`}
            color="#000"
            fontWeight={500}
          />
          <div
            className="cursor-pointer"
            onClick={() => {
              setDateValues([null, null])
            }}
          >
            <img className="rounded-full hover:bg-[#F3F3F3]" src="/admin_icons/close.svg" />
          </div>
        </div>
      )}
      <KButton
        leftIcon="/admin_icons/filters.svg"
        background="#F7F7F7"
        onClick={() => {
          setOpenOtherFilters(true)
        }}
        hoverBackground="#F3F3F3"
        padding="8px"
        height="36px"
        width="auto"
        gap="6px"
        text="Filters"
        shadowDisabled
      />
      <FilterOptionsDialog openFilters={openOtherFilters} setOpenFilters={setOpenOtherFilters} />
    </div>
  )

  return (
    <div className="catalog flex bg-[#fff]">
      <div
        className={`${
          openSidebar ? "min-w-[230px]" : "min-w-0 !w-[0px]"
        } max-w-[320px] w-[20%] bg-[#F7F7F7] min-h-[100vh] flex flex-col overflow-hidden shrink-0`}
        style={{
          transitionDuration: "0.5s"
        }}
      >
        <div className="pl-6 pr-3 py-4 whitespace-nowrap">
          <KTitleSpan text={"Asset Registry"} lineHeight="28px" fontSize={20} color="#111" />
        </div>

        {[{ label: "Overview", value: "overview", icon: SparkleIcon }, ...releaseStatusOptions].map(
          (option, index: number) => {
            const chosenStatus = selectedCatalogFilter.choosenStatus
            const isSelected =
              option.value === "overview" ? chosenStatus.length === 0 : chosenStatus.includes(option.value)
            return (
              <div className="py-1.5 px-4 h-11" key={`${index}`}>
                <div
                  className={`rounded-[10px] p-2 flex flex-row gap-2 ${
                    isSelected ? "bg-[#FFF]" : "transparent"
                  } cursor-pointer whitespace-nowrap`}
                  onClick={() => {
                    let chosenStatus = selectedCatalogFilter.choosenStatus
                    if (option.value === "overview") {
                      chosenStatus = []
                    } else if (option.value !== "overview" && !chosenStatus.includes(option.value.toLowerCase())) {
                      chosenStatus = [...chosenStatus, option.value.toLowerCase()]
                    } else {
                      chosenStatus = chosenStatus.filter((item) => item !== option.value.toLowerCase())
                    }
                    selectCatalogFilter(
                      chosenStatus,
                      selectedCatalogFilter.ownerStatus,
                      selectedCatalogFilter.choosenGenre
                    )
                  }}
                >
                  <img src={option.icon} width={16} height={16} className="shrink-0" />
                  <KSpan text={option.label} fontSize={12} lineHeight="16px" fontWeight={500} color="#111" />
                </div>
              </div>
            )
          }
        )}
      </div>
      <div className="grow flex justify-center overflow-x-auto">
        <div className="w-full">
          {" "}
          <TableNew
            title={tableTitle}
            pagination
            minRowCount={limit}
            limit={limit}
            columns={columns}
            data={props.paginatedReleases.results}
            count={props.paginatedReleases.count}
            topRightElement={renderFilters()}
            topFilters={topFilters}
            onClickPage={onClickPage}
            rowHeightInPx={40}
            loading={loading}
            overrideTableOverflow="auto"
            {...(isAdmin && {
              onSelectRow: (index: number | null, row) => {
                if (isAdmin) {
                  navigate(`${hashids.encode(row?.id?.toString() || "")}`)
                }
              }
            })}
            onMouseEnterRow={(row: any, index: number) => {
              setHoveredRowId(index)
            }}
            onMouseLeaveRow={(row: any, index: number) => {
              if (hoveredRowId === index) {
                setHoveredRowId(undefined)
              }
            }}
            hoveredRowId={hoveredRowId}
            pageNumber={pageNumber}
            sortParamsReader={setSortParams}
            overrideSortParams={true}
            sortParamsValue={sortParams}
            paginationKey={`${sortParams.field}-${sortParams.direction}`}
          />
        </div>
      </div>
      <ForceUploadModal release={selectedReleaseToUpload} setRelease={setSelectedReleaseToUpload} />
      <CustomizeModal
        open={openCustomizeModal}
        setOpen={setOpenCustomizeModal}
        onApprove={updateColumns}
        columnsState={columnsState}
        setColumnsState={setColumnsState}
        sortParams={sortParams}
        setSortParams={setSortParams}
      />
      <ConfirmationModal
        isOpen={selectedActionParams.type !== undefined}
        handleClose={() => {
          setSelectedActionParams({
            type: undefined,
            id: undefined,
            title: undefined,
            status: undefined,
            checked: false
          })
        }}
        handleConfirm={handleConfirm}
        description={handleDescription() || ""}
      />
    </div>
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    user: state.auth.user,
    loggedInUser: state.auth.user,
    paginatedReleases: state.catalogData.paginatedReleases,
    selectedCatalogFilter: state.catalogData.selectedCatalogFilter
  }
}

export default connect(mapStateToProps, {
  fetchCatalogData,
  selectCatalogFilter,
  updateReleaseLabelCamp,
  updateReleaseStatus,
  deleteRelease
})(AdminCatalog)
