import { CollaboratorData, TableColumn } from "../../../../../types"
import {
  TABLE_COLUMN_TYPE,
  collaboratorInvitationStatus
} from "../../../../../constants"
import React, { CSSProperties } from "react"
import { KSpan, KTooltip } from "kahuna-base-react-components"

const cellStyle = { fontWeight: 400, lineHeight: "20px", textColor: "#111827" }
const headerStyle: CSSProperties = { textAlign: "left", width: "25%" }

const trackShareHolderColumns: TableColumn[] = [
  {
    header: "Collaborator Name",
    headerStyle:{...headerStyle, width: "max-content"},
    cellStyle,
    type: TABLE_COLUMN_TYPE.FUNCTION,
    sortable: true,
    sortFilterField: "email",
    render: (row: CollaboratorData) => {
      return (
        <div className="flex w-full">
          <div className="flex flex-row gap-3 items-center">
            <img
              src={row?.photo || "/reports_icons/avatar.svg"}
              alt={`${row.first_name} ${row.last_name}`}
              className="min-w-10 min-h-10 max-h-10 max-w-10 rounded-full"
              width={40}
              height={40}
            />
            <div className="flex flex-col justify-between gap-0.5">
              {(row?.first_name || row?.last_name) && (
                <KSpan text={`${row?.first_name} ${row?.last_name}`} color="#000" fontWeight={500} />
              )}
              <div className="flex">
                <div className="rounded-[4px] bg-[#F7F7F7] p-1 flex justify-start">
                  <KSpan
                    text={row?.email}
                    color="#111"
                    fontSize={11}
                    fontWeight={500}
                    lineHeight="12px"
                    letterSpacing="0.22px"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    }
  },
  {
    header: "Roles",
    type: TABLE_COLUMN_TYPE.FUNCTION,
    headerStyle: { ...headerStyle, width: "30%" },
    cellStyle,
    render: (row: CollaboratorData) => {
      const roles = [...row?.roles]
      return roles?.length > 0 ? (
        <div className="flex flex-row gap-0.5">
          {roles.slice(0, 2).map((role, index: number) => {
            return (
              <div key={`${role}-${index}`} className="rounded-[4px] bg-[#F7F7F7] p-1 flex justify-center items-center">
                <KSpan
                  text={role}
                  color="#111"
                  fontSize={11}
                  fontWeight={500}
                  lineHeight="12px"
                  letterSpacing="0.22px"
                />
              </div>
            )
          })}
          {roles?.length > 2 && (
            <KTooltip
              backgroundColor="#000"
              padding="8px"
              arrowColor="#000"
              position="bottom"
              content={
                <div className="flex flex-col gap-1 items-start">
                  {roles.slice(2, roles?.length).map((role, index: number) => {
                    return (
                      <KSpan
                        key={`${role}-${index}`}
                        text={role}
                        fontSize={12}
                        fontWeight={500}
                        lineHeight="16px"
                        color="#FFF"
                      />
                    )
                  })}
                </div>
              }
            >
              <div className="rounded-[4px] bg-[#F7F7F7] p-1 flex justify-center items-center">
                <KSpan
                  text={`+${roles?.length - 2}`}
                  color="#111"
                  fontSize={11}
                  fontWeight={500}
                  lineHeight="12px"
                  letterSpacing="0.22px"
                />
              </div>
            </KTooltip>
          )}
        </div>
      ) : (
        <div className="flex">
          <div className="rounded-[4px] bg-[#F7F7F7] p-1 flex justify-center items-center">
            <KSpan text={`-`} color="#111" fontSize={11} fontWeight={500} lineHeight="12px" letterSpacing="0.22px" />
          </div>
        </div>
      )
    }
  },
  {
    header: "Share",
    type: TABLE_COLUMN_TYPE.FUNCTION,
    headerStyle: { ...headerStyle, width: "10%" },
    cellStyle,
    render: (row: CollaboratorData) => {
      return (
        <div className="flex">
          <div className="rounded-[4px] bg-[#F7F7F7] p-1 flex justify-center items-center">
            <KSpan
              text={`${row?.share}%`}
              color="#111"
              fontSize={11}
              fontWeight={500}
              lineHeight="12px"
              letterSpacing="0.22px"
            />
          </div>
        </div>
      )
    }
  },
  {
    header: "Status",
    type: TABLE_COLUMN_TYPE.FUNCTION,
    headerStyle: { ...headerStyle, width: "10%" },
    cellStyle,
    attribute: "status",
        render: (row: CollaboratorData) => {
          const statusNumber = row?.status
          const invitationStatus = collaboratorInvitationStatus?.[statusNumber]
          return (
            <div className="flex flex-row gap-1 items-center w-max">
              {invitationStatus && <img className="w-4 h-4 min-w-4 min-h-4" src={invitationStatus?.icon} />}
              <KSpan text={invitationStatus?.text || ""} color="#111" fontWeight={500} />
            </div>
          )
        }
  }
]

export default trackShareHolderColumns
