import React, { useState } from "react"
import navElements from "./listElements/navElements"
import { USER_ROLE } from "../../../constants"
import Accordion from "../../ui/Accordion"
import { KSpan } from "kahuna-base-react-components"
import NavListElement from "./NavListElements"

export interface navElement {
  name: string
  iconPath: string
  href: string
  bgColor: string
  permittedRoles: USER_ROLE[]
  bRadius: number
  type: string
  attributeToCheck?: string
  sublinks?: {
    name: string
    iconPath?: string
    href: string
    permittedRoles: USER_ROLE[]
  }[]
  borderBottom?: boolean
}

const NavSection = ({ user }: any) => {
  // ilk degeri -1 yaptim ki useEffect'te dependency olarak kullanilmasi sorun yaratmasin.
  const [selectedIndex, setSelectedIndex] = useState<number>(-1)

  return (
    <div className="flex flex-col gap-[12px]">
      {navElements.map((nav: navElement, index: number) => {
        const permittedRoles = nav.permittedRoles.includes(user.roleId)
        const attributeCheck = nav.attributeToCheck ? user[nav.attributeToCheck] : true

        return permittedRoles && attributeCheck
          ? (nav.type === "link" && (
              <NavListElement
                index={index}
                selectedIndex={selectedIndex}
                setSelectedIndex={setSelectedIndex}
                key={`navlist-element-${index}`}
                iconPath={nav.iconPath}
                text={nav.name}
                bgColor={nav.bgColor}
                bRadius={nav.bRadius}
                href={nav.name === "Admin" && user.roleId === USER_ROLE.MarketingAdmin ? "/admin/catalog" : nav.href}
              />
            )) ||
              (nav.type === "accordion" && (
                <Accordion
                  key={`navlist-accordion-${index}`}
                  title={
                    <div
                      className={`w-auto flex flex-row gap-[8px] justify-start items-center py-[8px] rounded-[10px]`}
                    >
                      <span className="w-[20px] aspect-square">
                        <img src={nav.iconPath} />
                      </span>
                      <span>
                        <KSpan text={nav.name} color="#111" fontWeight={500} />
                      </span>
                    </div>
                  }
                  content={
                    <div className="p-1">
                      {nav.sublinks!.map((sublink, index2: number) => {
                        return sublink.permittedRoles.includes(user.roleId) ? (
                          <NavListElement
                            index={index + index2}
                            selectedIndex={selectedIndex}
                            setSelectedIndex={setSelectedIndex}
                            key={`navlist-element-accordion-element-${index + index2}`}
                            iconPath={sublink.iconPath || nav.iconPath}
                            text={sublink.name}
                            bgColor={nav.bgColor}
                            bRadius={nav.bRadius}
                            href={`${nav.href}${sublink.href}`}
                          />
                        ) : null
                      })}
                    </div>
                  }
                />
              ))
          : null
      })}
    </div>
  )
}

export default NavSection
