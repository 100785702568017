import { Dialog } from "@headlessui/react"
import { FC } from "react"
import { tableCellClasses } from "../../../../../constants"
import { X } from "heroicons-react"
import * as React from "react"
import { KButton, KSpan, KTitleSpan } from "kahuna-base-react-components"

type ApprovalModalProps = {
  open: boolean
  onClose: () => void
  approve: () => void
  description: string
}
const ApproveAllModal: FC<ApprovalModalProps> = (props) => {
  const { onClose, open, description } = props
  return (
    <Dialog open={open} onClose={onClose} className="relative z-50">
      <div className="fixed inset-0 bg-[rgba(0,0,0,0.3)]" aria-hidden="true" />
      <div className="fixed inset-0 flex items-center justify-center p-4">
        <div className="mt-10 bg-white elev-b-sm rounded-lg overflow-hidden">
          <div
            className={`bg-gray-50 text-xs text-gray-500 font-medium 
              uppercase border-b flex justify-between ${tableCellClasses.sm}`}
          >
            <KTitleSpan
              text="APPROVE REPORT"
              color="#111"
              fontWeight={500}
              fontSize={14}
              lineHeight="20px"
              letterSpacing="0.2px"
            />
            <X className="h-4 mr-[-6px] cursor-pointer" onClick={onClose} />
          </div>
          <div className="w-full max-w-sm m-auto flex-column p-4 text-center">
            <KSpan text={description} fontSize={16} lineHeight="20px" color="#000" />
          </div>
          <div className="flex gap-4 p-4 px-8">
            <KButton
              text={"Cancel"}
              background="#F7F7F7"
              textColor="#000"
              height="48px"
              padding="14px"
              onClick={props.onClose}
            />
            <KButton
              text={"Approve"}
              background="#000"
              textColor="#FFF"
              height="48px"
              padding="14px"
              onClick={props.approve}
            />
          </div>
        </div>
      </div>
    </Dialog>
  )
}

export default ApproveAllModal
