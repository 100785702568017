import { Dialog } from "@headlessui/react"
import React, { FC, useRef, useState } from "react"
import { X } from "heroicons-react"
import { KButton, KSpan, KTextArea, KTitleSpan, KTooltip } from "kahuna-base-react-components"
//@ts-ignore
import UploadCloudIcon from "../../../assets/release-registry-icons/upload-cloud.svg"
import "../styles/Modals.css"
//@ts-ignore
import CodesUploadTemplateFile from "../../../assets/csv-files/Codes-Upload-Template.csv"
//@ts-ignore
import DownloadIcon from "../../../assets/release-registry-icons/download-new.svg"

type AddItemModalProps = {
  value: string
  setValue: (value: string) => void
  open: boolean
  onChange: (text: string) => void
  onClose: () => void
  onConfirm: () => void
  onCancel: () => void
  title?: string
  placeholder?: string
  buttonText?: string
  width?: number
}
const AddItemModal: FC<AddItemModalProps> = (props) => {
  const { onClose, open, onConfirm, onCancel, onChange } = props

  const [uploadFile, setUploadFile] = useState(false)
  const [dragging, setDragging] = useState<boolean>(false)

  const [loading, setLoading] = useState<boolean>(false)
  const fileReaderRef = useRef<FileReader | null>(null) // Ref to store FileReader instance
  const fileInputRef = useRef<HTMLInputElement>(null)
  const [errorInUpload, setErrorInUpload] = useState<boolean>(false)

  const handleUpload = (e: any) => {
    setErrorInUpload(false)
    setLoading(true)
    if (e.target.files.length > 0) {
      const file: File = e.target.files[0]
      if (file) {
        const reader = new FileReader()
        fileReaderRef.current = reader

        reader.onloadend = (e) => {
          if (e.target?.readyState === FileReader.DONE) {
            const text = e.target?.result as string
            const rows = text
              .split("\n") // Split by newlines
              .map((row) => row.split(",")[0]?.trim()) // Get the first column and trim
              .filter((cell) => cell !== undefined && cell !== "")
              .join(", ")
            props.setValue(rows)
          }
        }
        reader.onerror = () => {
          setErrorInUpload(true)
          setLoading(false)
        }

        reader.readAsText(file)
      } else {
        setLoading(false)
      }

      setTimeout(() => {
        setLoading(false)
        setUploadFile(false)
      }, 2000)
    }
  }

  const handleBrowseClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click()
    }
  }

  const handleDragOver = (e: any) => {
    e.preventDefault()
    setDragging(true)
  }

  const handleDragLeave = () => {
    setDragging(false)
  }

  const handleDrop = (e: React.DragEvent) => {
    e.preventDefault()
    setDragging(false)

    const file = e.dataTransfer.files[0]
    if (file) {
      handleUpload({ target: { files: [file] } })
    }
  }

  return (
    <Dialog open={open} onClose={onClose} className="relative">
      <div className="fixed inset-0 bg-[rgba(0,0,0,0.3)]" aria-hidden="true" />
      <div className="fixed inset-0 flex items-center justify-center p-4">
        <div>
          <div className={`flex justify-end`}>
            <X className="h-4 cursor-pointer mt-1" style={{ position: "absolute" }} onClick={onClose} />
          </div>
          <div
            className="bg-white elev-b-sm rounded-lg overflow-hidden flex flex-col gap-4"
            style={{
              overflowY: "auto",
              padding: 42,
              width: props.width || 450
            }}
          >
            <div className="flex justify-between items-center">
              <KTitleSpan text={props.title || "Add Item"} fontSize={32} />
              <KTooltip
                backgroundColor="#000"
                padding="0px 4px"
                height="20px"
                arrowColor="#000"
                showArrow
                position="bottom"
                content={
                  <div className="w-[86px] flex items-center p-1.5">
                    <KSpan
                      text={"Click to download the example file template"}
                      fontSize={12}
                      lineHeight="16px"
                      color="#FFF"
                    />
                  </div>
                }
              >
                <a href={CodesUploadTemplateFile} download={"Codes-Upload-Template"} className="outline-none">
                  <img src={DownloadIcon} alt="download-icon" className="outline-none" />
                </a>
              </KTooltip>
            </div>

            <div className="w-full flex flex-row gap-1 p-1 bg-[#F5F5F5] rounded-full">
              <div
                className={`rounded-full ${uploadFile && "add-shadow"}`}
                style={{
                  width: "calc(50% - 2px)"
                }}
              >
                <KButton
                  text="Upload a file"
                  onClick={() => {
                    setUploadFile(true)
                  }}
                  borderRadius={999}
                  padding="8px 4px"
                  shadowDisabled
                  height="36px"
                  background={uploadFile ? "#FFF" : "#F5F5F5"}
                  textColor={uploadFile ? "#111" : "#989898"}
                />
              </div>
              <div
                className={`flex-grow rounded-full ${!uploadFile && "add-shadow"}`}
                style={{
                  width: "calc(50% - 2px)"
                }}
              >
                <KButton
                  text={props.buttonText || "Write the item(s)"}
                  onClick={() => {
                    setUploadFile(false)
                  }}
                  borderRadius={999}
                  padding="8px 4px"
                  shadowDisabled
                  height="36px"
                  background={!uploadFile ? "#FFF" : "#F5F5F5"}
                  textColor={!uploadFile ? "#111" : "#989898"}
                />
              </div>
            </div>

            {uploadFile ? (
              <div
                className={`bg-[#F5F5F5] hover:bg-[#F0F0F0] rounded-[10px]`}
                style={{
                  display: uploadFile ? "" : "none",
                  backgroundColor: dragging ? "rgba(0, 0, 0, 0.32)" : "",
                  transition: "0.2s"
                }}
              >
                <label
                  htmlFor="file"
                  className={`${dragging ? "bg-[rgba(0, 0, 0, 0.32)]" : "bg-transparent"}
          `}
                  onDragOver={handleDragOver}
                  onDragLeave={handleDragLeave}
                  onDrop={handleDrop}
                >
                  <div className="flex items-center justify-center w-full">
                    {
                      <div className="flex flex-col items-center justify-center gap-5 p-8">
                        <div className={"flex justify-center"} style={{ height: 40 }}>
                          <img src={UploadCloudIcon} />
                        </div>
                        <div className={"flex flex-col gap-1 justify-center items-center"}>
                          <KSpan text={"Choose a file or drag & drop it here."} color={"#111"} fontWeight={500} />
                          <KSpan text={"CSV format"} fontSize={12} lineHeight="16px" />
                        </div>
                        <div
                          style={{
                            pointerEvents: "auto"
                          }}
                        >
                          <KButton
                            text="Browse File"
                            onClick={handleBrowseClick}
                            height="32px"
                            padding="6px 10px"
                            background="#FFF"
                            textColor="#111"
                          />
                        </div>
                      </div>
                    }
                    <input
                      className="hidden"
                      ref={fileInputRef}
                      id="file"
                      type="file"
                      accept=".csv"
                      multiple
                      onChange={handleUpload}
                    />
                  </div>
                </label>
              </div>
            ) : (
              <KTextArea
                value={props.value}
                onChange={onChange}
                placeholder={props.placeholder || "Write your content here..."}
                maxHeight={400}
                height={120}
              />
            )}

            <div className="flex pt-4 gap-2">
              <KButton text="Cancel" background="white" shadowDisabled={true} onClick={onCancel} />
              <KButton
                text="Confirm"
                background="black"
                textColor="white"
                onClick={onConfirm}
                disabled={props.value.length === 0 || loading}
              />
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  )
}

export default AddItemModal
