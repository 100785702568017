import { TableColumn, User } from "../../../../../types"
import { TABLE_COLUMN_TYPE, USER_PERMISSION, USER_ROLE } from "../../../../../constants"
import React from "react"
import { KButton, KSpan, KTooltip } from "kahuna-base-react-components"
import { UserTypeIcon } from "../../../../ui/icons"
//@ts-ignore
import CheckIcon from "../../../../../assets/admin_icons/check-circle.svg"
//@ts-ignore
import CloseIcon from "../../../../../assets/admin_icons/close-circle.svg"
import AdminUserMenu from "../menu/AdminUserMenu"
import { iconColor } from "../../../../../utility"


const permissionsLabels = {
  [USER_PERMISSION.NEW_RELEASE]: "New Release",
  [USER_PERMISSION.GET_ADVANCE]: "Get Advance",
  [USER_PERMISSION.UPLOAD_MSG]: "Upload MSG",
  [USER_PERMISSION.UPLOAD_MESAM]: "Upload MESAM",
  [USER_PERMISSION.UPLOAD_BMV]: "Upload BMV",
  [USER_PERMISSION.UPLOAD_STATE51]: "Upload STATE51"
}

const adminUserColumns = (
  isSuperAdmin: boolean,
  setModalOption: (option: number | undefined) => void,
  selectUser: (user: User) => void,
  setAddEditUserModalOpen: (open: boolean) => void
): TableColumn[] => {
  return [
    {
      header: "Username",
      type: TABLE_COLUMN_TYPE.FUNCTION,
      render: (row: User) => (
        <div className="flex w-full">
          <div className="flex flex-row gap-3 items-center">
            <img
              src={row?.photo || "/reports_icons/avatar.svg"}
              alt="profile-photo"
              className="min-w-10 min-h-10 max-h-10 max-w-10 rounded-full"
              width={40}
              height={40}
            />
            <div className="flex flex-col justify-between gap-0.5">
              {(row?.firstName || row?.lastName) && (
                <KSpan text={`${row.firstName} ${row.lastName}`} color="#000" fontWeight={500} />
              )}
              <div className="flex">
                <div className="rounded-[4px] bg-[#F7F7F7] p-1 flex justify-center">
                  <KSpan
                    text={row?.username}
                    color="#111"
                    fontSize={11}
                    fontWeight={500}
                    lineHeight="12px"
                    letterSpacing="0.22px"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      ),
      sortable: true,
      sortFilterField: "username"
    },
    {
      header: "Takedown",
      type: TABLE_COLUMN_TYPE.FUNCTION,
      render: (row: User) => {
        return (
          <div className="flex">
            <div className="rounded-[4px] bg-[#F7F7F7] p-1 flex">
              <KSpan
                text={`CREDIT: ${row?.userCredit?.takedown || "0"}`}
                color="#111"
                fontSize={11}
                fontWeight={500}
                lineHeight="12px"
                letterSpacing="0.22px"
              />
            </div>
          </div>
        )
      }
    },
    {
      header: "Urgent New Release",
      type: TABLE_COLUMN_TYPE.FUNCTION,
      render: (row: User) => {
        return (
          <div className="flex">
            <div className="rounded-[4px] bg-[#F7F7F7] p-1 flex whitespace-nowrap">
              <KSpan
                text={`CREDIT: ${row?.userCredit?.urgentNewRelease || "0"}`}
                color="#111"
                fontSize={11}
                fontWeight={500}
                lineHeight="12px"
                letterSpacing="0.22px"
              />
            </div>
          </div>
        )
      }
    },
    {
      header: "Permissions",
      type: TABLE_COLUMN_TYPE.FUNCTION,
      render: (row: User) => {
        return (
          <div className="flex w-max whitespace-nowrap">
            {row?.permissionUser && row?.permissionUser?.length > 0 ? (
              <KTooltip
                backgroundColor="#000"
                padding="8px"
                arrowColor="#000"
                position="bottom"
                content={
                  <div className="flex flex-col gap-1 items-start">
                    {row.permissionUser.map((p, index: number) => {
                      return (
                        <KSpan
                          key={`${row.username}-${index}`}
                          text={permissionsLabels?.[p?.permission?.id]}
                          fontSize={12}
                          lineHeight="16px"
                          color="#FFF"
                        />
                      )
                    })}
                  </div>
                }
              >
                <div className="rounded-[4px] bg-[#F7F7F7] p-1 flex">
                  <KSpan
                    text={`${row.permissionUser.length}`}
                    color="#111"
                    fontSize={11}
                    fontWeight={500}
                    lineHeight="12px"
                    letterSpacing="0.22px"
                  />
                </div>
              </KTooltip>
            ) : (
              <div className="rounded-[4px] bg-[#F7F7F7] p-1 flex">
                <KSpan
                  text={"0"}
                  color="#111"
                  fontSize={11}
                  fontWeight={500}
                  lineHeight="12px"
                  letterSpacing="0.22px"
                />
              </div>
            )}
          </div>
        )
      }
    },
    {
      header: "User Type",
      type: TABLE_COLUMN_TYPE.FUNCTION,
      sortable: true,
      sortFilterField: "role__name",
      render: (row: User) => {
        const color = iconColor(row?.role?.id || 0)
        return (
          <div className="flex flex-row gap-1 items-center">
            <div className="w-4 h-4 min-h-4 min-w-4">{UserTypeIcon(color)}</div>
            <KSpan text={row?.role?.name || ""} color="#111" fontWeight={500} />
          </div>
        )
      }
    },
    {
      header: "New Release",
      type: TABLE_COLUMN_TYPE.FUNCTION,
      render: (row: User) => (
        <div className="flex">
          <img
            className="max-w-5 min-w-5"
            src={
              row?.permissionUser?.find((p) => p?.permission?.id === USER_PERMISSION.NEW_RELEASE)
                ? CheckIcon
                : CloseIcon
            }
            width={20}
            height={20}
          />
        </div>
      )
    },
    {
      header: "",
      type: TABLE_COLUMN_TYPE.FUNCTION,
      headerStyle: {
        textAlign: "center",
        minWidth: "60px",
        width: "60px"
      },
      render: (row: User) => (
        <div className="flex flex-row gap-1 items-center no-parent-trigger">
          <div className="flex items-center no-parent-trigger">
            <KButton
              icon="/admin_icons/edit.svg"
              padding="8px"
              width="36px"
              height="36px"
              background="#FFF"
              border="1px solid #F3F3F3"
              onClick={() => {
                selectUser(row)
                setAddEditUserModalOpen(true)
              }}
            />
          </div>
          {isSuperAdmin && (
            <div
              className="flex no-parent-trigger"
              onClick={() => {
                selectUser(row)
              }}
            >
              <AdminUserMenu setModal={setModalOption} user={row} />
            </div>
          )}
        </div>
      )
    }
  ]
}

export default adminUserColumns
