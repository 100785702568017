import React from "react"
import CardTable from "../../../ui/table/CardTable"

const State51BatchIds = () => {

  return (
    <div className="flex justify-center">
      <CardTable title={`State 51 Batch IDs`} urlBase="state_51_batch_ids" dataName="Batch ID" dataKey="batchId" itemName="Batch ID" iconPath="/music-library.svg"/>
    </div>
  )
}

export default State51BatchIds
