import * as React from "react"
import { connect } from "react-redux"
import { RootState } from "../../../../../store"
import { FC, useEffect, useState } from "react"
import { AdminCollaboratorFormProps, User, AdminCollaboratorShareForm } from "../../../../../types"
import { saveCollaboratorForm, selectCollaboratorData, selectUser } from "../../../../../actions/adminActions"
import { roleOptions, ROLES } from "../../../../../constants"
import { handleAuthorizedRequest, users } from "../../../../../apis"
import { uniqBy } from "lodash"
import { mapListToCamelCase } from "../../../../../utility"
import adminCollaboratorValidator from "./validators/adminCollaboratorValidator"
import { KButton, KDropdown, KInput, KSpan, KTitleSpan } from "kahuna-base-react-components"
import { lang } from "../../../../../constants/languages"
import { Dialog } from "@headlessui/react"
import { KSelectOption } from "kahuna-base-react-components/dist/components/KDropdown/KDropdown"
import { MultiValue } from "react-select"
import ToggleInput from "../../../../ui/components/ToggleInput"

const AdminCollaboratorForm: FC<AdminCollaboratorFormProps> = (props) => {
  const [userOptions, setUserOptions] = useState<KSelectOption[]>([])
  const [userLoading, setUserLoading] = useState(false)
  const [timeoutId, setTimeoutId] = useState(undefined)
  const [searchedUsers, setSearchedUsers] = useState<User[]>([])
  const [isUnregisteredUser, setIsUnregisteredUser] = useState(false)

  const handleClose = () => {
    props.onClose()
    setUserOptions([])
    setIsUnregisteredUser(false)
  }

  const createCollaboratorFormFromData = (): AdminCollaboratorShareForm => {
    if (!props.selectedCollaboratorData) return new AdminCollaboratorShareForm()
    return {
      email: props.selectedCollaboratorData.email,
      roles: props.selectedCollaboratorData.role_ids,
      share: props.selectedCollaboratorData.share,
      track_id: props.track_id,
      name: `${props.selectedCollaboratorData.first_name} ${props.selectedCollaboratorData.last_name}`
    }
  }
  const [collaboratorData, setCollaboratorData] = useState<AdminCollaboratorShareForm>(createCollaboratorFormFromData())

  useEffect(() => {
    setCollaboratorData(createCollaboratorFormFromData())
  }, [props.selectedCollaboratorData])

  const onFormSubmit = async (values: AdminCollaboratorShareForm) => {
    props.saveCollaboratorForm({
      ...values,
      track_id: props.track_id,
      name: isUnregisteredUser
        ? "New Collaborator"
        : `${props.selectedCollaboratorData?.first_name} ${props.selectedCollaboratorData?.last_name}`
    })
  }

  const onSearchTextChanged = async (text: string) => {
    if (!text) return
    setUserOptions([])
    setUserLoading(true)
    clearTimeout(timeoutId)
    setTimeoutId(
      // @ts-ignore
      setTimeout(async () => {
        await handleAuthorizedRequest(async () => {
          const response = await users(`/?username=${text}&limit=10`, "get")
          const usersList = mapListToCamelCase(response.data.results)
          setSearchedUsers(usersList)
          if (usersList.length === 0) setIsUnregisteredUser(true)
          setUserOptions(
            usersList.length > 0
              ? usersList.map((user, index: number) => ({
                  value: -1,
                  label: user.username,
                  value2: user.username
                }))
              : [
                  {
                    value: -1,
                    label: text,
                    value2: text 
                  }
                ]
          )
          setUserLoading(false)
        }, "")
      }, 500)
    )
  }

  useEffect(() => {
    if (props.selectedCollaboratorData?.email) {
      // value is given as same number for each one. value2 will be used for checking
      setUserOptions(
        uniqBy(
          [
            ...userOptions,
            { value: -1, value2: props.selectedCollaboratorData?.email, label: props.selectedCollaboratorData?.email }
          ],
          "value"
        )
      )
    }
  }, [props.selectedCollaboratorData])


  return (
    <Dialog open={props.open} onClose={handleClose}>
      <div className="fixed w-screen h-screen top-0 left-0 z-250 flex items-center justify-center bg-[#0000004d] ">
        <div className="p-6 shadow-md w-[440px] rounded-[10px] bg-[#fff]">
          <div className="flex flex-col gap-6">
            <div className="flex flex-col gap-6 items-center justify-center">
              <span
                className="w-[72px] aspect-square rounded-full flex justify-center items-center"
                style={{
                  border: "1px solid #F3F3F3"
                }}
              >
                <img className="w-8 h-8" src="/admin_icons/user-gray.svg" />
              </span>
              <KTitleSpan text={"Collaborator Information"} fontSize={20} lineHeight="28px" color="#000" />
            </div>
            <div className="flex flex-row items-center gap-1"></div>
            <div className="flex flex-col gap-2">
              <KDropdown
                key={`${collaboratorData?.email}`}
                placeholder={"Search user"}
                padding="14px"
                gap="8px"
                menuWidth={400}
                menuLeftMargin={-40}
                selected={userOptions.find((userOption) => userOption?.value2 === collaboratorData?.email)}
                onInputChange={onSearchTextChanged}
                options={userOptions}
                isClearable={true}
                isEllipsis={true}
                onSelect={(selectedOption) => {
                  const selected = selectedOption as KSelectOption
                  if (!selected || !selected?.value2) {
                    setCollaboratorData({ ...collaboratorData, email: undefined })
                  } else {
                    setCollaboratorData({
                      ...collaboratorData,
                      email: selected?.value2
                    })
                  }
                }}
                leftIcon="/admin_icons/user-gray.svg"
                rightIcon="/admin_icons/caret-down.svg"
                enableIndicator
              />
              {isUnregisteredUser && (
                <div className="flex flex-row items-center gap-2 pl-1">
                  <img src="/admin_icons/alert-triangle.svg" className="w-5 h-5 min-w-5 min-h-5" />
                  <KSpan text="Adding an unregistered collaborator" color="#111" />
                </div>
              )}
              {collaboratorData?.roles?.includes(ROLES.MAIN_OWNER) && (
                <div className="flex items-center gap-1 p-0.5">
                  <ToggleInput
                    checked={true}
                    handleChange={() => {
                      // do nothing, it will be disabled
                    }}
                    disabled={true}
                  />
                  <KSpan text="Main owner" color="#000" fontWeight={500} />
                </div>
              )}
              {/*Normally completeRoleOptions were using but MainOwner was disabled,
                  so I changed it with roleOptions because in it there is no MainOwner option.
                  It is more clear. If there is MAIN_OWNER in roles, it will be show with ToggleInput,
                  changing it is not allowed and giving MAIN_OWNER role to a new created collaborator
                  is not allowed as well.*/}
              <KDropdown
                placeholder={"Select roles"}
                padding={"14px"}
                gap="8px"
                menuLeftMargin={-40}
                isMulti
                isEllipsis
                selected={[...roleOptions].filter((roleOption) => collaboratorData?.roles?.includes(roleOption?.value))}
                onInputChange={onSearchTextChanged}
                options={roleOptions}
                onSelect={(selectedOptions) => {
                  const selectedRoles = selectedOptions as MultiValue<KSelectOption>
                  if (!selectedOptions || selectedRoles?.length === 0) {
                    setCollaboratorData({ ...collaboratorData, roles: [] })
                  } else {
                    const roles = selectedRoles.map((selectedOption) => selectedOption?.value)
                    setCollaboratorData({ ...collaboratorData, roles })
                  }
                }}
                leftIcon="/admin_icons/user-circle.svg"
                rightIcon="/admin_icons/caret-down.svg"
                enableIndicator
              />
              <KInput
                placeholder="User share"
                padding="14px"
                value={collaboratorData?.share || ""}
                onChange={(text: string) => {
                  setCollaboratorData({ ...collaboratorData, share: text })
                }}
                leftIcon="/admin_icons/users.svg"
              />
            </div>
            <div className="flex gap-3 justify-center items-center">
              <KButton text={lang.common.cancel} onClick={handleClose} background="#fff" shadowDisabled={true} />
              <KButton
                text={lang.common.save}
                textColor="white"
                background="black"
                onClick={() => {
                  const errors = adminCollaboratorValidator(collaboratorData)

                  if (Object.keys(errors).length === 0) {
                    onFormSubmit(collaboratorData).then(() => {
                      handleClose()
                    })
                  }
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    selectedCollaboratorData: state.admin.selectedCollaboratorData,
    track_id: state.admin.selectedTrackShareData.trackDetail.id
  }
}

export default connect(mapStateToProps, { selectCollaboratorData, selectUser, saveCollaboratorForm })(
  AdminCollaboratorForm
)
