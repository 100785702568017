import { KButton, KSpan, KTitleSpan } from "kahuna-base-react-components"
import React from "react"
//@ts-ignore
import TrashIcon from "../../assets/release-registry-icons/trash-white.svg"

const CardNew = ({
  id,
  text,
  titleText,
  inUse,
  onDeleteButtonClick,
  imageUrl
}: {
  id: number
  text: string
  titleText: string
  inUse: boolean
  onDeleteButtonClick: () => void
  imageUrl?: string
}) => {
  return (
    <div
      className="p-6 flex flex-col gap-4 rounded-[10px] w-[320px] overflow-visible"
      style={{
        border: "1px solid #F3F3F3",
        boxShadow: "0px 1px 2px 0px rgba(183, 183, 183, 0.24)",
        backgroundColor: "#FFF",
        height: imageUrl ? "184px" : "166px"
      }}
    >
      <div className="flex justify-between items-center">
        <div className="flex rounded-[4px] bg-[#F7F7F7] gap-1 w-auto p-1">
          <KSpan text={text} color="#111" fontSize={11} lineHeight="12px" letterSpacing="0.22px" fontWeight={500} />
        </div>
        <div className="flex flex-row items-center gap-1" style={{ height: imageUrl ? "40px" : "auto" }}>
          {imageUrl ? (
            <img src={imageUrl} width={40} />
          ) : (
            <React.Fragment>
              <img src={inUse ? "/reports_icons/check-green.svg" : "/reports_icons/check.svg"} width={16} height={16} />
              <KSpan text={inUse ? "In Use" : "Available"} color="#111" fontWeight={500} />
            </React.Fragment>
          )}
        </div>
      </div>
      <div className="flex items-center justify-start">
        <KTitleSpan text={titleText} fontSize={20} lineHeight="28px" color="#111" />
      </div>
      <div className="flex items-center justify-start">
        <KButton
          text={"Delete"}
          textColor="#FFF"
          leftIcon={TrashIcon}
          padding="8px"
          height="36px"
          width="auto"
          borderRadius={10}
          gap="4px"
          background="#FF5865"
          onClick={() => {
            onDeleteButtonClick()
          }}
        />
      </div>
    </div>
  )
}

export default CardNew
