import * as React from "react"
import { FC, useEffect, useState } from "react"
import {
  AnalysisSummaries,
  AnalysisSummary,
  CurrencyExchange,
  ReportsApprovePaymentRequest,
  SortParams
} from "../../../../types"
import { connect } from "react-redux"
import { RootState } from "../../../../store"
import {
  ANALYSIS_FILTER,
  analysisFilterOptions,
  errorFilledToastOptions,
  successFilledToastOptions
} from "../../../../constants"
import {
  reportsApprovalAllAccountColumns,
  reportsApprovalAllPlatformTotalColumns,
  reportsApprovalPlatformColumns,
  reportsApprovalStoreFrontColumns,
  reportsApprovalTrackColumns
} from "./columns/reportsApprovalColumns"
import { approveSummary, fetchApprovalSummaries, selectFilter } from "../../../../actions/adminActions"
import {
  currencyExchange,
  handleAuthorizedRequest,
  summaries as summariesApi,
  userPayments,
  users
} from "../../../../apis"
import ApproveModal from "./modal/ApproveModal"
import { toast } from "react-toastify"
import { createDateObject, formatDateObject, mapListToCamelCase, mapToSnakeCase } from "../../../../utility"
import { sum } from "lodash"
import ApproveAllModal from "./modal/ApproveAllModal"
import ApprovePaymentModal from "./modal/ApprovePaymentModal"
import CurrencyExchangeModal from "./modal/CurrenyExchangeModal"
import { KButton, KSpan, KDropdown, KTitleSpan, KTooltip, KSelectDate } from "kahuna-base-react-components"
import { KSelectOption } from "kahuna-base-react-components/dist/components/KDropdown/KDropdown"
import TableNew from "../../../ui/table/TableNew"
//@ts-ignore
import CalendarIcon from "../../../../assets/admin_icons/calendar-dark.svg"
//@ts-ignore
import WalletIcon from "../../../../assets/admin_icons/wallet.svg"
//@ts-ignore
import MoneyIcon from "../../../../assets/admin_icons/money-cash.svg"

type ReportsApprovalProps = {
  selectFilter: (filter: string) => void
  summaries: AnalysisSummaries
  selectedFilter: string
  fetchApprovalSummaries: (
    type: string,
    filters?: { [key: string]: string | number | boolean },
    sortField?: string,
    sortDirection?: "asc" | "desc"
  ) => Promise<void>
  approveSummary: (summary: AnalysisSummary, type: string) => Promise<void>
}

const currencyOptions = [
  { label: "GBP", value: 1, value2: "GBP" },
  { label: "TRY", value: 2, value2: "TRY" },
  { label: "EUR", value: 3, value2: "EUR" },
  { label: "USD", value: 4, value2: "USD" }
]

const ReportsApproval: FC<ReportsApprovalProps> = (props) => {
  const [loading, setLoading] = useState(true)
  const [timeoutId, setTimeoutId] = useState(null)
  const [periods, setPeriods] = useState<KSelectOption[]>([])
  const [selectedPeriod, setSelectedPeriod] = useState<string>("")
  const [selectedPeriodDate, setSelectedPeriodDate] = useState<Date | undefined>(undefined)
  const [data, setData] = useState<AnalysisSummary[]>([])
  const [modalOpen, setModalOpen] = useState(false)
  const [approveAllModalOpen, setApproveAllModalOpen] = useState(false)
  const [approvePaymentModalOpen, setApprovePaymentModalOpen] = useState(false)
  const [userPaymentPeriodId, setUserPaymentPeriodId] = useState<number | undefined>()
  const [editedSummary, setEditedSummary] = useState<AnalysisSummary>()
  const [currencyRates, setCurrencyRates] = useState<CurrencyExchange[]>([])
  const [userOptions, setUserOptions] = useState<KSelectOption[]>([])
  const [userLoading, setUserLoading] = useState(false)
  const [selectedUser, setSelectedUser] = useState<KSelectOption | undefined>()
  const [offset, setOffset] = useState(0)
  const [currencyOfferModal, setCurrencyOfferModal] = useState(false)
  const [selectedCurrency, setSelectedCurrency] = useState<string>("GBP")
  const [selectedIds, setSelectedIds] = useState<number[]>([])

  const { approveSummary, fetchApprovalSummaries, selectedFilter, selectFilter, summaries } = props
  const [limit, setLimit] = useState<number>(20)

  const [openSidebar, setOpenSidebar] = useState<boolean>(true)
  const [searchUser, setSearchUser] = useState<boolean>(false)

  const [fetchedSummaries, setFetchedSummaries] = useState<AnalysisSummaries>(summaries)

  useEffect(() => {
    setFetchedSummaries(summaries)
  }, [summaries])

  const isTileDisabled = ({ date }: { date: Date }) => {
    const year = date.getFullYear()
    const month = (date.getMonth() + 1).toString().padStart(2, "0")
    const formattedDate = `${year}${month}` // Format as YYYYMM
    const includedPeriods = periods.map((period) => String(period.value))

    return !includedPeriods.includes(formattedDate) // Disable if not in the array
  }

  const [columns, setColumns] = useState(
    reportsApprovalPlatformColumns(
      selectedIds,
      setSelectedIds,
      [...fetchedSummaries[selectedFilter]].map((data) => data.id),
      selectedUser?.value !== undefined
    )
  )

  useEffect(() => {
    const getSummaryPeriods = async () => {
      await handleAuthorizedRequest(
        async () => {
          const response = await summariesApi("/summary_periods", "get")
          if (response.status === 200) {
            const sortedList = response.data.sort().reverse()
            setPeriods(sortedList.map((period) => ({ label: period, value: period })))
            setSelectedPeriod(sortedList[0])
            const date = createDateObject(sortedList[0])
            setSelectedPeriodDate(date)
          }
        },
        "Error on fetch summery periods.",
        undefined
      )
    }
    getSummaryPeriods().then()
    selectFilter(ANALYSIS_FILTER.TOP_PLATFORMS)
  }, [])

  const handleColumnChange = () => {
    const dataIds = fetchedSummaries[selectedFilter].map((data) => data.id)
    if (selectedFilter === ANALYSIS_FILTER.TOP_PLATFORMS) {
      setColumns(
        reportsApprovalPlatformColumns(selectedIds, setSelectedIds, dataIds, selectedUser?.value !== undefined)
      )
    } else if (selectedFilter === ANALYSIS_FILTER.TOP_TRACKS) {
      setColumns(reportsApprovalTrackColumns())
    } else if (selectedFilter === ANALYSIS_FILTER.TOP_COUNTRIES) {
      setColumns(reportsApprovalStoreFrontColumns())
    } else if (selectedFilter === ANALYSIS_FILTER.ALL_PLATFORM_TOTAL) {
      setColumns(reportsApprovalAllPlatformTotalColumns())
    } else if (selectedFilter === ANALYSIS_FILTER.ALL_ACCOUNTS) {
      setColumns(reportsApprovalAllAccountColumns())
    }
  }
  useEffect(() => {
    handleColumnChange()
  }, [selectedIds])

  useEffect(() => {
    const filteredData = fetchedSummaries[selectedFilter]?.slice(offset, limit + offset)
    handleColumnChange()
    setData(filteredData)
    setUserPaymentPeriodId(undefined)
    if (selectedUser?.value) {
      ;[ANALYSIS_FILTER.TOP_COUNTRIES, ANALYSIS_FILTER.TOP_PLATFORMS, ANALYSIS_FILTER.TOP_TRACKS].forEach((filter) => {
        if (fetchedSummaries[filter].length > 0 && fetchedSummaries[filter][0].paymentPeriodId) {
          setUserPaymentPeriodId(fetchedSummaries[filter][0].paymentPeriodId)
        }
      })
    }
  }, [fetchedSummaries, selectedFilter])

  useEffect(() => {
    const getCurrencies = async () => {
      const currencyResponse = await currencyExchange(`/?date=${selectedPeriod}`, "get")
      if (currencyResponse.status === 200) {
        setCurrencyRates(mapListToCamelCase(currencyResponse.data.results))
      } else {
        toast.error("Error while fetching currencies.", errorFilledToastOptions)
      }
    }
    if (selectedPeriod) {
      getCurrencies().then()
    }
  }, [selectedPeriod])

  useEffect(() => {
    setData(fetchedSummaries[selectedFilter]?.slice(0, limit))
    setOffset(0)
    setSelectedIds([])
  }, [selectedPeriod, selectedFilter])

  useEffect(() => {
    setSelectedIds([])
    const updatedLimit = selectedUser?.value ? 100 : 20
    setLimit(updatedLimit)
  }, [selectedUser])

  const onSortOrFilterChange = async (
    filters: { [key: string]: string | number | boolean },
    sortField: string,
    sortDirection?: "asc" | "desc",
    debounce?: boolean
  ) => {
    if (!selectedUser) {
      delete filters["user__username"]
    }
    setLoading(true)
    if (timeoutId) {
      clearTimeout(timeoutId)
    }

    setTimeoutId(
      //@ts-ignore
      setTimeout(
        () => {
          fetchApprovalSummaries(selectedFilter, filters, sortField, sortDirection).then(() => setLoading(false))
        },
        debounce ? 500 : 0
      )
    )
  }

  const onClickPage = (pageNumber: number) => {
    setData(fetchedSummaries[selectedFilter]?.slice(limit * (pageNumber - 1), limit * pageNumber))
    setOffset((pageNumber - 1) * limit)
  }

  const onSelectRow = (index) => {
    if (index !== null && index >= 0 && selectedFilter === ANALYSIS_FILTER.TOP_PLATFORMS) {
      setEditedSummary(data[index])
      setModalOpen(true)
    }
  }

  const approveReportSummary = async (values: AnalysisSummary) => {
    await approveSummary({ ...values, totalNetAll: values.totalNet }, selectedFilter)
    setModalOpen(false)
  }

  const currencyTooltipContent = (
    <div className="p-4 text-white">
      <KTitleSpan text={`Currency rates for ${selectedPeriod}`} fontSize={14} lineHeight="18px" color="#FFF" />
      <ul className={"pt-1"}>
        {currencyRates.map((currency) => (
          <li className={"mt-1 px-1 text-white"} key={currency.exchangeRates}>
            <KSpan
              text={`- ${currency.currencyTypeDetail.name} : ${currency.exchangeRates}`}
              fontSize={12}
              lineHeight="16px"
              color="#FFF"
            />
          </li>
        ))}
      </ul>
    </div>
  )

  const sumTooltipContent = (
    <div className="text-white p-4">
      <KTitleSpan text={`Totals for ${selectedPeriod}`} fontSize={14} lineHeight="18px" color="#FFF" />
      <ul className={"pt-1"}>
        <li className={"mt-1 px-1 text-white"}>
          <KSpan
            text={`- GROSS : ${sum(data.map((d) => parseFloat(String(d.totalAmount)))).toFixed(2)}`}
            fontSize={12}
            lineHeight="16px"
            color="#FFF"
          />
        </li>
        <li className={"mt-1 px-1 text-white"}>
          <KSpan
            text={`- NET : ${sum(data.map((d) => parseFloat(String(d.totalNet)))).toFixed(2)}`}
            fontSize={12}
            lineHeight="16px"
            color="#FFF"
          />
        </li>
      </ul>
    </div>
  )

  const onSearchTextChanged = async (text: string) => {
    if (!text) return
    setUserOptions([])
    setUserLoading(true)
    if (timeoutId) {
      clearTimeout(timeoutId)
    }
    setTimeoutId(
      //@ts-ignore
      setTimeout(async () => {
        await handleAuthorizedRequest(async () => {
          const response = await users(`/?limit=10&offset=0&username=${text}`, "get")
          setUserOptions(
            response.data.results.map((user) => ({
              value: user.id,
              label: user.username
            }))
          )
          setUserLoading(false)
        }, "")
      }, 500)
    )
  }

  const approveReport = async () => {
    try {
      const response = await summariesApi(`/approve_status/?approve=True&type=all&user=${selectedUser?.value}`, "put", {
        id_list: selectedIds
      })
      if (response.status === 200) {
        setApproveAllModalOpen(false)
        const summaries: AnalysisSummary[] = fetchedSummaries[selectedFilter]
        const updatedSummaries: AnalysisSummary[] = summaries.map((summary: AnalysisSummary) => {
          if (summary.id && selectedIds.includes(summary?.id)) {
            return { ...summary, approved: true }
          } else {
            return { ...summary }
          }
        })
        setSelectedIds([])
        toast.success(response.data, successFilledToastOptions)
        setFetchedSummaries({ ...fetchedSummaries, [selectedFilter]: updatedSummaries })
      }
    } catch {
      toast.error("Error report approve", errorFilledToastOptions)
    }
  }

  const approvePayment = async (paymentDay: string, date: string[]) => {
    if (!selectedUser?.value) {
      return
    }
    const requestBody: ReportsApprovePaymentRequest = {
      userId: selectedUser?.value,
      date,
      paymentDay
    }
    try {
      const response = await userPayments(`/approve_report_payment/`, "post", mapToSnakeCase(requestBody))
      if (response.status === 200) {
        setApprovePaymentModalOpen(false)
        toast.success(`Successfully updated ${response.data.count} records`, successFilledToastOptions)
        const updatedSummaries = fetchedSummaries[selectedFilter].map((summary: AnalysisSummary) => {
          if (date.includes(summary.date) && summary?.approved) {
            return { ...summary, paymentApproved: true }
          } else {
            return { ...summary }
          }
        })
        setFetchedSummaries({ ...fetchedSummaries, [selectedFilter]: updatedSummaries })
      } else {
        toast.error("Error payment approve", errorFilledToastOptions)
      }
    } catch (err) {
      toast.error("Error payment approve", errorFilledToastOptions)
    }
  }

  const topRightFilters = (
    <div className="flex gap-2">
      {(selectedFilter === ANALYSIS_FILTER.ALL_PLATFORM_TOTAL || selectedFilter === ANALYSIS_FILTER.ALL_ACCOUNTS) && (
        <KDropdown
          width={70}
          menuWidth={80}
          options={currencyOptions}
          defaultValuePrimitive={selectedCurrency}
          selected={currencyOptions.find((option) => option.value2 === selectedCurrency)}
          onSelect={(selected: any) => setSelectedCurrency(selected?.value2)}
          rightIcon="/analytics_icons/caret-down-new.svg"
          enableIndicator
        />
      )}
      <KTooltip
        backgroundColor="#000"
        padding="0px 4px"
        arrowColor="#000"
        width="200px"
        position="bottom"
        align="left"
        marginLeft="24px"
        content={sumTooltipContent}
      >
        <div className="w-12 h-12 p-3.5 flex items-center justify-center rounded-[10px] bg-[#F7F7F7] ">
          <img src={WalletIcon} alt="wallet-icon" className="min-w-5 min-h-5" />
        </div>
      </KTooltip>

      {currencyRates.length > 0 && (
        <KTooltip
          backgroundColor="#000"
          padding="0px 4px"
          arrowColor="#000"
          width="200px"
          position="bottom"
          align="left"
          marginLeft="24px"
          content={currencyTooltipContent}
        >
          <KButton
            icon={MoneyIcon}
            onClick={() => setCurrencyOfferModal(true)}
            width="48px"
            height="48px"
            padding="14px"
            background="#F7F7F7"
            hoverBackground="#F3F3F3"
            shadowDisabled
          />
        </KTooltip>
      )}
    </div>
  )

  const topFilters = (
    <div className="flex flex-row justify-between flex-grow">
      <div className="flex flex-row gap-2 items-center">
        {selectedPeriod && (
          <KSelectDate
            key={selectedPeriodDate?.toString()}
            value={selectedPeriodDate}
            onChange={(selected: Date | undefined) => {
              if (!selected) return

              setSelectedPeriodDate(selected)
              const dateAsString = formatDateObject(selected) //YYYYMM
              setSelectedPeriod(dateAsString)
            }}
            onlyMonthSelection
            isTileDisabled={isTileDisabled}
            icon={CalendarIcon}
            height="36px"
            width="auto"
            backgroundColor="#F7F7F7"
            hoverBackgroundColor="#F3F3F3"
            anchorToButton
            buttonText={selectedPeriod}
            hideBody
            boxShadow="none"
            applyUndefinedValue={false}
            align={openSidebar ? "center" : "right"}
          />
        )}
        <div className="relative">
          {searchUser ? (
            <div
              onKeyDown={(event) => {
                if (event.key === "Escape") {
                  setSearchUser(false)
                }
              }}
            >
              <KDropdown
                placeholder={"Search user to filter"}
                padding="8px"
                width={240}
                selected={selectedUser}
                onInputChange={onSearchTextChanged}
                options={userOptions}
                isClearable={true}
                onSelect={(selectedOption) => {
                  const selected = selectedOption as KSelectOption
                  setSelectedUser(selected)
                }}
              />
            </div>
          ) : (
            <div>
              <KButton
                leftIcon="/reports_icons/user.svg"
                gap="4px"
                text="Search user"
                height="36px"
                width="auto"
                background="#F7F7F7"
                hoverBackground="#F3F3F3"
                activeBackground="#F7F7F7"
                padding="8px"
                shadowDisabled
                onClick={() => {
                  setSearchUser(true)
                }}
              />
            </div>
          )}
        </div>
      </div>
      <div className="flex flex-row gap-2">
        {selectedUser?.value && userPaymentPeriodId && selectedFilter === ANALYSIS_FILTER.TOP_PLATFORMS && (
          <KButton
            width="150px"
            padding="8px"
            height="36px"
            text={"Approve Payment"}
            onClick={() => setApprovePaymentModalOpen(true)}
            background="#F2FE67"
            hoverBackground="#F6FE8C"
            activeBackground="#F2FE67"
          />
        )}
        {selectedUser?.value && selectedFilter === ANALYSIS_FILTER.TOP_PLATFORMS && (
          <KButton
            width="150px"
            padding="8px"
            height="36px"
            background="#000"
            hoverBackground="#111"
            activeBackground="#000"
            textColor="#FFF"
            text={"Approve Report"}
            onClick={() => setApproveAllModalOpen(true)}
            disabled={selectedIds.length === 0}
          />
        )}
      </div>
    </div>
  )

  const tableTitle = (
    <div className="flex flex-row gap-2 items-center !cursor-default">
      <KTooltip
        backgroundColor="#000"
        padding="0px 4px"
        height="20px"
        arrowColor="#000"
        position="bottom"
        content={
          <div className="h-5 w-max flex items-center">
            <KSpan text={openSidebar ? "Hide sidebar" : "Open sidebar"} fontSize={12} lineHeight="16px" color="#FFF" />
          </div>
        }
      >
        <div className="flex items-center">
          <KButton
            icon="/left-sidebar.svg"
            width="32px"
            height="32px"
            background="transparent"
            shadowDisabled={true}
            padding="6px"
            onClick={() => {
              if (setOpenSidebar) {
                setOpenSidebar(!openSidebar)
              }
            }}
          />
        </div>
      </KTooltip>
      <div className="pt-0.5">
        {" "}
        <KTitleSpan text="Reports Approval" color="#111" fontSize={20} lineHeight="28px" />
      </div>
    </div>
  )

  return (
    <div className={"catalog flex bg-[#fff]"}>
      <div
        className={`${
          openSidebar ? "min-w-[230px]" : "min-w-0 !w-[0px]"
        } max-w-[320px] w-[20%] bg-[#F7F7F7] min-h-[100vh] flex flex-col overflow-hidden shrink-0`}
        style={{
          transitionDuration: "0.5s"
        }}
      >
        <div className="pl-6 pr-3 py-4 whitespace-nowrap">
          <KTitleSpan text={"Reports Approval"} lineHeight="28px" fontSize={20} color="#111" />
        </div>
        {selectedFilter &&
          analysisFilterOptions.map((analysisFilterOption, index: number) => {
            const isSelected = analysisFilterOption.value2 === selectedFilter
            return (
              <div className="py-3.5 px-4 h-11" key={`${index}`}>
                <div
                  className={`rounded-[10px] p-2 flex flex-row gap-2 ${
                    isSelected ? "bg-[#FFF]" : "transparent"
                  } cursor-pointer whitespace-nowrap`}
                  onClick={() => {
                    if (analysisFilterOption?.value2) {
                      selectFilter(analysisFilterOption?.value2)
                    }
                  }}
                >
                  <img src={analysisFilterOption.icon} width={16} height={16} className="shrink-0" />
                  <KSpan
                    text={analysisFilterOption.label}
                    fontSize={12}
                    lineHeight="16px"
                    fontWeight={500}
                    color="#111"
                  />
                </div>
              </div>
            )
          })}
      </div>
      <div className="grow flex justify-center">
        <div className="w-full">
          <TableNew
            title={tableTitle}
            topRightElement={topRightFilters}
            topFilters={topFilters}
            columns={columns}
            data={data}
            loading={loading}
            pagination
            limit={limit}
            onSortOrFilterChange={onSortOrFilterChange}
            count={fetchedSummaries[selectedFilter]?.length}
            onClickPage={onClickPage}
            additionalFilters={{
              date: selectedPeriod,
              admin_currency: selectedCurrency,
              ...(selectedUser?.value && { user__username: selectedUser.label })
            }}
            onSelectRow={onSelectRow}
            notFoundTitle={"No Reports"}
            notFoundDesc={"No reports found matching the applied filters."}
          />
        </div>
      </div>

      {editedSummary && (
        <ApproveModal
          open={modalOpen}
          onClose={() => setModalOpen(false)}
          summary={editedSummary}
          approveSummary={approveReportSummary}
        />
      )}
      <ApproveAllModal
        open={approveAllModalOpen}
        onClose={() => setApproveAllModalOpen(false)}
        approve={approveReport}
        description={
          selectedIds.length === fetchedSummaries[selectedFilter].length
            ? "Are you sure you want to approve all reports on the table?"
            : "Are you sure you want to approve the selected reports?"
        }
      />
      <ApprovePaymentModal
        selectedPeriod={selectedPeriod}
        userPaymentPeriodId={userPaymentPeriodId}
        selectedUsername={selectedUser?.label}
        open={approvePaymentModalOpen}
        onClose={() => setApprovePaymentModalOpen(false)}
        approve={approvePayment}
      />
      <CurrencyExchangeModal open={currencyOfferModal} onClose={() => setCurrencyOfferModal(false)} />
    </div>
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    selectedFilter: state.admin.selectedFilter,
    summaries: state.admin.summaries
  }
}

export default connect(mapStateToProps, { fetchApprovalSummaries, selectFilter, approveSummary })(ReportsApproval)
